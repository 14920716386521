import {ValueAlignment} from 'interfaces/meeting';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import TextCard from 'ui/@_components/card/text-card';
import {useCurrentCompany} from 'ui/@atoms/current-company';
import List from './@components/list';
import SelectedTabView from './@components/selected-tab-view';
import styles from './styles.module.scss';

interface ElementProps {
  values: ValueAlignment[];
}

const ValuesAlignment = ({values}: ElementProps) => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.components.meeting-details.components.values-alignment',
  });

  const [activeValuesIndex, setActiveVitalSignIndex] = useState<number>(0);
  const currentCompany = useCurrentCompany();

  if (!values?.length) return <></>;

  const onTabClick = (tabIndex: number) => {
    setActiveVitalSignIndex(tabIndex);
  };

  return (
    <TextCard>
      <TextCard.Header
        title={t('title', {
          companyName: currentCompany?.name,
        })}
      />
      <TextCard.Content>
        <div className={styles.container}>
          <List
            onTabClick={onTabClick}
            values={values}
            activeValuesIndex={activeValuesIndex}
          />

          <SelectedTabView value={values[activeValuesIndex]} />
        </div>
      </TextCard.Content>
    </TextCard>
  );
};

export default ValuesAlignment;
