import {createQuery} from 'react-query-kit';
import {ORDER_BY_PARAMETERS} from '../../../constants/filtering/order-by';
import {COMPANIES_SORTABLE_COLUMNS} from '../../../constants/filtering/sort-by';
import {Company} from '../../../interfaces/admin/company';
import {Meta} from '../../../interfaces/meta';
import {fetchCompanies} from '../../../repositories/instill/queries';
import getOffsetValue from '../../../utils/get-offset-value/get-offset-value';

interface FetchCompaniesPayload {
  currentPage: number;
  itemsPerPage: number;
  limit: number;
  offset: number;
  orderBy?: ORDER_BY_PARAMETERS;
  query?: string;
  sortBy?: COMPANIES_SORTABLE_COLUMNS;
}

interface FetchCompanies {
  data: Company[];
  meta: Meta;
}

/**
 * @deprecated
 */
export const useCompanies = createQuery<
  FetchCompanies | undefined,
  FetchCompaniesPayload
>({
  primaryKey: 'companies',
  queryFn: async ({queryKey: [_, variables]}) => {
    let payload = {
      offset: getOffsetValue({
        offset: variables.offset,
        currentPage: variables.currentPage,
        itemsPerPage: variables.itemsPerPage,
      }),
      limit: variables.limit,
    } as FetchCompaniesPayload;

    if (variables.sortBy) {
      payload['sortBy'] = variables.sortBy;
    }

    if (variables.orderBy) {
      payload['orderBy'] = variables.orderBy;
    }

    if (variables.query) {
      payload['query'] = variables.query;
    }

    return await fetchCompanies(payload);
  },
});
