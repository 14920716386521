import {createMutation} from 'react-query-kit';
import {
  JoinCompanyPayload,
  joinCompany,
} from '../../../repositories/instill/mutations/join-company';
import {Company} from '../../../interfaces/company';
import {useQueryClient} from '@tanstack/react-query';
import {RQ_FULL_USER_PROFILE} from '../queries/query-keys';

export const useJoinCompany = () => {
  const queryClient = useQueryClient();

  return createMutation<Company | undefined, JoinCompanyPayload>({
    mutationFn: async (variables) => {
      return await joinCompany(variables.inviteToken);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([RQ_FULL_USER_PROFILE]);
    },
  })();
};
