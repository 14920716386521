import {EventName} from 'constants/analytics/event-name';
import {EventType} from 'constants/analytics/event-type';
import {useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import {buildPageTitle} from 'services/build-page-title';
import ApplicationHeader from 'ui/@_components/header/header';
import Spinner from 'ui/@_components/spinner';
import useAnalytics from 'ui/@hooks/use-analytics';
import FlowSubheader from './@components/flow-subheader';
import MeetingDetails from './@components/meeting-details';
import useFlowData from './@hooks/use-flow-data';
import styles from './styles.module.scss';
import useDemoAccountDetails from 'ui/@hooks/use-demo-account-details';
import {useFullUserProfile} from 'ui/@hooks/queries';
import {useCurrentCompanyUuid} from 'ui/@atoms/current-company';
import {useUpdateFlowReportSeen} from 'ui/@hooks/mutations/use-update-flow-report-seen';

const MeetingsShow = () => {
  const {t} = useTranslation('applicationPageTitle');
  const {trackEvent} = useAnalytics();

  const {meeting, meetingIsLoading, isCompanySelected} = useFlowData();

  const {flowBranding} = useDemoAccountDetails();

  const companyUuid = useCurrentCompanyUuid();

  const hasSeenFirstMeetingReport = useFullUserProfile({
    variables: {
      companyUuid,
    },
    select(data) {
      return data?.fullOnboardingChecklist.hasSeenFirstMeetingReport;
    },
  });

  const updateFlowReportSeen = useUpdateFlowReportSeen();

  useEffect(() => {
    if (
      !hasSeenFirstMeetingReport.data &&
      !hasSeenFirstMeetingReport.isLoading &&
      updateFlowReportSeen.isIdle
    ) {
      updateFlowReportSeen.mutate({
        companyUuid,
      });
    }
  }, [
    hasSeenFirstMeetingReport.isLoading,
    hasSeenFirstMeetingReport.data,
    updateFlowReportSeen,
    companyUuid,
  ]);

  useEffect(() => {
    if (meeting?.name) {
      trackEvent({
        eventName: EventName.FLOW.REPORT.PAGE_VIEW,
        eventType: EventType.PAGE_VIEW,
        eventProperties: {
          name: meeting.name,
        },
      });
    }
  }, [meeting?.name, trackEvent]);

  if (meetingIsLoading) return <Spinner />;

  if (!meeting) return <></>;

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            meeting.name,
            t('page-title.sense.meetings.index', {ns: 'applicationPageTitle'}),
            t(flowBranding, {ns: 'applicationPageTitle'}),
          ])}
        </title>
      </Helmet>

      {isCompanySelected && <ApplicationHeader />}

      <div className={isCompanySelected ? '' : styles.unauthorizedWrapper}>
        <FlowSubheader />

        <MeetingDetails />
      </div>
    </>
  );
};

export default MeetingsShow;
