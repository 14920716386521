import {useAuth0} from '@auth0/auth0-react';

export const SIGNUP_PROVIDERS = {
  GOOGLE: 'google',
  LINKEDIN: 'linkedin',
  MICROSOFT: 'microsoft',
  GITHUB: 'github',
  FACEBOOK: 'facebook',
  AUTH: 'auth0',
  UNKNOWN: 'unknown',
} as const;

const PROVIDERS_SUB_PREFIX = {
  GOOGLE: 'google-oauth2|',
  LINKEDIN: 'linkedin|',
  MICROSOFT: 'windowslive|',
  GITHUB: 'github|',
  FACEBOOK: 'facebook|',
  AUTH: 'auth0|',
};

const useUserSignupProvider = () => {
  const {user} = useAuth0();

  if (!user?.sub) {
    return null;
  }

  if (user.sub.startsWith(PROVIDERS_SUB_PREFIX.GOOGLE)) {
    return SIGNUP_PROVIDERS.GOOGLE;
  }

  if (user.sub.startsWith(PROVIDERS_SUB_PREFIX.LINKEDIN)) {
    return SIGNUP_PROVIDERS.LINKEDIN;
  }

  if (user.sub.startsWith(PROVIDERS_SUB_PREFIX.MICROSOFT)) {
    return SIGNUP_PROVIDERS.MICROSOFT;
  }

  if (user.sub.startsWith(PROVIDERS_SUB_PREFIX.GITHUB)) {
    return SIGNUP_PROVIDERS.GITHUB;
  }

  if (user.sub.startsWith(PROVIDERS_SUB_PREFIX.FACEBOOK)) {
    return SIGNUP_PROVIDERS.FACEBOOK;
  }

  if (user.sub.startsWith(PROVIDERS_SUB_PREFIX.AUTH)) {
    return SIGNUP_PROVIDERS.AUTH;
  }

  return SIGNUP_PROVIDERS.UNKNOWN;
};

export default useUserSignupProvider;
