import {CultureVitalSign, ValueAlignment} from 'interfaces/meeting';
import styles from './styles.module.scss';

interface ElementProps {
  parameter: CultureVitalSign | ValueAlignment;
}

const ValuesVitalSignHelper = ({parameter}: ElementProps) => {
  const feedbackArray = parameter.feedback.split('. '); // this could be done at BE

  return (
    <div className={styles.container}>
      <div className={styles.feedBackContainer}>
        <div className={styles.title}>Feedback</div>

        <ul className={styles.list}>
          {feedbackArray.map((sentence, index) => (
            <li key={index} className={styles.listItem}>
              {sentence}
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.exampleContainer}>
        <div className={styles.title}>Examples</div>

        <ul className={styles.list}>
          {parameter.examples?.map((example, index) => (
            <li key={index} className={styles.listItem}>
              <span>{`"${example.quote}" -`}</span> {example.explanation}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ValuesVitalSignHelper;
