const meetingIdParam = 'meetingId';
const jobPositionIdParam = 'jobPositionId';
const candidateIdParam = 'candidateId';
const valueIdParam = 'valueId';
const inviteTokenParam = 'inviteToken';
const teamUuid = 'teamId';
const userUuid = 'userId';
const cultureVitalParam = 'cultureVitalSlug';
const cultureKpiUuidParam = 'cultureKpiUuid';
const assessmentPopulationSummaryParam = 'assessmentId';
const instillCoachActionStepUuidParam = 'actionStepUuid';
const demoAccountCompanySlugParam = 'companySlug';

export const routeParams = {
  meetingIdParam,
  jobPositionIdParam,
  candidateIdParam,
  valueIdParam,
  teamUuid,
  userUuid,
  inviteTokenParam,
  cultureVitalParam,
  assessmentPopulationSummaryParam,
  instillCoachActionStepUuidParam,
  demoAccountCompanySlugParam,
  cultureKpiUuidParam,
};
