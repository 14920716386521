import {sanitizeVisionMissionGetHelpFromRaw} from 'ui/@sanitizers/vision-mission-get-help';
import InstillAPI from '../axios-instance';

export interface VisionMissionGetHelpPayload {
  helpField:
    | 'company_vision'
    | 'company_mission'
    | 'company_leadership_manifesto';
  companyUuid: string;
}

export async function visionMissionGetHelp({
  helpField,
  companyUuid,
}: VisionMissionGetHelpPayload) {
  const response = await InstillAPI.post(
    `/companies/${companyUuid}/values/get_help/vision_mission/${helpField}`
  );

  return sanitizeVisionMissionGetHelpFromRaw(response.data);
}
