import {
  CompanyChecklist,
  CompanyChecklistRaw,
  UserChecklist,
  UserChecklistRaw,
} from '../../interfaces/checklist';

export const sanitizeCompanyChecklistFromRaw = (
  data: CompanyChecklistRaw
): CompanyChecklist => {
  return {
    codifiedValue: data.codified_value,
    integratedSlackApp: data.integrated_slack_app,
    invitedTeam: data.invited_team,
    signedOffOnValues: data.signed_off_on_values,
  };
};

export const sanitizeUserChecklistFromRaw = (
  data: UserChecklistRaw
): UserChecklist => {
  return {
    hasCreatedValue: data.has_created_value,
    hasAddedCompanyValues: data.has_added_company_values,
    hasConfiguredAssessment: data.has_configured_assessment,
    hasCreatedCultureKpi: data.has_created_culture_kpi,
    hasInvitedCandidates: data.has_invited_candidates,
    hasInvitedFirstUser: data.has_invited_first_user,
    hasSeenFirstMeetingReport: data.has_seen_first_meeting_report,
    hasSharedFirstMeetingReport: data.has_shared_first_meeting_report,
    hasSyncedCalendar: data.has_synced_calendar,
    hasTakenPersonalityTest: data.has_taken_personality_test,
    hasGeneratedMeetingReport: data.has_generated_meeting_report,
    hasSelectedBusinessOutcomes: data.has_selected_business_outcomes,
    hasCompletedProfile: data.has_completed_profile,
    hasSignedOff: data.has_signed_off,
    hasCreatedProfile: data.has_created_profile,
    hasAddedFirstMeeting: data.has_added_first_meeting,
    skippedUserInvite: data.skipped_user_invite,
    hasAnyPersonalActionStep: data.has_any_personal_action_step,
  };
};
