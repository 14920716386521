import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';
import {useCompany} from 'ui/@hooks/queries';
import styles from './styles.module.scss';
import {ReactComponent as Plus2Icon} from 'ui/@_components/kit/icons/plus2.svg';
import IconButton from 'ui/@_components/icon-button';
import CompanyLogoModal from 'ui/@module-components/modals/company-logo-modal';
import useDisclosure from 'ui/@hooks/use-disclosure';
import {useHasPermission} from 'ui/@_components/has-permission';
import {Actions} from 'constants/permissions/actions';

const HeaderLogo = () => {
  const companyUuid = useSafeCurrentCompanyUuid();

  const currentCompany = useCompany({
    variables: {
      companyUuid,
    },
  });

  const {isOpen, onClose, onOpen} = useDisclosure();

  const hasPermission = useHasPermission();

  if (currentCompany.isLoading) {
    return null;
  }

  if (currentCompany.data?.companyLogo) {
    return (
      <img
        src={currentCompany.data?.companyLogo}
        className={styles.icon}
        alt="logo"
        height={40}
        width={40}
      />
    );
  }

  if (!hasPermission(Actions.EDIT_COMPANY)) {
    return null;
  }

  return (
    <>
      <IconButton onClick={onOpen}>
        <Plus2Icon />
      </IconButton>

      <CompanyLogoModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default HeaderLogo;
