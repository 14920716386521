import {EventName} from 'constants/analytics/event-name';
import {EventType} from 'constants/analytics/event-type';
import {routeNames} from 'constants/route-names';
import {FunctionComponent, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {connectCalendarOAuth} from 'repositories/instill';
import {ReactComponent as GCalendarSuccess} from 'ui/@_components/kit/icons/g-calendar-success.svg';
import {ReactComponent as GCalendar} from 'ui/@_components/kit/icons/google-calendar.svg';
import TwoPanePage from 'ui/@_components/two-pane-page';
import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';
import useAnalytics from 'ui/@hooks/use-analytics';
import {StringParam, useQueryParam} from 'use-query-params';
import {wait} from 'utils/wait/wait';
import styles from '../styles.module.scss';

const GoogleCalendarCallback: FunctionComponent = () => {
  const {applicationRoute} = routeNames;

  const {t} = useTranslation('oauthCallback', {
    keyPrefix: 'google-calendar',
  });

  const location = useLocation();

  const companyUuid = useSafeCurrentCompanyUuid();

  const redirectRoute = `/${applicationRoute}`;

  const {trackEvent} = useAnalytics();

  const [code] = useQueryParam('code', StringParam);
  const [error] = useQueryParam('error', StringParam);

  useEffect(() => {
    if (!!error) {
      wait(3000).then(() => {
        window.location.href = redirectRoute;
      });

      return;
    }

    if (code) {
      connectCalendarOAuth(
        {
          authorization_code: code,
          platform: 'google-calendar',
          company_uuid: companyUuid,
        },
        companyUuid
      )
        .then(() => {
          trackEvent({
            eventName: EventName.GETTING_STARTED.CALENDAR_SYNCED,
            eventType: EventType.BUTTON_CLICKED,
            eventProperties: {
              calendar: 'Google',
            },
          });
        })
        .finally(() =>
          wait(3000).then(() => {
            window.location.href = redirectRoute;
          })
        );
    }
  }, [location, companyUuid, code, error, redirectRoute, trackEvent]);

  return (
    <TwoPanePage>
      <div className={styles.container}>
        {!!error ? <GCalendar /> : <GCalendarSuccess />}

        <p className={styles.title}>
          {!!error ? t('failed-title') : t('title')}

          <span>{t('vendor')}</span>
        </p>

        <br />

        {t('redirecting')}
      </div>
    </TwoPanePage>
  );
};

export default GoogleCalendarCallback;
