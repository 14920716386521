import {useNavigate} from 'react-router';
import {ReactComponent as IconChevronRight} from '.././kit/icons/chevron-left.svg';
import Button from '../button';
import styles from './styles.module.scss';

interface ElementProps {
  route?: string;
  title: string;
}
const BackButton = ({route, title}: ElementProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (route) {
      navigate(route);

      return;
    }

    navigate(-1);
  };

  return (
    <Button className={styles.button} onClick={handleClick}>
      <IconChevronRight className={styles.icon} />
      {title}
    </Button>
  );
};

export default BackButton;
