import styles from './styles.module.scss';

interface ElementProps {
  icon: string;
  text: string;
}

const ListItem = ({icon, text}: ElementProps) => {
  return (
    <div className={styles.item}>
      <div>{icon}</div>
      <div>{text}</div>
    </div>
  );
};

export default ListItem;
