import {CSSProperties, ReactNode} from 'react';
import styles from './styles.module.scss';

interface ElementProps {
  children?: ReactNode;
  size?: 'large' | 'small' | 'xLarge' | 'medium';
  containerStyles?: CSSProperties;
}

const CardCurvedDesign = ({
  children,
  size = 'large',
  containerStyles,
}: ElementProps) => {
  const innerCurveClassName = `${styles[size]} ${styles.curvedInnerContainer}`;

  return (
    <div className={styles.curvedContainer} style={containerStyles}>
      <div className={innerCurveClassName}>{children}</div>
    </div>
  );
};

export default CardCurvedDesign;
