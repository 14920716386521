import {
  FullUserProfile,
  FullUserProfileRaw,
  PersonalizeUserProfile,
  PersonalizeUserProfileRaw,
  Recruit,
  RecruitRaw,
  RelatedUserProfile,
  RelatedUserProfileRaw,
  UserProfile,
  UserProfileRaw,
  UserProfileTeam,
  UserProfileTeamRaw,
  UserProfileUpdate,
  UserProfileUpdateRaw,
} from '../../interfaces/user-profile';
import {sanitizeUserChecklistFromRaw} from './checklist';
import {sanitizePersonalityProfileFromRaw} from './personality-profile';
import {sanitizeUserSettingsFromRaw} from './settings';
import {sanitizeWorkStylePreferencesFromRaw} from './work-style-preferences';

export const sanitizeRelatedUserProfileFromRaw = (
  data: RelatedUserProfileRaw
): RelatedUserProfile => {
  return {
    uuid: data.uuid,
    name: data.name,
    workEmail: data.work_email,
    picture: data.picture || null,
    jobTitle: data.job_title,
    status: data.status,
  };
};

export const sanitizeUserProfilesFromRaw = (
  data: UserProfileRaw[]
): UserProfile[] => {
  return data.map((userProfile) => sanitizeUserProfileFromRaw(userProfile));
};

export const sanitizeUserProfileFromRaw = (
  data: UserProfileRaw
): UserProfile => {
  return {
    companyUuid: data.company_uuid,
    connectedCalendars: data.connected_calendars,
    fullOnboardingChecklist: sanitizeUserChecklistFromRaw(
      data.full_onboarding_checklist
    ),
    isOnboarded: data.is_onboarded,
    funFact: data.fun_fact,
    jobTitle: data.job_title,
    kudosRemaining: data.kudos_remaining,
    kudosGivenCount: data.kudos_given_count,
    kudosReceivedCount: data.kudos_received_count,
    linkedinUrl: data.linkedin_url,
    location: data.location,
    name: data.name,
    personalEmail: data.personal_email,
    phoneNumber: data.phone_number,
    picture: data.signed_picture_url,
    preferredName: data.preferred_name,
    pronouns: data.pronouns,
    role: data.role,
    shortBio: data.short_bio,
    status: data.status,
    timeZone: data.time_zone,
    uuid: data.uuid,
    workEmail: data.work_email,
    percentageComplete: data.percentage_complete,
  };
};

export const sanitizeFullUserProfileFromRaw = (
  data: FullUserProfileRaw
): FullUserProfile => {
  const userProfile = sanitizeUserProfileFromRaw(data);

  return {
    ...userProfile,
    team: data.team ? sanitizeUserProfileTeamFromRaw(data.team) : null,
    manager: data.manager
      ? sanitizeRelatedUserProfileFromRaw(data.manager)
      : null,
    recruits: data.recruits ? data.recruits.map(sanitizeRecruitFromRaw) : [],
    personalityProfile: data.personality_profile
      ? sanitizePersonalityProfileFromRaw(data.personality_profile)
      : null,
    reporters: data.reporters
      ? data.reporters.map((report) =>
          sanitizeRelatedUserProfileFromRaw(report)
        )
      : [],
    workStylePreferences: data.work_style_preferences
      ? sanitizeWorkStylePreferencesFromRaw(data.work_style_preferences)
      : null,
    settings: data.settings ? sanitizeUserSettingsFromRaw(data.settings) : null,
  };
};

export const sanitizeUserProfileUpdateToRaw = (
  data: UserProfileUpdate
): UserProfileUpdateRaw => {
  return {
    work_email: data.workEmail,
    job_title: data.jobTitle,
    time_zone: data.timeZone,
    ...(data.name && {name: data.name}),
    ...(data.preferredName && {preferred_name: data.preferredName}),
    ...(data.personalEmail && {personal_email: data.personalEmail}),
    ...(data.phoneNumber && {phone_number: data.phoneNumber}),
    ...(data.picture && {picture: data.picture}),
    ...(data.shortBio && {short_bio: data.shortBio}),
    ...(data.pronouns && {pronouns: data.pronouns}),
    ...(data.location && {location: data.location}),
    ...(data.linkedinUrl && {linkedin_url: data.linkedinUrl}),
    ...(data.funFact && {fun_fact: data.funFact}),
  };
};

export const sanitizePersonalizeUserProfileToRaw = (
  data: PersonalizeUserProfile
): PersonalizeUserProfileRaw => {
  const payload = {} as PersonalizeUserProfileRaw;

  if (data.shortBio) {
    payload['short_bio'] = data.shortBio;
  }

  if (data.pronouns) {
    payload['pronouns'] = data.pronouns;
  }

  if (data.location) {
    payload['location'] = data.location;
  }

  if (data.linkedinUrl) {
    payload['linkedin_url'] = data.linkedinUrl;
  }

  if (data.funFact) {
    payload['fun_fact'] = data.funFact;
  }

  return payload;
};

export const sanitizeRecruitFromRaw = (data: RecruitRaw): Recruit => {
  return {
    jobTitle: data.job_title,
    uuid: data.uuid,
  };
};

export const sanitizeUserProfileTeamFromRaw = (
  data: UserProfileTeamRaw
): UserProfileTeam => {
  return {
    uuid: data.uuid,
    teamSize: data.team_size,
    name: data.name,
    description: data.description,
    colorHex: data.color_hex,
    teamManagers: data.team_managers.map((teamManager) => ({
      uuid: teamManager.uuid,
      name: teamManager.name,
    })),
  };
};
