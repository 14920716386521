import {createQuery} from 'react-query-kit';
import {UserAssessments} from '../../../interfaces/user-assessment';
import {fetchUserAssessments} from '../../../repositories/instill/queries';
import {FetchUserAssessmentsPayload} from '../../../repositories/instill/queries/fetch-user-assessments';
import {RQ_USER_ASSESSMENTS} from './query-keys';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {dummyUserAssessments} from 'ui/@demo-data/user-assessment';
import {QueryHookOptionsInt} from 'react-query-kit';
import useHasPlanAccess from '../use-has-plan-access';
import {Features} from 'constants/plan-access/features';

export const useUserAssessments = <T = UserAssessments | undefined>(
  params: QueryHookOptionsInt<
    UserAssessments | undefined,
    Error,
    T,
    FetchUserAssessmentsPayload
  >
) => {
  const {hasPlanAccess} = useHasPlanAccess();

  return createQuery<UserAssessments | undefined, FetchUserAssessmentsPayload>({
    primaryKey: RQ_USER_ASSESSMENTS,
    queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
      if (!hasPlanAccess(Features.ASSESSMENTS_POLLS)) {
        return [];
      }

      return await fetchUserAssessments(variables.userProfileUuid);
    }, dummyUserAssessments),
    enabled: (data, variables) => {
      return !!variables.userProfileUuid;
    },
  })(params);
};
