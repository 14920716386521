import {ValueAlignment} from 'interfaces/meeting';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {getScoreColor} from '../../../utils';
import styles from './styles.module.scss';

interface ElementProps {
  activeValuesIndex: number;
  values: ValueAlignment[];
  onTabClick: (tabIndex: number) => void;
}

const getActiveClass = (score: number) => {
  if (score < 50) {
    return styles['scoreLow'];
  }
  return styles['scoreHigh'];
};

const List: FunctionComponent<ElementProps> = ({
  values,
  onTabClick,
  activeValuesIndex,
}) => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.components.meeting-details.components.values-alignment.components.list',
  });

  const isTabActive = (index: number) => {
    return activeValuesIndex === index;
  };

  const listItemClassName = (value: ValueAlignment, index: number) =>
    `${styles.listItem} ${
      isTabActive(index) ? getActiveClass(value.score ?? -1) : ''
    }`;

  return (
    <ul className={styles.list}>
      {values.map((value, index) => (
        <li
          key={index}
          className={listItemClassName(value, index)}
          onClick={() => onTabClick(index)}
        >
          <div className={styles.nameIndicator}>
            <div
              className={styles.indicator}
              style={{
                backgroundColor: getScoreColor(value?.score ?? -1),
              }}
            />
            {value.name}
          </div>
          <span className={styles.score}>
            {t('score-label', {
              score: value.score,
            })}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default List;
