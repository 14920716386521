import {KeyboardEventHandler, useEffect, useMemo, useState} from 'react';
import {ENTER_KEY} from '../../constants/keyboard-constants';
import {useDebounce} from 'react-use';

interface UseSearchInputProps {
  value?: string;
  onChange: (value: string) => void;
  debouncedSearch?: boolean;
  debounceTimeout?: number;
}

const useSearchInput = ({
  value,
  onChange,
  debouncedSearch = true,
  debounceTimeout = 500,
}: UseSearchInputProps) => {
  const [searchText, setSearchText] = useState('');

  useDebounce(
    () => {
      if (!debouncedSearch) return;

      onChange(searchText);
    },
    debounceTimeout,
    [searchText, debouncedSearch]
  );

  useEffect(() => {
    setSearchText(value ?? '');
  }, [value]);

  // ? on enter key press search
  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === ENTER_KEY) {
      onChange(searchText);
    }
  };

  // ? In case we want to search on icon click
  const handleIconClick = () => {
    if (value) {
      if (value === searchText) {
        setSearchText('');
        onChange('');
        return;
      }
    }
    onChange(searchText);
  };

  // ? On input change
  const onInputChange = (newValue: string) => {
    setSearchText(newValue);
  };

  // ? In case we want clear and search icons
  const iconType = useMemo(() => {
    if (value) {
      if (value === searchText) {
        return 'clear';
      }
      return 'search';
    }
    return 'search';
  }, [value, searchText]);

  return {
    searchText,
    onInputChange,
    handleKeyDown,
    handleIconClick,
    iconType,
  };
};

export default useSearchInput;
