import {sanitizeMeetingFromRaw} from '../../../ui/@sanitizers/meeting';
import isResponseSuccessful from '../../../utils/is-response-successful';
import InstillAPI from '../axios-instance';

export interface FetchMeetingPayload {
  companyUuid?: string;
  meetingUuid?: string;
}

export async function fetchMeeting(parameters: FetchMeetingPayload) {
  const {companyUuid, meetingUuid} = parameters;

  const url = companyUuid
    ? `/companies/${companyUuid}/meetings/${meetingUuid}`
    : `/public/companies/meetings/${meetingUuid}`;

  try {
    const response = await InstillAPI.get(url);

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeMeetingFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
