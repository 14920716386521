import {createQuery} from 'react-query-kit';
import {
  DefinedBusinessOutcomesPayload,
  fetchDefinedBusinessOutcomes,
} from 'repositories/instill/queries/fetch-defined-business-outcomes';
import {dummyPreDefinedBusinessOutComes} from 'ui/@demo-data/business-outcomes';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {RQ_DEFINED_BUSINESS_OUTCOMES} from './query-keys';

export const useDefinedBusinessOutcomes = createQuery<
  string[] | undefined,
  DefinedBusinessOutcomesPayload
>({
  primaryKey: RQ_DEFINED_BUSINESS_OUTCOMES,
  queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
    return await fetchDefinedBusinessOutcomes(variables.companyUuid);
  }, dummyPreDefinedBusinessOutComes),
  enabled: (data, variables) => {
    return !!variables?.companyUuid;
  },
});
