import {createQuery} from 'react-query-kit';
import {CoreValue} from '../../../interfaces/core-value';
import {fetchCoreValues} from '../../../repositories/instill';
import {sortByName} from '../../../utils/sort-by/name';

/**
 * @deprecated Unused
 */
export const useCoreValues = createQuery<CoreValue[] | undefined, null>({
  primaryKey: 'coreValues',
  queryFn: async ({queryKey: [_, variables]}) => {
    const response = await fetchCoreValues();
    if (!response) return;

    return sortByName(response);
  },
});
