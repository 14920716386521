import Button from 'ui/@_components/button';
import {ReactComponent as ZeroStateFaces2} from 'ui/@_components/kit/icons/zero-state-faces2.svg';
import styles from './styles.module.scss';
import {useTranslation} from 'react-i18next';

const MainContent = () => {
  const {t} = useTranslation('pageNotFound', {
    keyPrefix: 'components.main-content',
  });

  return (
    <div className={styles.errorContentWrapper}>
      <h1 className={styles.errorStatus}>{t('404')}</h1>
      <h2 className={styles.title}>{t('title')}</h2>
      <h3 className={styles.errorText}>{t('subtitle')}</h3>
      <Button>{t('cta-text')}</Button>
      <ZeroStateFaces2 className={styles.errorIcon} />
    </div>
  );
};

export default MainContent;
