import {Meeting} from 'interfaces/meeting';
import {Transcript} from 'interfaces/transcript';
import AudioTranscriptPlayer from 'ui/@_components/audio-transcript-player';
import {useCurrentAuthUser} from 'ui/@atoms/current-auth-user';
import CultureVitals from './@component/culture-vitals';
import MeetingFeedback from './@component/meeting-feedback';
import MeetingSummary from './@component/meeting-summary';
import Participants from './@component/participants';
import ProcessingData from './@component/processing-data';
import ToneAnalysis from './@component/tone-analysis';
import TopicCloud from './@component/topic-cloud';
import ValuesAlignment from './@component/values-alignment';
import styles from './styles.module.scss';

interface ElementProps {
  meeting: Meeting;
  transcript: Transcript[];
}

const MeetingDetails = ({meeting, transcript}: ElementProps) => {
  const currentUser = useCurrentAuthUser();

  if (meeting.summary.summary.length === 0) {
    return <ProcessingData />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div className={styles.row}>
          <div className={styles.rowColumn}>
            <ToneAnalysis analysis={meeting.sentimentalAnalysis} />
          </div>

          <div className={styles.rowColumn}>
            <Participants participants={meeting.participantsNames} />
          </div>
        </div>

        <MeetingSummary summary={meeting.summary.summary} />

        <CultureVitals cultureVitalSigns={meeting.cultureVitalSigns} />

        <ValuesAlignment values={meeting.valuesAlignment} />

        <div className={styles.topicCloudContainer}>
          <TopicCloud topics={meeting.summary.topics.slice(0, 20)} />
        </div>

        {!!currentUser && <MeetingFeedback meeting={meeting} />}
      </div>

      <div className={styles.transcriptColumn}>
        <AudioTranscriptPlayer transcript={transcript} meeting={meeting} />
      </div>
    </div>
  );
};

export default MeetingDetails;
