import {createQuery} from 'react-query-kit';
import {FetchJobPositionPayload} from 'repositories/instill/queries/fetch-job-position';
import {JobPosition} from '../../../interfaces/job-position';
import {fetchJobPosition} from '../../../repositories/instill/queries';
import {RQ_JOB_POSITION} from './query-keys';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {dummyJobPosition} from 'ui/@demo-data/job-position';

interface FetchJobPositionResponsePayload {
  data: JobPosition;
}

export const useJobPosition = createQuery<
  FetchJobPositionResponsePayload | undefined,
  FetchJobPositionPayload
>({
  primaryKey: RQ_JOB_POSITION,
  queryFn: queryDemoWrapper(
    async ({queryKey: [_, variables]}) => {
      return await fetchJobPosition({
        companyUuid: variables.companyUuid,
        jobPositionUuid: variables.jobPositionUuid,
      });
    },
    {
      data: dummyJobPosition,
    }
  ),
  enabled: (data, variables) => {
    return !!variables.companyUuid && !!variables.jobPositionUuid;
  },
  staleTime: 5 * 60 * 1000,
  cacheTime: 5 * 60 * 1000,
});
