import HorizontalProgressBar from 'ui/@_components/progress-bar/horizontal/horizontal';
import Spinner from 'ui/@_components/spinner';
import useDeterminateProgressBar from 'ui/@hooks/use-determinate-progress-bar';
import humanFileSize from 'utils/files/human-file-size';
import styles from './styles.module.scss';

interface ElementProps {
  fileName: string;
  fileSize: number;
}

const UploadingInfo = ({fileName, fileSize}: ElementProps) => {
  const percentage = useDeterminateProgressBar();

  return (
    <div className={styles.uploadingInfo}>
      <Spinner variant="small" />
      <div className={styles.uploadBarContainer}>
        <div className={styles.fileDetailsContainer}>
          <div className={styles.fileDetails}>
            <p className={styles.fileName}>{fileName}</p>
            <p className={styles.fileSize}>({humanFileSize(fileSize)})</p>
          </div>
          <p className={styles.percent}>{percentage}%</p>
        </div>
        <HorizontalProgressBar percentage={percentage} variant="gradient" />
      </div>
    </div>
  );
};

export default UploadingInfo;
