import {createMutation} from 'react-query-kit';
import {upsertCandidateAssessmentResponse} from '../../../repositories/instill';
import {UpsertCandidateAssessmentResponsePayload} from '../../../repositories/instill/mutations/upsert-candidate-assessment-response';
import {useQueryClient} from '@tanstack/react-query';
import {RQ_CANDIDATE_ASSESSMENT} from '../queries/query-keys';

export const useUpsertCandidateAssessmentResponse = () => {
  const queryClient = useQueryClient();

  return createMutation<
    {value: string} | undefined,
    UpsertCandidateAssessmentResponsePayload
  >({
    mutationFn: async (variables) => {
      return await upsertCandidateAssessmentResponse(
        {value: variables.value},
        variables.candidateToken,
        variables.candidateUuid,
        variables.userAssessmentUuid,
        variables.moduleUuid,
        variables.questionUuid
      );
    },
    onSuccess() {
      queryClient.invalidateQueries([RQ_CANDIDATE_ASSESSMENT]);
    },
  })();
};
