import {createMutation} from 'react-query-kit';
import {
  updateFlowReportSeen,
  UpdateFlowReportSeenPayload,
} from 'repositories/instill/mutations/update-flow-report-seen';
import {useSyncSetFullUserProfile} from '../queries';

export const useUpdateFlowReportSeen = () => {
  const syncSetFullUserProfile = useSyncSetFullUserProfile();

  return createMutation<void, UpdateFlowReportSeenPayload>({
    mutationFn: async (variables) => {
      return await updateFlowReportSeen({
        companyUuid: variables.companyUuid,
      });
    },
    onSuccess: (_, variables) => {
      syncSetFullUserProfile(
        {
          companyUuid: variables.companyUuid,
        },
        (prev) =>
          prev
            ? {
                ...(prev || {}),
                fullOnboardingChecklist: {
                  ...prev.fullOnboardingChecklist,
                  hasSeenFirstMeetingReport: true,
                },
              }
            : prev
      );
    },
  })();
};
