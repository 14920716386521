import {routeNames} from 'constants/route-names';
import {Link} from 'react-router-dom';
import AvatarPlaceholder from 'ui/@_components/avatar-placeholder';
import Tooltip from '../tooltip';
import styles from './styles.module.scss';

const mapSizes = {
  xSmall: 18,
  small: 40,
  normal: 48,
  large: 120,
  xLarge: 160,
  xxLarge: 220,
} as const;

interface ElementProps {
  userUuid?: string;
  userName: string;
  profilePic: string | null;
  size?: 'xSmall' | 'small' | 'normal' | 'large' | 'xLarge' | 'xxLarge';
  withNameTooltip?: boolean;
  asLink?: boolean;
  useRandomColors?: boolean;
}

const UserProfileAvatar = ({
  userName,
  profilePic,
  size = 'normal',
  withNameTooltip,
  userUuid,
  asLink,
  useRandomColors,
}: ElementProps) => {
  const {applicationRoute, peopleRoute, peopleTeamsRoute} = routeNames;

  const content = profilePic ? (
    <div className={`${styles.imageContainer} ${styles[size]}`}>
      <img
        src={profilePic}
        height={mapSizes[size]}
        width={mapSizes[size]}
        alt="profile"
      />
    </div>
  ) : (
    <AvatarPlaceholder
      useRandomColors={useRandomColors}
      size={size}
      variant="blue"
      name={userName}
    />
  );

  if (asLink && userUuid) {
    return (
      <Link
        className={styles.asLink}
        to={`/${applicationRoute}/${peopleTeamsRoute}/${peopleRoute}/${userUuid}`}
      >
        {content}
      </Link>
    );
  }

  if (withNameTooltip) {
    const tooltipContent = userUuid ? (
      <Link
        className={styles.tooltipLink}
        to={`/${applicationRoute}/${peopleTeamsRoute}/${peopleRoute}/${userUuid}`}
      >
        {userName}
      </Link>
    ) : (
      userName
    );

    return <Tooltip content={tooltipContent}>{content}</Tooltip>;
  }

  return content;
};

export default UserProfileAvatar;
