import {useQueryClient} from '@tanstack/react-query';
import {createMutation} from 'react-query-kit';
import {CompanyAssessment} from '../../../interfaces/company-assessments';
import {updateCompanyAssessment} from '../../../repositories/instill/mutations';
import {UpdateCompanyAssessmentPayload} from '../../../repositories/instill/mutations/update-company-assessment';
import {useSyncSetFullUserProfile} from '../queries';
import {
  RQ_COMPANY_ASSESSMENTS,
  RQ_USER_ASSESSMENTS,
} from '../queries/query-keys';

export const useUpdateCompanyAssessment = () => {
  const queryClient = useQueryClient();
  const syncSetFullUserProfile = useSyncSetFullUserProfile();

  return createMutation<
    CompanyAssessment | undefined,
    UpdateCompanyAssessmentPayload
  >({
    mutationFn: async (variables) => {
      return await updateCompanyAssessment(
        variables.companyUuid,
        variables.assessmentUuid,
        variables.payload
      );
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([RQ_COMPANY_ASSESSMENTS]);
      queryClient.invalidateQueries([RQ_USER_ASSESSMENTS]);
      syncSetFullUserProfile(
        {
          companyUuid: variables.companyUuid,
        },
        (prev) =>
          prev
            ? {
                ...(prev || {}),
                fullOnboardingChecklist: {
                  ...prev.fullOnboardingChecklist,
                  hasConfiguredAssessment: true,
                },
              }
            : prev
      );
    },
  })();
};
