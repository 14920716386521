import {ORDER_BY_PARAMETERS} from 'constants/filtering/order-by';
import {sanitizeMetaFromRaw} from 'ui/@sanitizers/meta';
import {sanitizeUserProfilesFromRaw} from 'ui/@sanitizers/user-profile';
import isResponseSuccessful from 'utils/is-response-successful';
import addUrlQueriesConditionally from 'utils/url-utils/add-url-queries-conditionally';
import InstillAPI from '../axios-instance';

export interface FetchEligibleManagersPayload {
  companyUuid: string;
  teamUuid?: string;
  limit?: number;
  offset?: number;
  orderBy?: ORDER_BY_PARAMETERS;
  sortBy?: string;
  query?: string;
  includeInvitedUsers?: boolean;
}

export const fetchEligibleManagers = async (
  params: FetchEligibleManagersPayload
) => {
  const {
    companyUuid,
    teamUuid,
    limit,
    offset,
    orderBy,
    sortBy,
    includeInvitedUsers,
    query,
  } = params;

  const url = addUrlQueriesConditionally(
    `/companies/${companyUuid}/teams/eligible-managers`,
    [
      {
        key: 'q',
        condition: !!query,
        value: query,
      },
      {
        key: 'limit',
        condition: limit !== undefined,
        value: limit,
      },
      {
        key: 'sort_by',
        condition: !!sortBy,
        value: sortBy,
      },
      {
        key: 'order',
        condition: !!orderBy,
        value: orderBy,
      },
      {
        key: 'skip',
        condition: offset !== undefined,
        value: offset,
      },
      {
        key: 'team_uuid',
        condition: teamUuid !== undefined,
        value: teamUuid,
      },
      {
        key: 'include_invited_users',
        condition: includeInvitedUsers === true,
        value: includeInvitedUsers,
      },
    ]
  );

  try {
    const response = await InstillAPI.get(url);

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return {
      data: sanitizeUserProfilesFromRaw(response.data.data),
      meta: sanitizeMetaFromRaw(response.data.meta),
    };
  } catch (error) {
    console.error(error);
  }
};
