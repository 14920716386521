import {createMutation} from 'react-query-kit';
import {
  CreateSignaturePayload,
  createSignature,
} from '../../../repositories/instill/mutations/create-signature';
import {Signature} from '../../../interfaces/signature';
import {useSyncSetFullUserProfile} from '../queries';

export const useCreateSignature = () => {
  const syncSetFullUserProfile = useSyncSetFullUserProfile();

  return createMutation<Signature | undefined, CreateSignaturePayload>({
    mutationFn: async (variables) => {
      return await createSignature(
        variables.signaturePayload,
        variables.companyUuid
      );
    },
    onSuccess: (_, variables) => {
      syncSetFullUserProfile(
        {
          companyUuid: variables.companyUuid,
        },
        (prev) =>
          prev
            ? {
                ...(prev || {}),
                fullOnboardingChecklist: {
                  ...prev.fullOnboardingChecklist,
                  hasSignedOff: true,
                },
              }
            : prev
      );
    },
  })();
};
