import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface deleteJobPositionPayload {
  companyUuid: string;
  jobPositionUuid: string;
}

export async function deleteJobPosition(parameters: deleteJobPositionPayload) {
  const {companyUuid, jobPositionUuid} = parameters;

  const response = await InstillAPI.delete(
    `/companies/${companyUuid}/job-positions/${jobPositionUuid}`
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return response.data;
}
