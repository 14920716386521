import {Roles} from 'constants/roles';
import {USER_ASSESSMENT_STATUS} from 'constants/user-assessment-status';
import {
  AssessmentStatusByVitalSign,
  AssessmentUserProfileSummary,
} from 'interfaces/assessment';
import {Meta} from 'interfaces/meta';

export const dummyAssessmentStatusByVitalSign: AssessmentStatusByVitalSign = {
  status: USER_ASSESSMENT_STATUS.NOT_STARTED,
};

export const dummyAssessmentUserProfile: {
  data: AssessmentUserProfileSummary[];
  meta: Meta;
} = {
  data: [
    {
      uuid: '1fa3dc04-7e71-4662-89df-2fb65db8be1b',
      lastResponseAt: '2024-03-21T12:31:13.574867+00:00',
      assessmentEventId: 482,
      userProfileId: 821,
      userProfile: {
        uuid: 'd9091ffb-acd0-452a-ae51-972cd7aafafc',
        companyUuid: 'ceef91db-d30f-4f90-a278-54acfdc1edc8',
        role: Roles.ADMIN,
        name: 'mathura+admin200',
        jobTitle: null,
        team: {
          uuid: '31cd712b-0db1-4091-b41a-1ed2e5e0a3f9',
          name: 'Instill Local',
          colorHex: '#fefefe',
        },
        picture: null,
      },
      status: USER_ASSESSMENT_STATUS.COMPLETED,
    },
  ],
  meta: {
    currentPage: 1,
    totalEntries: 1,
    totalPages: 1,
  },
};
