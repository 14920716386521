import {
  PersonalityProfile,
  PersonalityProfileRaw,
  PersonalityScore,
  PersonalityScoreRaw,
  PersonaContent,
  PersonaContentRaw,
  PersonalValues,
  PersonalValuesRaw,
} from '../../interfaces/personality-profile';

export const sanitizePersonalityProfileFromRaw = (
  data: PersonalityProfileRaw
): PersonalityProfile => {
  return {
    ...sanitizePersonalityScoreFromRaw(data),
    persona: data.persona,
    personaContent: sanitizePersonaContentFromRaw(
      data.persona_content,
      data.persona
    ),
    personalValues: sanitizePersonalValuesFromRaw(data.personal_values),
  };
};

export const sanitizePersonalityScoreFromRaw = (
  data: PersonalityScoreRaw
): PersonalityScore => {
  return {
    agreeableness: data.agreeableness,
    conscientiousness: data.conscientiousness,
    emotionality: data.emotionality,
    extroversion: data.extroversion,
    honesty: data.honesty,
    openness: data.openness,
  };
};

export const sanitizePersonaContentFromRaw = (
  data: PersonaContentRaw,
  personaName: string
): PersonaContent => {
  return {
    name: personaName,
    deepDive: {
      content: data.deep_dive.content,
      headline: data.deep_dive.headline,
    },
    wellFunctioningGroup: {
      content: data.when_in_a_well_functioning_group,
    },
    outOfSync: {
      content: data.when_out_of_sync,
    },
    description: data.description,
    summary: data.summary,
    traits: data.traits,
  };
};

export const sanitizePersonalValuesFromRaw = (
  data: PersonalValuesRaw
): PersonalValues => {
  return {
    selfDirection: data.self_direction,
    stimulation: data.stimulation,
    hedonism: data.hedonism,
    achievement: data.achievement,
    power: data.power,
    security: data.security,
    conformity: data.conformity,
    tradition: data.tradition,
    benevolence: data.benevolence,
    universalism: data.universalism,
    userPersonalValues: data.user_personal_values,
  };
};
