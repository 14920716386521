import {Actions} from 'constants/permissions/actions';
import HasPermission from '../has-permission';
import HeaderInviteUsers from './@components/header-invite-users';
import HeaderLogo from './@components/header-logo';
import HeaderProfile from './@components/header-profile';
import styles from './styles.module.scss';

const ApplicationHeader = () => {
  return (
    <div className={styles.headerStyles}>
      <div className={styles.leftContainer}>
        <div className={styles.logoContainer}>
          <HeaderLogo />
        </div>

        <HasPermission action={Actions.ADD_EMPLOYEE}>
          <HeaderInviteUsers />
        </HasPermission>
      </div>

      <div className={styles.actionContainer}>
        <HeaderProfile />
      </div>
    </div>
  );
};

export default ApplicationHeader;
