import {DemoAccountType, demoAccountType} from 'constants/demo-account';
import {CompanyCoreValue} from 'interfaces/company-core-value';

export const dummyCompanyCoreValues: () => CompanyCoreValue[] = () => {
  if (demoAccountType === DemoAccountType.CULTURE_FORCE) {
    return [
      {
        name: 'Fun',
        description:
          'Let’s face it, we love what we do and have fun doing it. Life is too short not to love and have fun. We lighten the atmosphere by bringing fun back in the workplace.',
        uuid: 'value1',
        expectations: [
          {
            id: '180',
            level: 2,
            normsAndBehaviour: [
              'Regularly initiates and leads activities that foster a fun and engaging work environment without compromising professionalism.',
            ],
          },
          {
            id: '179',
            level: 2,
            normsAndBehaviour: [
              'Consistently incorporates elements of fun into meetings and team projects in a way that enhances productivity and team cohesion.',
            ],
          },
          {
            id: '178',
            level: 2,
            normsAndBehaviour: [
              'Acts as a role model by frequently demonstrating a positive attitude and encouraging others to participate in fun and uplifting workplace initiatives.',
            ],
          },
          {
            id: '177',
            level: 1,
            normsAndBehaviour: [
              'Participates willingly in company-organized fun activities and supports a positive work environment through occasional light-hearted interactions.',
            ],
          },
          {
            id: '176',
            level: 1,
            normsAndBehaviour: [
              'Balances work responsibilities with moments of levity, contributing to a pleasant workplace atmosphere.',
            ],
          },
          {
            id: '175',
            level: 1,
            normsAndBehaviour: [
              "Shows respect for the company's fun-oriented culture by engaging in and facilitating enjoyable experiences for colleagues during appropriate times.",
            ],
          },
          {
            id: '174',
            level: 0,
            normsAndBehaviour: [
              'Rarely participates in or contributes to activities designed to create a fun workplace, often maintaining a consistently serious demeanor.',
            ],
          },
          {
            id: '173',
            level: 0,
            normsAndBehaviour: [
              'Does not engage with the team in fun initiatives and may inadvertently contribute to a dull or overly formal team atmosphere.',
            ],
          },
          {
            id: '172',
            level: 0,
            normsAndBehaviour: [
              'Expresses a negative attitude towards company efforts to incorporate fun into the workplace, potentially affecting team morale.',
            ],
          },
        ],
        media: [],
        underlyingTraits: [
          'Creativity',
          'Open-mindedness',
          'Collaboration',
          'Positivity',
          'Adaptability',
        ],
        cover: {
          type: 'image',
          url: '/static/demo-account/values/fun.png',
        },
      },
      {
        name: 'Honor',
        description:
          'With a strong Navy SEAL heritage, honor is part of our bloodline. We honor our team and customers through respecting their time, respecting their ideas, being truthful and expressing our love towards them.',
        uuid: 'value2',
        expectations: [
          {
            id: '162',
            level: 2,
            normsAndBehaviour: [
              'Proactively seeks feedback from both team and customers to improve services and work environment, showing a high level of respect for their opinions and time.',
            ],
          },
          {
            id: '161',
            level: 2,
            normsAndBehaviour: [
              "Regularly engages in acts that demonstrate deep respect and consideration, such as recognizing individual team members' contributions in meaningful ways beyond standard expectations.",
            ],
          },
          {
            id: '160',
            level: 2,
            normsAndBehaviour: [
              'Consistently communicates with honesty and transparency, fostering trust and a no-blame culture that encourages learning from mistakes and successes alike.',
            ],
          },
          {
            id: '159',
            level: 1,
            normsAndBehaviour: [
              "Shows up on time for meetings and delivers work within deadlines, demonstrating respect for others' time.",
            ],
          },
          {
            id: '158',
            level: 1,
            normsAndBehaviour: [
              "Listens attentively to team members and customers, valuing their ideas and responding constructively, embodying the company's value of respect.",
            ],
          },
          {
            id: '157',
            level: 1,
            normsAndBehaviour: [
              'Maintains an honest approach in all dealings, providing truthful information and setting realistic expectations with team members and clients.',
            ],
          },
          {
            id: '156',
            level: 0,
            normsAndBehaviour: [
              'Consistently late to meetings or misses deadlines, showing disregard for the time of team members and customers.',
            ],
          },
          {
            id: '155',
            level: 0,
            normsAndBehaviour: [
              "Dismisses or undervalues ideas and input from colleagues or clients, not fully embracing the company's stance on respect.",
            ],
          },
          {
            id: '154',
            level: 0,
            normsAndBehaviour: [
              'Demonstrates a lack of transparency or honesty in communication, eroding trust with team members and clients.',
            ],
          },
        ],
        media: [],
        underlyingTraits: [
          'Integrity',
          'Respectfulness',
          'Commitment',
          'Accountability',
          'Transparency',
        ],
        cover: {
          type: 'image',
          url: '/static/demo-account/values/honor.png',
        },
      },
      {
        name: 'Passion',
        description:
          'We are all passionate about serving others, particularly making the work environment more enjoyable and making sure people can flourish. Our passion extends to our purpose and the culture of each client’s organizations. We strive to deliver the highest quality and aim to create raving fans every time.',
        uuid: 'value3',
        expectations: [
          {
            id: '189',
            level: 2,
            normsAndBehaviour: [
              'Initiates and executes projects that significantly enhance the work environment or client satisfaction without being prompted.',
            ],
          },
          {
            id: '188',
            level: 2,
            normsAndBehaviour: [
              'Regularly contributes innovative ideas that improve product quality or team efficiency, and these are often implemented with positive results.',
            ],
          },
          {
            id: '187',
            level: 2,
            normsAndBehaviour: [
              'Actively and consistently seeks feedback from clients and colleagues to drive personal and organizational improvement.',
            ],
          },
          {
            id: '186',
            level: 1,
            normsAndBehaviour: [
              'Demonstrates consistent enthusiasm and dedication towards work tasks and team objectives.',
            ],
          },
          {
            id: '185',
            level: 1,
            normsAndBehaviour: [
              'Provides quality work that satisfies client needs and adheres to company standards.',
            ],
          },
          {
            id: '184',
            level: 1,
            normsAndBehaviour: [
              'Shows willingness to learn and apply new skills or techniques to improve individual and team performance.',
            ],
          },
          {
            id: '183',
            level: 0,
            normsAndBehaviour: [
              "Displays a lack of enthusiasm or commitment to the company's mission and projects.",
            ],
          },
          {
            id: '182',
            level: 0,
            normsAndBehaviour: [
              'Delivers work that often requires significant revision or does not meet client or company standards.',
            ],
          },
          {
            id: '181',
            level: 0,
            normsAndBehaviour: [
              'Shows reluctance to participate in team activities or contribute to a positive work environment.',
            ],
          },
        ],
        media: [],
        underlyingTraits: [
          'Innovativeness',
          'Client-Centric Mindset',
          'Attention to Detail',
          'Collaborative Spirit',
          'Continuous Improvement',
        ],
        cover: {
          type: 'image',
          url: '/static/demo-account/values/passion.png',
        },
      },
    ];
  }

  return [
    {
      name: 'Leadership for Tomorrow',
      description:
        'We set new standards in team dynamics, guiding our team towards resilience and a brighter future. Beyond immediate goals, we envision a lasting, positive impact, building a legacy through our leadership.',
      uuid: 'value1',
      expectations: [
        {
          id: '180',
          level: 2,
          normsAndBehaviour: [
            'Takes initiative to identify and pursue opportunities for growth and innovation',
          ],
        },
        {
          id: '179',
          level: 2,
          normsAndBehaviour: [
            'Inspires and motivates team members to reach their full potential',
          ],
        },
        {
          id: '178',
          level: 2,
          normsAndBehaviour: [
            'Demonstrates a forward-thinking mindset by anticipating future challenges and proactively finding solutions',
          ],
        },
        {
          id: '177',
          level: 1,
          normsAndBehaviour: [
            'Effectively collaborates with team members to achieve common goals',
          ],
        },
        {
          id: '176',
          level: 1,
          normsAndBehaviour: [
            'Displays a strong sense of accountability and responsibility in their role',
          ],
        },
        {
          id: '175',
          level: 1,
          normsAndBehaviour: [
            'Adapts and embraces change to drive continuous improvement',
          ],
        },
        {
          id: '174',
          level: 0,
          normsAndBehaviour: [
            'Lacks proactive approach and waits for instructions or guidance',
          ],
        },
        {
          id: '173',
          level: 0,
          normsAndBehaviour: [
            'Fails to inspire and motivate team members, leading to low morale',
          ],
        },
        {
          id: '172',
          level: 0,
          normsAndBehaviour: [
            'Shows resistance to change and struggles to adapt to new situations',
          ],
        },
      ],
      media: [],
      underlyingTraits: [
        'Resilience',
        'Innovation',
        'Guidance',
        'Vision',
        'Legacy-building',
      ],
      cover: {
        type: 'image',
        url: '/static/demo-account/values/leadership-for-tomorrow.webp',
      },
    },
    {
      name: 'Innovation at the Heart',
      description:
        'We relentlessly pursue groundbreaking approaches, aiming to redefine the way our team approaches challenges. With an unwavering commitment to exploring uncharted territory, we champion innovation even in the face of skepticism.',
      uuid: 'value2',
      expectations: [
        {
          id: '162',
          level: 2,
          normsAndBehaviour: ['Consistently generates new ideas and concepts'],
        },
        {
          id: '161',
          level: 2,
          normsAndBehaviour: [
            'Takes calculated risks to explore innovative solutions',
          ],
        },
        {
          id: '160',
          level: 2,
          normsAndBehaviour: [
            'Actively seeks out feedback and suggestions to improve innovation',
          ],
        },
        {
          id: '159',
          level: 1,
          normsAndBehaviour: [
            'Regularly contributes to brainstorming sessions and discussions',
          ],
        },
        {
          id: '158',
          level: 1,
          normsAndBehaviour: [
            'Applies creative problem-solving skills to address challenges',
          ],
        },
        {
          id: '157',
          level: 1,
          normsAndBehaviour: [
            'Keeps up-to-date with industry trends and emerging technologies',
          ],
        },
        {
          id: '156',
          level: 0,
          normsAndBehaviour: [
            'Resists change and sticks to conventional methods',
          ],
        },
        {
          id: '155',
          level: 0,
          normsAndBehaviour: [
            'Avoids taking initiative or exploring new approaches',
          ],
        },
        {
          id: '154',
          level: 0,
          normsAndBehaviour: [
            'Shows a lack of interest in learning and adopting innovative practices',
          ],
        },
      ],
      media: [],
      underlyingTraits: [
        'Continuous learning',
        'Collaboration',
        'Adaptability',
        'Curiosity',
        'Risk-taking',
      ],
      cover: {
        type: 'image',
        url: '/static/demo-account/values/innovation-at-heart.webp',
      },
    },
    {
      name: 'Integrity in Action',
      description:
        'We embody unwavering integrity within our team, nurturing trust through our genuine actions. By consistently asking the tough questions, we ensure our actions are always guided by authenticity and sincerity.',
      uuid: 'value3',
      expectations: [
        {
          id: '189',
          level: 2,
          normsAndBehaviour: [
            'Actively seeks feedback and acts upon it to improve trust and credibility',
          ],
        },
        {
          id: '188',
          level: 2,
          normsAndBehaviour: [
            'Takes ownership of mistakes and proactively works to rectify them',
          ],
        },
        {
          id: '187',
          level: 2,
          normsAndBehaviour: [
            'Consistently demonstrates honesty and transparency in all interactions',
          ],
        },
        {
          id: '186',
          level: 1,
          normsAndBehaviour: [
            'Respects confidentiality and handles sensitive information with care',
          ],
        },
        {
          id: '185',
          level: 1,
          normsAndBehaviour: [
            'Follows through on commitments and delivers on promises',
          ],
        },
        {
          id: '184',
          level: 1,
          normsAndBehaviour: [
            'Communicates openly and honestly with colleagues and stakeholders',
          ],
        },
        {
          id: '183',
          level: 0,
          normsAndBehaviour: [
            'Shows disregard for the trust and confidence of colleagues and stakeholders',
          ],
        },
        {
          id: '182',
          level: 0,
          normsAndBehaviour: [
            'Fails to take responsibility for mistakes and blames others',
          ],
        },
        {
          id: '181',
          level: 0,
          normsAndBehaviour: ['Engages in dishonest or deceitful behavior'],
        },
      ],
      media: [],
      underlyingTraits: [
        'Honesty',
        'Transparency',
        'Accountability',
        'Consistency',
        'Respect',
      ],
      cover: {
        type: 'image',
        url: '/static/demo-account/values/integrity.webp',
      },
    },
    {
      name: 'Leverage Evidence',
      description:
        'Our decision-making is rooted in data and evidence, ensuring our actions are both reliable and impactful. While we value evidence, we remain agile, adapting to situations when complete data is unavailable.',
      uuid: 'value4',
      expectations: [
        {
          id: '171',
          level: 2,
          normsAndBehaviour: [
            'Consistently bases decisions on data and evidence',
          ],
        },
        {
          id: '170',
          level: 2,
          normsAndBehaviour: [
            'Proactively seeks out additional data to inform decision-making',
          ],
        },
        {
          id: '169',
          level: 2,
          normsAndBehaviour: [
            'Effectively analyzes and interprets data to drive impactful actions',
          ],
        },
        {
          id: '168',
          level: 1,
          normsAndBehaviour: [
            'Uses data and evidence to support decision-making',
          ],
        },
        {
          id: '167',
          level: 1,
          normsAndBehaviour: [
            'Shows flexibility in adapting decisions when complete data is unavailable',
          ],
        },
        {
          id: '166',
          level: 1,
          normsAndBehaviour: [
            'Demonstrates understanding of the importance of evidence-based actions',
          ],
        },
        {
          id: '165',
          level: 2,
          normsAndBehaviour: [
            'Relies solely on intuition or personal opinions when making decisions',
          ],
        },
        {
          id: '164',
          level: 2,
          normsAndBehaviour: [
            'Fails to consider available data and evidence in decision-making',
          ],
        },
        {
          id: '163',
          level: 2,
          normsAndBehaviour: [
            'Shows resistance to adapting decisions when faced with incomplete data',
          ],
        },
      ],
      media: [],
      underlyingTraits: [
        'Analytical thinking',
        'Data-driven decision-making',
        'Open-mindedness',
        'Adaptability',
        'Critical reasoning',
      ],
      cover: {
        type: 'image',
        url: '/static/demo-account/values/leveraging-evidence.webp',
      },
    },
  ];
};
