import {FunctionComponent, TextareaHTMLAttributes, useRef} from 'react';
import {useFormControlContext} from '../form-control';
import styles from './styles.module.scss';

interface AutoresizeTextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  hasError?: boolean;
}

const Textarea: FunctionComponent<AutoresizeTextareaProps> = ({...props}) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const {hasError} = useFormControlContext();

  return (
    <>
      <textarea
        className={
          hasError ? `${styles.textarea} ${styles.error}` : styles.textarea
        }
        ref={textareaRef}
        {...props}
      />
    </>
  );
};

export default Textarea;
