import {createQuery} from 'react-query-kit';
import {dummyCompanyCoreValues} from 'ui/@demo-data/company-core-values';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {CompanyCoreValue} from '../../../interfaces/company-core-value';
import {fetchCompanyCoreValue} from '../../../repositories/instill';
import {CompanyCoreValuePayload} from '../../../repositories/instill/queries/fetch-company-core-value';
import {RQ_COMPANY_CORE_VALUE} from './query-keys';

export const useCompanyCoreValue = createQuery<
  CompanyCoreValue | undefined,
  CompanyCoreValuePayload
>({
  primaryKey: RQ_COMPANY_CORE_VALUE,
  queryFn: queryDemoWrapper(
    async ({queryKey: [_, variables]}) => {
      if (!variables.valueUuid) {
        return Promise.reject();
      }

      return await fetchCompanyCoreValue(
        variables.valueUuid,
        variables.companyUuid
      );
    },
    ({queryKey: [_, variables]}) => {
      return dummyCompanyCoreValues().find(
        (value) => value.uuid === variables.valueUuid
      );
    }
  ),
  enabled: (data, variables) => {
    return !!variables.companyUuid && !!variables.valueUuid;
  },
});
