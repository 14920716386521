import InstillAPI from '../axios-instance';

export interface ConnectCalendarOAuthPayload {
  authorization_code: string;
  platform: 'google-calendar' | 'microsoft-outlook';
  company_uuid: string;
}

export const connectCalendarOAuth = async (
  connectCalendarOAuthPayload: ConnectCalendarOAuthPayload,
  companyUuid: string
) => {
  if (connectCalendarOAuthPayload.platform === 'google-calendar') {
    const response = await InstillAPI.post(
      `oauth2/google-calendar/connect?company_uuid=${companyUuid}`,
      connectCalendarOAuthPayload
    );

    return response;
  }

  if (connectCalendarOAuthPayload.platform === 'microsoft-outlook') {
    const response = await InstillAPI.post(
      `oauth2/microsoft-outlook/connect?company_uuid=${companyUuid}`,
      connectCalendarOAuthPayload
    );

    return response;
  }

  throw new Error('Unsupported platform');
};
