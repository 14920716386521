import {useQueryClient} from '@tanstack/react-query';
import {createMutation} from 'react-query-kit';
import {CompanyCoreValue} from '../../../interfaces/company-core-value';
import {createCompanyCoreValues} from '../../../repositories/instill';
import {CreateCompanyCoreValuesPayload} from '../../../repositories/instill/mutations/create-company-core-values';
import {useSyncSetFullUserProfile} from '../queries';
import {RQ_COMPANY_CORE_VALUES} from '../queries/query-keys';

export const useCreateCompanyCoreValues = () => {
  const queryClient = useQueryClient();
  const syncSetFullUserProfile = useSyncSetFullUserProfile();

  return createMutation<
    CompanyCoreValue[] | undefined,
    CreateCompanyCoreValuesPayload
  >({
    mutationFn: async (variables) => {
      return await createCompanyCoreValues(
        variables.companyCoreValuePayload,
        variables.companyUuid
      );
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([RQ_COMPANY_CORE_VALUES]);
      syncSetFullUserProfile(
        {
          companyUuid: variables.companyUuid,
        },
        (prev) =>
          prev
            ? {
                ...(prev || {}),
                fullOnboardingChecklist: {
                  ...prev.fullOnboardingChecklist,
                  hasAddedCompanyValues: true,
                  hasCreatedValue: true,
                },
              }
            : prev
      );
    },
  })();
};
