import {InfiniteData} from '@tanstack/react-query';

type inferEnabled<TFnData, TVariables, TPageParam = never> =
  | boolean
  | ([TPageParam] extends [never]
      ? (data: TFnData | undefined, variables: TVariables) => boolean
      : (
          data: InfiniteData<TFnData> | undefined,
          variables: TVariables
        ) => boolean);

const paramEnabled = <T, U>(
  enabled: inferEnabled<T, U, never> | undefined,
  data: T | undefined,
  variables: U
) => {
  if (enabled === undefined) {
    return true;
  }

  return typeof enabled === 'boolean' ? !!enabled : enabled(data, variables);
};

export default paramEnabled;
