//onboarding routes
const userSetupRoute = 'user-setup';
const applicationRoute = 'application';
const dashboardRoute = 'dashboard';

// register company
const registerCompanyRoute = 'register-company';

//sense routes
const senseRoute = 'sense';
const specialistRoute = 'specialist';
const meetingsRoute = 'meetings';
const upcomingMeetingsRoute = 'upcoming';
const unAuthorizedMeetingRoute = 'meeting';

//candidate calibration routes
const candidateCalibrationRoute = 'candidate-calibration';
const jobPositionsRoute = 'job-positions';

// candidate assessment routes
const candidatesRoute = 'candidates';
const candidateRegisterRoute = 'register';
const candidateAssessmentRoute = 'assessment';
const candidateAssessmentSent = 'success';
const candidateAssessmentExpired = 'expired';
const candidateResult = 'result';

// Culture Routes
const cultureRoute = 'culture';
const cultureSampleRoute = 'sample';
const cultureCreateRoute = 'create';
const cultureCreateViaChatbotRoute = 'chatbot';
const cultureValuesRoute = 'values';
const cultureValuesSignOff = 'sign-off';

// Action steps
const actionStepsRoute = 'action-steps';

// Assessments - Polls
const assessmentPollRoute = 'assessments-polls';
const myAssessmentsRoute = 'my-assessments';
const manageAssessmentsRoute = 'manage-assessments';
const takeAssessmentsRoute = 'assessments';
const assessmentSummaryRoute = 'assessments-summary';
const assessmentPopulationRoute = 'assessment-population';
const assessmentModulesRoute = 'modules';

// people-teams
const peopleTeamsRoute = 'people-teams';
const peopleRoute = 'people';
const teamsRoute = 'teams';

const pageNotFoundRoute = 'page-not-found';
// culture-vitals
const cultureVitalsRoute = 'culture-vitals';

// culture-kpi
const cultureKpiRoute = 'culture-kpi';
const cultureKpiCreateRoute = 'create';

//settings
const settingsRoute = 'settings';
const myAccountSettingsRoute = 'myAccount';
const companySettingsRoute = 'companyProfile';
const flowSettingsRoute = 'flow';
const notificationSettingsRoute = 'notifications';
const securitySettingsRoute = 'security';
const billingSettingsRoute = 'billing';
const integrationSettingsRoute = 'integrations';

// instill-coach
const instillCoachRoute = 'coach';
const actionStepsCoachRoute = 'action-steps-coach';

// demo account route
const demoAccountRoute = 'demo-account';

// link-slack routes
const linkSlackRoute = 'link-slack';
const linkSlackUserRoute = 'user';
const linkSlackWorkspaceRoute = 'workspace';

// plans
const plansRoute = 'plans';
const plansCheckoutRoute = 'checkout';

// oauth callbacks
const oAuthCallbackRoute = 'oauth-callback';
const googleCalendarCallbackRoute = 'google-calendar';
const microsoftOutlookCallbackRoute = 'microsoft-outlook';
// logout
const logoutRoute = 'logout';

// getting-stated
const gettingStartedRoute = 'getting-started';

export const routeNames = {
  logoutRoute,
  actionStepsRoute,
  assessmentPollRoute,
  applicationRoute,
  dashboardRoute,
  senseRoute,
  specialistRoute,
  meetingsRoute,
  upcomingMeetingsRoute,
  unAuthorizedMeetingRoute,
  userSetupRoute,
  candidateCalibrationRoute,
  jobPositionsRoute,
  candidateAssessmentRoute,
  cultureRoute,
  cultureSampleRoute,
  cultureCreateRoute,
  cultureCreateViaChatbotRoute,
  candidatesRoute,
  candidateRegisterRoute,
  candidateAssessmentSent,
  candidateAssessmentExpired,
  pageNotFoundRoute,
  candidateResult,
  peopleTeamsRoute,
  peopleRoute,
  teamsRoute,
  cultureValuesRoute,
  cultureValuesSignOff,
  manageAssessmentsRoute,
  myAssessmentsRoute,
  takeAssessmentsRoute,
  cultureVitalsRoute,
  cultureKpiRoute,
  cultureKpiCreateRoute,
  assessmentSummaryRoute,
  assessmentPopulationRoute,
  assessmentModulesRoute,
  settingsRoute,
  myAccountSettingsRoute,
  companySettingsRoute,
  flowSettingsRoute,
  integrationSettingsRoute,
  notificationSettingsRoute,
  billingSettingsRoute,
  securitySettingsRoute,
  instillCoachRoute,
  actionStepsCoachRoute,
  demoAccountRoute,
  linkSlackRoute,
  linkSlackUserRoute,
  linkSlackWorkspaceRoute,
  plansRoute,
  plansCheckoutRoute,
  oAuthCallbackRoute,
  googleCalendarCallbackRoute,
  microsoftOutlookCallbackRoute,
  registerCompanyRoute,
  gettingStartedRoute,
} as const;
