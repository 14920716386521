import {Kudo} from 'interfaces/kudo';

export const kudosCount = {
  totalCount: 560,
  pastWeekCount: 30,
};

export const dummyKudos: Kudo[] = [
  {
    createdAt: '2024-03-08T19:28:21.091392+00:00',
    description:
      "While very competent, she's a pleasure to collaborate with and...",
    fromUser: {
      name: 'Ken Northwood',
      uuid: '6a5eb455-771e-46e9-a0b3-f45030dcc818',
      picture: '/static/demo-account/people/person3.jpeg',
    },
    isHidden: false,
    toUser: {
      name: 'Daniella Mauer',
      uuid: 'e49096b3-d562-448e-a7cb-f1a46e451b44',
      picture: '/static/demo-account/people/person1.jpeg',
    },
    uuid: '58dd9a49-d09e-4ea5-8ff7-f5a54ebfd923',
    value: {
      name: 'Leadership for Tomorrow',
      description: '',
      uuid: 'value1',
    },
  },
  {
    createdAt: '2024-03-08T19:28:21.091392+00:00',
    description:
      "While very competent, she's a pleasure to collaborate with and...",
    fromUser: {
      name: 'Daniella Mauer',
      uuid: '6a5eb455-771e-46e9-a0b3-f45030dcc818',
      picture: '/static/demo-account/people/person1.jpeg',
    },
    isHidden: false,
    toUser: {
      name: 'Sam Smith',
      uuid: 'e49096b3-d562-448e-a7cb-f1a46e451b44',
      picture: '/static/demo-account/people/person2.jpeg',
    },
    uuid: '58dd9a49-d09e-4ea5-8ff7-f5a54ebfd923',
    value: {
      name: 'Integrity in Action',
      description: '',
      uuid: 'value3',
    },
  },
  {
    createdAt: '2024-03-08T19:28:21.091392+00:00',
    description:
      "While very competent, she's a pleasure to collaborate with and...",
    fromUser: {
      name: 'Daniella Mauer',
      uuid: '6a5eb455-771e-46e9-a0b3-f45030dcc818',
      picture: '/static/demo-account/people/person1.jpeg',
    },
    isHidden: false,
    toUser: {
      name: 'Ken Northwood',
      uuid: 'e49096b3-d562-448e-a7cb-f1a46e451b44',
      picture: '/static/demo-account/people/person3.png',
    },
    uuid: '58dd9a49-d09e-4ea5-8ff7-f5a54ebfd923',
    value: {
      name: 'Innovation at the Heart',
      description: '',
      uuid: 'value2',
    },
  },
];
