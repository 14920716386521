import {useQueryClient} from '@tanstack/react-query';
import {createMutation} from 'react-query-kit';
import {CompanyCoreValue} from '../../../interfaces/company-core-value';
import {
  UpdateCompanyCoreValuePayload,
  updateCompanyCoreValue,
} from '../../../repositories/instill/mutations/update-company-core-value';
import {
  RQ_COMPANY_CORE_VALUE,
  RQ_COMPANY_CORE_VALUES,
} from '../queries/query-keys';

export const useUpdateCompanyCoreValue = () => {
  const queryClient = useQueryClient();

  return createMutation<
    CompanyCoreValue | undefined,
    UpdateCompanyCoreValuePayload
  >({
    mutationFn: async (variables) => {
      return await updateCompanyCoreValue(
        variables.payload,
        variables.companyUuid,
        variables.coreValueUuid
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries([RQ_COMPANY_CORE_VALUES]);
      queryClient.invalidateQueries([RQ_COMPANY_CORE_VALUE]);
    },
  })();
};
