import {QueryHookOptionsInt, createQuery} from 'react-query-kit';
import {
  BusinessOutcomesPayload,
  fetchBusinessOutcomes,
} from 'repositories/instill/queries/fetch-business-outcomes';
import {companyBusinessOutcomes} from 'ui/@demo-data/business-outcomes';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {RQ_BUSINESS_OUTCOMES} from './query-keys';
import useHasPlanAccess from '../use-has-plan-access';
import {Features} from 'constants/plan-access/features';
import paramEnabled from 'utils/query-utils/param-enabled';

export const useBusinessOutcomes = (
  params: QueryHookOptionsInt<
    string[] | undefined,
    Error,
    string[] | undefined,
    BusinessOutcomesPayload
  >
) => {
  const {hasPlanAccess} = useHasPlanAccess();

  return createQuery<string[] | undefined, BusinessOutcomesPayload>({
    primaryKey: RQ_BUSINESS_OUTCOMES,
    queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
      return await fetchBusinessOutcomes(variables.companyUuid);
    }, companyBusinessOutcomes),
  })({
    ...params,
    enabled(data, variables) {
      return (
        !!variables?.companyUuid &&
        hasPlanAccess(Features.BUSINESS_OUTCOMES) &&
        paramEnabled(params.enabled, data, variables)
      );
    },
  });
};
