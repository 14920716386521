import {InputHTMLAttributes} from 'react';
import {useFormControlContext} from '../form-control';
import styles from './styles.module.scss';

type InputVariant = 'primary' | 'noOutlined' | 'empty';

interface ElementProps extends InputHTMLAttributes<HTMLInputElement> {
  variant?: InputVariant;
  fullWidth?: boolean;
}

const Input = ({
  variant = 'primary',
  fullWidth = true,
  ...props
}: ElementProps) => {
  let classes = styles.input;
  const {hasError} = useFormControlContext();

  if (hasError) {
    classes = `${classes} ${styles.error}`;
  }

  if (variant) {
    classes = `${classes} ${styles[variant]}`;
  }

  if (fullWidth) {
    classes = `${classes} ${styles.fullWidth}`;
  }

  return <input className={classes} {...props} />;
};

export default Input;
