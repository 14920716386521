import {createMutation} from 'react-query-kit';
import {Company} from '../../../interfaces/company';
import {updateCompany} from '../../../repositories/instill';
import {UpdateCompanyPayload} from '../../../repositories/instill/mutations/update-company';
import {useSyncSetCompany} from '../queries';

export const useUpdateCompany = () => {
  const syncSetCompany = useSyncSetCompany();

  return createMutation<Company | undefined, UpdateCompanyPayload>({
    mutationFn: async (variables) => {
      return await updateCompany(variables.payload, variables.companyUuid);
    },
    onSuccess: (data, variables) => {
      syncSetCompany(data, {
        companyUuid: variables.companyUuid,
      });
    },
  })();
};
