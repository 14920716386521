import Teams from 'assets/images/teams.png';
import {EventName} from 'constants/analytics/event-name';
import {EventType} from 'constants/analytics/event-type';
import {Actions} from 'constants/permissions/actions';
import {routeNames} from 'constants/route-names';
import {useTranslation} from 'react-i18next';
import Button from 'ui/@_components/button';
import {useHasPermission} from 'ui/@_components/has-permission';
import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';
import {useUpdateFlowReportShared} from 'ui/@hooks/mutations/use-update-flow-report-shared';
import useAnalytics from 'ui/@hooks/use-analytics';
import useDisclosure from 'ui/@hooks/use-disclosure';
import useGettingStartedStep, {
  GETTING_STARTED_STEP,
} from 'ui/@hooks/use-getting-started-step';
import useShareUrl from 'ui/@hooks/use-share-url';
import InviteUser from 'ui/@module-components/modals/invite-user';
import useFlowData from 'ui/app/sense/meetings/show-v2/@hooks/use-flow-data';
import styles from './styles.module.scss';

const InviteTeamBanner = () => {
  const {t} = useTranslation('gettingStarted', {
    keyPrefix: 'components.teams-banner',
  });
  const {unAuthorizedMeetingRoute} = routeNames;

  const companyUuid = useSafeCurrentCompanyUuid();

  const {isOpen, onClose, onOpen} = useDisclosure();

  const {currentGettingStartedStep} = useGettingStartedStep();

  const {trackEvent} = useAnalytics();

  const {meeting} = useFlowData();

  const updateFlowReportShared = useUpdateFlowReportShared();

  const {onShare} = useShareUrl();

  const numberOfTips =
    (meeting?.cultureVitalSigns.reduce((total, current) => {
      return total + current.feedback.split('. ').length;
    }, 0) || 0) +
    (meeting?.valuesAlignment.reduce((total, current) => {
      return total + current.feedback.split('. ').length;
    }, 0) || 0);

  const onShareClick = () => {
    if (!meeting) {
      return;
    }

    trackEvent({
      eventName: EventName.FLOW.REPORT.REPORT_SHARE,
      eventType: EventType.BUTTON_CLICKED,
      eventProperties: {
        name: meeting.name,
      },
    });

    onShare(
      `${unAuthorizedMeetingRoute}/${meeting.uuid}`,
      t('share-feedback-message')
    );

    if (!companyUuid) return;

    updateFlowReportShared.mutate({
      companyUuid,
    });
  };

  const hasPermission = useHasPermission();

  if (
    currentGettingStartedStep !== GETTING_STARTED_STEP.INVITE_USER &&
    currentGettingStartedStep !== GETTING_STARTED_STEP.SHARE_FLOW_REPORT &&
    currentGettingStartedStep !== GETTING_STARTED_STEP.SEE_FLOW_REPORT
  ) {
    return null;
  }

  return (
    <>
      <InviteUser isOpen={isOpen} onClose={onClose} />

      <div className={styles.container}>
        <div className={styles.leftContent}>
          <p className={styles.subtitle}>{t('subtitle')}</p>

          <p className={styles.title}>
            {t('title-2', {
              teamMembers: meeting?.participantsNames?.slice(0, 2).join(', '),
              numberOfTips,
            })}
          </p>

          <div className={styles.buttonsContainer}>
            {hasPermission(Actions.ADD_EMPLOYEE) && (
              <Button onClick={onOpen}>{t('invite')}</Button>
            )}

            <Button
              disabled={!meeting?.uuid}
              variant="secondary"
              onClick={onShareClick}
            >
              {t('share')}
            </Button>
          </div>
        </div>

        <div className={styles.bigCircle} />

        <div className={styles.smallCircle} />

        <img
          src={Teams}
          className={styles.image}
          alt={t('img-alt')}
          width="242px"
        />
      </div>
    </>
  );
};

export default InviteTeamBanner;
