export const printPage = (__DIV_ID__: string) => {
  let printContents = document?.getElementById(__DIV_ID__)?.innerHTML;

  if (!printContents) return;

  let originalContents = document.body.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  document.body.innerHTML = originalContents;
  return;
};
