import styles from './styles.module.scss';
import CreatingAccountGif from 'assets/images/creating-account-gif.gif';
import {useEffect} from 'react';
import nProgress from 'nprogress';
import {useAccountCreationLoaderShowState} from 'ui/@atoms/account-creation-loader-show';

const CreatingAccount = () => {
  const [accountCreationLoaderShow, setAccountCreationLoaderShow] =
    useAccountCreationLoaderShowState();

  useEffect(() => {
    if (accountCreationLoaderShow) {
      setTimeout(() => {
        setAccountCreationLoaderShow(false);
        nProgress.done();
      }, 9000);
    }
  }, [setAccountCreationLoaderShow, accountCreationLoaderShow]);

  useEffect(() => {
    if (accountCreationLoaderShow) {
      nProgress.start();
    } else {
      nProgress.done();
    }
  }, [accountCreationLoaderShow]);

  if (!accountCreationLoaderShow) {
    return null;
  }

  return (
    <div className={styles.container}>
      <img
        src={CreatingAccountGif}
        alt="Creating Account"
        height="600px"
        className={styles.img}
      />
    </div>
  );
};

export default CreatingAccount;
