export const COMPANY_ONBOARDING_COMPLETE_STORAGE_KEY =
  'Instill-CompanyOnboardingCompleted';

export const REDIRECT_URL_ON_LOGIN_STORAGE_KEY = 'Instill-RedirectUrLOnLogin';

export const SELECTED_COMPANY_UUID_KEY = 'Instill-SelectedCompanyUuid';

export const OVERALL_HEALTH_FILTERS_STORAGE_KEY =
  'Instill-OverallHealthFilters';

export const IS_SIGNUP_TRACKED_STORAGE_KEY = 'Instill-IsSignupTracked';
export const IS_SIGNIN_TRACKED_STORAGE_KEY = 'Instill-IsSignInTracked';

export const DEMO_ACCOUNT_TYPE = 'Instill-DemoAccountType';

export const SIDEBAR_LOCALSTORAGEKEY = 'Instill-Sidebar';
