import {
  CustomerPortalResponse,
  CustomerPortalResponseRaw,
} from 'interfaces/customer-portal';

export const sanitizeCustomerPortalResponseFromRaw = (
  data: CustomerPortalResponseRaw
): CustomerPortalResponse => {
  return {
    url: data.url,
  };
};
