export const RQ_CANDIDATE_PROFILE = 'candidateProfile';
export const RQ_BUSINESS_OUTCOMES = 'businessOutcomes';
export const RQ_DEFINED_BUSINESS_OUTCOMES = 'definedBusinessOutcomes';
export const RQ_CURRENT_SUBSCRIPTIONS = 'currentSubscriptions';
export const RQ_PRODUCT_PLAN_PROPERTIES = 'productPlanProperties';
export const RQ_JOB_POSITIONS = 'jobPositions';
export const RQ_JOB_POSITION = 'jobPosition';
export const RQ_KUDOS_COUNT = 'kudosCount';
export const RQ_COMPANY = 'company';
export const RQ_PRODUCTS = 'products';
export const RQ_KUDOS = 'kudos';
export const RQ_MEETINGS_LIST_BY_VITAL_SIGN = 'meetingsListByVitalSign';
export const RQ_ASSESSMENT_STATUS_BY_VITAL_SIGN = 'assessmentStatusByVitalSign';
export const RQ_COMPANY_EMPLOYEES = 'companyEmployees';
export const RQ_TEAMS = 'teams';
export const RQ_CALENDAR_EVENTS = 'calendarEvents';
export const RQ_CHECK_INVITE_TOKEN = 'checkInviteToken';
export const RQ_CURRENT_AUTH_USER = 'currentAuthUser';
export const RQ_TEAM = 'team';
export const RQ_CULTURE_GRAPH = 'cultureGraph';
export const RQ_COMPANY_USER_PROFILE = 'companyUserProfile';
export const RQ_CUSTOMER_PORTAL = 'customerPortal';
export const RQ_INFINITE_VALUES_GRAPH = 'infiniteValuesGraph';
export const RQ_INFINITE_KUDOS = 'infiniteKudos';
export const RQ_INFINITE_VITAL_SIGNS_GRAPH = 'infiniteVitalSignsGraph';
export const RQ_VALUES_ALIGNMENT_SCORES = 'valuesAlignmentScores';
export const RQ_VITAL_SIGN_SCORES = 'vitalSignScores';
export const RQ_CUSTOM_CULTURE_KPIS = 'customCultureKpis';
export const RQ_CUSTOM_KPI_QUESTIONS = 'customKpiQuestions';
export const RQ_CUSTOM_CULTURE_KPI = 'customKpi';
export const RQ_MEETINGS_ANALYSIS = 'meetingsAnalysis';
export const RQ_CANDIDATE_ALIGNMENT_RESULT = 'candidateAlignmentResult';
export const RQ_FULL_USER_PROFILE = 'fullUserProfile';
export const RQ_JOB_POSITIONS_SUMMARIES_ALL = 'jobPositionsSummariesAll';
export const RQ_USER_ASSESSMENTS = 'userAssessments';
export const RQ_USER_ASSESSMENT = 'userAssessment';
export const RQ_COMPANY_CORE_VALUES = 'companyCoreValues';
export const RQ_COMPANY_CORE_VALUE = 'companyCoreValue';
export const RQ_COMPANY_ASSESSMENTS = 'companyAssessments';
export const RQ_MEETINGS = 'meetings';
export const RQ_MEETING = 'meeting';
export const RQ_TRANSCRIPT = 'transcript';
export const RQ_ASSESSMENT_RESULT_SUMMARY = 'assessmentResultSummary';
export const RQ_ASSESSMENT_USER_PROFILE_SUMMARY =
  'assessmentUserProfileSummary';
export const RQ_USER_ACTION_STEPS = 'userActionSteps';
export const RQ_PROFILE_COMPLETION_PERCENTAGE = 'profileCompletionPercentage';
export const RQ_CANDIDATE_ASSESSMENT = 'candidateAssessment';
export const RQ_CANDIDATES_FOR_JOBS = 'candidatesForJob';

// Infinite Queries
export const RQ_INFINITE_COMPANY_EMPLOYEES = 'infiniteCompanyEmployees';
export const RQ_INFINITE_ELIGIBLE_MANAGERS = 'infiniteEligibleManagers';
export const RQ_INFINITE_TEAMS = 'infiniteTeams';
export const RQ_INFINITE_CULTURE_GRAPH = 'infiniteCultureGraph';
export const RQ_INFINITE_CUSTOM_CULTURE_KPI_GRAPH =
  'infiniteCustomCultureKpiGraph';
