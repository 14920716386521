import {CultureDesignUserResponse} from 'interfaces/culture-design-specialist';
import {
  sanitizeCultureDesignAIResponseFromRaw,
  sanitizeCultureDesignUserResponseToRaw,
} from 'ui/@sanitizers/culture-design-specialist';
import isResponseSuccessful from '../../../utils/is-response-successful';
import InstillAPI from '../axios-instance';

export interface GenerateCultureDesignPayload {
  companyUuid: string;
  sessionUuid: string;
  payload: CultureDesignUserResponse;
}

export const generateCultureDesignResponse = async (
  parameters: GenerateCultureDesignPayload
) => {
  const {companyUuid, sessionUuid, payload} = parameters;
  const sanitizedPayload = sanitizeCultureDesignUserResponseToRaw(payload);

  const response = await InstillAPI.post(
    `/companies/${companyUuid}/values/specialist/${sessionUuid}/`,
    sanitizedPayload.user_response
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return sanitizeCultureDesignAIResponseFromRaw(response.data);
};
