import {createQuery} from 'react-query-kit';
import {CandidateAlignmentResult} from '../../../interfaces/candidate-alignment-result';
import {
  FetchCandidateAlignmentResultPayload,
  fetchCandidateAlignmentResult,
} from '../../../repositories/instill/queries/fetch-candidate-alignment-result';
import {RQ_CANDIDATE_ALIGNMENT_RESULT} from './query-keys';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {dummyCandidateAlignmentResult} from 'ui/@demo-data/candidate-alignment-result';

export const useCandidateAlignmentResult = createQuery<
  CandidateAlignmentResult | undefined,
  FetchCandidateAlignmentResultPayload
>({
  primaryKey: RQ_CANDIDATE_ALIGNMENT_RESULT,
  queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
    if (!variables.candidateUuid) {
      return Promise.reject();
    }

    return await fetchCandidateAlignmentResult(variables.candidateUuid);
  }, dummyCandidateAlignmentResult),
});
