import {useQueryClient} from '@tanstack/react-query';
import {createMutation} from 'react-query-kit';
import {Candidate} from '../../../interfaces/candidate';
import {createCandidate} from '../../../repositories/instill';
import {CreateCandidatePayload} from '../../../repositories/instill/mutations/create-candidate';
import {useSyncSetFullUserProfile} from '../queries';

export const useCreateCandidates = () => {
  const queryClient = useQueryClient();
  const syncSetFullUserProfile = useSyncSetFullUserProfile();

  return createMutation<Candidate | undefined, CreateCandidatePayload>({
    mutationFn: async (variables) => {
      return await createCandidate({
        companyUuid: variables.companyUuid,
        jobPositionUuid: variables.jobPositionUuid,
        payload: variables.payload,
      });
    },
    onSuccess: (_, variables) => {
      queryClient.refetchQueries(['candidatesForJob']);

      syncSetFullUserProfile(
        {
          companyUuid: variables.companyUuid,
        },
        (prev) =>
          prev
            ? {
                ...(prev || {}),
                fullOnboardingChecklist: {
                  ...prev.fullOnboardingChecklist,
                  hasInvitedCandidates: true,
                },
              }
            : prev
      );
    },
  })();
};
