import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {DemoAccountMutationError} from 'constants/errors';
import {ReactNode, useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import SignupPromptModal from 'ui/@_components/signup-prompt-modal';
import useDemoAccount from 'ui/@hooks/use-demo-account';
import useDemoAccountDetails from 'ui/@hooks/use-demo-account-details';
const FIVE_MINS = 30_00_00; // 5mins

interface Props {
  children: ReactNode;
}

const InstillQueryClientProvider = ({children}: Props) => {
  const [isSignupPromptModalOpen, setIsSignupPromptModalOpen] = useState(false);

  const {isDemoAccount} = useDemoAccount();

  const {mutationToast} = useDemoAccountDetails();

  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          cacheTime: FIVE_MINS,
          staleTime: FIVE_MINS,
        },
        mutations: {
          retry: 3,
          retryDelay: 2000,
          onMutate: () => {
            if (isDemoAccount) {
              if (mutationToast.show) {
                toast.error(mutationToast.text);
              } else {
                setIsSignupPromptModalOpen(true);
              }
              throw new DemoAccountMutationError(
                'Create/ Update/ Delete operations are disabled in demo mode.'
              );
            }
          },
        },
      },
    });
  }, [isDemoAccount, mutationToast.show, mutationToast.text]);

  return (
    <>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>

      <SignupPromptModal
        isOpen={isSignupPromptModalOpen}
        onClose={() => setIsSignupPromptModalOpen(false)}
      />
    </>
  );
};

export default InstillQueryClientProvider;
