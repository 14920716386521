import {
  Meeting,
  MeetingFeedback as MeetingFeedbackInterface,
} from 'interfaces/meeting';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import Button from 'ui/@_components/button';
import TextCard from 'ui/@_components/card/text-card';
import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';
import {useUpdateMeetingFeedback} from 'ui/@hooks/mutations/use-update-meeting-feedback';
import styles from './styles.module.scss';
import useAnalytics from 'ui/@hooks/use-analytics';
import {EventName} from 'constants/analytics/event-name';
import {EventType} from 'constants/analytics/event-type';

interface ElementProps {
  meeting: Meeting;
}

const MeetingFeedBack = ({meeting}: ElementProps) => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.components.meeting-details.components.meeting-feedback',
  });
  const {trackEvent} = useAnalytics();

  const [isFeedbackPositive, setIsFeedbackPositive] = useState(() => {
    return meeting.feedback?.isPositive;
  });

  const companyUuid = useSafeCurrentCompanyUuid();
  const updateMeetingFeedback = useUpdateMeetingFeedback();

  const updateFeedback = useCallback(
    async (payload: MeetingFeedbackInterface) => {
      const response = await updateMeetingFeedback.mutateAsync({
        companyUuid,
        payload,
        meetingUuid: meeting?.uuid,
      });

      if (!response) return;

      trackEvent({
        eventName: EventName.FLOW.REPORT.REPORT_FEEDBACK,
        eventType: EventType.BUTTON_CLICKED,
        eventProperties: {
          isPositive: payload.isPositive,
          meetingName: meeting.name,
        },
      });
      const feedbackMessage = t('feedback-messages.update-success');

      toast.success(feedbackMessage);

      return response;
    },
    [
      companyUuid,
      meeting.name,
      meeting?.uuid,
      t,
      trackEvent,
      updateMeetingFeedback,
    ]
  );

  const onFeedbackButtonClicked = useCallback(
    async (isPositive: boolean) => {
      const payload = {isPositive};

      const response = await updateFeedback(payload);
      if (!response) return;

      setIsFeedbackPositive(response.isPositive);
    },
    [updateFeedback]
  );

  return (
    <TextCard>
      <TextCard.Header title={t('title')} />
      <TextCard.Content>
        <div className={styles.container}>
          {t('question')}

          <div className={styles.buttonContainer}>
            <Button
              variant={isFeedbackPositive ? 'primary' : 'secondary'}
              onClick={() => onFeedbackButtonClicked(true)}
            >
              {t('options.yes')}
            </Button>

            <Button
              variant={!isFeedbackPositive ? 'primary' : 'secondary'}
              onClick={() => onFeedbackButtonClicked(false)}
            >
              {t('options.no')}
            </Button>
          </div>
        </div>
      </TextCard.Content>
    </TextCard>
  );
};

export default MeetingFeedBack;
