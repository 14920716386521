import {EventName} from 'constants/analytics/event-name';
import {EventType} from 'constants/analytics/event-type';
import {CONTACT_US_URL} from 'constants/contact-us';
import {routeNames} from 'constants/route-names';
import {useTranslation} from 'react-i18next';
import ApplicationSubHeader from 'ui/@_components/application-sub-header';
import Breadcrumb from 'ui/@_components/breadcrumb';
import Button from 'ui/@_components/button';
import {ReactComponent as IconShare} from 'ui/@_components/kit/icons/share.svg';
import {useCurrentCompanyUuid} from 'ui/@atoms/current-company';
import {useUpdateFlowReportShared} from 'ui/@hooks/mutations/use-update-flow-report-shared';
import useAnalytics from 'ui/@hooks/use-analytics';
import useShareUrl from 'ui/@hooks/use-share-url';
import useFlowData from '../../@hooks/use-flow-data';
import styles from './styles.module.scss';

const FlowSubheader = () => {
  const {t} = useTranslation('sense', {
    keyPrefix: 'meetings.show-v2.components.flow-subheader',
  });

  const {
    applicationRoute,
    senseRoute,
    meetingsRoute,
    unAuthorizedMeetingRoute,
  } = routeNames;

  const companyUuid = useCurrentCompanyUuid();

  const {trackEvent} = useAnalytics();

  const {meeting, isUserAuthenticated} = useFlowData();

  const updateFlowReportShared = useUpdateFlowReportShared();

  const {onShare} = useShareUrl();

  if (!meeting) return <></>;

  const onShareClick = () => {
    trackEvent({
      eventName: EventName.FLOW.REPORT.REPORT_SHARE,
      eventType: EventType.BUTTON_CLICKED,
      eventProperties: {
        name: meeting.name,
      },
    });

    onShare(
      `${unAuthorizedMeetingRoute}/${meeting.uuid}`,
      t('share-feedback-message')
    );

    if (!companyUuid) return;

    updateFlowReportShared.mutate({
      companyUuid,
    });
  };

  const onGetInstill = () => {
    window.open(CONTACT_US_URL, '_blank');
  };

  return (
    <ApplicationSubHeader>
      <ApplicationSubHeader.Leading>
        {isUserAuthenticated ? (
          <Breadcrumb
            breadcrumbItem={t('breadcrumb', {
              meetingName: meeting.name,
            })}
            links={[`/${applicationRoute}/${senseRoute}/${meetingsRoute}`]}
          />
        ) : (
          <Breadcrumb breadcrumbItem={meeting.name} />
        )}
      </ApplicationSubHeader.Leading>

      <ApplicationSubHeader.Trailing>
        <Button onClick={isUserAuthenticated ? onShareClick : onGetInstill}>
          {isUserAuthenticated ? (
            <div className={styles.shareButton}>
              <IconShare /> {t('share')}
            </div>
          ) : (
            t('get-instill')
          )}
        </Button>
      </ApplicationSubHeader.Trailing>
    </ApplicationSubHeader>
  );
};

export default FlowSubheader;
