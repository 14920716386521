import {sanitizeCandidatesForJobFromRaw} from 'ui/@sanitizers/candidate';
import {ORDER_BY_PARAMETERS} from '../../../constants/filtering/order-by';
import {sanitizeMetaFromRaw} from '../../../ui/@sanitizers/meta';
import isResponseSuccessful from '../../../utils/is-response-successful';
import InstillAPI from '../axios-instance';

export interface FetchCandidatseForJobPayload {
  companyUuid: string;
  limit?: number;
  offset?: number;
  orderBy?: ORDER_BY_PARAMETERS;
  query?: string;
  jobPositionUuid: string;
  sortBy?: string;
  assessmentStatusFilter?: 'completed' | 'not_started' | 'started';
}

export const fetchCandidatesForJob = async (
  parameters: FetchCandidatseForJobPayload
) => {
  const {
    companyUuid,
    limit,
    offset,
    orderBy,
    query,
    sortBy,
    jobPositionUuid,
    assessmentStatusFilter,
  } = parameters;

  let url = `/companies/${companyUuid}/job_position/${jobPositionUuid}/candidates`;
  let queryParams: string[] = [];

  if (offset) {
    queryParams.push(`skip=${offset}`);
  }

  if (limit) {
    queryParams.push(`limit=${limit}`);
  }

  if (orderBy) {
    queryParams.push(`order=${orderBy}`);
  }

  if (sortBy) {
    queryParams.push(`sort_by=${sortBy}`);
  }

  if (query) {
    queryParams.push(`name_search_query=${query}`);
  }

  if (assessmentStatusFilter) {
    queryParams.push(`assessment_status_filter=${assessmentStatusFilter}`);
  }

  if (queryParams) {
    url = `${url}?${queryParams.join('&')}`;
  }

  try {
    const response = await InstillAPI.get(url);

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return {
      data: sanitizeCandidatesForJobFromRaw(response.data.data),
      meta: sanitizeMetaFromRaw(response.data.meta),
    };
  } catch (error) {
    console.error(error);
  }
};
