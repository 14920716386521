import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import BaseCard from 'ui/@_components/card/base-card';
import styles from './styles.module.scss';

const ProcessingData: FunctionComponent = () => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.components.meeting-details.components.processing-data',
  });

  return (
    <BaseCard>
      <div className={styles.notReadyContainer}>
        <p className={styles.primaryMessage}>{t('primary-message')}</p>

        <p>{t('secondary-message')}</p>
      </div>
    </BaseCard>
  );
};

export default ProcessingData;
