import {createQuery} from 'react-query-kit';
import {Kudo} from '../../../interfaces/kudo';
import {fetchKudo} from '../../../repositories/instill';
import {FetchKudoPayload} from '../../../repositories/instill/queries/fetch-kudo';

/**
 * @deprecated
 */
export const useKudo = createQuery<Kudo | undefined, FetchKudoPayload>({
  primaryKey: 'kudo',
  queryFn: async ({queryKey: [_, variables]}) => {
    if (!variables.kudoUuid) {
      return Promise.reject();
    }

    return fetchKudo({
      companyUuid: variables.companyUuid,
      kudoUuid: variables.kudoUuid,
    });
  },
});
