import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';
import {sanitizeCustomerPortalResponseFromRaw} from 'ui/@sanitizers/customer-portal';

export interface FetchCustomerPortalPayload {
  companyUuid: string;
}

export async function fetchCustomerPortal({
  companyUuid,
}: FetchCustomerPortalPayload) {
  try {
    const response = await InstillAPI.get(
      `/companies/${companyUuid}/customer-portal`
    );

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeCustomerPortalResponseFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
