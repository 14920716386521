import {createQuery} from 'react-query-kit';
import {fetchCurrentAuthUser} from '../../../repositories/instill';
import {AuthUser} from '../../../interfaces/auth-user';
import {RQ_CURRENT_AUTH_USER} from './query-keys';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {dummyAuthUser} from 'ui/@demo-data/auth-user';
import {useQueryClient} from '@tanstack/react-query';

type Response = AuthUser | undefined;

export const useCurrentAuthUser = createQuery<Response, null>({
  primaryKey: RQ_CURRENT_AUTH_USER,
  queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
    return await fetchCurrentAuthUser();
  }, dummyAuthUser),
});

export const useSyncSetCurrentAuthUser = () => {
  const queryClient = useQueryClient();

  const syncSetCurrentAuthUser = (
    newCurrentAuthUser: Response | ((prevData: Response) => Response)
  ) => {
    queryClient.setQueryData([RQ_CURRENT_AUTH_USER], newCurrentAuthUser);
  };

  return syncSetCurrentAuthUser;
};
