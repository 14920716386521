import * as amplitude from '@amplitude/analytics-browser';
import {OAuthError} from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {onlineManager} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {DemoAccountMutationError} from 'constants/errors';
import i18n from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {HelmetProvider} from 'react-helmet-async';
import {initReactI18next} from 'react-i18next';
import {BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {RecoilRoot} from 'recoil';
import InstillQueryClientProvider from 'ui/@contexts/instill-query-client-provider';
import {ThemeContextProvider} from 'ui/@contexts/theme-context';
import {QueryParamProvider} from 'use-query-params';
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6';
import isLocalHost from 'utils/environment-utils/is-local-host';
import 'yup-phone';
import './index.scss';
import './init-environment';
import translations from './locales';
import reportWebVitals from './report-web-vitals';
import ApplicationRoutes from './ui/application-routes';
import Auth0ProviderWithRedirectCallback from './ui/auth0';

const appPackage = require('../package.json');

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

onlineManager.setOnline(true);

i18n.use(initReactI18next).init({
  defaultNS: 'common',
  fallbackLng: 'en',
  lng: 'en',
  resources: translations,
  returnNull: false,
  interpolation: {
    escapeValue: false,
  },
});

Sentry.init({
  enabled: Boolean(window.env.REACT_APP_SENTRY_DSN),
  dsn: window.env.REACT_APP_SENTRY_DSN,
  environment: window.env.REACT_APP_ENVIRONMENT_NAME,
  integrations: [new BrowserTracing()],
  release: appPackage.version,
  debug: window.env.NODE_ENV !== 'production',
  beforeSend(event, hint) {
    const error = hint.originalException;

    if (error && error instanceof DemoAccountMutationError) {
      return null;
    }

    if (
      error &&
      error instanceof OAuthError &&
      error.message?.includes('Login required')
    ) {
      return null;
    }

    return event;
  },
});

if (Boolean(window.env.REACT_APP_AMPLITUDE_API_KEY)) {
  const appVersion = window.env.REACT_APP_AMPLITUDE_ENVIRONMENT_NAME
    ? `${window.env.REACT_APP_AMPLITUDE_ENVIRONMENT_NAME}-${appPackage.version}`
    : appPackage.version;

  amplitude.init(window.env.REACT_APP_AMPLITUDE_API_KEY, undefined, {
    appVersion: appVersion,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <HelmetProvider>
        <BrowserRouter>
          <Auth0ProviderWithRedirectCallback>
            <InstillQueryClientProvider>
              <QueryParamProvider adapter={ReactRouter6Adapter}>
                <ThemeContextProvider>
                  <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    closeOnClick
                    hideProgressBar={false}
                    pauseOnFocusLoss={false}
                    theme="colored"
                    closeButton={false}
                  />

                  <ApplicationRoutes />
                </ThemeContextProvider>
              </QueryParamProvider>

              {isLocalHost && (
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position="bottom-right"
                />
              )}
            </InstillQueryClientProvider>
          </Auth0ProviderWithRedirectCallback>
        </BrowserRouter>
      </HelmetProvider>
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
