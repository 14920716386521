import {routeNames} from 'constants/route-names';
import {ComponentProps, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import Button from 'ui/@_components/button';
import Dialog from 'ui/@_components/kit/dialog';
import {ReactComponent as ChevronRightIcon} from 'ui/@_components/kit/icons/chevron-right.svg';
import UserProfileAvatar from 'ui/@_components/user-profile-avatar';
import useFlowData from 'ui/app/sense/meetings/show-v2/@hooks/use-flow-data';
import styles from './styles.module.scss';

const MAX_PARTICIPANTS_TO_SHOW = 2;

const Participants = () => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show-v2.components.meeting-details.components.info-summary.components.participants',
  });

  const [showParticipantsDialog, setShowParticipantsDialog] = useState(false);

  const {applicationRoute, peopleTeamsRoute, peopleRoute} = routeNames;
  const {participantsWithImages, isCompanySelected} = useFlowData();

  const generatePropsForAvatar = (
    participant: (typeof participantsWithImages)[0]
  ) => {
    let props: ComponentProps<typeof UserProfileAvatar> = {
      useRandomColors: true,
      profilePic: participant?.picture ?? null,
      userName: participant.name,
    };

    if (!!participant.uuid && isCompanySelected) {
      props.userUuid = participant.uuid;
      props.asLink = true;
    }

    return props;
  };

  const getParticipantNameElement = (
    participant: (typeof participantsWithImages)[0]
  ) => {
    if (!participant.uuid) return <p>{participant.name}</p>;

    return (
      <Link
        to={`/${applicationRoute}/${peopleTeamsRoute}/${peopleRoute}/${participant.uuid}`}
      >
        <p>{participant.name}</p>
      </Link>
    );
  };
  return (
    <>
      <div className={styles.container}>
        <p className={styles.count}>
          {t('title', {
            participantCount: participantsWithImages.length,
          })}
        </p>

        <div className={styles.divider} />

        <div className={styles.participantContainer}>
          {participantsWithImages
            .slice(0, MAX_PARTICIPANTS_TO_SHOW)
            .map((participant, index) => {
              return (
                <div className={styles.participant} key={index}>
                  <UserProfileAvatar {...generatePropsForAvatar(participant)} />

                  {getParticipantNameElement(participant)}
                </div>
              );
            })}
        </div>

        <div className={styles.divider} />

        <div>
          <Button
            variant="tertiaryInversed"
            onClick={() => setShowParticipantsDialog(!showParticipantsDialog)}
          >
            {t('view-all')}

            <ChevronRightIcon />
          </Button>
        </div>
      </div>

      <Dialog
        isOpen={showParticipantsDialog}
        onClose={() => setShowParticipantsDialog(false)}
      >
        <Dialog.Header
          title={t('dialog-title', {
            participantCount: participantsWithImages.length,
          })}
        />

        <Dialog.Content>
          <div className={styles.participantDialogContainer}>
            {participantsWithImages.map((participant, index) => {
              return (
                <div className={styles.participantDialog} key={index}>
                  <UserProfileAvatar {...generatePropsForAvatar(participant)} />

                  {getParticipantNameElement(participant)}
                </div>
              );
            })}
          </div>
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default Participants;
