import {sanitizeAssessmentStatusByVitalSignFromRaw} from 'ui/@sanitizers/assessment';
import isResponseSuccessful from 'utils/is-response-successful';
import addUrlQueriesConditionally from 'utils/url-utils/add-url-queries-conditionally';
import InstillAPI from '../axios-instance';

export interface FetchAssessmentStatusByVitalSignPayload {
  companyUuid: string;
  vitalSign: string;
}

export const fetchAssessmentStatusByVitalSign = async (
  parameters: FetchAssessmentStatusByVitalSignPayload
) => {
  const {companyUuid, vitalSign} = parameters;

  const url = addUrlQueriesConditionally(
    `/user-assessments/me/vital-sign-status`,
    [
      {
        key: 'vital_sign_slug',
        value: vitalSign,
        condition: !!vitalSign,
      },
      {
        key: 'company_uuid',
        value: companyUuid,
        condition: !!companyUuid,
      },
    ]
  );

  try {
    const response = await InstillAPI.get(url);

    if (!isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeAssessmentStatusByVitalSignFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
};
