import {sanitizeMeetingsListByVitalSignFromRaw} from 'ui/@sanitizers/meeting';
import addUrlQueriesConditionally from 'utils/url-utils/add-url-queries-conditionally';
import isResponseSuccessful from '../../../utils/is-response-successful';
import InstillAPI from '../axios-instance';

export interface FetchMeetingsListByVitalSignsPayload {
  companyUuid: string;
  vitalSign: string;
  teamUuid?: string;
}

export async function fetchMeetingsListByVitalSign(
  parameters: FetchMeetingsListByVitalSignsPayload
) {
  const {companyUuid, teamUuid, vitalSign} = parameters;

  const url = addUrlQueriesConditionally(
    `/companies/${companyUuid}/meetings-by-vital-signs`,
    [
      {
        key: 'vital_sign',
        value: vitalSign,
        condition: vitalSign !== undefined,
      },
      {
        key: 'team_uuid',
        value: teamUuid,
        condition: teamUuid !== undefined,
      },
    ]
  );

  try {
    const response = await InstillAPI.get(url);

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeMeetingsListByVitalSignFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
