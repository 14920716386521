import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

const useShareUrl = () => {
  const {t} = useTranslation('common');

  const onShare = (url: string, feedbackMessage?: string) => {
    const defaultFeedbackMessage = t('share-feedback-message');

    toast.success(feedbackMessage || defaultFeedbackMessage);

    navigator.clipboard.writeText(`${window.location.origin}/${url}`);
  };

  return {onShare};
};

export default useShareUrl;
