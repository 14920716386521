import {Plans, paidPlans} from 'constants/plans';
import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';
import roundOff from 'utils/number/round-off';
import {useCompanyEmployees} from './queries';
import {useCurrentSubscriptions} from './queries/use-current-subscriptions';

const usePlanLimits = () => {
  const companyUuid = useSafeCurrentCompanyUuid();

  const currentSubscriptions = useCurrentSubscriptions({
    variables: {
      companyUuid,
    },
  });

  const currentPlans = currentSubscriptions.data?.map((subscription) => {
    return subscription.product.name;
  }) || [Plans.FREE_PLAN];

  const totalInstillFlowCredit = roundOff(
    (currentSubscriptions.data?.reduce((total, current) => {
      return total + current.maxFlowCreditCount;
    }, 0) ?? 0) /
      (60 * 60),
    1
  );

  const totalCandidateCalibrationAssessment =
    currentSubscriptions.data?.reduce((total, current) => {
      return total + current.maxAssessmentCount;
    }, 0) ?? 0;

  const totalRemainingInstillFlowCredit = roundOff(
    (currentSubscriptions.data?.reduce((total, current) => {
      return total + current.flowCreditCount;
    }, 0) ?? 0) /
      (60 * 60),
    1
  );

  const totalRemainingCandidateCalibrationAssessment =
    currentSubscriptions.data?.reduce((total, current) => {
      return total + current.assessmentCount;
    }, 0) ?? 0;

  const userLimits = currentSubscriptions.data?.map(
    (subscription) => subscription.numSeatsCount
  );

  const minUserLimit = userLimits?.length ? Math.min(...userLimits) : 0;

  const companyEmployees = useCompanyEmployees({
    variables: {
      companyUuid,
      limit: 0,
      offset: 0,
      includeInvitedUsers: true,
    },
  });

  const totalUsers = companyEmployees.data?.data.length || 0;

  const isInstillFlowLimitExceeded = totalRemainingInstillFlowCredit === 0;
  const isCandidateCalibrationAssessmentLimitExceeded =
    totalRemainingCandidateCalibrationAssessment === 0;

  const getIsUserLimitExceeded = (newUsersCount: number) => {
    if (!paidPlans.find((plan) => plan === currentPlans[0])) {
      return false;
    }

    return totalUsers + newUsersCount > minUserLimit;
  };

  return {
    isInstillFlowLimitExceeded,
    isCandidateCalibrationAssessmentLimitExceeded,
    getIsUserLimitExceeded,
    totalRemainingInstillFlowCredit,
    totalRemainingCandidateCalibrationAssessment,
    totalInstillFlowCredit,
    totalCandidateCalibrationAssessment,
  };
};

export default usePlanLimits;
