import {ComponentProps} from 'react';
import HorizontalProgressBar from '../horizontal/horizontal';
import styles from './styles.module.scss';

interface ElementProps {
  size?: ComponentProps<typeof HorizontalProgressBar>['size'];
  percentageLeft: number;
  percentageRight: number;
}

const BiDirectionBar = ({
  size = 'x-large',
  percentageLeft,
  percentageRight,
}: ElementProps) => {
  return (
    <div className={styles.barWrapper}>
      <HorizontalProgressBar
        percentage={percentageLeft}
        size={size}
        containerClassName={styles.barContainerLeft}
        progressBarClassName={styles.barInnerLeft}
      />
      <HorizontalProgressBar
        percentage={percentageRight}
        size={size}
        containerClassName={styles.barContainerRight}
        progressBarClassName={styles.barInnerRight}
      />
    </div>
  );
};

export default BiDirectionBar;
