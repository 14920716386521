import {FunctionComponent, HTMLAttributes} from 'react';
import styles from './styles.module.scss';

type VARIANT_LARGE = 'large' | 'normal' | 'small';

type SpinnerProps = HTMLAttributes<HTMLButtonElement> & {
  variant?: VARIANT_LARGE;
  fullScreen?: boolean;
  className?: string;
};

const Spinner: FunctionComponent<SpinnerProps> = ({
  variant = 'normal',
  fullScreen,
  className,
}) => {
  let wrapperClassName = fullScreen
    ? styles.fullScreenContainer
    : styles.container;

  if (className) {
    wrapperClassName += ` ${className}`;
  }

  if (fullScreen) {
    return (
      <div className={wrapperClassName}>
        <span className={styles.large} />
      </div>
    );
  }

  return (
    <div className={wrapperClassName}>
      <span className={styles[variant]} />
    </div>
  );
};

export default Spinner;
