import isResponseSuccessful from 'utils/is-response-successful';
import InstillAPI from '../axios-instance';

export interface SeatsRequestPayload {
  companyUuid: string;
  seatsRequested: number;
}

export const seatsRequest = async ({
  companyUuid,
  seatsRequested,
}: SeatsRequestPayload) => {
  const response = await InstillAPI.post(
    `/companies/${companyUuid}/seats-request`,
    {
      seats_requested: seatsRequested,
    }
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return response.data as string;
};
