import {
  type ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

const Themes = ['light', 'dark', 'culture-force'] as const;

type Theme = (typeof Themes)[number];

interface Context {
  setTheme: (newTheme: Theme) => void;
  theme: Theme;
  toggleMode: () => void;
}

const ThemeContext = createContext<Context | null>(null);

export const useThemeContext = () => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useThemeContext must be used within ThemeContextProvider');
  }

  return {
    ...context,
    isLightMode: context.theme === 'light',
    isDarkMode: context.theme === 'dark',
  };
};

export const ThemeContextProvider = ({children}: {children: ReactNode}) => {
  const localStorageValue = localStorage.getItem('theme') as Theme;

  const getInitialTheme = () => {
    if (Themes.includes(localStorageValue)) {
      return localStorageValue;
    }

    return 'light';
  };

  const [theme, setTheme] = useState<Theme>(getInitialTheme());

  useEffect(() => {
    if (!['light', 'dark'].includes(localStorageValue)) {
      return localStorage.removeItem('theme');
    }
  }, [localStorageValue]);

  useEffect(() => {
    document.body.classList.forEach((className) => {
      if (className.includes('theme-')) {
        document.body.classList.remove(className);
      }
    });
    document.body.classList.add(`theme-${theme}`);
  }, [theme]);

  const onSetTheme: Context['setTheme'] = useCallback((newTheme) => {
    if (newTheme === 'dark' || newTheme === 'light') {
      localStorage.setItem('theme', newTheme);
    }

    setTheme(newTheme);
  }, []);

  const toggleMode = () => {
    if (theme === 'light') {
      onSetTheme('dark');
    } else {
      onSetTheme('light');
    }
  };

  return (
    <ThemeContext.Provider value={{setTheme: onSetTheme, theme, toggleMode}}>
      {children}
    </ThemeContext.Provider>
  );
};
