import {useQueryClient} from '@tanstack/react-query';
import {createMutation} from 'react-query-kit';
import {Team} from '../../../interfaces/team';
import {createTeam} from '../../../repositories/instill';
import {CreateTeamPayload} from '../../../repositories/instill/mutations/create-team';
import {
  RQ_FULL_USER_PROFILE,
  RQ_INFINITE_ELIGIBLE_MANAGERS,
  RQ_TEAMS,
} from '../queries/query-keys';

export const useCreateTeam = () => {
  const queryClient = useQueryClient();

  return createMutation<Team | undefined, CreateTeamPayload>({
    mutationFn: async (variables) => {
      return await createTeam({
        companyUuid: variables.companyUuid,
        payload: variables.payload,
      });
    },
    onSuccess() {
      queryClient.invalidateQueries([RQ_TEAMS]);
      queryClient.invalidateQueries([RQ_FULL_USER_PROFILE]);
      queryClient.invalidateQueries([RQ_INFINITE_ELIGIBLE_MANAGERS]);
    },
  })();
};
