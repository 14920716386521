import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Button from 'ui/@_components/button';
import BaseCard from 'ui/@_components/card/base-card';
import useFlowData from '../../../../@hooks/use-flow-data';
import styles from './styles.module.scss';

const Overview = () => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show-v2.components.meeting-details.components.overview',
  });

  const [isReadMore, setIsReadMore] = useState(false);

  const {meeting} = useFlowData();

  if (!meeting) return <></>;

  const summaryToShow = isReadMore
    ? meeting.summary.summary
    : meeting.summary.summary.slice(0, 2);

  return (
    <BaseCard>
      <div className={styles.container}>
        <span className={styles.title}>{t('title')}</span>

        <ul className={styles.listContainer}>
          {summaryToShow.map((summary, index) => {
            return (
              <li key={index} className={styles.listItem}>
                <span>•</span>
                <p>{summary}</p>
              </li>
            );
          })}
        </ul>

        <div>
          <Button variant="tertiary" onClick={() => setIsReadMore(!isReadMore)}>
            {!isReadMore ? t('read-more') : t('read-less')}
          </Button>
        </div>
      </div>
    </BaseCard>
  );
};

export default Overview;
