import {createMutation} from 'react-query-kit';
import {InviteSense} from '../../../interfaces/meeting';
import {inviteSense} from '../../../repositories/instill';
import {InviteSensePayload} from '../../../repositories/instill/mutations/invite-sense';
import {useQueryClient} from '@tanstack/react-query';
import {RQ_MEETINGS, RQ_MEETINGS_ANALYSIS} from '../queries/query-keys';
import {useSyncSetFullUserProfile} from '../queries';

export const useInviteSense = () => {
  const queryClient = useQueryClient();
  const syncSetFullUserProfile = useSyncSetFullUserProfile();

  return createMutation<InviteSense | undefined, InviteSensePayload>({
    mutationFn: async (variables) => {
      return await inviteSense({
        companyUuid: variables.companyUuid,
        payload: variables.payload,
      });
    },
    onSuccess(_, variables) {
      queryClient.invalidateQueries([RQ_MEETINGS]);
      queryClient.invalidateQueries([RQ_MEETINGS_ANALYSIS]);

      syncSetFullUserProfile(
        {
          companyUuid: variables.companyUuid,
        },
        (prev) =>
          prev
            ? {
                ...(prev || {}),
                fullOnboardingChecklist: {
                  ...prev.fullOnboardingChecklist,
                  hasAddedFirstMeeting: true,
                },
              }
            : prev
      );
    },
  })();
};
