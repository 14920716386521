import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import BaseCard from 'ui/@_components/card/base-card';
import Dialog from 'ui/@_components/kit/dialog';
import {ReactComponent as ChevronDownIcon} from 'ui/@_components/kit/icons/chevron-down.svg';
import {ReactComponent as ChevronRight} from 'ui/@_components/kit/icons/chevron-right.svg';
import HorizontalProgressBar from 'ui/@_components/progress-bar/horizontal/horizontal';
import {handleBurnoutName} from 'utils/handle-renames/burnout';
import useFlowData from '../../../../@hooks/use-flow-data';
import ValuesVitalSignHelper from '../values-vital-sign-helper';
import styles from './styles.module.scss';

const CustomCultureKpi = () => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show-v2.components.meeting-details.components.custom-culture-kpi',
  });

  const [isCultureKPIsExpanded, setIsCultureKPIsExpanded] = useState(true);

  const [showCultureKPI, setShowCultureKPI] = useState(false);
  const [activeCultureKPI, setActiveCultureKPI] = useState(0);

  const {meeting} = useFlowData();

  if (!meeting || !meeting.customCultureKpi) return <></>;

  const {customCultureKpi} = meeting;

  const onOpenCultureKPI = (index: number) => {
    setActiveCultureKPI(index);

    setShowCultureKPI(true);
  };

  const getFeedbackCount = (feedback: string) => {
    const feedbackList = feedback.split('. ');

    return feedbackList.length;
  };

  return (
    <>
      <div className={styles.container}>
        <BaseCard>
          <p className={styles.title}>
            {t('title')}

            <button
              className={
                isCultureKPIsExpanded
                  ? styles.expandedButton
                  : styles.unExpandedButton
              }
              onClick={() => setIsCultureKPIsExpanded(!isCultureKPIsExpanded)}
            >
              <ChevronDownIcon />
            </button>
          </p>

          {isCultureKPIsExpanded && (
            <ul className={styles.listContainer}>
              {customCultureKpi.map((cultureKPI, index) => (
                <li
                  className={styles.list}
                  key={index}
                  onClick={() => onOpenCultureKPI(index)}
                >
                  <div className={styles.nameContainer}>
                    {cultureKPI.name}

                    <p>
                      {`Feedback (${getFeedbackCount(cultureKPI.feedback)})`}
                      <ChevronRight className={styles.rightIcon} />
                    </p>
                  </div>

                  <div className={styles.progressBarContainer}>
                    <HorizontalProgressBar percentage={cultureKPI.score} />
                    {cultureKPI.score}%
                  </div>
                </li>
              ))}
            </ul>
          )}
        </BaseCard>
      </div>

      <Dialog
        variant="large"
        isOpen={showCultureKPI}
        onClose={() => {
          setShowCultureKPI(!showCultureKPI);
        }}
      >
        <Dialog.Header
          title={handleBurnoutName(customCultureKpi[activeCultureKPI].name)}
        />

        <Dialog.Content>
          <ValuesVitalSignHelper
            parameter={customCultureKpi[activeCultureKPI]}
          />
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default CustomCultureKpi;
