import {CandidateAlignmentResult} from 'interfaces/candidate-alignment-result';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import HexacoRadar from 'ui/@_components/hexaco-radar';
import Dialog from 'ui/@_components/kit/dialog';
import BiDirectionBar from 'ui/@_components/progress-bar/bi-direction-bar/bi-direction-bar';
import HorizontalProgressBar from 'ui/@_components/progress-bar/horizontal/horizontal';
import Radio from 'ui/@_components/radio/radio';
import Search from 'ui/@_components/search';
import Spinner from 'ui/@_components/spinner';
import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';
import {useCandidatesForJob} from 'ui/@hooks/queries';
import {useCandidateAlignmentResult} from 'ui/@hooks/queries/use-candidate-alignment-result';
import {StringParam, useQueryParams} from 'use-query-params';
import styles from './styles.module.scss';
import {EventName} from 'constants/analytics/event-name';
import {EventType} from 'constants/analytics/event-type';
import useAnalytics from 'ui/@hooks/use-analytics';

interface ElementProps {
  currentCandidateData: CandidateAlignmentResult;
  isOpen: boolean;
  onClose: () => void;
}

const CompareModal = ({
  isOpen,
  onClose,
  currentCandidateData,
}: ElementProps) => {
  const {t} = useTranslation('candidateCalibration', {
    keyPrefix:
      'job-positions.show.report.components.main-content.components.report-details.components.compare-modal',
  });
  const {trackEvent} = useAnalytics();

  const companyUuid = useSafeCurrentCompanyUuid();

  const {jobPositionId: jobPositionUuid} = useParams();

  const [queryParameters, setQueryParameters] = useQueryParams({
    query: StringParam,
  });

  const candidatesForJob = useCandidatesForJob({
    variables: {
      companyUuid,
      jobPositionUuid: jobPositionUuid || '',
      assessmentStatusFilter: 'completed',
      query: queryParameters.query ?? undefined,
    },
  });

  const [selectedCandidateUuid, setSelectedCandidate] = useState(
    currentCandidateData.candidateProfile.candidateUuid
  );

  const candidateAlignmentResult = useCandidateAlignmentResult({
    variables: {
      candidateUuid: selectedCandidateUuid,
    },
  });

  const onRadioClick = (candidateUuid: string, candidateName: string) => {
    trackEvent({
      eventName:
        EventName.CANDIDATE_CALIBRATION.JOB_POSITIONS_SHOW.REPORT
          .REPORT_COMPARE_SELECTED,
      eventType: EventType.BUTTON_CLICKED,
      eventProperties: {
        secondCandidateName: candidateName,
      },
    });
    setSelectedCandidate(candidateUuid);

    candidateAlignmentResult.refetch();
  };

  const {
    candidateResult: {moduleResult},
  } = currentCandidateData;

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      innerContainerClassName={styles.compareModal}
    >
      <Dialog.Header
        title={t('title', {
          candidateName: currentCandidateData.candidateProfile.name,
        })}
      />

      <Dialog.Content>
        <div className={styles.modalContent}>
          <div className={styles.leftContent}>
            <Search
              value={queryParameters?.query || ''}
              onChange={(text) => {
                setQueryParameters({
                  query: text,
                });
              }}
              placeholder={t('search-placeholder')}
              customContainerCss={styles.searchBox}
            />

            {candidatesForJob.data?.data && (
              <ul className={styles.candidatesWrapper}>
                {candidatesForJob.data?.data.map((candidate, index) => (
                  <li key={index}>
                    <div
                      onClick={() =>
                        onRadioClick(candidate.candidateUuid, candidate.name)
                      }
                      className={styles.candidateRow}
                    >
                      <div className={styles.radioWrapper}>
                        <Radio
                          checked={
                            selectedCandidateUuid === candidate.candidateUuid
                          }
                        />

                        {candidate.name}
                      </div>

                      <div className={styles.progressBarWrapper}>
                        <HorizontalProgressBar
                          percentage={candidate.valuesCalibrationScore}
                          size="small"
                        />
                        {candidate.valuesCalibrationScore}%
                      </div>
                    </div>

                    <div className={styles.divider} />
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className={styles.rightContent}>
            {candidateAlignmentResult.isLoading ||
            !candidateAlignmentResult.data ? (
              <Spinner />
            ) : (
              <div className={styles.hexacoContent}>
                <BiDirectionBar
                  percentageLeft={moduleResult.alignmentProfile.score}
                  percentageRight={
                    candidateAlignmentResult.data.candidateResult.moduleResult
                      .alignmentProfile.score
                  }
                />

                <HexacoRadar
                  personalityScore={[
                    getFirstCandidateHexaco(moduleResult),
                    getSecondCandidateHexaco(candidateAlignmentResult.data),
                  ]}
                />
              </div>
            )}

            {/* <div className={styles.compareInfoWrapper}>
              <p className={styles.compareInfoTitle}>
                {t('compare-info-title')}
              </p>

              <p className={styles.compareInfo}>
                candidate seems to be a good fit for the company based on their
                responses, which demonstrate qualities such as problem-solving
                skills, effective communication, collaboration, and a commitment
                to continuous improvement and customer satisfaction.
              </p>
            </div> */}
          </div>
        </div>
      </Dialog.Content>
    </Dialog>
  );
};

export default CompareModal;

const getFirstCandidateHexaco = (
  moduleResult: CandidateAlignmentResult['candidateResult']['moduleResult']
) => {
  const {
    personalityProfile: {
      agreeableness,
      conscientiousness,
      emotionality,
      extroversion,
      honesty,
      openness,
    },
  } = moduleResult;

  return {
    agreeableness,
    conscientiousness,
    emotionality,
    extroversion,
    honesty,
    openness,
  };
};

const getSecondCandidateHexaco = (data: CandidateAlignmentResult) => {
  const {
    candidateResult: {
      moduleResult: {
        personalityProfile: {
          agreeableness,
          conscientiousness,
          emotionality,
          extroversion,
          honesty,
          openness,
        },
      },
    },
  } = data;

  return {
    agreeableness,
    conscientiousness,
    emotionality,
    extroversion,
    honesty,
    openness,
  };
};
