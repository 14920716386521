import {Team} from 'interfaces/team';

export const dummyTeams: Team[] = [
  {
    uuid: 'e049a182-37f6-4e6d-bf1b-216ee9ed3cb6',
    name: 'Support Team',
    colorHex: '#fefefe',
    teamSize: 2,
    managers: [
      {
        connectedCalendars: [],
        personalEmail: null,
        kudosGivenCount: null,
        kudosReceivedCount: null,
        percentageComplete: 0,
        companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
        fullOnboardingChecklist: {
          skippedUserInvite: false,
          hasAnyPersonalActionStep: true,
          hasCreatedValue: true,
          hasCompletedProfile: true,
          hasAddedFirstMeeting: true,
          hasCreatedProfile: true,
          hasSignedOff: true,
          hasAddedCompanyValues: true,
          hasConfiguredAssessment: true,
          hasCreatedCultureKpi: true,
          hasGeneratedMeetingReport: true,
          hasInvitedCandidates: true,
          hasInvitedFirstUser: true,
          hasSeenFirstMeetingReport: true,
          hasSelectedBusinessOutcomes: true,
          hasSharedFirstMeetingReport: true,
          hasSyncedCalendar: true,
          hasTakenPersonalityTest: true,
        },
        isOnboarded: false,
        funFact: 'I breathe through my nose',
        jobTitle: 'Sr Software Engineer',
        kudosRemaining: 20,
        linkedinUrl: null,
        location: null,
        name: 'Sam Smith',
        phoneNumber: '6132862308',
        picture: '/static/demo-account/people/person2.jpeg',
        preferredName: 'Sam',
        pronouns: null,
        role: 'admin',
        shortBio: 'Well',
        status: 'joined',
        timeZone: 'US/Eastern',
        uuid: '05fdc4f7-7a9b-4579-849e-ee0621aabb84',
        workEmail: 'sam@instill.ai',
      },
    ],
    description: '',
    members: [
      {
        connectedCalendars: [],
        personalEmail: null,
        kudosGivenCount: null,
        kudosReceivedCount: null,
        percentageComplete: 0,
        companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
        fullOnboardingChecklist: {
          skippedUserInvite: false,
          hasAnyPersonalActionStep: true,
          hasCompletedProfile: true,
          hasCreatedValue: true,
          hasAddedFirstMeeting: true,
          hasCreatedProfile: true,
          hasSignedOff: true,
          hasAddedCompanyValues: true,
          hasConfiguredAssessment: true,
          hasCreatedCultureKpi: true,
          hasGeneratedMeetingReport: true,
          hasInvitedCandidates: true,
          hasInvitedFirstUser: true,
          hasSeenFirstMeetingReport: true,
          hasSelectedBusinessOutcomes: true,
          hasSharedFirstMeetingReport: true,
          hasSyncedCalendar: true,
          hasTakenPersonalityTest: true,
        },
        isOnboarded: false,
        funFact: 'I breathe through my nose',
        jobTitle: 'Product Manager',
        kudosRemaining: 20,
        linkedinUrl: null,
        location: null,
        name: 'Dan Johnson',
        phoneNumber: '6132862308',
        picture: '/static/demo-account/people/person4.png',
        preferredName: 'Dan',
        pronouns: null,
        role: 'admin',
        shortBio: 'Well',
        status: 'joined',
        timeZone: 'US/Eastern',
        uuid: '05fdc4f7-7a9b-4579-849e-ee0621aabb84',
        workEmail: 'dan@instill.ai',
      },
      {
        connectedCalendars: [],
        personalEmail: null,
        kudosGivenCount: null,
        kudosReceivedCount: null,
        percentageComplete: 0,
        companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
        fullOnboardingChecklist: {
          skippedUserInvite: false,
          hasAnyPersonalActionStep: true,
          hasCompletedProfile: true,
          hasCreatedValue: true,
          hasAddedFirstMeeting: true,
          hasCreatedProfile: true,
          hasSignedOff: true,
          hasAddedCompanyValues: true,
          hasConfiguredAssessment: true,
          hasCreatedCultureKpi: true,
          hasGeneratedMeetingReport: true,
          hasInvitedCandidates: true,
          hasInvitedFirstUser: true,
          hasSeenFirstMeetingReport: true,
          hasSelectedBusinessOutcomes: true,
          hasSharedFirstMeetingReport: true,
          hasSyncedCalendar: true,
          hasTakenPersonalityTest: true,
        },
        isOnboarded: false,
        funFact: 'I breathe through my nose',
        jobTitle: 'Product Manager',
        kudosRemaining: 20,
        linkedinUrl: null,
        location: null,
        name: 'Dan Johnson',
        phoneNumber: '6132862308',
        picture: '/static/demo-account/people/person4.png',
        preferredName: 'Dan',
        pronouns: null,
        role: 'admin',
        shortBio: 'Well',
        status: 'joined',
        timeZone: 'US/Eastern',
        uuid: '05fdc4f7-7a9b-4579-849e-ee0621aabb84',
        workEmail: 'dan@instill.ai',
      },
    ],
  },
  {
    uuid: 'ec85e407-f557-4194-8e35-81ad531f2a28',
    name: 'Design Team',
    colorHex: '#fefefe',
    teamSize: 3,
    managers: [
      {
        connectedCalendars: [],
        personalEmail: null,
        kudosGivenCount: null,
        kudosReceivedCount: null,
        percentageComplete: 0,
        companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
        fullOnboardingChecklist: {
          skippedUserInvite: false,
          hasAnyPersonalActionStep: true,
          hasCompletedProfile: true,
          hasCreatedValue: true,
          hasAddedFirstMeeting: true,
          hasCreatedProfile: true,
          hasSignedOff: true,
          hasAddedCompanyValues: true,
          hasConfiguredAssessment: true,
          hasCreatedCultureKpi: true,
          hasGeneratedMeetingReport: true,
          hasInvitedCandidates: true,
          hasInvitedFirstUser: true,
          hasSeenFirstMeetingReport: true,
          hasSelectedBusinessOutcomes: true,
          hasSharedFirstMeetingReport: true,
          hasSyncedCalendar: true,
          hasTakenPersonalityTest: true,
        },
        isOnboarded: false,
        funFact: null,
        jobTitle: 'Sr Designer',
        kudosRemaining: 20,
        linkedinUrl: null,
        location: null,
        name: 'Will Smith',
        phoneNumber: '+91 98704 33452',
        picture: '/static/demo-account/people/person6.png',
        preferredName: 'Will',
        pronouns: null,
        role: 'manager',
        shortBio: null,
        status: 'joined',
        timeZone: 'Asia/Calcutta',
        uuid: '47b47d84-972f-4b38-820d-67ed914ad858',
        workEmail: 'will@instill.ai',
      },
    ],
    description: '',
    members: [
      {
        connectedCalendars: [],
        personalEmail: null,
        kudosGivenCount: null,
        kudosReceivedCount: null,
        percentageComplete: 0,
        companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
        fullOnboardingChecklist: {
          skippedUserInvite: false,
          hasAnyPersonalActionStep: true,
          hasCompletedProfile: true,
          hasCreatedValue: true,
          hasAddedFirstMeeting: true,
          hasCreatedProfile: true,
          hasSignedOff: true,
          hasAddedCompanyValues: true,
          hasConfiguredAssessment: true,
          hasCreatedCultureKpi: true,
          hasGeneratedMeetingReport: true,
          hasInvitedCandidates: true,
          hasInvitedFirstUser: true,
          hasSeenFirstMeetingReport: true,
          hasSelectedBusinessOutcomes: true,
          hasSharedFirstMeetingReport: true,
          hasSyncedCalendar: true,
          hasTakenPersonalityTest: true,
        },
        isOnboarded: false,
        funFact: 'I breathe through my nose',
        jobTitle: 'Product Manager',
        kudosRemaining: 20,
        linkedinUrl: null,
        location: null,
        name: 'Dan Johnson',
        phoneNumber: '6132862308',
        picture: '/static/demo-account/people/person4.png',
        preferredName: 'Dan',
        pronouns: null,
        role: 'admin',
        shortBio: 'Well',
        status: 'joined',
        timeZone: 'US/Eastern',
        uuid: '05fdc4f7-7a9b-4579-849e-ee0621aabb84',
        workEmail: 'dan@instill.ai',
      },
      {
        connectedCalendars: [],
        personalEmail: null,
        kudosGivenCount: null,
        kudosReceivedCount: null,
        percentageComplete: 0,
        companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
        fullOnboardingChecklist: {
          skippedUserInvite: false,
          hasAnyPersonalActionStep: true,
          hasCompletedProfile: true,
          hasCreatedValue: true,
          hasAddedFirstMeeting: true,
          hasCreatedProfile: true,
          hasSignedOff: true,
          hasAddedCompanyValues: true,
          hasConfiguredAssessment: true,
          hasCreatedCultureKpi: true,
          hasGeneratedMeetingReport: true,
          hasInvitedCandidates: true,
          hasInvitedFirstUser: true,
          hasSeenFirstMeetingReport: true,
          hasSelectedBusinessOutcomes: true,
          hasSharedFirstMeetingReport: true,
          hasSyncedCalendar: true,
          hasTakenPersonalityTest: true,
        },
        isOnboarded: false,
        funFact: 'I breathe through my nose',
        jobTitle: 'Product Manager',
        kudosRemaining: 20,
        linkedinUrl: null,
        location: null,
        name: 'Dan Johnson',
        phoneNumber: '6132862308',
        picture: '/static/demo-account/people/person4.png',
        preferredName: 'Dan',
        pronouns: null,
        role: 'admin',
        shortBio: 'Well',
        status: 'joined',
        timeZone: 'US/Eastern',
        uuid: '05fdc4f7-7a9b-4579-849e-ee0621aabb84',
        workEmail: 'dan@instill.ai',
      },
      {
        connectedCalendars: [],
        personalEmail: null,
        kudosGivenCount: null,
        kudosReceivedCount: null,
        percentageComplete: 0,
        companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
        fullOnboardingChecklist: {
          skippedUserInvite: false,
          hasAnyPersonalActionStep: true,
          hasCompletedProfile: true,
          hasCreatedValue: true,
          hasCreatedProfile: true,
          hasAddedFirstMeeting: true,
          hasSignedOff: true,
          hasAddedCompanyValues: true,
          hasConfiguredAssessment: true,
          hasCreatedCultureKpi: true,
          hasGeneratedMeetingReport: true,
          hasInvitedCandidates: true,
          hasInvitedFirstUser: true,
          hasSeenFirstMeetingReport: true,
          hasSelectedBusinessOutcomes: true,
          hasSharedFirstMeetingReport: true,
          hasSyncedCalendar: true,
          hasTakenPersonalityTest: true,
        },
        isOnboarded: false,
        funFact: 'I breathe through my nose',
        jobTitle: 'Product Manager',
        kudosRemaining: 20,
        linkedinUrl: null,
        location: null,
        name: 'Dan Johnson',
        phoneNumber: '6132862308',
        picture: '/static/demo-account/people/person4.png',
        preferredName: 'Dan',
        pronouns: null,
        role: 'admin',
        shortBio: 'Well',
        status: 'joined',
        timeZone: 'US/Eastern',
        uuid: '05fdc4f7-7a9b-4579-849e-ee0621aabb84',
        workEmail: 'dan@instill.ai',
      },
    ],
  },
  {
    uuid: 'ec85e407-f557-4194-8e35-81ad531f2a27',
    name: 'Product Team',
    colorHex: '#fefefe',
    teamSize: 3,
    managers: [
      {
        connectedCalendars: [],
        personalEmail: null,
        kudosGivenCount: null,
        kudosReceivedCount: null,
        percentageComplete: 0,
        companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
        fullOnboardingChecklist: {
          skippedUserInvite: false,
          hasAnyPersonalActionStep: true,
          hasCompletedProfile: true,
          hasCreatedValue: true,
          hasCreatedProfile: true,
          hasAddedFirstMeeting: true,
          hasSignedOff: true,
          hasAddedCompanyValues: true,
          hasConfiguredAssessment: true,
          hasCreatedCultureKpi: true,
          hasGeneratedMeetingReport: true,
          hasInvitedCandidates: true,
          hasInvitedFirstUser: true,
          hasSeenFirstMeetingReport: true,
          hasSelectedBusinessOutcomes: true,
          hasSharedFirstMeetingReport: true,
          hasSyncedCalendar: true,
          hasTakenPersonalityTest: true,
        },
        isOnboarded: false,
        funFact: null,
        jobTitle: 'CEO',
        kudosRemaining: 20,
        linkedinUrl: null,
        location: null,
        name: 'Jane Doe',
        phoneNumber: '+91 98704 33452',
        picture: '/static/demo-account/people/person1.jpeg',
        preferredName: 'Jane',
        pronouns: null,
        role: 'manager',
        shortBio: null,
        status: 'joined',
        timeZone: 'Asia/Calcutta',
        uuid: '47b47d84-972f-4b38-820d-67ed914ad858',
        workEmail: 'will@instill.ai',
      },
      {
        connectedCalendars: [],
        personalEmail: null,
        kudosGivenCount: null,
        kudosReceivedCount: null,
        percentageComplete: 0,
        companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
        fullOnboardingChecklist: {
          skippedUserInvite: false,
          hasAnyPersonalActionStep: true,
          hasCompletedProfile: true,
          hasCreatedValue: true,
          hasCreatedProfile: true,
          hasSignedOff: true,
          hasAddedCompanyValues: true,
          hasConfiguredAssessment: true,
          hasAddedFirstMeeting: true,
          hasCreatedCultureKpi: true,
          hasGeneratedMeetingReport: true,
          hasInvitedCandidates: true,
          hasInvitedFirstUser: true,
          hasSeenFirstMeetingReport: true,
          hasSelectedBusinessOutcomes: true,
          hasSharedFirstMeetingReport: true,
          hasSyncedCalendar: true,
          hasTakenPersonalityTest: true,
        },
        isOnboarded: false,
        funFact: null,
        jobTitle: 'CEO',
        kudosRemaining: 20,
        linkedinUrl: null,
        location: null,
        name: 'Jane Doe',
        phoneNumber: '+91 98704 33452',
        picture: '/static/demo-account/people/person4.png',
        preferredName: 'Jane',
        pronouns: null,
        role: 'manager',
        shortBio: null,
        status: 'joined',
        timeZone: 'Asia/Calcutta',
        uuid: '47b47d84-972f-4b38-820d-67ed914ad858',
        workEmail: 'will@instill.ai',
      },
      {
        connectedCalendars: [],
        personalEmail: null,
        kudosGivenCount: null,
        kudosReceivedCount: null,
        percentageComplete: 0,
        companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
        fullOnboardingChecklist: {
          skippedUserInvite: false,
          hasAnyPersonalActionStep: true,
          hasCompletedProfile: true,
          hasCreatedValue: true,
          hasCreatedProfile: true,
          hasSignedOff: true,
          hasAddedCompanyValues: true,
          hasAddedFirstMeeting: true,
          hasConfiguredAssessment: true,
          hasCreatedCultureKpi: true,
          hasGeneratedMeetingReport: true,
          hasInvitedCandidates: true,
          hasInvitedFirstUser: true,
          hasSeenFirstMeetingReport: true,
          hasSelectedBusinessOutcomes: true,
          hasSharedFirstMeetingReport: true,
          hasSyncedCalendar: true,
          hasTakenPersonalityTest: true,
        },
        isOnboarded: false,
        funFact: null,
        jobTitle: 'CEO',
        kudosRemaining: 20,
        linkedinUrl: null,
        location: null,
        name: 'Jane Doe',
        phoneNumber: '+91 98704 33452',
        picture: '/static/demo-account/profile.jpeg',
        preferredName: 'Jane',
        pronouns: null,
        role: 'manager',
        shortBio: null,
        status: 'joined',
        timeZone: 'Asia/Calcutta',
        uuid: '47b47d84-972f-4b38-820d-67ed914ad858',
        workEmail: 'will@instill.ai',
      },
    ],
    description: '',
    members: [
      {
        connectedCalendars: [],
        personalEmail: null,
        kudosGivenCount: null,
        kudosReceivedCount: null,
        percentageComplete: 0,
        companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
        fullOnboardingChecklist: {
          skippedUserInvite: false,
          hasAnyPersonalActionStep: true,
          hasAddedFirstMeeting: true,
          hasCompletedProfile: true,
          hasCreatedValue: true,
          hasCreatedProfile: true,
          hasSignedOff: true,
          hasAddedCompanyValues: true,
          hasConfiguredAssessment: true,
          hasCreatedCultureKpi: true,
          hasGeneratedMeetingReport: true,
          hasInvitedCandidates: true,
          hasInvitedFirstUser: true,
          hasSeenFirstMeetingReport: true,
          hasSelectedBusinessOutcomes: true,
          hasSharedFirstMeetingReport: true,
          hasSyncedCalendar: true,
          hasTakenPersonalityTest: true,
        },
        isOnboarded: false,
        funFact: 'I breathe through my nose',
        jobTitle: 'Product Manager',
        kudosRemaining: 20,
        linkedinUrl: null,
        location: null,
        name: 'Dan Johnson',
        phoneNumber: '6132862308',
        picture: '/static/demo-account/people/person4.png',
        preferredName: 'Dan',
        pronouns: null,
        role: 'admin',
        shortBio: 'Well',
        status: 'joined',
        timeZone: 'US/Eastern',
        uuid: '05fdc4f7-7a9b-4579-849e-ee0621aabb84',
        workEmail: 'dan@instill.ai',
      },
      {
        connectedCalendars: [],
        personalEmail: null,
        kudosGivenCount: null,
        kudosReceivedCount: null,
        percentageComplete: 0,
        companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
        fullOnboardingChecklist: {
          skippedUserInvite: false,
          hasAnyPersonalActionStep: true,
          hasAddedFirstMeeting: true,
          hasCompletedProfile: true,
          hasCreatedValue: true,
          hasCreatedProfile: true,
          hasSignedOff: true,
          hasAddedCompanyValues: true,
          hasConfiguredAssessment: true,
          hasCreatedCultureKpi: true,
          hasGeneratedMeetingReport: true,
          hasInvitedCandidates: true,
          hasInvitedFirstUser: true,
          hasSeenFirstMeetingReport: true,
          hasSelectedBusinessOutcomes: true,
          hasSharedFirstMeetingReport: true,
          hasSyncedCalendar: true,
          hasTakenPersonalityTest: true,
        },
        isOnboarded: false,
        funFact: 'I breathe through my nose',
        jobTitle: 'Product Manager',
        kudosRemaining: 20,
        linkedinUrl: null,
        location: null,
        name: 'Dan Johnson',
        phoneNumber: '6132862308',
        picture: '/static/demo-account/people/person4.png',
        preferredName: 'Dan',
        pronouns: null,
        role: 'admin',
        shortBio: 'Well',
        status: 'joined',
        timeZone: 'US/Eastern',
        uuid: '05fdc4f7-7a9b-4579-849e-ee0621aabb84',
        workEmail: 'dan@instill.ai',
      },
      {
        connectedCalendars: [],
        personalEmail: null,
        kudosGivenCount: null,
        kudosReceivedCount: null,
        percentageComplete: 0,
        companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
        fullOnboardingChecklist: {
          skippedUserInvite: false,
          hasAnyPersonalActionStep: true,
          hasCompletedProfile: true,
          hasCreatedValue: true,
          hasCreatedProfile: true,
          hasSignedOff: true,
          hasAddedCompanyValues: true,
          hasConfiguredAssessment: true,
          hasCreatedCultureKpi: true,
          hasGeneratedMeetingReport: true,
          hasInvitedCandidates: true,
          hasAddedFirstMeeting: true,
          hasInvitedFirstUser: true,
          hasSeenFirstMeetingReport: true,
          hasSelectedBusinessOutcomes: true,
          hasSharedFirstMeetingReport: true,
          hasSyncedCalendar: true,
          hasTakenPersonalityTest: true,
        },
        isOnboarded: false,
        funFact: 'I breathe through my nose',
        jobTitle: 'Product Manager',
        kudosRemaining: 20,
        linkedinUrl: null,
        location: null,
        name: 'Dan Johnson',
        phoneNumber: '6132862308',
        picture: '/static/demo-account/people/person4.png',
        preferredName: 'Dan',
        pronouns: null,
        role: 'admin',
        shortBio: 'Well',
        status: 'joined',
        timeZone: 'US/Eastern',
        uuid: '05fdc4f7-7a9b-4579-849e-ee0621aabb84',
        workEmail: 'dan@instill.ai',
      },
      {
        connectedCalendars: [],
        personalEmail: null,
        kudosGivenCount: null,
        kudosReceivedCount: null,
        percentageComplete: 0,
        companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
        fullOnboardingChecklist: {
          skippedUserInvite: false,
          hasAnyPersonalActionStep: true,
          hasCompletedProfile: true,
          hasCreatedValue: true,
          hasCreatedProfile: true,
          hasSignedOff: true,
          hasAddedFirstMeeting: true,
          hasAddedCompanyValues: true,
          hasConfiguredAssessment: true,
          hasCreatedCultureKpi: true,
          hasGeneratedMeetingReport: true,
          hasInvitedCandidates: true,
          hasInvitedFirstUser: true,
          hasSeenFirstMeetingReport: true,
          hasSelectedBusinessOutcomes: true,
          hasSharedFirstMeetingReport: true,
          hasSyncedCalendar: true,
          hasTakenPersonalityTest: true,
        },
        isOnboarded: false,
        funFact: 'I breathe through my nose',
        jobTitle: 'Product Manager',
        kudosRemaining: 20,
        linkedinUrl: null,
        location: null,
        name: 'Dan Johnson',
        phoneNumber: '6132862308',
        picture: '/static/demo-account/people/person4.png',
        preferredName: 'Dan',
        pronouns: null,
        role: 'admin',
        shortBio: 'Well',
        status: 'joined',
        timeZone: 'US/Eastern',
        uuid: '05fdc4f7-7a9b-4579-849e-ee0621aabb84',
        workEmail: 'dan@instill.ai',
      },
      {
        connectedCalendars: [],
        personalEmail: null,
        kudosGivenCount: null,
        kudosReceivedCount: null,
        percentageComplete: 0,
        companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
        fullOnboardingChecklist: {
          skippedUserInvite: false,
          hasAnyPersonalActionStep: true,
          hasCompletedProfile: true,
          hasCreatedValue: true,
          hasCreatedProfile: true,
          hasSignedOff: true,
          hasAddedCompanyValues: true,
          hasConfiguredAssessment: true,
          hasCreatedCultureKpi: true,
          hasAddedFirstMeeting: true,
          hasGeneratedMeetingReport: true,
          hasInvitedCandidates: true,
          hasInvitedFirstUser: true,
          hasSeenFirstMeetingReport: true,
          hasSelectedBusinessOutcomes: true,
          hasSharedFirstMeetingReport: true,
          hasSyncedCalendar: true,
          hasTakenPersonalityTest: true,
        },
        isOnboarded: false,
        funFact: 'I breathe through my nose',
        jobTitle: 'Product Manager',
        kudosRemaining: 20,
        linkedinUrl: null,
        location: null,
        name: 'Dan Johnson',
        phoneNumber: '6132862308',
        picture: '/static/demo-account/people/person4.png',
        preferredName: 'Dan',
        pronouns: null,
        role: 'admin',
        shortBio: 'Well',
        status: 'joined',
        timeZone: 'US/Eastern',
        uuid: '05fdc4f7-7a9b-4579-849e-ee0621aabb84',
        workEmail: 'dan@instill.ai',
      },
      {
        connectedCalendars: [],
        personalEmail: null,
        kudosGivenCount: null,
        kudosReceivedCount: null,
        percentageComplete: 0,
        companyUuid: '03b3998e-e55c-4544-88e6-015f18af850b',
        fullOnboardingChecklist: {
          skippedUserInvite: false,
          hasAnyPersonalActionStep: true,
          hasCompletedProfile: true,
          hasCreatedValue: true,
          hasAddedFirstMeeting: true,
          hasCreatedProfile: true,
          hasSignedOff: true,
          hasAddedCompanyValues: true,
          hasConfiguredAssessment: true,
          hasCreatedCultureKpi: true,
          hasGeneratedMeetingReport: true,
          hasInvitedCandidates: true,
          hasInvitedFirstUser: true,
          hasSeenFirstMeetingReport: true,
          hasSelectedBusinessOutcomes: true,
          hasSharedFirstMeetingReport: true,
          hasSyncedCalendar: true,
          hasTakenPersonalityTest: true,
        },
        isOnboarded: false,
        funFact: 'I breathe through my nose',
        jobTitle: 'Product Manager',
        kudosRemaining: 20,
        linkedinUrl: null,
        location: null,
        name: 'Dan Johnson',
        phoneNumber: '6132862308',
        picture: '/static/demo-account/people/person4.png',
        preferredName: 'Dan',
        pronouns: null,
        role: 'admin',
        shortBio: 'Well',
        status: 'joined',
        timeZone: 'US/Eastern',
        uuid: '05fdc4f7-7a9b-4579-849e-ee0621aabb84',
        workEmail: 'dan@instill.ai',
      },
    ],
  },
];
