import {JobPosition, JobPositionSummary} from 'interfaces/job-position';

export const dummyJobPositions = {
  data: [
    {
      createdAt: '2024-01-08T15:50:16.075536+00:00',
      isActive: true,
      title: 'Accountant',
      uuid: '77b7b92d-1dcf-41f2-abab9-69dbc90bfc49',
      closeDate: '2024-04-11T18:30:00+00:00',
      candidateDistribution: {
        numCandidates: 500,
        numMatches: 30,
        numCompletedAssessments: 408,
      },
    },
    {
      createdAt: '2024-01-08T15:50:16.075536+00:00',
      isActive: true,
      title: 'Sales Manager',
      uuid: '77b7b92d-1d8f-41f2-abc9-69dbc90bfc49',
      closeDate: '2024-04-11T18:30:00+00:00',
      candidateDistribution: {
        numCandidates: 480,
        numMatches: 55,
        numCompletedAssessments: 413,
      },
    },
    {
      createdAt: '2024-01-08T15:50:16.075536+00:00',
      isActive: true,
      title: 'Lawyer',
      uuid: '77b7b92d-1dcf-41f2-abc9-k9dbc90bfc49',
      closeDate: '2024-04-11T18:30:00+00:00',
      candidateDistribution: {
        numCandidates: 500,
        numMatches: 90,
        numCompletedAssessments: 489,
      },
    },
    {
      createdAt: '2024-01-18T18:42:47.794890+00:00',
      isActive: true,
      title: 'Translator',
      uuid: 'd95f331e-a831-4c3d-87a7-bc8e064c5734',
      closeDate: '2024-09-11T18:30:00+00:00',
      candidateDistribution: {
        numCandidates: 250,
        numMatches: 110,
        numCompletedAssessments: 220,
      },
    },
    {
      createdAt: '2024-02-07T17:50:09.916211+00:00',
      isActive: false,
      title: 'Entrepreneur',
      uuid: 'b21fef8d-9b23-haka7-8c74-3bcf00f91a27',
      closeDate: '2024-03-15T06:00:00+00:00',
      candidateDistribution: {
        numCandidates: 310,
        numMatches: 180,
        numCompletedAssessments: 250,
      },
    },
  ],
  meta: {
    currentPage: 1,
    totalEntries: 5,
    totalPages: 1,
  },
};

export const dummyJobPositionSummariesAll: JobPositionSummary[] = [
  {
    uuid: 'b5010066-657a-447d-b6aa-82ff245ad726',
    title: 'Accountant',
    averageScore: 40,
    responseReceived: 408,
    responseReceivedDelta: 8,
    averageScoreDelta: -2,
  },
  {
    uuid: 'b6c13b91-6b30-41a5-b448-ad0ff8341547',
    title: 'Sales Manager',
    averageScore: 81,
    responseReceived: 413,
    responseReceivedDelta: 12,
    averageScoreDelta: -2,
  },
  {
    uuid: 'c53dff0e-1d44-4ded-978d-7907eaddf5d7',
    title: 'Lawyer',
    averageScore: 76,
    responseReceived: 489,
    responseReceivedDelta: 5,
    averageScoreDelta: 2,
  },
];

export const dummyJobPosition: JobPosition = {
  createdAt: '2023-11-15T19:55:50.391120+00:00',
  uuid: 'c53dff0e-1d44-4ded-978d-7907eaddf5d7',
  title: 'Personal Assistant',
  isActive: true,
  closeDate: '2024-05-09T18:30:00+00:00',
  candidateDistribution: {
    numCandidates: 8,
    numMatches: 1,
    numCompletedAssessments: 1,
  },
};
