import {Fragment} from 'react';
import styles from './styles.module.scss';

interface ElementProps {
  title: string;
  subtitle: string | string[];
  tags: string[];
}

const Item = ({title, subtitle, tags}: ElementProps) => {
  return (
    <div>
      <div className={styles.titleText}>{title}</div>

      {Array.isArray(subtitle) ? (
        subtitle.map((item, index) => (
          <Fragment key={index}>
            <div className={styles.subtitle}>{item}</div>
          </Fragment>
        ))
      ) : (
        <div className={styles.subtitle}>{subtitle}</div>
      )}

      <ul className={styles.tagsWrapper}>
        {tags.map((tag: string, index) => (
          <li key={index} className={styles.tag}>
            {tag}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Item;
