import {FunctionComponent, PropsWithChildren} from 'react';
import Content from '../@components/content';
import Header from '../@components/header';
import styles from './styles.module.scss';

interface ElementProps {
  columnSpan?: 1 | 2 | 3;
  border?: boolean;
  spacing?: 'small';
}

interface CardComponent
  extends FunctionComponent<PropsWithChildren<ElementProps>> {
  Header: typeof Header;
  Content: typeof Content;
}

const TextCard: CardComponent = ({
  children,
  columnSpan = 1,
  border = false,
  spacing,
}) => {
  let className = `${styles.cardContainer} `;

  if (border) {
    className = `${styles.border} `;
  }

  if (spacing) {
    className += styles['spacingSmall'];
  }

  return (
    <div
      className={className}
      style={{
        gridColumn: `span ${columnSpan}`,
      }}
    >
      {children}
    </div>
  );
};

TextCard.Header = Header;
TextCard.Content = Content;

export default TextCard;
