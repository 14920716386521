import {useTranslation} from 'react-i18next';
import logo1Source from 'ui/@_components/kit/images/auth-feedback/stanford-university-logo.svg';
import logo2Source from 'ui/@_components/kit/images/auth-feedback/US-army-logo.svg';
import logo3Source from 'ui/@_components/kit/images/auth-feedback/the-muse-logo.svg';
import styles from './styles.module.scss';

function Branding() {
  const {t} = useTranslation('authFeedback', {
    keyPrefix: 'components.branding',
  });

  return (
    <>
      <div className={styles.carouselContainer}>
        <div className={styles.staticText}>{t('pre-header')}</div>
        <div className={styles.carouselWrapper}>
          <ul className={styles.carousel}>
            <li className={styles.item}>{t('header-1')}</li>
            <li className={styles.item}>{t('header-2')}</li>
            <li className={styles.item}>{t('header-3')}</li>
          </ul>
        </div>
      </div>

      <div className={styles.companiesList}>
        <p className={styles.subtext}>{t('subtext')}</p>

        <ul className={styles.companyLogos}>
          <img src={logo1Source} alt="logo1" height="32px" />

          <div className={styles.verticalDivider} />

          <img src={logo2Source} alt="logo2" height="32px" />

          <div className={styles.verticalDivider} />

          <img src={logo3Source} alt="logo3" height="32px" />
        </ul>
      </div>
    </>
  );
}

export default Branding;
