import styles from './styles.module.scss';

interface ElementProps {
  title: string;
  value: string;
}

const ItemContainer = ({title, value}: ElementProps) => {
  return (
    <div>
      <div className={styles.title}>{title}</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
};

export default ItemContainer;
