import {PropsWithChildren} from 'react';
import {Navigate, useLocation} from 'react-router';
import Spinner from 'ui/@_components/spinner';
import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';
import {
  useCompany,
  useCurrentAuthUser,
  useFullUserProfile,
} from 'ui/@hooks/queries';
import {useCurrentSubscriptions} from 'ui/@hooks/queries/use-current-subscriptions';

const USER_SETUP_PATH = 'user-setup';

const OnboardingGuard = ({children}: PropsWithChildren) => {
  const companyUuid = useSafeCurrentCompanyUuid();

  const currentCompany = useCompany({
    variables: {
      companyUuid,
    },
  });

  const currentAuthUser = useCurrentAuthUser();

  const userProfile = useFullUserProfile({
    variables: {
      companyUuid,
    },
  });

  const currentSubscriptions = useCurrentSubscriptions({
    variables: {
      companyUuid,
    },
  });

  const location = useLocation();

  const shouldRedirectToUserSetup = () => {
    if (currentCompany.data?.creatorUuid === currentAuthUser.data?.uuid)
      return false;

    if (userProfile.data?.fullOnboardingChecklist.hasCreatedProfile)
      return false;
    if (location.pathname.includes(USER_SETUP_PATH)) return false;

    return true;
  };

  if (
    userProfile.isLoading ||
    currentAuthUser.isLoading ||
    currentSubscriptions.isLoading ||
    currentCompany.isLoading
  ) {
    return <Spinner fullScreen />;
  }

  if (shouldRedirectToUserSetup()) {
    return <Navigate replace to={USER_SETUP_PATH} />;
  }

  return <>{children}</>;
};

export default OnboardingGuard;
