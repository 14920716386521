import {CultureVitalSign} from 'interfaces/meeting';
import {FunctionComponent} from 'react';
import styles from './styles.module.scss';

interface ElementProps {
  activeVitalSignIndex: number;
  cultureVitalSigns: CultureVitalSign[];
  onTabClick: (tabIndex: number) => void;
}

const getActiveClass = (score: number) => {
  if (score < 50) {
    return styles.scoreLow;
  }
  return styles.scoreHigh;
};

const List: FunctionComponent<ElementProps> = ({
  cultureVitalSigns,
  onTabClick,
  activeVitalSignIndex,
}) => {
  const isTabActive = (index: number) => {
    return activeVitalSignIndex === index;
  };

  const listItemClassName = (index: number, vitalSign: CultureVitalSign) =>
    `${styles.listItem} ${
      isTabActive(index) ? getActiveClass(vitalSign.score) : ''
    }`;

  return (
    <ul className={styles.list}>
      {cultureVitalSigns.map((vitalSign, index) => (
        <li
          key={index}
          className={listItemClassName(index, vitalSign)}
          onClick={() => onTabClick(index)}
        >
          {vitalSign.name}

          <div className={styles.vitalSignScore}>{`${vitalSign.score}%`}</div>

          {isTabActive(index) && <div className={styles.borderBottom} />}
        </li>
      ))}
    </ul>
  );
};

export default List;
