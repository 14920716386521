import {createQuery} from 'react-query-kit';
import {
  HierarchyRecommendationsPayload,
  fetchHierarchyRecommendations,
} from '../../../repositories/instill/queries/fetch-hierarchy-recommendations';
import {HierarchyRecommendations} from '../../../interfaces/map-your-organization';

/**
 * @deprecated not being used currently in beta app
 */
export const useHierarchyRecommendations = createQuery<
  HierarchyRecommendations | undefined,
  HierarchyRecommendationsPayload
>({
  primaryKey: 'hierarchyRecommendations',
  queryFn: async ({queryKey: [_, variables]}) => {
    return await fetchHierarchyRecommendations(
      variables.companyUuid,
      variables.userProfileUuid
    );
  },
});
