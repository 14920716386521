import {EventName} from 'constants/analytics/event-name';
import {EventType} from 'constants/analytics/event-type';
import {Actions} from 'constants/permissions/actions';
import {AddonRequests} from 'constants/plan-access/features';
import {Roles} from 'constants/roles';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import Button from 'ui/@_components/button';
import {useHasPermission} from 'ui/@_components/has-permission';
import Dialog from 'ui/@_components/kit/dialog';
import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';
import {useInviteUsers} from 'ui/@hooks/mutations';
import useAnalytics from 'ui/@hooks/use-analytics';
import useDisclosure from 'ui/@hooks/use-disclosure';
import usePlanLimits from 'ui/@hooks/use-plan-limits';
import RequestModal from 'ui/@module-components/request-modal';
import isResponseSuccessful from 'utils/is-response-successful';
import * as Yup from 'yup';
import UserLimitCard from './@components/user-limit-card';
import styles from './styles.module.scss';
import CardCurvedDesign from 'ui/@_components/card-curved-design';
import {useEffect, useState} from 'react';
import AddInviteUser, {UserToInvite} from './@components/add-invite-user';
import Chip from 'ui/@_components/chip';
import Select from 'ui/@_components/kit/form/select';
import useInviteRolesOptions from './@hooks/use-invite-roles-options';

interface ElementProps {
  isOpen: boolean;
  onClose: () => void;
}

const InviteUser = ({isOpen, onClose}: ElementProps) => {
  const {t} = useTranslation('moduleComponents', {
    keyPrefix: 'modals.invite-user',
  });
  const {trackEvent} = useAnalytics();

  const [initialValues, setInitialValues] = useState<UserToInvite[]>([]);

  const {getIsUserLimitExceeded} = usePlanLimits();

  const {
    isOpen: isRequestModalOpen,
    onClose: onRequestModalClose,
    onOpen: onRequestModalOpen,
  } = useDisclosure();

  const companyUuid = useSafeCurrentCompanyUuid();

  const inviteUsers = useInviteUsers();

  const hasPermission = useHasPermission();

  const inviteRolesOptions = useInviteRolesOptions();

  const validationSchema = Yup.array()
    .of(
      Yup.object().shape({
        role: Yup.string().required(t('role-required')),
        email: Yup.string()
          .email(t('incorrect-email'))
          .required(t('email-required')),
      })
    )
    .min(1);

  useEffect(() => {
    setInitialValues([]);
  }, [isOpen]);

  const onSubmit = async (values: UserToInvite[]) => {
    const response = await inviteUsers.mutateAsync({
      userInvites: values,
      companyUuid,
    });

    if (isResponseSuccessful(response)) {
      const feedbackMessage = t('invite-sent-feedback', {
        noOfUsers: values.length,
      });

      trackEvent({
        eventName: EventName.PEOPLE.PEOPLE_INVITED,
        eventType: EventType.BUTTON_CLICKED,
        eventProperties: {
          admins: values
            .filter((user) => user.role === Roles.ADMIN)
            .map((user) => user.email),
          managers: values
            .filter((user) => user.role === Roles.MANAGER)
            .map((user) => user.email),
          members: values
            .filter((user) => user.role === Roles.MEMBER)
            .map((user) => user.email),
        },
      });

      toast.success(feedbackMessage);
    }

    onClose();
  };

  if (!hasPermission(Actions.ADD_EMPLOYEE)) {
    return null;
  }

  return (
    <>
      <Dialog isOpen={isOpen && !isRequestModalOpen} onClose={onClose}>
        <div className={styles.header}>
          <div className={styles.leftContent}>
            <p className={styles.title}>{t('title')}</p>

            <p className={styles.subtitle}>{t('subtitle')}</p>
          </div>

          <CardCurvedDesign
            size="xLarge"
            containerStyles={{
              height: '120px',
              width: '100%',
              maxWidth: '200px',
            }}
          />
        </div>

        <Dialog.Content>
          <AddInviteUser
            onSubmit={(newUser, formikHelpers) => {
              setInitialValues((prev) => [...prev, newUser]);
              formikHelpers.resetForm();
            }}
            inviteUsers={initialValues}
          />

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({values, handleSubmit, setValues, isValid}) => (
              <Form>
                {!!values.length && (
                  <div>
                    <p className={styles.titleInvitedUsers}>
                      {t('invited-users')}
                    </p>

                    <div className={styles.invitedUsers}>
                      {values.map((user, idx) => (
                        <div className={styles.invitedUser} key={idx}>
                          <Chip
                            onClose={() => {
                              setValues((prev) =>
                                prev.filter((_, index) => idx !== index)
                              );
                              setInitialValues((prev) =>
                                prev.filter((_, index) => idx !== index)
                              );
                            }}
                          >
                            {user.email}
                          </Chip>

                          <div>
                            <Select
                              variant="noOutline"
                              value={user.role}
                              onChange={(e) =>
                                setValues((prev) =>
                                  prev.map((prevUser, index) => {
                                    if (index === idx) {
                                      return {
                                        ...prevUser,
                                        role: e.target.value as Roles,
                                      };
                                    }

                                    return prevUser;
                                  })
                                )
                              }
                            >
                              {inviteRolesOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Select>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {getIsUserLimitExceeded(values.length) && (
                  <div className={styles.userLimitCardContainer}>
                    <UserLimitCard onRequestAccess={onRequestModalOpen} />
                  </div>
                )}

                <div className={styles.buttonContainer}>
                  <Button variant="secondary" onClick={onClose}>
                    {t('cancel')}
                  </Button>

                  <Button
                    disabled={
                      !isValid ||
                      getIsUserLimitExceeded(values.length) ||
                      values.length === 0
                    }
                    aria-disabled={
                      !isValid ||
                      getIsUserLimitExceeded(values.length) ||
                      values.length === 0
                    }
                    onClick={() => handleSubmit()}
                  >
                    {t('invite')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>

      <RequestModal
        isOpen={isRequestModalOpen}
        onHide={onRequestModalClose}
        requestType={AddonRequests.SEAT_REQUEST}
      />
    </>
  );
};

export default InviteUser;
