import {useMemo} from 'react';
import {useParams} from 'react-router';
import {useCurrentCompanyUuid} from 'ui/@atoms/current-company';
import {
  useCompany,
  useCurrentAuthUser,
  useMeeting,
  useTranscript,
} from 'ui/@hooks/queries';

const useFlowData = () => {
  const companyUuid = useCurrentCompanyUuid();

  const company = useCompany({
    variables: {
      companyUuid,
    },
  });

  const {meetingId: meetingUuid} = useParams<string>();

  const isCompanySelected = !!companyUuid;

  const currentAuthUser = useCurrentAuthUser();

  const isUserAuthenticated = !!currentAuthUser.data?.uuid;

  const meeting = useMeeting({
    variables: {
      companyUuid: companyUuid,
      meetingUuid: meetingUuid,
    },
  });

  const transcript = useTranscript({
    variables: {
      companyUuid: companyUuid,
      meetingUuid: meetingUuid,
    },
  });

  const valuesAlignmentSum =
    meeting.data?.valuesAlignment.reduce((total, current) => {
      return total + (current.score || 0);
    }, 0) || 0 / (meeting.data?.valuesAlignment.length || 1);

  const participantsWithImages = useMemo(() => {
    if (!meeting) return [];

    const {participantsNames = [], calendarEventParticipants = []} =
      meeting.data || {};

    const tempParticipantsList = [];

    for (let i = 0; i < participantsNames.length; i++) {
      const currentParticipantName = participantsNames[i];

      const foundIndex = calendarEventParticipants.findIndex(
        (participants) =>
          participants.associatedUser?.name.toLowerCase() ===
          currentParticipantName.toLowerCase()
      );

      if (foundIndex !== -1) {
        tempParticipantsList.push({
          name: currentParticipantName,
          uuid:
            calendarEventParticipants[foundIndex].associatedUser?.uuid ?? null,
          picture:
            calendarEventParticipants[foundIndex].associatedUser?.picture ??
            null,
        });
      } else {
        tempParticipantsList.push({
          name: currentParticipantName,
          uuid: null,
          picture: null,
        });
      }
    }

    return tempParticipantsList;
  }, [meeting]);

  return {
    companyName: company?.data?.name,
    meetingIsLoading: meeting.isLoading,
    meeting: meeting.data,
    transcriptIsLoading: transcript.isLoading,
    transcript: transcript.data,
    isCompanySelected,
    isUserAuthenticated,
    valuesAlignmentScore:
      valuesAlignmentSum / (meeting.data?.valuesAlignment.length || 1),
    participantsWithImages,
  };
};

export default useFlowData;
