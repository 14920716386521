import Values from 'assets/images/values.png';
import {routeNames} from 'constants/route-names';
import {useTranslation} from 'react-i18next';
import useGettingStartedStep, {
  GETTING_STARTED_STEP,
} from 'ui/@hooks/use-getting-started-step';
import Button from 'ui/@_components/button';
import styles from './styles.module.scss';

const AddValue = () => {
  const {t} = useTranslation('gettingStarted', {
    keyPrefix: 'components.values-banner',
  });

  const {cultureRoute, applicationRoute} = routeNames;

  const {currentGettingStartedStep} = useGettingStartedStep();

  if (currentGettingStartedStep !== GETTING_STARTED_STEP.ADD_COMPANY_VALUES) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftContent}>
        <p className={styles.title}>{t('title')}</p>

        <div className={styles.buttonsContainer}>
          <Button as="link" to={`/${applicationRoute}/${cultureRoute}`}>
            {t('add')}
          </Button>
        </div>
      </div>

      <div className={styles.bigCircle} />

      <div className={styles.smallCircle} />

      <img
        src={Values}
        className={styles.image}
        alt={t('img-alt')}
        width="242px"
      />
    </div>
  );
};

export default AddValue;
