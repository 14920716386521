import {sanitizeKudosCountFromRaw} from 'ui/@sanitizers/kudo';
import isResponseSuccessful from 'utils/is-response-successful';
import InstillAPI from '../axios-instance';

export interface FetchKudosCountPayload {
  companyUuid: string;
  valueUuid?: string;
  teamUuid?: string;
}

export const fetchKudosCount = async (parameters: FetchKudosCountPayload) => {
  const {companyUuid, valueUuid, teamUuid} = parameters;

  let url = `/companies/${companyUuid}/kudos/count`;

  let queryParams: string[] = [];

  if (valueUuid) {
    queryParams.push(`value_uuid=${valueUuid}`);
  }

  if (teamUuid) {
    queryParams.push(`team_uuid=${teamUuid}`);
  }

  if (!!queryParams.length) {
    url = `${url}?${queryParams.join('&')}`;
  }

  try {
    const response = await InstillAPI.get(url);

    if (!isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeKudosCountFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
};
