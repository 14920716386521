import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Button from 'ui/@_components/button';
import BaseCard from 'ui/@_components/card/base-card';
import Dialog from 'ui/@_components/kit/dialog';
import UserProfileAvatar from 'ui/@_components/user-profile-avatar';
import useDisclosure from 'ui/@hooks/use-disclosure';
import useInDesktop from 'ui/@hooks/use-in-desktop';
import useFlowData from '../../../../@hooks/use-flow-data';
import styles from './styles.module.scss';

const MAX_TAKEAWAYS_TO_SHOW = 3;

const KeyTakeaways = () => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show-v2.components.meeting-details.components.key-takeaways',
  });

  const {isOpen, onClose, onOpen} = useDisclosure();
  const [takeawaysToShow, setTakeawaysToShow] = useState(0);
  const [loadMore, setLoadMore] = useState(false);

  const {meeting} = useFlowData();
  const isDesktop = useInDesktop();

  if (!meeting || !meeting.summary.participantTakeaways?.length) return <></>;

  const {
    summary: {participantTakeaways},
  } = meeting;

  const handleShowMore = (participantIndex: number) => {
    setTakeawaysToShow(participantIndex);

    onOpen();
  };

  const takeawaysList = loadMore
    ? participantTakeaways
    : participantTakeaways.slice(0, MAX_TAKEAWAYS_TO_SHOW);

  const remainingTakeawaysCount =
    participantTakeaways.length - takeawaysList.length;

  return (
    <>
      <BaseCard
        style={{
          padding: isDesktop ? '32px 0 32px 0' : '16px 0 16px 0',
        }}
      >
        <div className={styles.container}>
          <p className={styles.title}>{t('title')}</p>

          <ul className={styles.listContainer}>
            {takeawaysList.map((participant, index1) => (
              <li className={styles.list} key={index1}>
                <div>
                  <div className={styles.titleContainer}>
                    <UserProfileAvatar
                      useRandomColors
                      profilePic={null}
                      userName={participant.participant}
                    />

                    <p>{participant.participant}</p>
                  </div>

                  <ul className={styles.subListContainer}>
                    {participant.takeaways
                      .slice(0, Math.min(participant.takeaways.length, 3))
                      .map((takeaway, index2) => (
                        <li className={styles.subList} key={index2}>
                          <div className={styles.divider} />

                          <div className={styles.subListItem}>
                            • <div>{takeaway}</div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>

                {participant.takeaways.length > 3 && (
                  <Button
                    onClick={() => handleShowMore(index1)}
                    variant="tertiary"
                  >
                    {t('show-more')}
                  </Button>
                )}
              </li>
            ))}
          </ul>

          {participantTakeaways.length > MAX_TAKEAWAYS_TO_SHOW && (
            <>
              <div className={styles.divider} />

              <div className={styles.loadMoreContainer}>
                <Button
                  onClick={() => setLoadMore(!loadMore)}
                  variant="tertiary"
                >
                  {loadMore
                    ? t('hide')
                    : t('load-more', {
                        remainingTakeawaysCount,
                      })}
                </Button>
              </div>
            </>
          )}
        </div>
      </BaseCard>

      <Dialog isOpen={isOpen} onClose={onClose}>
        <Dialog.Header>
          <div className={styles.titleContainer}>
            <UserProfileAvatar
              profilePic={null}
              userName={participantTakeaways[takeawaysToShow].participant}
            />

            <p>{participantTakeaways[takeawaysToShow].participant}</p>
          </div>
        </Dialog.Header>

        <Dialog.Content>
          <ul className={styles.subListContainer}>
            {participantTakeaways[takeawaysToShow].takeaways.map(
              (takeaway, index) => (
                <li className={styles.subList} key={index}>
                  <div className={styles.divider} />

                  <div className={styles.subListItem}>{takeaway}</div>
                </li>
              )
            )}
          </ul>
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default KeyTakeaways;
