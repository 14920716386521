import {EventName} from 'constants/analytics/event-name';
import {EventType} from 'constants/analytics/event-type';
import {routeNames} from 'constants/route-names';
import {FunctionComponent, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {connectCalendarOAuth} from 'repositories/instill';
import {ReactComponent as OutlookSuccess} from 'ui/@_components/kit/icons/outlook-success.svg';
import TwoPanePage from 'ui/@_components/two-pane-page';
import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';
import useAnalytics from 'ui/@hooks/use-analytics';
import {StringParam, useQueryParam} from 'use-query-params';
import {wait} from 'utils/wait/wait';
import styles from '../styles.module.scss';

const MicrosoftOutlookCallback: FunctionComponent = () => {
  const {t} = useTranslation('oauthCallback', {
    keyPrefix: 'microsoft-outlook',
  });

  const {applicationRoute} = routeNames;

  const location = useLocation();

  const companyUuid = useSafeCurrentCompanyUuid();

  const {trackEvent} = useAnalytics();

  const [code] = useQueryParam('code', StringParam);

  useEffect(() => {
    if (code) {
      connectCalendarOAuth(
        {
          authorization_code: code,
          platform: 'microsoft-outlook',
          company_uuid: companyUuid,
        },
        companyUuid
      ).then(() => {
        trackEvent({
          eventName: EventName.GETTING_STARTED.CALENDAR_SYNCED,
          eventType: EventType.BUTTON_CLICKED,
          eventProperties: {
            calendar: 'Outlook',
          },
        });

        wait(3000).then(() => {
          window.location.href = `/${applicationRoute}`;
        });
      });
    }
  }, [location, companyUuid, applicationRoute, code, trackEvent]);

  return (
    <TwoPanePage>
      <div className={styles.container}>
        <OutlookSuccess />

        <p className={styles.title}>
          {t('title')}

          <span>{t('vendor')}</span>
        </p>

        <br />

        {t('redirecting')}
      </div>
    </TwoPanePage>
  );
};

export default MicrosoftOutlookCallback;
