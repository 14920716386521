import {useQueryClient} from '@tanstack/react-query';
import {createMutation} from 'react-query-kit';
import {Candidate} from '../../../interfaces/candidate';
import {
  UpdateCandidatePayload,
  updateCandidate,
} from '../../../repositories/instill/mutations/update-candidate';

export const useUpdateCandidate = () => {
  const queryClient = useQueryClient();

  return createMutation<Candidate | undefined, UpdateCandidatePayload>({
    mutationFn: async (variables) => {
      return await updateCandidate({...variables});
    },
    onSuccess: () => {
      queryClient.refetchQueries(['candidatesForJob']);
    },
  })();
};
