import {PersonalityScore} from 'interfaces/personality-profile';
import HexacoRadar from 'ui/@_components/hexaco-radar';
import styles from './styles.module.scss';

interface ElementProps {
  hexacoScore: PersonalityScore;
}

const Hexaco = ({hexacoScore}: ElementProps) => {
  return (
    <div className={styles.hexacoContainer}>
      <HexacoRadar personalityScore={[hexacoScore]} />
    </div>
  );
};

export default Hexaco;
