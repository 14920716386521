import {UserProfileUpdate} from '../../../interfaces/user-profile';
import {
  sanitizeFullUserProfileFromRaw,
  sanitizeUserProfileUpdateToRaw,
} from '../../../ui/@sanitizers/user-profile';
import InstillAPI from '../axios-instance';

export interface UpdateMyUserProfilePayload {
  payload: UserProfileUpdate;
  companyUuid: string;
}

export async function updateMeUserProfile({
  payload,
  companyUuid,
}: UpdateMyUserProfilePayload) {
  const sanitizedPayload = sanitizeUserProfileUpdateToRaw(payload);

  const response = await InstillAPI.put(
    `companies/${companyUuid}/user-profiles/me`,
    sanitizedPayload
  );

  return sanitizeFullUserProfileFromRaw(response.data);
}
