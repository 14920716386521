import {QueryFunction} from '@tanstack/react-query';
import {createInfiniteQuery, createQuery} from 'react-query-kit';
import {dummyKudos} from 'ui/@demo-data/kudo';
import getOffsetValue from 'utils/get-offset-value';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {Kudo} from '../../../interfaces/kudo';
import {Meta} from '../../../interfaces/meta';
import {fetchKudos} from '../../../repositories/instill';
import {FetchKudosPayload} from '../../../repositories/instill/queries/fetch-kudos';
import {RQ_INFINITE_KUDOS, RQ_KUDOS} from './query-keys';

interface FetchKudos {
  data: Kudo[];
  meta: Meta;
}

const queryFn: QueryFunction<
  FetchKudos | undefined,
  [string, FetchKudosPayload]
> = queryDemoWrapper(
  async ({queryKey: [_, variables], pageParam = 0}) => {
    let payload = {
      offset:
        variables.offset ??
        getOffsetValue({
          currentPage: pageParam,
          itemsPerPage: variables.limit ?? 0,
        }),
      limit: variables.limit,
      companyUuid: variables.companyUuid,
    } as FetchKudosPayload;

    if (variables.valueUuid) payload['valueUuid'] = variables.valueUuid;
    if (variables.toUserUuid) payload['toUserUuid'] = variables.toUserUuid;

    return fetchKudos({
      ...payload,
      companyUuid: variables.companyUuid,
      limit: variables.limit,
    });
  },
  ({queryKey: [_, variables]}) => ({
    data: dummyKudos.filter((kudo) =>
      variables.valueUuid ? kudo.value.uuid === variables.valueUuid : true
    ),
    meta: {
      currentPage: 1,
      totalPages: 1,
      totalEntries: 3,
    },
  })
);

export const useKudos = createQuery<FetchKudos | undefined, FetchKudosPayload>({
  primaryKey: RQ_KUDOS,
  queryFn,
  enabled: (data, variables) => {
    return !!variables.companyUuid;
  },
});

export const useInfiniteKudos = createInfiniteQuery<
  FetchKudos | undefined,
  Omit<FetchKudosPayload, 'itemsPerPage' | 'offset' | 'currentPage'>
>({
  primaryKey: RQ_INFINITE_KUDOS,
  queryFn,
  enabled: (data, variables) => {
    return !!variables.companyUuid;
  },
  getNextPageParam: (lastPage, allPages) => {
    return lastPage?.meta.totalPages &&
      lastPage?.meta.totalPages > allPages.length
      ? allPages.length + 1
      : undefined;
  },
  getPreviousPageParam: (firstPage, allPages) => {
    return allPages.length ? allPages.length - 1 : undefined;
  },
});
