import {DemoAccountType} from 'constants/demo-account';
import {useEffect} from 'react';
import {useThemeContext} from 'ui/@contexts/theme-context';
import useDemoAccount from 'ui/@hooks/use-demo-account';

const useWhiteLabellingTheme = () => {
  const {demoAccountType} = useDemoAccount();
  const {setTheme} = useThemeContext();

  useEffect(() => {
    if (demoAccountType === DemoAccountType.CULTURE_FORCE) {
      setTheme('culture-force');
    }
  }, [demoAccountType, setTheme]);
};

export default useWhiteLabellingTheme;
