import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';
import {sanitizeSubscriptionsFromRaw} from 'ui/@sanitizers/subscription';

export interface CurrentSubscriptionsPayload {
  companyUuid: string;
}

export async function fetchCurrentSubscriptions({
  companyUuid,
}: CurrentSubscriptionsPayload) {
  try {
    const response = await InstillAPI.get(
      `/companies/${companyUuid}/current-subscriptions`
    );

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeSubscriptionsFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
