import {EventName} from 'constants/analytics/event-name';
import {EventType} from 'constants/analytics/event-type';
import {MeetingFeedback as MeetingFeedbackInterface} from 'interfaces/meeting';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import Button from 'ui/@_components/button';
import TextCard from 'ui/@_components/card/text-card';
import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';
import {useUpdateMeetingFeedback} from 'ui/@hooks/mutations/use-update-meeting-feedback';
import useAnalytics from 'ui/@hooks/use-analytics';
import useFlowData from '../../../../@hooks/use-flow-data';
import styles from './styles.module.scss';

const MeetingFeedBack = () => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show-v2.components.meeting-details.components.meeting-feedback',
  });

  const {trackEvent} = useAnalytics();

  const {meeting} = useFlowData();

  const [isFeedbackPositive, setIsFeedbackPositive] = useState(() => {
    return meeting?.feedback?.isPositive;
  });

  const companyUuid = useSafeCurrentCompanyUuid();
  const updateMeetingFeedback = useUpdateMeetingFeedback();

  if (!meeting) return <></>;

  const updateFeedback = async (payload: MeetingFeedbackInterface) => {
    const response = await updateMeetingFeedback.mutateAsync({
      companyUuid,
      payload,
      meetingUuid: meeting.uuid,
    });

    if (!response) return;

    trackEvent({
      eventName: EventName.FLOW.REPORT.REPORT_FEEDBACK,
      eventType: EventType.BUTTON_CLICKED,
      eventProperties: {
        isPositive: payload.isPositive,
        meetingName: meeting.name,
      },
    });
    const feedbackMessage = t('feedback-messages.update-success');

    toast.success(feedbackMessage);

    return response;
  };

  const onFeedbackButtonClicked = async (isPositive: boolean) => {
    const payload = {isPositive};

    const response = await updateFeedback(payload);
    if (!response) return;

    setIsFeedbackPositive(response.isPositive);
  };

  return (
    <TextCard>
      <TextCard.Header title={t('title')} />
      <TextCard.Content>
        <div className={styles.container}>
          {t('question')}

          <div className={styles.buttonContainer}>
            <Button
              variant={isFeedbackPositive ? 'primary' : 'secondary'}
              onClick={() => onFeedbackButtonClicked(true)}
            >
              {t('options.yes')}
            </Button>

            <Button
              variant={!isFeedbackPositive ? 'primary' : 'secondary'}
              onClick={() => onFeedbackButtonClicked(false)}
            >
              {t('options.no')}
            </Button>
          </div>
        </div>
      </TextCard.Content>
    </TextCard>
  );
};

export default MeetingFeedBack;
