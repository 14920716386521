import {DEMO_ACCOUNT_TYPE} from './local-storage-keys';

export enum DemoAccountType {
  GENERIC = 'generic',
  CULTURE_FORCE = 'culture-force',
}

export const demoAccountTypes = [
  DemoAccountType.GENERIC,
  DemoAccountType.CULTURE_FORCE,
] as const;

export const demoAccountType = demoAccountTypes.includes(
  sessionStorage.getItem(DEMO_ACCOUNT_TYPE) as DemoAccountType
)
  ? (sessionStorage.getItem(DEMO_ACCOUNT_TYPE) as DemoAccountType)
  : null;
