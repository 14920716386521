import {
  CultureGraphItem,
  ValuesGraphItem,
  VitalSignsItem,
} from 'interfaces/graph';

export const dummyCultureGraph: CultureGraphItem[] = [
  {
    dataFor: '2023-03-17 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 73.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-03-18 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-03-19 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-03-20 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-03-21 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-03-22 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-03-23 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-03-24 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-03-25 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-03-26 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-03-27 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-03-28 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-03-29 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-03-30 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-03-31 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-01 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-02 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-03 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-04 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-05 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-06 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-07 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-08 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-09 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-10 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-11 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-12 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-13 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-14 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-15 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-16 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-17 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-18 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-19 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-20 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-21 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-22 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-23 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-24 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-25 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-26 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-27 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-28 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-29 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-04-30 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-01 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-02 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-03 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-04 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-05 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-06 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-07 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-08 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-09 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-10 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-11 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-12 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-13 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-14 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-15 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-16 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-17 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-18 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-19 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-20 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-21 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-22 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-23 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-24 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-25 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-26 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-27 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-28 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-29 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-30 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-05-31 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-01 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-02 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-03 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-04 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-05 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-06 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-07 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-08 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-09 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-10 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-11 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-12 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-13 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-14 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-15 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-16 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-17 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-18 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-19 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-20 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-21 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-22 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-23 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-24 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-25 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-26 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-27 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-28 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-29 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-06-30 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-01 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-02 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-03 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-04 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-05 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-06 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-07 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-08 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-09 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-10 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-11 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-12 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-13 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-14 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-15 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-16 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-17 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-18 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-19 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-20 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-21 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-22 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-23 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-24 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-25 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-26 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-27 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-28 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-29 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-30 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-07-31 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-01 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-02 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-03 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-04 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-05 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-06 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-07 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-08 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-09 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-10 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-11 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-12 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-13 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-14 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-15 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-16 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-17 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-18 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-19 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-20 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-21 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-22 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-23 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-24 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-25 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-26 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-27 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-28 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-29 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-30 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-08-31 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-01 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-02 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-03 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-04 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-05 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-06 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-07 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-08 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-09 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-10 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-11 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-12 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-13 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-14 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-15 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-16 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-17 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-18 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-19 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-20 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-21 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-22 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-23 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-24 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-25 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-26 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-27 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-28 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-29 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-09-30 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-01 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-02 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-03 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-04 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-05 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-06 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-07 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-08 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-09 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-10 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-11 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-12 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-13 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-14 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-15 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-16 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-17 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-18 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-19 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-20 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-21 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-22 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-23 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-24 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-25 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-26 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-27 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-28 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-29 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-30 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-10-31 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-01 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-02 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-03 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-04 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-05 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-06 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-07 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-08 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-09 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-10 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-11 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-12 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-13 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-14 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-15 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-16 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-17 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-18 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-19 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-20 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-21 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-22 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-23 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-24 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-25 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-26 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-27 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-28 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-29 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-11-30 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-01 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-02 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-03 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-04 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-05 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-06 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-07 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-08 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-09 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-10 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-11 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-12 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-13 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-14 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-15 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-16 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-17 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-18 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-19 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-20 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-21 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-22 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-23 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-24 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-25 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-26 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-27 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-28 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-29 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-30 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2023-12-31 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-01 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-02 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-03 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-04 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-05 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-06 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-07 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-08 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-09 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-10 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-11 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-12 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-13 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-14 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-15 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-16 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-17 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-18 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-19 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-20 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-21 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-22 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-23 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-24 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-25 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-26 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-27 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-28 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-29 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-30 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-01-31 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-01 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-02 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-03 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-04 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-05 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-06 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-07 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-08 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-09 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-10 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-11 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-12 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-13 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-14 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-15 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-16 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-17 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-18 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-19 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-20 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-21 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-22 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-23 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-24 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-25 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-26 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-27 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-28 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-02-29 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-03-01 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-03-02 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-03-03 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-03-04 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-03-05 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-03-06 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-03-07 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-03-08 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-03-09 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-03-10 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-03-11 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-03-12 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-03-13 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-03-14 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
  {
    dataFor: '2024-03-15 14:13:28',
    combinedAverage: 73.5,
    vitalSignAverage: 72.5,
    customCultureKpiAverage: 72.5,
    valuesAverage: 54,
  },
];

export const dummyVitalSignsGraph: VitalSignsItem[] = [
  {
    slug: 'trust',
    graphData: [
      {
        dataFor: '2023-03-17 14:13:28',
        vitalSignScore: 73.5,
      },
      {
        dataFor: '2023-03-18 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-19 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-20 14:13:28',
        vitalSignScore: 72.5,
      },
      {
        dataFor: '2023-03-21 14:13:28',
        vitalSignScore: 73.6,
      },
      {
        dataFor: '2023-03-22 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-03-23 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-03-24 14:13:28',
        vitalSignScore: 70.2,
      },
      {
        dataFor: '2023-03-25 14:13:28',
        vitalSignScore: 70.8,
      },
      {
        dataFor: '2023-03-26 14:13:28',
        vitalSignScore: 70.5,
      },
      {
        dataFor: '2023-03-27 14:13:28',
        vitalSignScore: 70.8,
      },
      {
        dataFor: '2023-03-28 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-03-29 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-30 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-31 14:13:28',
        vitalSignScore: 71.6,
      },
      {
        dataFor: '2023-04-01 14:13:28',
        vitalSignScore: 71.4,
      },
      {
        dataFor: '2023-04-02 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-04-03 14:13:28',
        vitalSignScore: 72,
      },
      {
        dataFor: '2023-04-04 14:13:28',
        vitalSignScore: 72,
      },
      {
        dataFor: '2023-04-05 14:13:28',
        vitalSignScore: 70.5,
      },
      {
        dataFor: '2023-04-06 14:13:28',
        vitalSignScore: 69,
      },
      {
        dataFor: '2023-04-07 14:13:28',
        vitalSignScore: 69.3,
      },
      {
        dataFor: '2023-04-08 14:13:28',
        vitalSignScore: 70.5,
      },
      {
        dataFor: '2023-04-09 14:13:28',
        vitalSignScore: 71.7,
      },
      {
        dataFor: '2023-04-10 14:13:28',
        vitalSignScore: 72.9,
      },
      {
        dataFor: '2023-04-11 14:13:28',
        vitalSignScore: 71.4,
      },
      {
        dataFor: '2023-04-12 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-04-13 14:13:28',
        vitalSignScore: 70.4,
      },
      {
        dataFor: '2023-04-14 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-04-15 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-04-16 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-04-17 14:13:28',
        vitalSignScore: 73,
      },
      {
        dataFor: '2023-04-18 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-04-19 14:13:28',
        vitalSignScore: 70.1,
      },
      {
        dataFor: '2023-04-20 14:13:28',
        vitalSignScore: 69.7,
      },
      {
        dataFor: '2023-04-21 14:13:28',
        vitalSignScore: 70.4,
      },
      {
        dataFor: '2023-04-22 14:13:28',
        vitalSignScore: 72.6,
      },
      {
        dataFor: '2023-04-23 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-04-24 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-04-25 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-04-26 14:13:28',
        vitalSignScore: 71,
      },
      {
        dataFor: '2023-04-27 14:13:28',
        vitalSignScore: 70.4,
      },
      {
        dataFor: '2023-04-28 14:13:28',
        vitalSignScore: 69.5,
      },
      {
        dataFor: '2023-04-29 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-04-30 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-05-01 14:13:28',
        vitalSignScore: 73.1,
      },
      {
        dataFor: '2023-05-02 14:13:28',
        vitalSignScore: 71,
      },
      {
        dataFor: '2023-05-03 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-05-04 14:13:28',
        vitalSignScore: 71.2,
      },
      {
        dataFor: '2023-05-05 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-05-06 14:13:28',
        vitalSignScore: 70.9,
      },
      {
        dataFor: '2023-05-07 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-05-08 14:13:28',
        vitalSignScore: 72.1,
      },
      {
        dataFor: '2023-05-09 14:13:28',
        vitalSignScore: 72,
      },
      {
        dataFor: '2023-05-10 14:13:28',
        vitalSignScore: 71.8,
      },
      {
        dataFor: '2023-05-11 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-05-12 14:13:28',
        vitalSignScore: 73.3,
      },
      {
        dataFor: '2023-05-13 14:13:28',
        vitalSignScore: 73.6,
      },
      {
        dataFor: '2023-05-14 14:13:28',
        vitalSignScore: 73,
      },
      {
        dataFor: '2023-05-15 14:13:28',
        vitalSignScore: 72.7,
      },
      {
        dataFor: '2023-05-16 14:13:28',
        vitalSignScore: 72.1,
      },
      {
        dataFor: '2023-05-17 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-05-18 14:13:28',
        vitalSignScore: 72.1,
      },
      {
        dataFor: '2023-05-19 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-05-20 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-05-21 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-05-22 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-05-23 14:13:28',
        vitalSignScore: 72.6,
      },
      {
        dataFor: '2023-05-24 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-05-25 14:13:28',
        vitalSignScore: 73.5,
      },
      {
        dataFor: '2023-05-26 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-05-27 14:13:28',
        vitalSignScore: 74.5,
      },
      {
        dataFor: '2023-05-28 14:13:28',
        vitalSignScore: 75.2,
      },
      {
        dataFor: '2023-05-29 14:13:28',
        vitalSignScore: 74,
      },
      {
        dataFor: '2023-05-30 14:13:28',
        vitalSignScore: 74.2,
      },
      {
        dataFor: '2023-05-31 14:13:28',
        vitalSignScore: 73.1,
      },
      {
        dataFor: '2023-06-01 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-06-02 14:13:28',
        vitalSignScore: 72.8,
      },
      {
        dataFor: '2023-06-03 14:13:28',
        vitalSignScore: 73.1,
      },
      {
        dataFor: '2023-06-04 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-06-05 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-06-06 14:13:28',
        vitalSignScore: 74.3,
      },
      {
        dataFor: '2023-06-07 14:13:28',
        vitalSignScore: 74.6,
      },
      {
        dataFor: '2023-06-08 14:13:28',
        vitalSignScore: 74.4,
      },
      {
        dataFor: '2023-06-09 14:13:28',
        vitalSignScore: 74.8,
      },
      {
        dataFor: '2023-06-10 14:13:28',
        vitalSignScore: 75.8,
      },
      {
        dataFor: '2023-06-11 14:13:28',
        vitalSignScore: 77.7,
      },
      {
        dataFor: '2023-06-12 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-06-13 14:13:28',
        vitalSignScore: 74.7,
      },
      {
        dataFor: '2023-06-14 14:13:28',
        vitalSignScore: 74.7,
      },
      {
        dataFor: '2023-06-15 14:13:28',
        vitalSignScore: 74.6,
      },
      {
        dataFor: '2023-06-16 14:13:28',
        vitalSignScore: 76.2,
      },
      {
        dataFor: '2023-06-17 14:13:28',
        vitalSignScore: 75.7,
      },
      {
        dataFor: '2023-06-18 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-06-19 14:13:28',
        vitalSignScore: 76.1,
      },
      {
        dataFor: '2023-06-20 14:13:28',
        vitalSignScore: 76.5,
      },
      {
        dataFor: '2023-06-21 14:13:28',
        vitalSignScore: 75.9,
      },
      {
        dataFor: '2023-06-22 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-06-23 14:13:28',
        vitalSignScore: 76.6,
      },
      {
        dataFor: '2023-06-24 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-06-25 14:13:28',
        vitalSignScore: 77,
      },
      {
        dataFor: '2023-06-26 14:13:28',
        vitalSignScore: 76.1,
      },
      {
        dataFor: '2023-06-27 14:13:28',
        vitalSignScore: 75.1,
      },
      {
        dataFor: '2023-06-28 14:13:28',
        vitalSignScore: 74.5,
      },
      {
        dataFor: '2023-06-29 14:13:28',
        vitalSignScore: 74.7,
      },
      {
        dataFor: '2023-06-30 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-07-01 14:13:28',
        vitalSignScore: 76,
      },
      {
        dataFor: '2023-07-02 14:13:28',
        vitalSignScore: 76.3,
      },
      {
        dataFor: '2023-07-03 14:13:28',
        vitalSignScore: 76.4,
      },
      {
        dataFor: '2023-07-04 14:13:28',
        vitalSignScore: 77.7,
      },
      {
        dataFor: '2023-07-05 14:13:28',
        vitalSignScore: 79.5,
      },
      {
        dataFor: '2023-07-06 14:13:28',
        vitalSignScore: 78.9,
      },
      {
        dataFor: '2023-07-07 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-08 14:13:28',
        vitalSignScore: 77.5,
      },
      {
        dataFor: '2023-07-09 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-07-10 14:13:28',
        vitalSignScore: 77.9,
      },
      {
        dataFor: '2023-07-11 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-07-12 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-13 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-14 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-15 14:13:28',
        vitalSignScore: 77.8,
      },
      {
        dataFor: '2023-07-16 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-07-17 14:13:28',
        vitalSignScore: 76.4,
      },
      {
        dataFor: '2023-07-18 14:13:28',
        vitalSignScore: 77.1,
      },
      {
        dataFor: '2023-07-19 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-20 14:13:28',
        vitalSignScore: 77.1,
      },
      {
        dataFor: '2023-07-21 14:13:28',
        vitalSignScore: 76,
      },
      {
        dataFor: '2023-07-22 14:13:28',
        vitalSignScore: 77.8,
      },
      {
        dataFor: '2023-07-23 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-07-24 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-07-25 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-07-26 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-07-27 14:13:28',
        vitalSignScore: 76.6,
      },
      {
        dataFor: '2023-07-28 14:13:28',
        vitalSignScore: 77.2,
      },
      {
        dataFor: '2023-07-29 14:13:28',
        vitalSignScore: 76.5,
      },
      {
        dataFor: '2023-07-30 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-31 14:13:28',
        vitalSignScore: 77.8,
      },
      {
        dataFor: '2023-08-01 14:13:28',
        vitalSignScore: 78.2,
      },
      {
        dataFor: '2023-08-02 14:13:28',
        vitalSignScore: 78,
      },
      {
        dataFor: '2023-08-03 14:13:28',
        vitalSignScore: 77,
      },
      {
        dataFor: '2023-08-04 14:13:28',
        vitalSignScore: 76.2,
      },
      {
        dataFor: '2023-08-05 14:13:28',
        vitalSignScore: 76.3,
      },
      {
        dataFor: '2023-08-06 14:13:28',
        vitalSignScore: 77.2,
      },
      {
        dataFor: '2023-08-07 14:13:28',
        vitalSignScore: 78.6,
      },
      {
        dataFor: '2023-08-08 14:13:28',
        vitalSignScore: 80,
      },
      {
        dataFor: '2023-08-09 14:13:28',
        vitalSignScore: 80.6,
      },
      {
        dataFor: '2023-08-10 14:13:28',
        vitalSignScore: 79.6,
      },
      {
        dataFor: '2023-08-11 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-12 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-08-13 14:13:28',
        vitalSignScore: 77.7,
      },
      {
        dataFor: '2023-08-14 14:13:28',
        vitalSignScore: 77,
      },
      {
        dataFor: '2023-08-15 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-08-16 14:13:28',
        vitalSignScore: 78.9,
      },
      {
        dataFor: '2023-08-17 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-08-18 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-08-19 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-08-20 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-21 14:13:28',
        vitalSignScore: 78.2,
      },
      {
        dataFor: '2023-08-22 14:13:28',
        vitalSignScore: 77.9,
      },
      {
        dataFor: '2023-08-23 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-08-24 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-08-25 14:13:28',
        vitalSignScore: 79.5,
      },
      {
        dataFor: '2023-08-26 14:13:28',
        vitalSignScore: 80.4,
      },
      {
        dataFor: '2023-08-27 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-08-28 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-29 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-30 14:13:28',
        vitalSignScore: 78.1,
      },
      {
        dataFor: '2023-08-31 14:13:28',
        vitalSignScore: 78,
      },
      {
        dataFor: '2023-09-01 14:13:28',
        vitalSignScore: 78.3,
      },
      {
        dataFor: '2023-09-02 14:13:28',
        vitalSignScore: 79.2,
      },
      {
        dataFor: '2023-09-03 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-09-04 14:13:28',
        vitalSignScore: 80.4,
      },
      {
        dataFor: '2023-09-05 14:13:28',
        vitalSignScore: 80.8,
      },
      {
        dataFor: '2023-09-06 14:13:28',
        vitalSignScore: 79.8,
      },
      {
        dataFor: '2023-09-07 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-09-08 14:13:28',
        vitalSignScore: 78.9,
      },
      {
        dataFor: '2023-09-09 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-09-10 14:13:28',
        vitalSignScore: 79.1,
      },
      {
        dataFor: '2023-09-11 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-09-12 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-09-13 14:13:28',
        vitalSignScore: 79.3,
      },
      {
        dataFor: '2023-09-14 14:13:28',
        vitalSignScore: 78.7,
      },
      {
        dataFor: '2023-09-15 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-09-16 14:13:28',
        vitalSignScore: 77.2,
      },
      {
        dataFor: '2023-09-17 14:13:28',
        vitalSignScore: 78.6,
      },
      {
        dataFor: '2023-09-18 14:13:28',
        vitalSignScore: 78,
      },
      {
        dataFor: '2023-09-19 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-09-20 14:13:28',
        vitalSignScore: 78.5,
      },
      {
        dataFor: '2023-09-21 14:13:28',
        vitalSignScore: 79.1,
      },
      {
        dataFor: '2023-09-22 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-09-23 14:13:28',
        vitalSignScore: 80.1,
      },
      {
        dataFor: '2023-09-24 14:13:28',
        vitalSignScore: 80.8,
      },
      {
        dataFor: '2023-09-25 14:13:28',
        vitalSignScore: 79.8,
      },
      {
        dataFor: '2023-09-26 14:13:28',
        vitalSignScore: 79.9,
      },
      {
        dataFor: '2023-09-27 14:13:28',
        vitalSignScore: 80.1,
      },
      {
        dataFor: '2023-09-28 14:13:28',
        vitalSignScore: 80.1,
      },
      {
        dataFor: '2023-09-29 14:13:28',
        vitalSignScore: 81.5,
      },
      {
        dataFor: '2023-09-30 14:13:28',
        vitalSignScore: 81.7,
      },
      {
        dataFor: '2023-10-01 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-10-02 14:13:28',
        vitalSignScore: 83.8,
      },
      {
        dataFor: '2023-10-03 14:13:28',
        vitalSignScore: 83,
      },
      {
        dataFor: '2023-10-04 14:13:28',
        vitalSignScore: 81.5,
      },
      {
        dataFor: '2023-10-05 14:13:28',
        vitalSignScore: 81.4,
      },
      {
        dataFor: '2023-10-06 14:13:28',
        vitalSignScore: 82.1,
      },
      {
        dataFor: '2023-10-07 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-10-08 14:13:28',
        vitalSignScore: 81,
      },
      {
        dataFor: '2023-10-09 14:13:28',
        vitalSignScore: 80.6,
      },
      {
        dataFor: '2023-10-10 14:13:28',
        vitalSignScore: 79.7,
      },
      {
        dataFor: '2023-10-11 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-10-12 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-10-13 14:13:28',
        vitalSignScore: 82.2,
      },
      {
        dataFor: '2023-10-14 14:13:28',
        vitalSignScore: 82.3,
      },
      {
        dataFor: '2023-10-15 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-10-16 14:13:28',
        vitalSignScore: 82.1,
      },
      {
        dataFor: '2023-10-17 14:13:28',
        vitalSignScore: 81.3,
      },
      {
        dataFor: '2023-10-18 14:13:28',
        vitalSignScore: 80.5,
      },
      {
        dataFor: '2023-10-19 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-10-20 14:13:28',
        vitalSignScore: 81.8,
      },
      {
        dataFor: '2023-10-21 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-10-22 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-10-23 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-10-24 14:13:28',
        vitalSignScore: 81.2,
      },
      {
        dataFor: '2023-10-25 14:13:28',
        vitalSignScore: 81,
      },
      {
        dataFor: '2023-10-26 14:13:28',
        vitalSignScore: 82,
      },
      {
        dataFor: '2023-10-27 14:13:28',
        vitalSignScore: 81.2,
      },
      {
        dataFor: '2023-10-28 14:13:28',
        vitalSignScore: 81.6,
      },
      {
        dataFor: '2023-10-29 14:13:28',
        vitalSignScore: 81.5,
      },
      {
        dataFor: '2023-10-30 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-10-31 14:13:28',
        vitalSignScore: 82.3,
      },
      {
        dataFor: '2023-11-01 14:13:28',
        vitalSignScore: 82.1,
      },
      {
        dataFor: '2023-11-02 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-11-03 14:13:28',
        vitalSignScore: 81.2,
      },
      {
        dataFor: '2023-11-04 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-11-05 14:13:28',
        vitalSignScore: 82.3,
      },
      {
        dataFor: '2023-11-06 14:13:28',
        vitalSignScore: 81.9,
      },
      {
        dataFor: '2023-11-07 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-08 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-11-09 14:13:28',
        vitalSignScore: 83.1,
      },
      {
        dataFor: '2023-11-10 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-11 14:13:28',
        vitalSignScore: 82.4,
      },
      {
        dataFor: '2023-11-12 14:13:28',
        vitalSignScore: 83.4,
      },
      {
        dataFor: '2023-11-13 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-14 14:13:28',
        vitalSignScore: 82.5,
      },
      {
        dataFor: '2023-11-15 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-11-16 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-17 14:13:28',
        vitalSignScore: 81.9,
      },
      {
        dataFor: '2023-11-18 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-19 14:13:28',
        vitalSignScore: 83.9,
      },
      {
        dataFor: '2023-11-20 14:13:28',
        vitalSignScore: 84.5,
      },
      {
        dataFor: '2023-11-21 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-11-22 14:13:28',
        vitalSignScore: 82.5,
      },
      {
        dataFor: '2023-11-23 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-11-24 14:13:28',
        vitalSignScore: 83.8,
      },
      {
        dataFor: '2023-11-25 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-11-26 14:13:28',
        vitalSignScore: 84.2,
      },
      {
        dataFor: '2023-11-27 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2023-11-28 14:13:28',
        vitalSignScore: 84.5,
      },
      {
        dataFor: '2023-11-29 14:13:28',
        vitalSignScore: 83.1,
      },
      {
        dataFor: '2023-11-30 14:13:28',
        vitalSignScore: 81.7,
      },
      {
        dataFor: '2023-12-01 14:13:28',
        vitalSignScore: 82.6,
      },
      {
        dataFor: '2023-12-02 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-12-03 14:13:28',
        vitalSignScore: 83.6,
      },
      {
        dataFor: '2023-12-04 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-12-05 14:13:28',
        vitalSignScore: 83.6,
      },
      {
        dataFor: '2023-12-06 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-12-07 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-12-08 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-12-09 14:13:28',
        vitalSignScore: 85.1,
      },
      {
        dataFor: '2023-12-10 14:13:28',
        vitalSignScore: 84.9,
      },
      {
        dataFor: '2023-12-11 14:13:28',
        vitalSignScore: 84.6,
      },
      {
        dataFor: '2023-12-12 14:13:28',
        vitalSignScore: 84.9,
      },
      {
        dataFor: '2023-12-13 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-12-14 14:13:28',
        vitalSignScore: 84.6,
      },
      {
        dataFor: '2023-12-15 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2023-12-16 14:13:28',
        vitalSignScore: 86.1,
      },
      {
        dataFor: '2023-12-17 14:13:28',
        vitalSignScore: 84.6,
      },
      {
        dataFor: '2023-12-18 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2023-12-19 14:13:28',
        vitalSignScore: 85.2,
      },
      {
        dataFor: '2023-12-20 14:13:28',
        vitalSignScore: 84.1,
      },
      {
        dataFor: '2023-12-21 14:13:28',
        vitalSignScore: 85.2,
      },
      {
        dataFor: '2023-12-22 14:13:28',
        vitalSignScore: 85.3,
      },
      {
        dataFor: '2023-12-23 14:13:28',
        vitalSignScore: 87.4,
      },
      {
        dataFor: '2023-12-24 14:13:28',
        vitalSignScore: 85.6,
      },
      {
        dataFor: '2023-12-25 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2023-12-26 14:13:28',
        vitalSignScore: 86.3,
      },
      {
        dataFor: '2023-12-27 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2023-12-28 14:13:28',
        vitalSignScore: 85.6,
      },
      {
        dataFor: '2023-12-29 14:13:28',
        vitalSignScore: 86.2,
      },
      {
        dataFor: '2023-12-30 14:13:28',
        vitalSignScore: 86.3,
      },
      {
        dataFor: '2023-12-31 14:13:28',
        vitalSignScore: 88,
      },
      {
        dataFor: '2024-01-01 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-01-02 14:13:28',
        vitalSignScore: 86.1,
      },
      {
        dataFor: '2024-01-03 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-01-04 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-05 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-01-06 14:13:28',
        vitalSignScore: 86.1,
      },
      {
        dataFor: '2024-01-07 14:13:28',
        vitalSignScore: 86.9,
      },
      {
        dataFor: '2024-01-08 14:13:28',
        vitalSignScore: 87.5,
      },
      {
        dataFor: '2024-01-09 14:13:28',
        vitalSignScore: 86.5,
      },
      {
        dataFor: '2024-01-10 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2024-01-11 14:13:28',
        vitalSignScore: 85.5,
      },
      {
        dataFor: '2024-01-12 14:13:28',
        vitalSignScore: 87,
      },
      {
        dataFor: '2024-01-13 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2024-01-14 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-15 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-16 14:13:28',
        vitalSignScore: 86.4,
      },
      {
        dataFor: '2024-01-17 14:13:28',
        vitalSignScore: 86,
      },
      {
        dataFor: '2024-01-18 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2024-01-19 14:13:28',
        vitalSignScore: 85.5,
      },
      {
        dataFor: '2024-01-20 14:13:28',
        vitalSignScore: 86.4,
      },
      {
        dataFor: '2024-01-21 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-22 14:13:28',
        vitalSignScore: 87.6,
      },
      {
        dataFor: '2024-01-23 14:13:28',
        vitalSignScore: 87,
      },
      {
        dataFor: '2024-01-24 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-25 14:13:28',
        vitalSignScore: 85.7,
      },
      {
        dataFor: '2024-01-26 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-27 14:13:28',
        vitalSignScore: 87.1,
      },
      {
        dataFor: '2024-01-28 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-29 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-30 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-31 14:13:28',
        vitalSignScore: 86.9,
      },
      {
        dataFor: '2024-02-01 14:13:28',
        vitalSignScore: 86.5,
      },
      {
        dataFor: '2024-02-02 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-02-03 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2024-02-04 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-02-05 14:13:28',
        vitalSignScore: 86.2,
      },
      {
        dataFor: '2024-02-06 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-02-07 14:13:28',
        vitalSignScore: 89.1,
      },
      {
        dataFor: '2024-02-08 14:13:28',
        vitalSignScore: 88.9,
      },
      {
        dataFor: '2024-02-09 14:13:28',
        vitalSignScore: 89.7,
      },
      {
        dataFor: '2024-02-10 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-02-11 14:13:28',
        vitalSignScore: 88.5,
      },
      {
        dataFor: '2024-02-12 14:13:28',
        vitalSignScore: 88.4,
      },
      {
        dataFor: '2024-02-13 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-02-14 14:13:28',
        vitalSignScore: 86.3,
      },
      {
        dataFor: '2024-02-15 14:13:28',
        vitalSignScore: 86.2,
      },
      {
        dataFor: '2024-02-16 14:13:28',
        vitalSignScore: 85.9,
      },
      {
        dataFor: '2024-02-17 14:13:28',
        vitalSignScore: 88,
      },
      {
        dataFor: '2024-02-18 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-02-19 14:13:28',
        vitalSignScore: 89.5,
      },
      {
        dataFor: '2024-02-20 14:13:28',
        vitalSignScore: 90,
      },
      {
        dataFor: '2024-02-21 14:13:28',
        vitalSignScore: 90.2,
      },
      {
        dataFor: '2024-02-22 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-02-23 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-02-24 14:13:28',
        vitalSignScore: 87.3,
      },
      {
        dataFor: '2024-02-25 14:13:28',
        vitalSignScore: 87.6,
      },
      {
        dataFor: '2024-02-26 14:13:28',
        vitalSignScore: 88.2,
      },
      {
        dataFor: '2024-02-27 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-02-28 14:13:28',
        vitalSignScore: 89.3,
      },
      {
        dataFor: '2024-02-29 14:13:28',
        vitalSignScore: 89.8,
      },
      {
        dataFor: '2024-03-01 14:13:28',
        vitalSignScore: 89.3,
      },
      {
        dataFor: '2024-03-02 14:13:28',
        vitalSignScore: 88,
      },
      {
        dataFor: '2024-03-03 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-03-04 14:13:28',
        vitalSignScore: 88.8,
      },
      {
        dataFor: '2024-03-05 14:13:28',
        vitalSignScore: 89.4,
      },
      {
        dataFor: '2024-03-06 14:13:28',
        vitalSignScore: 88.2,
      },
      {
        dataFor: '2024-03-07 14:13:28',
        vitalSignScore: 87.5,
      },
      {
        dataFor: '2024-03-08 14:13:28',
        vitalSignScore: 87.7,
      },
      {
        dataFor: '2024-03-09 14:13:28',
        vitalSignScore: 87.7,
      },
      {
        dataFor: '2024-03-10 14:13:28',
        vitalSignScore: 89.4,
      },
      {
        dataFor: '2024-03-11 14:13:28',
        vitalSignScore: 90.3,
      },
      {
        dataFor: '2024-03-12 14:13:28',
        vitalSignScore: 90.7,
      },
      {
        dataFor: '2024-03-13 14:13:28',
        vitalSignScore: 90.9,
      },
      {
        dataFor: '2024-03-14 14:13:28',
        vitalSignScore: 90.7,
      },
      {
        dataFor: '2024-03-15 14:13:28',
        vitalSignScore: 90.8,
      },
    ],
  },
  {
    slug: 'inclusion',
    graphData: [
      {
        dataFor: '2023-03-17 14:13:28',
        vitalSignScore: 73.5,
      },
      {
        dataFor: '2023-03-18 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-19 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-20 14:13:28',
        vitalSignScore: 72.5,
      },
      {
        dataFor: '2023-03-21 14:13:28',
        vitalSignScore: 73.6,
      },
      {
        dataFor: '2023-03-22 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-03-23 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-03-24 14:13:28',
        vitalSignScore: 70.2,
      },
      {
        dataFor: '2023-03-25 14:13:28',
        vitalSignScore: 70.8,
      },
      {
        dataFor: '2023-03-26 14:13:28',
        vitalSignScore: 70.5,
      },
      {
        dataFor: '2023-03-27 14:13:28',
        vitalSignScore: 70.8,
      },
      {
        dataFor: '2023-03-28 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-03-29 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-30 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-31 14:13:28',
        vitalSignScore: 71.6,
      },
      {
        dataFor: '2023-04-01 14:13:28',
        vitalSignScore: 71.4,
      },
      {
        dataFor: '2023-04-02 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-04-03 14:13:28',
        vitalSignScore: 72,
      },
      {
        dataFor: '2023-04-04 14:13:28',
        vitalSignScore: 72,
      },
      {
        dataFor: '2023-04-05 14:13:28',
        vitalSignScore: 70.5,
      },
      {
        dataFor: '2023-04-06 14:13:28',
        vitalSignScore: 69,
      },
      {
        dataFor: '2023-04-07 14:13:28',
        vitalSignScore: 69.3,
      },
      {
        dataFor: '2023-04-08 14:13:28',
        vitalSignScore: 70.5,
      },
      {
        dataFor: '2023-04-09 14:13:28',
        vitalSignScore: 71.7,
      },
      {
        dataFor: '2023-04-10 14:13:28',
        vitalSignScore: 72.9,
      },
      {
        dataFor: '2023-04-11 14:13:28',
        vitalSignScore: 71.4,
      },
      {
        dataFor: '2023-04-12 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-04-13 14:13:28',
        vitalSignScore: 70.4,
      },
      {
        dataFor: '2023-04-14 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-04-15 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-04-16 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-04-17 14:13:28',
        vitalSignScore: 73,
      },
      {
        dataFor: '2023-04-18 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-04-19 14:13:28',
        vitalSignScore: 70.1,
      },
      {
        dataFor: '2023-04-20 14:13:28',
        vitalSignScore: 69.7,
      },
      {
        dataFor: '2023-04-21 14:13:28',
        vitalSignScore: 70.4,
      },
      {
        dataFor: '2023-04-22 14:13:28',
        vitalSignScore: 72.6,
      },
      {
        dataFor: '2023-04-23 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-04-24 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-04-25 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-04-26 14:13:28',
        vitalSignScore: 71,
      },
      {
        dataFor: '2023-04-27 14:13:28',
        vitalSignScore: 70.4,
      },
      {
        dataFor: '2023-04-28 14:13:28',
        vitalSignScore: 69.5,
      },
      {
        dataFor: '2023-04-29 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-04-30 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-05-01 14:13:28',
        vitalSignScore: 73.1,
      },
      {
        dataFor: '2023-05-02 14:13:28',
        vitalSignScore: 71,
      },
      {
        dataFor: '2023-05-03 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-05-04 14:13:28',
        vitalSignScore: 71.2,
      },
      {
        dataFor: '2023-05-05 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-05-06 14:13:28',
        vitalSignScore: 70.9,
      },
      {
        dataFor: '2023-05-07 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-05-08 14:13:28',
        vitalSignScore: 72.1,
      },
      {
        dataFor: '2023-05-09 14:13:28',
        vitalSignScore: 72,
      },
      {
        dataFor: '2023-05-10 14:13:28',
        vitalSignScore: 71.8,
      },
      {
        dataFor: '2023-05-11 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-05-12 14:13:28',
        vitalSignScore: 73.3,
      },
      {
        dataFor: '2023-05-13 14:13:28',
        vitalSignScore: 73.6,
      },
      {
        dataFor: '2023-05-14 14:13:28',
        vitalSignScore: 73,
      },
      {
        dataFor: '2023-05-15 14:13:28',
        vitalSignScore: 72.7,
      },
      {
        dataFor: '2023-05-16 14:13:28',
        vitalSignScore: 72.1,
      },
      {
        dataFor: '2023-05-17 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-05-18 14:13:28',
        vitalSignScore: 72.1,
      },
      {
        dataFor: '2023-05-19 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-05-20 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-05-21 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-05-22 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-05-23 14:13:28',
        vitalSignScore: 72.6,
      },
      {
        dataFor: '2023-05-24 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-05-25 14:13:28',
        vitalSignScore: 73.5,
      },
      {
        dataFor: '2023-05-26 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-05-27 14:13:28',
        vitalSignScore: 74.5,
      },
      {
        dataFor: '2023-05-28 14:13:28',
        vitalSignScore: 75.2,
      },
      {
        dataFor: '2023-05-29 14:13:28',
        vitalSignScore: 74,
      },
      {
        dataFor: '2023-05-30 14:13:28',
        vitalSignScore: 74.2,
      },
      {
        dataFor: '2023-05-31 14:13:28',
        vitalSignScore: 73.1,
      },
      {
        dataFor: '2023-06-01 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-06-02 14:13:28',
        vitalSignScore: 72.8,
      },
      {
        dataFor: '2023-06-03 14:13:28',
        vitalSignScore: 73.1,
      },
      {
        dataFor: '2023-06-04 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-06-05 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-06-06 14:13:28',
        vitalSignScore: 74.3,
      },
      {
        dataFor: '2023-06-07 14:13:28',
        vitalSignScore: 74.6,
      },
      {
        dataFor: '2023-06-08 14:13:28',
        vitalSignScore: 74.4,
      },
      {
        dataFor: '2023-06-09 14:13:28',
        vitalSignScore: 74.8,
      },
      {
        dataFor: '2023-06-10 14:13:28',
        vitalSignScore: 75.8,
      },
      {
        dataFor: '2023-06-11 14:13:28',
        vitalSignScore: 77.7,
      },
      {
        dataFor: '2023-06-12 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-06-13 14:13:28',
        vitalSignScore: 74.7,
      },
      {
        dataFor: '2023-06-14 14:13:28',
        vitalSignScore: 74.7,
      },
      {
        dataFor: '2023-06-15 14:13:28',
        vitalSignScore: 74.6,
      },
      {
        dataFor: '2023-06-16 14:13:28',
        vitalSignScore: 76.2,
      },
      {
        dataFor: '2023-06-17 14:13:28',
        vitalSignScore: 75.7,
      },
      {
        dataFor: '2023-06-18 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-06-19 14:13:28',
        vitalSignScore: 76.1,
      },
      {
        dataFor: '2023-06-20 14:13:28',
        vitalSignScore: 76.5,
      },
      {
        dataFor: '2023-06-21 14:13:28',
        vitalSignScore: 75.9,
      },
      {
        dataFor: '2023-06-22 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-06-23 14:13:28',
        vitalSignScore: 76.6,
      },
      {
        dataFor: '2023-06-24 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-06-25 14:13:28',
        vitalSignScore: 77,
      },
      {
        dataFor: '2023-06-26 14:13:28',
        vitalSignScore: 76.1,
      },
      {
        dataFor: '2023-06-27 14:13:28',
        vitalSignScore: 75.1,
      },
      {
        dataFor: '2023-06-28 14:13:28',
        vitalSignScore: 74.5,
      },
      {
        dataFor: '2023-06-29 14:13:28',
        vitalSignScore: 74.7,
      },
      {
        dataFor: '2023-06-30 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-07-01 14:13:28',
        vitalSignScore: 76,
      },
      {
        dataFor: '2023-07-02 14:13:28',
        vitalSignScore: 76.3,
      },
      {
        dataFor: '2023-07-03 14:13:28',
        vitalSignScore: 76.4,
      },
      {
        dataFor: '2023-07-04 14:13:28',
        vitalSignScore: 77.7,
      },
      {
        dataFor: '2023-07-05 14:13:28',
        vitalSignScore: 79.5,
      },
      {
        dataFor: '2023-07-06 14:13:28',
        vitalSignScore: 78.9,
      },
      {
        dataFor: '2023-07-07 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-08 14:13:28',
        vitalSignScore: 77.5,
      },
      {
        dataFor: '2023-07-09 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-07-10 14:13:28',
        vitalSignScore: 77.9,
      },
      {
        dataFor: '2023-07-11 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-07-12 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-13 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-14 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-15 14:13:28',
        vitalSignScore: 77.8,
      },
      {
        dataFor: '2023-07-16 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-07-17 14:13:28',
        vitalSignScore: 76.4,
      },
      {
        dataFor: '2023-07-18 14:13:28',
        vitalSignScore: 77.1,
      },
      {
        dataFor: '2023-07-19 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-20 14:13:28',
        vitalSignScore: 77.1,
      },
      {
        dataFor: '2023-07-21 14:13:28',
        vitalSignScore: 76,
      },
      {
        dataFor: '2023-07-22 14:13:28',
        vitalSignScore: 77.8,
      },
      {
        dataFor: '2023-07-23 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-07-24 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-07-25 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-07-26 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-07-27 14:13:28',
        vitalSignScore: 76.6,
      },
      {
        dataFor: '2023-07-28 14:13:28',
        vitalSignScore: 77.2,
      },
      {
        dataFor: '2023-07-29 14:13:28',
        vitalSignScore: 76.5,
      },
      {
        dataFor: '2023-07-30 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-31 14:13:28',
        vitalSignScore: 77.8,
      },
      {
        dataFor: '2023-08-01 14:13:28',
        vitalSignScore: 78.2,
      },
      {
        dataFor: '2023-08-02 14:13:28',
        vitalSignScore: 78,
      },
      {
        dataFor: '2023-08-03 14:13:28',
        vitalSignScore: 77,
      },
      {
        dataFor: '2023-08-04 14:13:28',
        vitalSignScore: 76.2,
      },
      {
        dataFor: '2023-08-05 14:13:28',
        vitalSignScore: 76.3,
      },
      {
        dataFor: '2023-08-06 14:13:28',
        vitalSignScore: 77.2,
      },
      {
        dataFor: '2023-08-07 14:13:28',
        vitalSignScore: 78.6,
      },
      {
        dataFor: '2023-08-08 14:13:28',
        vitalSignScore: 80,
      },
      {
        dataFor: '2023-08-09 14:13:28',
        vitalSignScore: 80.6,
      },
      {
        dataFor: '2023-08-10 14:13:28',
        vitalSignScore: 79.6,
      },
      {
        dataFor: '2023-08-11 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-12 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-08-13 14:13:28',
        vitalSignScore: 77.7,
      },
      {
        dataFor: '2023-08-14 14:13:28',
        vitalSignScore: 77,
      },
      {
        dataFor: '2023-08-15 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-08-16 14:13:28',
        vitalSignScore: 78.9,
      },
      {
        dataFor: '2023-08-17 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-08-18 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-08-19 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-08-20 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-21 14:13:28',
        vitalSignScore: 78.2,
      },
      {
        dataFor: '2023-08-22 14:13:28',
        vitalSignScore: 77.9,
      },
      {
        dataFor: '2023-08-23 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-08-24 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-08-25 14:13:28',
        vitalSignScore: 79.5,
      },
      {
        dataFor: '2023-08-26 14:13:28',
        vitalSignScore: 80.4,
      },
      {
        dataFor: '2023-08-27 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-08-28 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-29 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-30 14:13:28',
        vitalSignScore: 78.1,
      },
      {
        dataFor: '2023-08-31 14:13:28',
        vitalSignScore: 78,
      },
      {
        dataFor: '2023-09-01 14:13:28',
        vitalSignScore: 78.3,
      },
      {
        dataFor: '2023-09-02 14:13:28',
        vitalSignScore: 79.2,
      },
      {
        dataFor: '2023-09-03 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-09-04 14:13:28',
        vitalSignScore: 80.4,
      },
      {
        dataFor: '2023-09-05 14:13:28',
        vitalSignScore: 80.8,
      },
      {
        dataFor: '2023-09-06 14:13:28',
        vitalSignScore: 79.8,
      },
      {
        dataFor: '2023-09-07 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-09-08 14:13:28',
        vitalSignScore: 78.9,
      },
      {
        dataFor: '2023-09-09 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-09-10 14:13:28',
        vitalSignScore: 79.1,
      },
      {
        dataFor: '2023-09-11 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-09-12 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-09-13 14:13:28',
        vitalSignScore: 79.3,
      },
      {
        dataFor: '2023-09-14 14:13:28',
        vitalSignScore: 78.7,
      },
      {
        dataFor: '2023-09-15 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-09-16 14:13:28',
        vitalSignScore: 77.2,
      },
      {
        dataFor: '2023-09-17 14:13:28',
        vitalSignScore: 78.6,
      },
      {
        dataFor: '2023-09-18 14:13:28',
        vitalSignScore: 78,
      },
      {
        dataFor: '2023-09-19 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-09-20 14:13:28',
        vitalSignScore: 78.5,
      },
      {
        dataFor: '2023-09-21 14:13:28',
        vitalSignScore: 79.1,
      },
      {
        dataFor: '2023-09-22 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-09-23 14:13:28',
        vitalSignScore: 80.1,
      },
      {
        dataFor: '2023-09-24 14:13:28',
        vitalSignScore: 80.8,
      },
      {
        dataFor: '2023-09-25 14:13:28',
        vitalSignScore: 79.8,
      },
      {
        dataFor: '2023-09-26 14:13:28',
        vitalSignScore: 79.9,
      },
      {
        dataFor: '2023-09-27 14:13:28',
        vitalSignScore: 80.1,
      },
      {
        dataFor: '2023-09-28 14:13:28',
        vitalSignScore: 80.1,
      },
      {
        dataFor: '2023-09-29 14:13:28',
        vitalSignScore: 81.5,
      },
      {
        dataFor: '2023-09-30 14:13:28',
        vitalSignScore: 81.7,
      },
      {
        dataFor: '2023-10-01 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-10-02 14:13:28',
        vitalSignScore: 83.8,
      },
      {
        dataFor: '2023-10-03 14:13:28',
        vitalSignScore: 83,
      },
      {
        dataFor: '2023-10-04 14:13:28',
        vitalSignScore: 81.5,
      },
      {
        dataFor: '2023-10-05 14:13:28',
        vitalSignScore: 81.4,
      },
      {
        dataFor: '2023-10-06 14:13:28',
        vitalSignScore: 82.1,
      },
      {
        dataFor: '2023-10-07 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-10-08 14:13:28',
        vitalSignScore: 81,
      },
      {
        dataFor: '2023-10-09 14:13:28',
        vitalSignScore: 80.6,
      },
      {
        dataFor: '2023-10-10 14:13:28',
        vitalSignScore: 79.7,
      },
      {
        dataFor: '2023-10-11 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-10-12 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-10-13 14:13:28',
        vitalSignScore: 82.2,
      },
      {
        dataFor: '2023-10-14 14:13:28',
        vitalSignScore: 82.3,
      },
      {
        dataFor: '2023-10-15 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-10-16 14:13:28',
        vitalSignScore: 82.1,
      },
      {
        dataFor: '2023-10-17 14:13:28',
        vitalSignScore: 81.3,
      },
      {
        dataFor: '2023-10-18 14:13:28',
        vitalSignScore: 80.5,
      },
      {
        dataFor: '2023-10-19 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-10-20 14:13:28',
        vitalSignScore: 81.8,
      },
      {
        dataFor: '2023-10-21 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-10-22 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-10-23 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-10-24 14:13:28',
        vitalSignScore: 81.2,
      },
      {
        dataFor: '2023-10-25 14:13:28',
        vitalSignScore: 81,
      },
      {
        dataFor: '2023-10-26 14:13:28',
        vitalSignScore: 82,
      },
      {
        dataFor: '2023-10-27 14:13:28',
        vitalSignScore: 81.2,
      },
      {
        dataFor: '2023-10-28 14:13:28',
        vitalSignScore: 81.6,
      },
      {
        dataFor: '2023-10-29 14:13:28',
        vitalSignScore: 81.5,
      },
      {
        dataFor: '2023-10-30 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-10-31 14:13:28',
        vitalSignScore: 82.3,
      },
      {
        dataFor: '2023-11-01 14:13:28',
        vitalSignScore: 82.1,
      },
      {
        dataFor: '2023-11-02 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-11-03 14:13:28',
        vitalSignScore: 81.2,
      },
      {
        dataFor: '2023-11-04 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-11-05 14:13:28',
        vitalSignScore: 82.3,
      },
      {
        dataFor: '2023-11-06 14:13:28',
        vitalSignScore: 81.9,
      },
      {
        dataFor: '2023-11-07 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-08 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-11-09 14:13:28',
        vitalSignScore: 83.1,
      },
      {
        dataFor: '2023-11-10 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-11 14:13:28',
        vitalSignScore: 82.4,
      },
      {
        dataFor: '2023-11-12 14:13:28',
        vitalSignScore: 83.4,
      },
      {
        dataFor: '2023-11-13 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-14 14:13:28',
        vitalSignScore: 82.5,
      },
      {
        dataFor: '2023-11-15 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-11-16 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-17 14:13:28',
        vitalSignScore: 81.9,
      },
      {
        dataFor: '2023-11-18 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-19 14:13:28',
        vitalSignScore: 83.9,
      },
      {
        dataFor: '2023-11-20 14:13:28',
        vitalSignScore: 84.5,
      },
      {
        dataFor: '2023-11-21 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-11-22 14:13:28',
        vitalSignScore: 82.5,
      },
      {
        dataFor: '2023-11-23 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-11-24 14:13:28',
        vitalSignScore: 83.8,
      },
      {
        dataFor: '2023-11-25 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-11-26 14:13:28',
        vitalSignScore: 84.2,
      },
      {
        dataFor: '2023-11-27 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2023-11-28 14:13:28',
        vitalSignScore: 84.5,
      },
      {
        dataFor: '2023-11-29 14:13:28',
        vitalSignScore: 83.1,
      },
      {
        dataFor: '2023-11-30 14:13:28',
        vitalSignScore: 81.7,
      },
      {
        dataFor: '2023-12-01 14:13:28',
        vitalSignScore: 82.6,
      },
      {
        dataFor: '2023-12-02 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-12-03 14:13:28',
        vitalSignScore: 83.6,
      },
      {
        dataFor: '2023-12-04 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-12-05 14:13:28',
        vitalSignScore: 83.6,
      },
      {
        dataFor: '2023-12-06 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-12-07 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-12-08 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-12-09 14:13:28',
        vitalSignScore: 85.1,
      },
      {
        dataFor: '2023-12-10 14:13:28',
        vitalSignScore: 84.9,
      },
      {
        dataFor: '2023-12-11 14:13:28',
        vitalSignScore: 84.6,
      },
      {
        dataFor: '2023-12-12 14:13:28',
        vitalSignScore: 84.9,
      },
      {
        dataFor: '2023-12-13 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-12-14 14:13:28',
        vitalSignScore: 84.6,
      },
      {
        dataFor: '2023-12-15 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2023-12-16 14:13:28',
        vitalSignScore: 86.1,
      },
      {
        dataFor: '2023-12-17 14:13:28',
        vitalSignScore: 84.6,
      },
      {
        dataFor: '2023-12-18 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2023-12-19 14:13:28',
        vitalSignScore: 85.2,
      },
      {
        dataFor: '2023-12-20 14:13:28',
        vitalSignScore: 84.1,
      },
      {
        dataFor: '2023-12-21 14:13:28',
        vitalSignScore: 85.2,
      },
      {
        dataFor: '2023-12-22 14:13:28',
        vitalSignScore: 85.3,
      },
      {
        dataFor: '2023-12-23 14:13:28',
        vitalSignScore: 87.4,
      },
      {
        dataFor: '2023-12-24 14:13:28',
        vitalSignScore: 85.6,
      },
      {
        dataFor: '2023-12-25 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2023-12-26 14:13:28',
        vitalSignScore: 86.3,
      },
      {
        dataFor: '2023-12-27 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2023-12-28 14:13:28',
        vitalSignScore: 85.6,
      },
      {
        dataFor: '2023-12-29 14:13:28',
        vitalSignScore: 86.2,
      },
      {
        dataFor: '2023-12-30 14:13:28',
        vitalSignScore: 86.3,
      },
      {
        dataFor: '2023-12-31 14:13:28',
        vitalSignScore: 88,
      },
      {
        dataFor: '2024-01-01 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-01-02 14:13:28',
        vitalSignScore: 86.1,
      },
      {
        dataFor: '2024-01-03 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-01-04 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-05 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-01-06 14:13:28',
        vitalSignScore: 86.1,
      },
      {
        dataFor: '2024-01-07 14:13:28',
        vitalSignScore: 86.9,
      },
      {
        dataFor: '2024-01-08 14:13:28',
        vitalSignScore: 87.5,
      },
      {
        dataFor: '2024-01-09 14:13:28',
        vitalSignScore: 86.5,
      },
      {
        dataFor: '2024-01-10 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2024-01-11 14:13:28',
        vitalSignScore: 85.5,
      },
      {
        dataFor: '2024-01-12 14:13:28',
        vitalSignScore: 87,
      },
      {
        dataFor: '2024-01-13 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2024-01-14 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-15 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-16 14:13:28',
        vitalSignScore: 86.4,
      },
      {
        dataFor: '2024-01-17 14:13:28',
        vitalSignScore: 86,
      },
      {
        dataFor: '2024-01-18 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2024-01-19 14:13:28',
        vitalSignScore: 85.5,
      },
      {
        dataFor: '2024-01-20 14:13:28',
        vitalSignScore: 86.4,
      },
      {
        dataFor: '2024-01-21 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-22 14:13:28',
        vitalSignScore: 87.6,
      },
      {
        dataFor: '2024-01-23 14:13:28',
        vitalSignScore: 87,
      },
      {
        dataFor: '2024-01-24 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-25 14:13:28',
        vitalSignScore: 85.7,
      },
      {
        dataFor: '2024-01-26 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-27 14:13:28',
        vitalSignScore: 87.1,
      },
      {
        dataFor: '2024-01-28 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-29 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-30 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-31 14:13:28',
        vitalSignScore: 86.9,
      },
      {
        dataFor: '2024-02-01 14:13:28',
        vitalSignScore: 86.5,
      },
      {
        dataFor: '2024-02-02 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-02-03 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2024-02-04 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-02-05 14:13:28',
        vitalSignScore: 86.2,
      },
      {
        dataFor: '2024-02-06 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-02-07 14:13:28',
        vitalSignScore: 89.1,
      },
      {
        dataFor: '2024-02-08 14:13:28',
        vitalSignScore: 88.9,
      },
      {
        dataFor: '2024-02-09 14:13:28',
        vitalSignScore: 89.7,
      },
      {
        dataFor: '2024-02-10 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-02-11 14:13:28',
        vitalSignScore: 88.5,
      },
      {
        dataFor: '2024-02-12 14:13:28',
        vitalSignScore: 88.4,
      },
      {
        dataFor: '2024-02-13 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-02-14 14:13:28',
        vitalSignScore: 86.3,
      },
      {
        dataFor: '2024-02-15 14:13:28',
        vitalSignScore: 86.2,
      },
      {
        dataFor: '2024-02-16 14:13:28',
        vitalSignScore: 85.9,
      },
      {
        dataFor: '2024-02-17 14:13:28',
        vitalSignScore: 88,
      },
      {
        dataFor: '2024-02-18 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-02-19 14:13:28',
        vitalSignScore: 89.5,
      },
      {
        dataFor: '2024-02-20 14:13:28',
        vitalSignScore: 90,
      },
      {
        dataFor: '2024-02-21 14:13:28',
        vitalSignScore: 90.2,
      },
      {
        dataFor: '2024-02-22 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-02-23 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-02-24 14:13:28',
        vitalSignScore: 87.3,
      },
      {
        dataFor: '2024-02-25 14:13:28',
        vitalSignScore: 87.6,
      },
      {
        dataFor: '2024-02-26 14:13:28',
        vitalSignScore: 88.2,
      },
      {
        dataFor: '2024-02-27 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-02-28 14:13:28',
        vitalSignScore: 89.3,
      },
      {
        dataFor: '2024-02-29 14:13:28',
        vitalSignScore: 89.8,
      },
      {
        dataFor: '2024-03-01 14:13:28',
        vitalSignScore: 89.3,
      },
      {
        dataFor: '2024-03-02 14:13:28',
        vitalSignScore: 88,
      },
      {
        dataFor: '2024-03-03 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-03-04 14:13:28',
        vitalSignScore: 88.8,
      },
      {
        dataFor: '2024-03-05 14:13:28',
        vitalSignScore: 89.4,
      },
      {
        dataFor: '2024-03-06 14:13:28',
        vitalSignScore: 88.2,
      },
      {
        dataFor: '2024-03-07 14:13:28',
        vitalSignScore: 87.5,
      },
      {
        dataFor: '2024-03-08 14:13:28',
        vitalSignScore: 87.7,
      },
      {
        dataFor: '2024-03-09 14:13:28',
        vitalSignScore: 87.7,
      },
      {
        dataFor: '2024-03-10 14:13:28',
        vitalSignScore: 89.4,
      },
      {
        dataFor: '2024-03-11 14:13:28',
        vitalSignScore: 90.3,
      },
      {
        dataFor: '2024-03-12 14:13:28',
        vitalSignScore: 90.7,
      },
      {
        dataFor: '2024-03-13 14:13:28',
        vitalSignScore: 90.9,
      },
      {
        dataFor: '2024-03-14 14:13:28',
        vitalSignScore: 90.7,
      },
      {
        dataFor: '2024-03-15 14:13:28',
        vitalSignScore: 90.8,
      },
    ],
  },
  {
    slug: 'innovation',
    graphData: [
      {
        dataFor: '2023-03-17 14:13:28',
        vitalSignScore: 73.5,
      },
      {
        dataFor: '2023-03-18 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-19 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-20 14:13:28',
        vitalSignScore: 72.5,
      },
      {
        dataFor: '2023-03-21 14:13:28',
        vitalSignScore: 73.6,
      },
      {
        dataFor: '2023-03-22 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-03-23 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-03-24 14:13:28',
        vitalSignScore: 70.2,
      },
      {
        dataFor: '2023-03-25 14:13:28',
        vitalSignScore: 70.8,
      },
      {
        dataFor: '2023-03-26 14:13:28',
        vitalSignScore: 70.5,
      },
      {
        dataFor: '2023-03-27 14:13:28',
        vitalSignScore: 70.8,
      },
      {
        dataFor: '2023-03-28 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-03-29 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-30 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-31 14:13:28',
        vitalSignScore: 71.6,
      },
      {
        dataFor: '2023-04-01 14:13:28',
        vitalSignScore: 71.4,
      },
      {
        dataFor: '2023-04-02 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-04-03 14:13:28',
        vitalSignScore: 72,
      },
      {
        dataFor: '2023-04-04 14:13:28',
        vitalSignScore: 72,
      },
      {
        dataFor: '2023-04-05 14:13:28',
        vitalSignScore: 70.5,
      },
      {
        dataFor: '2023-04-06 14:13:28',
        vitalSignScore: 69,
      },
      {
        dataFor: '2023-04-07 14:13:28',
        vitalSignScore: 69.3,
      },
      {
        dataFor: '2023-04-08 14:13:28',
        vitalSignScore: 70.5,
      },
      {
        dataFor: '2023-04-09 14:13:28',
        vitalSignScore: 71.7,
      },
      {
        dataFor: '2023-04-10 14:13:28',
        vitalSignScore: 72.9,
      },
      {
        dataFor: '2023-04-11 14:13:28',
        vitalSignScore: 71.4,
      },
      {
        dataFor: '2023-04-12 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-04-13 14:13:28',
        vitalSignScore: 70.4,
      },
      {
        dataFor: '2023-04-14 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-04-15 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-04-16 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-04-17 14:13:28',
        vitalSignScore: 73,
      },
      {
        dataFor: '2023-04-18 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-04-19 14:13:28',
        vitalSignScore: 70.1,
      },
      {
        dataFor: '2023-04-20 14:13:28',
        vitalSignScore: 69.7,
      },
      {
        dataFor: '2023-04-21 14:13:28',
        vitalSignScore: 70.4,
      },
      {
        dataFor: '2023-04-22 14:13:28',
        vitalSignScore: 72.6,
      },
      {
        dataFor: '2023-04-23 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-04-24 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-04-25 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-04-26 14:13:28',
        vitalSignScore: 71,
      },
      {
        dataFor: '2023-04-27 14:13:28',
        vitalSignScore: 70.4,
      },
      {
        dataFor: '2023-04-28 14:13:28',
        vitalSignScore: 69.5,
      },
      {
        dataFor: '2023-04-29 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-04-30 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-05-01 14:13:28',
        vitalSignScore: 73.1,
      },
      {
        dataFor: '2023-05-02 14:13:28',
        vitalSignScore: 71,
      },
      {
        dataFor: '2023-05-03 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-05-04 14:13:28',
        vitalSignScore: 71.2,
      },
      {
        dataFor: '2023-05-05 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-05-06 14:13:28',
        vitalSignScore: 70.9,
      },
      {
        dataFor: '2023-05-07 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-05-08 14:13:28',
        vitalSignScore: 72.1,
      },
      {
        dataFor: '2023-05-09 14:13:28',
        vitalSignScore: 72,
      },
      {
        dataFor: '2023-05-10 14:13:28',
        vitalSignScore: 71.8,
      },
      {
        dataFor: '2023-05-11 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-05-12 14:13:28',
        vitalSignScore: 73.3,
      },
      {
        dataFor: '2023-05-13 14:13:28',
        vitalSignScore: 73.6,
      },
      {
        dataFor: '2023-05-14 14:13:28',
        vitalSignScore: 73,
      },
      {
        dataFor: '2023-05-15 14:13:28',
        vitalSignScore: 72.7,
      },
      {
        dataFor: '2023-05-16 14:13:28',
        vitalSignScore: 72.1,
      },
      {
        dataFor: '2023-05-17 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-05-18 14:13:28',
        vitalSignScore: 72.1,
      },
      {
        dataFor: '2023-05-19 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-05-20 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-05-21 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-05-22 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-05-23 14:13:28',
        vitalSignScore: 72.6,
      },
      {
        dataFor: '2023-05-24 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-05-25 14:13:28',
        vitalSignScore: 73.5,
      },
      {
        dataFor: '2023-05-26 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-05-27 14:13:28',
        vitalSignScore: 74.5,
      },
      {
        dataFor: '2023-05-28 14:13:28',
        vitalSignScore: 75.2,
      },
      {
        dataFor: '2023-05-29 14:13:28',
        vitalSignScore: 74,
      },
      {
        dataFor: '2023-05-30 14:13:28',
        vitalSignScore: 74.2,
      },
      {
        dataFor: '2023-05-31 14:13:28',
        vitalSignScore: 73.1,
      },
      {
        dataFor: '2023-06-01 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-06-02 14:13:28',
        vitalSignScore: 72.8,
      },
      {
        dataFor: '2023-06-03 14:13:28',
        vitalSignScore: 73.1,
      },
      {
        dataFor: '2023-06-04 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-06-05 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-06-06 14:13:28',
        vitalSignScore: 74.3,
      },
      {
        dataFor: '2023-06-07 14:13:28',
        vitalSignScore: 74.6,
      },
      {
        dataFor: '2023-06-08 14:13:28',
        vitalSignScore: 74.4,
      },
      {
        dataFor: '2023-06-09 14:13:28',
        vitalSignScore: 74.8,
      },
      {
        dataFor: '2023-06-10 14:13:28',
        vitalSignScore: 75.8,
      },
      {
        dataFor: '2023-06-11 14:13:28',
        vitalSignScore: 77.7,
      },
      {
        dataFor: '2023-06-12 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-06-13 14:13:28',
        vitalSignScore: 74.7,
      },
      {
        dataFor: '2023-06-14 14:13:28',
        vitalSignScore: 74.7,
      },
      {
        dataFor: '2023-06-15 14:13:28',
        vitalSignScore: 74.6,
      },
      {
        dataFor: '2023-06-16 14:13:28',
        vitalSignScore: 76.2,
      },
      {
        dataFor: '2023-06-17 14:13:28',
        vitalSignScore: 75.7,
      },
      {
        dataFor: '2023-06-18 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-06-19 14:13:28',
        vitalSignScore: 76.1,
      },
      {
        dataFor: '2023-06-20 14:13:28',
        vitalSignScore: 76.5,
      },
      {
        dataFor: '2023-06-21 14:13:28',
        vitalSignScore: 75.9,
      },
      {
        dataFor: '2023-06-22 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-06-23 14:13:28',
        vitalSignScore: 76.6,
      },
      {
        dataFor: '2023-06-24 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-06-25 14:13:28',
        vitalSignScore: 77,
      },
      {
        dataFor: '2023-06-26 14:13:28',
        vitalSignScore: 76.1,
      },
      {
        dataFor: '2023-06-27 14:13:28',
        vitalSignScore: 75.1,
      },
      {
        dataFor: '2023-06-28 14:13:28',
        vitalSignScore: 74.5,
      },
      {
        dataFor: '2023-06-29 14:13:28',
        vitalSignScore: 74.7,
      },
      {
        dataFor: '2023-06-30 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-07-01 14:13:28',
        vitalSignScore: 76,
      },
      {
        dataFor: '2023-07-02 14:13:28',
        vitalSignScore: 76.3,
      },
      {
        dataFor: '2023-07-03 14:13:28',
        vitalSignScore: 76.4,
      },
      {
        dataFor: '2023-07-04 14:13:28',
        vitalSignScore: 77.7,
      },
      {
        dataFor: '2023-07-05 14:13:28',
        vitalSignScore: 79.5,
      },
      {
        dataFor: '2023-07-06 14:13:28',
        vitalSignScore: 78.9,
      },
      {
        dataFor: '2023-07-07 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-08 14:13:28',
        vitalSignScore: 77.5,
      },
      {
        dataFor: '2023-07-09 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-07-10 14:13:28',
        vitalSignScore: 77.9,
      },
      {
        dataFor: '2023-07-11 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-07-12 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-13 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-14 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-15 14:13:28',
        vitalSignScore: 77.8,
      },
      {
        dataFor: '2023-07-16 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-07-17 14:13:28',
        vitalSignScore: 76.4,
      },
      {
        dataFor: '2023-07-18 14:13:28',
        vitalSignScore: 77.1,
      },
      {
        dataFor: '2023-07-19 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-20 14:13:28',
        vitalSignScore: 77.1,
      },
      {
        dataFor: '2023-07-21 14:13:28',
        vitalSignScore: 76,
      },
      {
        dataFor: '2023-07-22 14:13:28',
        vitalSignScore: 77.8,
      },
      {
        dataFor: '2023-07-23 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-07-24 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-07-25 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-07-26 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-07-27 14:13:28',
        vitalSignScore: 76.6,
      },
      {
        dataFor: '2023-07-28 14:13:28',
        vitalSignScore: 77.2,
      },
      {
        dataFor: '2023-07-29 14:13:28',
        vitalSignScore: 76.5,
      },
      {
        dataFor: '2023-07-30 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-31 14:13:28',
        vitalSignScore: 77.8,
      },
      {
        dataFor: '2023-08-01 14:13:28',
        vitalSignScore: 78.2,
      },
      {
        dataFor: '2023-08-02 14:13:28',
        vitalSignScore: 78,
      },
      {
        dataFor: '2023-08-03 14:13:28',
        vitalSignScore: 77,
      },
      {
        dataFor: '2023-08-04 14:13:28',
        vitalSignScore: 76.2,
      },
      {
        dataFor: '2023-08-05 14:13:28',
        vitalSignScore: 76.3,
      },
      {
        dataFor: '2023-08-06 14:13:28',
        vitalSignScore: 77.2,
      },
      {
        dataFor: '2023-08-07 14:13:28',
        vitalSignScore: 78.6,
      },
      {
        dataFor: '2023-08-08 14:13:28',
        vitalSignScore: 80,
      },
      {
        dataFor: '2023-08-09 14:13:28',
        vitalSignScore: 80.6,
      },
      {
        dataFor: '2023-08-10 14:13:28',
        vitalSignScore: 79.6,
      },
      {
        dataFor: '2023-08-11 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-12 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-08-13 14:13:28',
        vitalSignScore: 77.7,
      },
      {
        dataFor: '2023-08-14 14:13:28',
        vitalSignScore: 77,
      },
      {
        dataFor: '2023-08-15 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-08-16 14:13:28',
        vitalSignScore: 78.9,
      },
      {
        dataFor: '2023-08-17 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-08-18 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-08-19 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-08-20 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-21 14:13:28',
        vitalSignScore: 78.2,
      },
      {
        dataFor: '2023-08-22 14:13:28',
        vitalSignScore: 77.9,
      },
      {
        dataFor: '2023-08-23 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-08-24 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-08-25 14:13:28',
        vitalSignScore: 79.5,
      },
      {
        dataFor: '2023-08-26 14:13:28',
        vitalSignScore: 80.4,
      },
      {
        dataFor: '2023-08-27 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-08-28 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-29 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-30 14:13:28',
        vitalSignScore: 78.1,
      },
      {
        dataFor: '2023-08-31 14:13:28',
        vitalSignScore: 78,
      },
      {
        dataFor: '2023-09-01 14:13:28',
        vitalSignScore: 78.3,
      },
      {
        dataFor: '2023-09-02 14:13:28',
        vitalSignScore: 79.2,
      },
      {
        dataFor: '2023-09-03 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-09-04 14:13:28',
        vitalSignScore: 80.4,
      },
      {
        dataFor: '2023-09-05 14:13:28',
        vitalSignScore: 80.8,
      },
      {
        dataFor: '2023-09-06 14:13:28',
        vitalSignScore: 79.8,
      },
      {
        dataFor: '2023-09-07 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-09-08 14:13:28',
        vitalSignScore: 78.9,
      },
      {
        dataFor: '2023-09-09 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-09-10 14:13:28',
        vitalSignScore: 79.1,
      },
      {
        dataFor: '2023-09-11 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-09-12 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-09-13 14:13:28',
        vitalSignScore: 79.3,
      },
      {
        dataFor: '2023-09-14 14:13:28',
        vitalSignScore: 78.7,
      },
      {
        dataFor: '2023-09-15 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-09-16 14:13:28',
        vitalSignScore: 77.2,
      },
      {
        dataFor: '2023-09-17 14:13:28',
        vitalSignScore: 78.6,
      },
      {
        dataFor: '2023-09-18 14:13:28',
        vitalSignScore: 78,
      },
      {
        dataFor: '2023-09-19 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-09-20 14:13:28',
        vitalSignScore: 78.5,
      },
      {
        dataFor: '2023-09-21 14:13:28',
        vitalSignScore: 79.1,
      },
      {
        dataFor: '2023-09-22 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-09-23 14:13:28',
        vitalSignScore: 80.1,
      },
      {
        dataFor: '2023-09-24 14:13:28',
        vitalSignScore: 80.8,
      },
      {
        dataFor: '2023-09-25 14:13:28',
        vitalSignScore: 79.8,
      },
      {
        dataFor: '2023-09-26 14:13:28',
        vitalSignScore: 79.9,
      },
      {
        dataFor: '2023-09-27 14:13:28',
        vitalSignScore: 80.1,
      },
      {
        dataFor: '2023-09-28 14:13:28',
        vitalSignScore: 80.1,
      },
      {
        dataFor: '2023-09-29 14:13:28',
        vitalSignScore: 81.5,
      },
      {
        dataFor: '2023-09-30 14:13:28',
        vitalSignScore: 81.7,
      },
      {
        dataFor: '2023-10-01 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-10-02 14:13:28',
        vitalSignScore: 83.8,
      },
      {
        dataFor: '2023-10-03 14:13:28',
        vitalSignScore: 83,
      },
      {
        dataFor: '2023-10-04 14:13:28',
        vitalSignScore: 81.5,
      },
      {
        dataFor: '2023-10-05 14:13:28',
        vitalSignScore: 81.4,
      },
      {
        dataFor: '2023-10-06 14:13:28',
        vitalSignScore: 82.1,
      },
      {
        dataFor: '2023-10-07 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-10-08 14:13:28',
        vitalSignScore: 81,
      },
      {
        dataFor: '2023-10-09 14:13:28',
        vitalSignScore: 80.6,
      },
      {
        dataFor: '2023-10-10 14:13:28',
        vitalSignScore: 79.7,
      },
      {
        dataFor: '2023-10-11 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-10-12 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-10-13 14:13:28',
        vitalSignScore: 82.2,
      },
      {
        dataFor: '2023-10-14 14:13:28',
        vitalSignScore: 82.3,
      },
      {
        dataFor: '2023-10-15 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-10-16 14:13:28',
        vitalSignScore: 82.1,
      },
      {
        dataFor: '2023-10-17 14:13:28',
        vitalSignScore: 81.3,
      },
      {
        dataFor: '2023-10-18 14:13:28',
        vitalSignScore: 80.5,
      },
      {
        dataFor: '2023-10-19 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-10-20 14:13:28',
        vitalSignScore: 81.8,
      },
      {
        dataFor: '2023-10-21 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-10-22 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-10-23 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-10-24 14:13:28',
        vitalSignScore: 81.2,
      },
      {
        dataFor: '2023-10-25 14:13:28',
        vitalSignScore: 81,
      },
      {
        dataFor: '2023-10-26 14:13:28',
        vitalSignScore: 82,
      },
      {
        dataFor: '2023-10-27 14:13:28',
        vitalSignScore: 81.2,
      },
      {
        dataFor: '2023-10-28 14:13:28',
        vitalSignScore: 81.6,
      },
      {
        dataFor: '2023-10-29 14:13:28',
        vitalSignScore: 81.5,
      },
      {
        dataFor: '2023-10-30 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-10-31 14:13:28',
        vitalSignScore: 82.3,
      },
      {
        dataFor: '2023-11-01 14:13:28',
        vitalSignScore: 82.1,
      },
      {
        dataFor: '2023-11-02 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-11-03 14:13:28',
        vitalSignScore: 81.2,
      },
      {
        dataFor: '2023-11-04 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-11-05 14:13:28',
        vitalSignScore: 82.3,
      },
      {
        dataFor: '2023-11-06 14:13:28',
        vitalSignScore: 81.9,
      },
      {
        dataFor: '2023-11-07 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-08 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-11-09 14:13:28',
        vitalSignScore: 83.1,
      },
      {
        dataFor: '2023-11-10 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-11 14:13:28',
        vitalSignScore: 82.4,
      },
      {
        dataFor: '2023-11-12 14:13:28',
        vitalSignScore: 83.4,
      },
      {
        dataFor: '2023-11-13 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-14 14:13:28',
        vitalSignScore: 82.5,
      },
      {
        dataFor: '2023-11-15 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-11-16 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-17 14:13:28',
        vitalSignScore: 81.9,
      },
      {
        dataFor: '2023-11-18 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-19 14:13:28',
        vitalSignScore: 83.9,
      },
      {
        dataFor: '2023-11-20 14:13:28',
        vitalSignScore: 84.5,
      },
      {
        dataFor: '2023-11-21 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-11-22 14:13:28',
        vitalSignScore: 82.5,
      },
      {
        dataFor: '2023-11-23 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-11-24 14:13:28',
        vitalSignScore: 83.8,
      },
      {
        dataFor: '2023-11-25 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-11-26 14:13:28',
        vitalSignScore: 84.2,
      },
      {
        dataFor: '2023-11-27 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2023-11-28 14:13:28',
        vitalSignScore: 84.5,
      },
      {
        dataFor: '2023-11-29 14:13:28',
        vitalSignScore: 83.1,
      },
      {
        dataFor: '2023-11-30 14:13:28',
        vitalSignScore: 81.7,
      },
      {
        dataFor: '2023-12-01 14:13:28',
        vitalSignScore: 82.6,
      },
      {
        dataFor: '2023-12-02 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-12-03 14:13:28',
        vitalSignScore: 83.6,
      },
      {
        dataFor: '2023-12-04 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-12-05 14:13:28',
        vitalSignScore: 83.6,
      },
      {
        dataFor: '2023-12-06 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-12-07 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-12-08 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-12-09 14:13:28',
        vitalSignScore: 85.1,
      },
      {
        dataFor: '2023-12-10 14:13:28',
        vitalSignScore: 84.9,
      },
      {
        dataFor: '2023-12-11 14:13:28',
        vitalSignScore: 84.6,
      },
      {
        dataFor: '2023-12-12 14:13:28',
        vitalSignScore: 84.9,
      },
      {
        dataFor: '2023-12-13 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-12-14 14:13:28',
        vitalSignScore: 84.6,
      },
      {
        dataFor: '2023-12-15 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2023-12-16 14:13:28',
        vitalSignScore: 86.1,
      },
      {
        dataFor: '2023-12-17 14:13:28',
        vitalSignScore: 84.6,
      },
      {
        dataFor: '2023-12-18 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2023-12-19 14:13:28',
        vitalSignScore: 85.2,
      },
      {
        dataFor: '2023-12-20 14:13:28',
        vitalSignScore: 84.1,
      },
      {
        dataFor: '2023-12-21 14:13:28',
        vitalSignScore: 85.2,
      },
      {
        dataFor: '2023-12-22 14:13:28',
        vitalSignScore: 85.3,
      },
      {
        dataFor: '2023-12-23 14:13:28',
        vitalSignScore: 87.4,
      },
      {
        dataFor: '2023-12-24 14:13:28',
        vitalSignScore: 85.6,
      },
      {
        dataFor: '2023-12-25 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2023-12-26 14:13:28',
        vitalSignScore: 86.3,
      },
      {
        dataFor: '2023-12-27 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2023-12-28 14:13:28',
        vitalSignScore: 85.6,
      },
      {
        dataFor: '2023-12-29 14:13:28',
        vitalSignScore: 86.2,
      },
      {
        dataFor: '2023-12-30 14:13:28',
        vitalSignScore: 86.3,
      },
      {
        dataFor: '2023-12-31 14:13:28',
        vitalSignScore: 88,
      },
      {
        dataFor: '2024-01-01 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-01-02 14:13:28',
        vitalSignScore: 86.1,
      },
      {
        dataFor: '2024-01-03 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-01-04 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-05 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-01-06 14:13:28',
        vitalSignScore: 86.1,
      },
      {
        dataFor: '2024-01-07 14:13:28',
        vitalSignScore: 86.9,
      },
      {
        dataFor: '2024-01-08 14:13:28',
        vitalSignScore: 87.5,
      },
      {
        dataFor: '2024-01-09 14:13:28',
        vitalSignScore: 86.5,
      },
      {
        dataFor: '2024-01-10 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2024-01-11 14:13:28',
        vitalSignScore: 85.5,
      },
      {
        dataFor: '2024-01-12 14:13:28',
        vitalSignScore: 87,
      },
      {
        dataFor: '2024-01-13 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2024-01-14 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-15 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-16 14:13:28',
        vitalSignScore: 86.4,
      },
      {
        dataFor: '2024-01-17 14:13:28',
        vitalSignScore: 86,
      },
      {
        dataFor: '2024-01-18 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2024-01-19 14:13:28',
        vitalSignScore: 85.5,
      },
      {
        dataFor: '2024-01-20 14:13:28',
        vitalSignScore: 86.4,
      },
      {
        dataFor: '2024-01-21 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-22 14:13:28',
        vitalSignScore: 87.6,
      },
      {
        dataFor: '2024-01-23 14:13:28',
        vitalSignScore: 87,
      },
      {
        dataFor: '2024-01-24 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-25 14:13:28',
        vitalSignScore: 85.7,
      },
      {
        dataFor: '2024-01-26 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-27 14:13:28',
        vitalSignScore: 87.1,
      },
      {
        dataFor: '2024-01-28 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-29 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-30 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-31 14:13:28',
        vitalSignScore: 86.9,
      },
      {
        dataFor: '2024-02-01 14:13:28',
        vitalSignScore: 86.5,
      },
      {
        dataFor: '2024-02-02 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-02-03 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2024-02-04 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-02-05 14:13:28',
        vitalSignScore: 86.2,
      },
      {
        dataFor: '2024-02-06 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-02-07 14:13:28',
        vitalSignScore: 89.1,
      },
      {
        dataFor: '2024-02-08 14:13:28',
        vitalSignScore: 88.9,
      },
      {
        dataFor: '2024-02-09 14:13:28',
        vitalSignScore: 89.7,
      },
      {
        dataFor: '2024-02-10 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-02-11 14:13:28',
        vitalSignScore: 88.5,
      },
      {
        dataFor: '2024-02-12 14:13:28',
        vitalSignScore: 88.4,
      },
      {
        dataFor: '2024-02-13 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-02-14 14:13:28',
        vitalSignScore: 86.3,
      },
      {
        dataFor: '2024-02-15 14:13:28',
        vitalSignScore: 86.2,
      },
      {
        dataFor: '2024-02-16 14:13:28',
        vitalSignScore: 85.9,
      },
      {
        dataFor: '2024-02-17 14:13:28',
        vitalSignScore: 88,
      },
      {
        dataFor: '2024-02-18 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-02-19 14:13:28',
        vitalSignScore: 89.5,
      },
      {
        dataFor: '2024-02-20 14:13:28',
        vitalSignScore: 90,
      },
      {
        dataFor: '2024-02-21 14:13:28',
        vitalSignScore: 90.2,
      },
      {
        dataFor: '2024-02-22 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-02-23 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-02-24 14:13:28',
        vitalSignScore: 87.3,
      },
      {
        dataFor: '2024-02-25 14:13:28',
        vitalSignScore: 87.6,
      },
      {
        dataFor: '2024-02-26 14:13:28',
        vitalSignScore: 88.2,
      },
      {
        dataFor: '2024-02-27 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-02-28 14:13:28',
        vitalSignScore: 89.3,
      },
      {
        dataFor: '2024-02-29 14:13:28',
        vitalSignScore: 89.8,
      },
      {
        dataFor: '2024-03-01 14:13:28',
        vitalSignScore: 89.3,
      },
      {
        dataFor: '2024-03-02 14:13:28',
        vitalSignScore: 88,
      },
      {
        dataFor: '2024-03-03 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-03-04 14:13:28',
        vitalSignScore: 88.8,
      },
      {
        dataFor: '2024-03-05 14:13:28',
        vitalSignScore: 89.4,
      },
      {
        dataFor: '2024-03-06 14:13:28',
        vitalSignScore: 88.2,
      },
      {
        dataFor: '2024-03-07 14:13:28',
        vitalSignScore: 87.5,
      },
      {
        dataFor: '2024-03-08 14:13:28',
        vitalSignScore: 87.7,
      },
      {
        dataFor: '2024-03-09 14:13:28',
        vitalSignScore: 87.7,
      },
      {
        dataFor: '2024-03-10 14:13:28',
        vitalSignScore: 89.4,
      },
      {
        dataFor: '2024-03-11 14:13:28',
        vitalSignScore: 90.3,
      },
      {
        dataFor: '2024-03-12 14:13:28',
        vitalSignScore: 90.7,
      },
      {
        dataFor: '2024-03-13 14:13:28',
        vitalSignScore: 90.9,
      },
      {
        dataFor: '2024-03-14 14:13:28',
        vitalSignScore: 90.7,
      },
      {
        dataFor: '2024-03-15 14:13:28',
        vitalSignScore: 90.8,
      },
    ],
  },
  {
    slug: 'psychological_safety',
    graphData: [
      {
        dataFor: '2023-03-17 14:13:28',
        vitalSignScore: 73.5,
      },
      {
        dataFor: '2023-03-18 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-19 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-20 14:13:28',
        vitalSignScore: 72.5,
      },
      {
        dataFor: '2023-03-21 14:13:28',
        vitalSignScore: 73.6,
      },
      {
        dataFor: '2023-03-22 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-03-23 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-03-24 14:13:28',
        vitalSignScore: 70.2,
      },
      {
        dataFor: '2023-03-25 14:13:28',
        vitalSignScore: 70.8,
      },
      {
        dataFor: '2023-03-26 14:13:28',
        vitalSignScore: 70.5,
      },
      {
        dataFor: '2023-03-27 14:13:28',
        vitalSignScore: 70.8,
      },
      {
        dataFor: '2023-03-28 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-03-29 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-30 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-31 14:13:28',
        vitalSignScore: 71.6,
      },
      {
        dataFor: '2023-04-01 14:13:28',
        vitalSignScore: 71.4,
      },
      {
        dataFor: '2023-04-02 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-04-03 14:13:28',
        vitalSignScore: 72,
      },
      {
        dataFor: '2023-04-04 14:13:28',
        vitalSignScore: 72,
      },
      {
        dataFor: '2023-04-05 14:13:28',
        vitalSignScore: 70.5,
      },
      {
        dataFor: '2023-04-06 14:13:28',
        vitalSignScore: 69,
      },
      {
        dataFor: '2023-04-07 14:13:28',
        vitalSignScore: 69.3,
      },
      {
        dataFor: '2023-04-08 14:13:28',
        vitalSignScore: 70.5,
      },
      {
        dataFor: '2023-04-09 14:13:28',
        vitalSignScore: 71.7,
      },
      {
        dataFor: '2023-04-10 14:13:28',
        vitalSignScore: 72.9,
      },
      {
        dataFor: '2023-04-11 14:13:28',
        vitalSignScore: 71.4,
      },
      {
        dataFor: '2023-04-12 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-04-13 14:13:28',
        vitalSignScore: 70.4,
      },
      {
        dataFor: '2023-04-14 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-04-15 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-04-16 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-04-17 14:13:28',
        vitalSignScore: 73,
      },
      {
        dataFor: '2023-04-18 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-04-19 14:13:28',
        vitalSignScore: 70.1,
      },
      {
        dataFor: '2023-04-20 14:13:28',
        vitalSignScore: 69.7,
      },
      {
        dataFor: '2023-04-21 14:13:28',
        vitalSignScore: 70.4,
      },
      {
        dataFor: '2023-04-22 14:13:28',
        vitalSignScore: 72.6,
      },
      {
        dataFor: '2023-04-23 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-04-24 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-04-25 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-04-26 14:13:28',
        vitalSignScore: 71,
      },
      {
        dataFor: '2023-04-27 14:13:28',
        vitalSignScore: 70.4,
      },
      {
        dataFor: '2023-04-28 14:13:28',
        vitalSignScore: 69.5,
      },
      {
        dataFor: '2023-04-29 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-04-30 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-05-01 14:13:28',
        vitalSignScore: 73.1,
      },
      {
        dataFor: '2023-05-02 14:13:28',
        vitalSignScore: 71,
      },
      {
        dataFor: '2023-05-03 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-05-04 14:13:28',
        vitalSignScore: 71.2,
      },
      {
        dataFor: '2023-05-05 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-05-06 14:13:28',
        vitalSignScore: 70.9,
      },
      {
        dataFor: '2023-05-07 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-05-08 14:13:28',
        vitalSignScore: 72.1,
      },
      {
        dataFor: '2023-05-09 14:13:28',
        vitalSignScore: 72,
      },
      {
        dataFor: '2023-05-10 14:13:28',
        vitalSignScore: 71.8,
      },
      {
        dataFor: '2023-05-11 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-05-12 14:13:28',
        vitalSignScore: 73.3,
      },
      {
        dataFor: '2023-05-13 14:13:28',
        vitalSignScore: 73.6,
      },
      {
        dataFor: '2023-05-14 14:13:28',
        vitalSignScore: 73,
      },
      {
        dataFor: '2023-05-15 14:13:28',
        vitalSignScore: 72.7,
      },
      {
        dataFor: '2023-05-16 14:13:28',
        vitalSignScore: 72.1,
      },
      {
        dataFor: '2023-05-17 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-05-18 14:13:28',
        vitalSignScore: 72.1,
      },
      {
        dataFor: '2023-05-19 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-05-20 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-05-21 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-05-22 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-05-23 14:13:28',
        vitalSignScore: 72.6,
      },
      {
        dataFor: '2023-05-24 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-05-25 14:13:28',
        vitalSignScore: 73.5,
      },
      {
        dataFor: '2023-05-26 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-05-27 14:13:28',
        vitalSignScore: 74.5,
      },
      {
        dataFor: '2023-05-28 14:13:28',
        vitalSignScore: 75.2,
      },
      {
        dataFor: '2023-05-29 14:13:28',
        vitalSignScore: 74,
      },
      {
        dataFor: '2023-05-30 14:13:28',
        vitalSignScore: 74.2,
      },
      {
        dataFor: '2023-05-31 14:13:28',
        vitalSignScore: 73.1,
      },
      {
        dataFor: '2023-06-01 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-06-02 14:13:28',
        vitalSignScore: 72.8,
      },
      {
        dataFor: '2023-06-03 14:13:28',
        vitalSignScore: 73.1,
      },
      {
        dataFor: '2023-06-04 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-06-05 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-06-06 14:13:28',
        vitalSignScore: 74.3,
      },
      {
        dataFor: '2023-06-07 14:13:28',
        vitalSignScore: 74.6,
      },
      {
        dataFor: '2023-06-08 14:13:28',
        vitalSignScore: 74.4,
      },
      {
        dataFor: '2023-06-09 14:13:28',
        vitalSignScore: 74.8,
      },
      {
        dataFor: '2023-06-10 14:13:28',
        vitalSignScore: 75.8,
      },
      {
        dataFor: '2023-06-11 14:13:28',
        vitalSignScore: 77.7,
      },
      {
        dataFor: '2023-06-12 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-06-13 14:13:28',
        vitalSignScore: 74.7,
      },
      {
        dataFor: '2023-06-14 14:13:28',
        vitalSignScore: 74.7,
      },
      {
        dataFor: '2023-06-15 14:13:28',
        vitalSignScore: 74.6,
      },
      {
        dataFor: '2023-06-16 14:13:28',
        vitalSignScore: 76.2,
      },
      {
        dataFor: '2023-06-17 14:13:28',
        vitalSignScore: 75.7,
      },
      {
        dataFor: '2023-06-18 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-06-19 14:13:28',
        vitalSignScore: 76.1,
      },
      {
        dataFor: '2023-06-20 14:13:28',
        vitalSignScore: 76.5,
      },
      {
        dataFor: '2023-06-21 14:13:28',
        vitalSignScore: 75.9,
      },
      {
        dataFor: '2023-06-22 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-06-23 14:13:28',
        vitalSignScore: 76.6,
      },
      {
        dataFor: '2023-06-24 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-06-25 14:13:28',
        vitalSignScore: 77,
      },
      {
        dataFor: '2023-06-26 14:13:28',
        vitalSignScore: 76.1,
      },
      {
        dataFor: '2023-06-27 14:13:28',
        vitalSignScore: 75.1,
      },
      {
        dataFor: '2023-06-28 14:13:28',
        vitalSignScore: 74.5,
      },
      {
        dataFor: '2023-06-29 14:13:28',
        vitalSignScore: 74.7,
      },
      {
        dataFor: '2023-06-30 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-07-01 14:13:28',
        vitalSignScore: 76,
      },
      {
        dataFor: '2023-07-02 14:13:28',
        vitalSignScore: 76.3,
      },
      {
        dataFor: '2023-07-03 14:13:28',
        vitalSignScore: 76.4,
      },
      {
        dataFor: '2023-07-04 14:13:28',
        vitalSignScore: 77.7,
      },
      {
        dataFor: '2023-07-05 14:13:28',
        vitalSignScore: 79.5,
      },
      {
        dataFor: '2023-07-06 14:13:28',
        vitalSignScore: 78.9,
      },
      {
        dataFor: '2023-07-07 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-08 14:13:28',
        vitalSignScore: 77.5,
      },
      {
        dataFor: '2023-07-09 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-07-10 14:13:28',
        vitalSignScore: 77.9,
      },
      {
        dataFor: '2023-07-11 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-07-12 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-13 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-14 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-15 14:13:28',
        vitalSignScore: 77.8,
      },
      {
        dataFor: '2023-07-16 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-07-17 14:13:28',
        vitalSignScore: 76.4,
      },
      {
        dataFor: '2023-07-18 14:13:28',
        vitalSignScore: 77.1,
      },
      {
        dataFor: '2023-07-19 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-20 14:13:28',
        vitalSignScore: 77.1,
      },
      {
        dataFor: '2023-07-21 14:13:28',
        vitalSignScore: 76,
      },
      {
        dataFor: '2023-07-22 14:13:28',
        vitalSignScore: 77.8,
      },
      {
        dataFor: '2023-07-23 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-07-24 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-07-25 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-07-26 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-07-27 14:13:28',
        vitalSignScore: 76.6,
      },
      {
        dataFor: '2023-07-28 14:13:28',
        vitalSignScore: 77.2,
      },
      {
        dataFor: '2023-07-29 14:13:28',
        vitalSignScore: 76.5,
      },
      {
        dataFor: '2023-07-30 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-31 14:13:28',
        vitalSignScore: 77.8,
      },
      {
        dataFor: '2023-08-01 14:13:28',
        vitalSignScore: 78.2,
      },
      {
        dataFor: '2023-08-02 14:13:28',
        vitalSignScore: 78,
      },
      {
        dataFor: '2023-08-03 14:13:28',
        vitalSignScore: 77,
      },
      {
        dataFor: '2023-08-04 14:13:28',
        vitalSignScore: 76.2,
      },
      {
        dataFor: '2023-08-05 14:13:28',
        vitalSignScore: 76.3,
      },
      {
        dataFor: '2023-08-06 14:13:28',
        vitalSignScore: 77.2,
      },
      {
        dataFor: '2023-08-07 14:13:28',
        vitalSignScore: 78.6,
      },
      {
        dataFor: '2023-08-08 14:13:28',
        vitalSignScore: 80,
      },
      {
        dataFor: '2023-08-09 14:13:28',
        vitalSignScore: 80.6,
      },
      {
        dataFor: '2023-08-10 14:13:28',
        vitalSignScore: 79.6,
      },
      {
        dataFor: '2023-08-11 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-12 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-08-13 14:13:28',
        vitalSignScore: 77.7,
      },
      {
        dataFor: '2023-08-14 14:13:28',
        vitalSignScore: 77,
      },
      {
        dataFor: '2023-08-15 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-08-16 14:13:28',
        vitalSignScore: 78.9,
      },
      {
        dataFor: '2023-08-17 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-08-18 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-08-19 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-08-20 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-21 14:13:28',
        vitalSignScore: 78.2,
      },
      {
        dataFor: '2023-08-22 14:13:28',
        vitalSignScore: 77.9,
      },
      {
        dataFor: '2023-08-23 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-08-24 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-08-25 14:13:28',
        vitalSignScore: 79.5,
      },
      {
        dataFor: '2023-08-26 14:13:28',
        vitalSignScore: 80.4,
      },
      {
        dataFor: '2023-08-27 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-08-28 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-29 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-30 14:13:28',
        vitalSignScore: 78.1,
      },
      {
        dataFor: '2023-08-31 14:13:28',
        vitalSignScore: 78,
      },
      {
        dataFor: '2023-09-01 14:13:28',
        vitalSignScore: 78.3,
      },
      {
        dataFor: '2023-09-02 14:13:28',
        vitalSignScore: 79.2,
      },
      {
        dataFor: '2023-09-03 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-09-04 14:13:28',
        vitalSignScore: 80.4,
      },
      {
        dataFor: '2023-09-05 14:13:28',
        vitalSignScore: 80.8,
      },
      {
        dataFor: '2023-09-06 14:13:28',
        vitalSignScore: 79.8,
      },
      {
        dataFor: '2023-09-07 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-09-08 14:13:28',
        vitalSignScore: 78.9,
      },
      {
        dataFor: '2023-09-09 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-09-10 14:13:28',
        vitalSignScore: 79.1,
      },
      {
        dataFor: '2023-09-11 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-09-12 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-09-13 14:13:28',
        vitalSignScore: 79.3,
      },
      {
        dataFor: '2023-09-14 14:13:28',
        vitalSignScore: 78.7,
      },
      {
        dataFor: '2023-09-15 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-09-16 14:13:28',
        vitalSignScore: 77.2,
      },
      {
        dataFor: '2023-09-17 14:13:28',
        vitalSignScore: 78.6,
      },
      {
        dataFor: '2023-09-18 14:13:28',
        vitalSignScore: 78,
      },
      {
        dataFor: '2023-09-19 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-09-20 14:13:28',
        vitalSignScore: 78.5,
      },
      {
        dataFor: '2023-09-21 14:13:28',
        vitalSignScore: 79.1,
      },
      {
        dataFor: '2023-09-22 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-09-23 14:13:28',
        vitalSignScore: 80.1,
      },
      {
        dataFor: '2023-09-24 14:13:28',
        vitalSignScore: 80.8,
      },
      {
        dataFor: '2023-09-25 14:13:28',
        vitalSignScore: 79.8,
      },
      {
        dataFor: '2023-09-26 14:13:28',
        vitalSignScore: 79.9,
      },
      {
        dataFor: '2023-09-27 14:13:28',
        vitalSignScore: 80.1,
      },
      {
        dataFor: '2023-09-28 14:13:28',
        vitalSignScore: 80.1,
      },
      {
        dataFor: '2023-09-29 14:13:28',
        vitalSignScore: 81.5,
      },
      {
        dataFor: '2023-09-30 14:13:28',
        vitalSignScore: 81.7,
      },
      {
        dataFor: '2023-10-01 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-10-02 14:13:28',
        vitalSignScore: 83.8,
      },
      {
        dataFor: '2023-10-03 14:13:28',
        vitalSignScore: 83,
      },
      {
        dataFor: '2023-10-04 14:13:28',
        vitalSignScore: 81.5,
      },
      {
        dataFor: '2023-10-05 14:13:28',
        vitalSignScore: 81.4,
      },
      {
        dataFor: '2023-10-06 14:13:28',
        vitalSignScore: 82.1,
      },
      {
        dataFor: '2023-10-07 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-10-08 14:13:28',
        vitalSignScore: 81,
      },
      {
        dataFor: '2023-10-09 14:13:28',
        vitalSignScore: 80.6,
      },
      {
        dataFor: '2023-10-10 14:13:28',
        vitalSignScore: 79.7,
      },
      {
        dataFor: '2023-10-11 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-10-12 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-10-13 14:13:28',
        vitalSignScore: 82.2,
      },
      {
        dataFor: '2023-10-14 14:13:28',
        vitalSignScore: 82.3,
      },
      {
        dataFor: '2023-10-15 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-10-16 14:13:28',
        vitalSignScore: 82.1,
      },
      {
        dataFor: '2023-10-17 14:13:28',
        vitalSignScore: 81.3,
      },
      {
        dataFor: '2023-10-18 14:13:28',
        vitalSignScore: 80.5,
      },
      {
        dataFor: '2023-10-19 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-10-20 14:13:28',
        vitalSignScore: 81.8,
      },
      {
        dataFor: '2023-10-21 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-10-22 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-10-23 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-10-24 14:13:28',
        vitalSignScore: 81.2,
      },
      {
        dataFor: '2023-10-25 14:13:28',
        vitalSignScore: 81,
      },
      {
        dataFor: '2023-10-26 14:13:28',
        vitalSignScore: 82,
      },
      {
        dataFor: '2023-10-27 14:13:28',
        vitalSignScore: 81.2,
      },
      {
        dataFor: '2023-10-28 14:13:28',
        vitalSignScore: 81.6,
      },
      {
        dataFor: '2023-10-29 14:13:28',
        vitalSignScore: 81.5,
      },
      {
        dataFor: '2023-10-30 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-10-31 14:13:28',
        vitalSignScore: 82.3,
      },
      {
        dataFor: '2023-11-01 14:13:28',
        vitalSignScore: 82.1,
      },
      {
        dataFor: '2023-11-02 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-11-03 14:13:28',
        vitalSignScore: 81.2,
      },
      {
        dataFor: '2023-11-04 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-11-05 14:13:28',
        vitalSignScore: 82.3,
      },
      {
        dataFor: '2023-11-06 14:13:28',
        vitalSignScore: 81.9,
      },
      {
        dataFor: '2023-11-07 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-08 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-11-09 14:13:28',
        vitalSignScore: 83.1,
      },
      {
        dataFor: '2023-11-10 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-11 14:13:28',
        vitalSignScore: 82.4,
      },
      {
        dataFor: '2023-11-12 14:13:28',
        vitalSignScore: 83.4,
      },
      {
        dataFor: '2023-11-13 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-14 14:13:28',
        vitalSignScore: 82.5,
      },
      {
        dataFor: '2023-11-15 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-11-16 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-17 14:13:28',
        vitalSignScore: 81.9,
      },
      {
        dataFor: '2023-11-18 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-19 14:13:28',
        vitalSignScore: 83.9,
      },
      {
        dataFor: '2023-11-20 14:13:28',
        vitalSignScore: 84.5,
      },
      {
        dataFor: '2023-11-21 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-11-22 14:13:28',
        vitalSignScore: 82.5,
      },
      {
        dataFor: '2023-11-23 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-11-24 14:13:28',
        vitalSignScore: 83.8,
      },
      {
        dataFor: '2023-11-25 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-11-26 14:13:28',
        vitalSignScore: 84.2,
      },
      {
        dataFor: '2023-11-27 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2023-11-28 14:13:28',
        vitalSignScore: 84.5,
      },
      {
        dataFor: '2023-11-29 14:13:28',
        vitalSignScore: 83.1,
      },
      {
        dataFor: '2023-11-30 14:13:28',
        vitalSignScore: 81.7,
      },
      {
        dataFor: '2023-12-01 14:13:28',
        vitalSignScore: 82.6,
      },
      {
        dataFor: '2023-12-02 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-12-03 14:13:28',
        vitalSignScore: 83.6,
      },
      {
        dataFor: '2023-12-04 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-12-05 14:13:28',
        vitalSignScore: 83.6,
      },
      {
        dataFor: '2023-12-06 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-12-07 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-12-08 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-12-09 14:13:28',
        vitalSignScore: 85.1,
      },
      {
        dataFor: '2023-12-10 14:13:28',
        vitalSignScore: 84.9,
      },
      {
        dataFor: '2023-12-11 14:13:28',
        vitalSignScore: 84.6,
      },
      {
        dataFor: '2023-12-12 14:13:28',
        vitalSignScore: 84.9,
      },
      {
        dataFor: '2023-12-13 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-12-14 14:13:28',
        vitalSignScore: 84.6,
      },
      {
        dataFor: '2023-12-15 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2023-12-16 14:13:28',
        vitalSignScore: 86.1,
      },
      {
        dataFor: '2023-12-17 14:13:28',
        vitalSignScore: 84.6,
      },
      {
        dataFor: '2023-12-18 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2023-12-19 14:13:28',
        vitalSignScore: 85.2,
      },
      {
        dataFor: '2023-12-20 14:13:28',
        vitalSignScore: 84.1,
      },
      {
        dataFor: '2023-12-21 14:13:28',
        vitalSignScore: 85.2,
      },
      {
        dataFor: '2023-12-22 14:13:28',
        vitalSignScore: 85.3,
      },
      {
        dataFor: '2023-12-23 14:13:28',
        vitalSignScore: 87.4,
      },
      {
        dataFor: '2023-12-24 14:13:28',
        vitalSignScore: 85.6,
      },
      {
        dataFor: '2023-12-25 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2023-12-26 14:13:28',
        vitalSignScore: 86.3,
      },
      {
        dataFor: '2023-12-27 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2023-12-28 14:13:28',
        vitalSignScore: 85.6,
      },
      {
        dataFor: '2023-12-29 14:13:28',
        vitalSignScore: 86.2,
      },
      {
        dataFor: '2023-12-30 14:13:28',
        vitalSignScore: 86.3,
      },
      {
        dataFor: '2023-12-31 14:13:28',
        vitalSignScore: 88,
      },
      {
        dataFor: '2024-01-01 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-01-02 14:13:28',
        vitalSignScore: 86.1,
      },
      {
        dataFor: '2024-01-03 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-01-04 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-05 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-01-06 14:13:28',
        vitalSignScore: 86.1,
      },
      {
        dataFor: '2024-01-07 14:13:28',
        vitalSignScore: 86.9,
      },
      {
        dataFor: '2024-01-08 14:13:28',
        vitalSignScore: 87.5,
      },
      {
        dataFor: '2024-01-09 14:13:28',
        vitalSignScore: 86.5,
      },
      {
        dataFor: '2024-01-10 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2024-01-11 14:13:28',
        vitalSignScore: 85.5,
      },
      {
        dataFor: '2024-01-12 14:13:28',
        vitalSignScore: 87,
      },
      {
        dataFor: '2024-01-13 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2024-01-14 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-15 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-16 14:13:28',
        vitalSignScore: 86.4,
      },
      {
        dataFor: '2024-01-17 14:13:28',
        vitalSignScore: 86,
      },
      {
        dataFor: '2024-01-18 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2024-01-19 14:13:28',
        vitalSignScore: 85.5,
      },
      {
        dataFor: '2024-01-20 14:13:28',
        vitalSignScore: 86.4,
      },
      {
        dataFor: '2024-01-21 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-22 14:13:28',
        vitalSignScore: 87.6,
      },
      {
        dataFor: '2024-01-23 14:13:28',
        vitalSignScore: 87,
      },
      {
        dataFor: '2024-01-24 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-25 14:13:28',
        vitalSignScore: 85.7,
      },
      {
        dataFor: '2024-01-26 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-27 14:13:28',
        vitalSignScore: 87.1,
      },
      {
        dataFor: '2024-01-28 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-29 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-30 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-31 14:13:28',
        vitalSignScore: 86.9,
      },
      {
        dataFor: '2024-02-01 14:13:28',
        vitalSignScore: 86.5,
      },
      {
        dataFor: '2024-02-02 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-02-03 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2024-02-04 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-02-05 14:13:28',
        vitalSignScore: 86.2,
      },
      {
        dataFor: '2024-02-06 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-02-07 14:13:28',
        vitalSignScore: 89.1,
      },
      {
        dataFor: '2024-02-08 14:13:28',
        vitalSignScore: 88.9,
      },
      {
        dataFor: '2024-02-09 14:13:28',
        vitalSignScore: 89.7,
      },
      {
        dataFor: '2024-02-10 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-02-11 14:13:28',
        vitalSignScore: 88.5,
      },
      {
        dataFor: '2024-02-12 14:13:28',
        vitalSignScore: 88.4,
      },
      {
        dataFor: '2024-02-13 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-02-14 14:13:28',
        vitalSignScore: 86.3,
      },
      {
        dataFor: '2024-02-15 14:13:28',
        vitalSignScore: 86.2,
      },
      {
        dataFor: '2024-02-16 14:13:28',
        vitalSignScore: 85.9,
      },
      {
        dataFor: '2024-02-17 14:13:28',
        vitalSignScore: 88,
      },
      {
        dataFor: '2024-02-18 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-02-19 14:13:28',
        vitalSignScore: 89.5,
      },
      {
        dataFor: '2024-02-20 14:13:28',
        vitalSignScore: 90,
      },
      {
        dataFor: '2024-02-21 14:13:28',
        vitalSignScore: 90.2,
      },
      {
        dataFor: '2024-02-22 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-02-23 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-02-24 14:13:28',
        vitalSignScore: 87.3,
      },
      {
        dataFor: '2024-02-25 14:13:28',
        vitalSignScore: 87.6,
      },
      {
        dataFor: '2024-02-26 14:13:28',
        vitalSignScore: 88.2,
      },
      {
        dataFor: '2024-02-27 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-02-28 14:13:28',
        vitalSignScore: 89.3,
      },
      {
        dataFor: '2024-02-29 14:13:28',
        vitalSignScore: 89.8,
      },
      {
        dataFor: '2024-03-01 14:13:28',
        vitalSignScore: 89.3,
      },
      {
        dataFor: '2024-03-02 14:13:28',
        vitalSignScore: 88,
      },
      {
        dataFor: '2024-03-03 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-03-04 14:13:28',
        vitalSignScore: 88.8,
      },
      {
        dataFor: '2024-03-05 14:13:28',
        vitalSignScore: 89.4,
      },
      {
        dataFor: '2024-03-06 14:13:28',
        vitalSignScore: 88.2,
      },
      {
        dataFor: '2024-03-07 14:13:28',
        vitalSignScore: 87.5,
      },
      {
        dataFor: '2024-03-08 14:13:28',
        vitalSignScore: 87.7,
      },
      {
        dataFor: '2024-03-09 14:13:28',
        vitalSignScore: 87.7,
      },
      {
        dataFor: '2024-03-10 14:13:28',
        vitalSignScore: 89.4,
      },
      {
        dataFor: '2024-03-11 14:13:28',
        vitalSignScore: 90.3,
      },
      {
        dataFor: '2024-03-12 14:13:28',
        vitalSignScore: 90.7,
      },
      {
        dataFor: '2024-03-13 14:13:28',
        vitalSignScore: 90.9,
      },
      {
        dataFor: '2024-03-14 14:13:28',
        vitalSignScore: 90.7,
      },
      {
        dataFor: '2024-03-15 14:13:28',
        vitalSignScore: 90.8,
      },
    ],
  },
  {
    slug: 'burnout',
    graphData: [
      {
        dataFor: '2023-03-17 14:13:28',
        vitalSignScore: 73.5,
      },
      {
        dataFor: '2023-03-18 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-19 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-20 14:13:28',
        vitalSignScore: 72.5,
      },
      {
        dataFor: '2023-03-21 14:13:28',
        vitalSignScore: 73.6,
      },
      {
        dataFor: '2023-03-22 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-03-23 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-03-24 14:13:28',
        vitalSignScore: 70.2,
      },
      {
        dataFor: '2023-03-25 14:13:28',
        vitalSignScore: 70.8,
      },
      {
        dataFor: '2023-03-26 14:13:28',
        vitalSignScore: 70.5,
      },
      {
        dataFor: '2023-03-27 14:13:28',
        vitalSignScore: 70.8,
      },
      {
        dataFor: '2023-03-28 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-03-29 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-30 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-03-31 14:13:28',
        vitalSignScore: 71.6,
      },
      {
        dataFor: '2023-04-01 14:13:28',
        vitalSignScore: 71.4,
      },
      {
        dataFor: '2023-04-02 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-04-03 14:13:28',
        vitalSignScore: 72,
      },
      {
        dataFor: '2023-04-04 14:13:28',
        vitalSignScore: 72,
      },
      {
        dataFor: '2023-04-05 14:13:28',
        vitalSignScore: 70.5,
      },
      {
        dataFor: '2023-04-06 14:13:28',
        vitalSignScore: 69,
      },
      {
        dataFor: '2023-04-07 14:13:28',
        vitalSignScore: 69.3,
      },
      {
        dataFor: '2023-04-08 14:13:28',
        vitalSignScore: 70.5,
      },
      {
        dataFor: '2023-04-09 14:13:28',
        vitalSignScore: 71.7,
      },
      {
        dataFor: '2023-04-10 14:13:28',
        vitalSignScore: 72.9,
      },
      {
        dataFor: '2023-04-11 14:13:28',
        vitalSignScore: 71.4,
      },
      {
        dataFor: '2023-04-12 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-04-13 14:13:28',
        vitalSignScore: 70.4,
      },
      {
        dataFor: '2023-04-14 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-04-15 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-04-16 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-04-17 14:13:28',
        vitalSignScore: 73,
      },
      {
        dataFor: '2023-04-18 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-04-19 14:13:28',
        vitalSignScore: 70.1,
      },
      {
        dataFor: '2023-04-20 14:13:28',
        vitalSignScore: 69.7,
      },
      {
        dataFor: '2023-04-21 14:13:28',
        vitalSignScore: 70.4,
      },
      {
        dataFor: '2023-04-22 14:13:28',
        vitalSignScore: 72.6,
      },
      {
        dataFor: '2023-04-23 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-04-24 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-04-25 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-04-26 14:13:28',
        vitalSignScore: 71,
      },
      {
        dataFor: '2023-04-27 14:13:28',
        vitalSignScore: 70.4,
      },
      {
        dataFor: '2023-04-28 14:13:28',
        vitalSignScore: 69.5,
      },
      {
        dataFor: '2023-04-29 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-04-30 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-05-01 14:13:28',
        vitalSignScore: 73.1,
      },
      {
        dataFor: '2023-05-02 14:13:28',
        vitalSignScore: 71,
      },
      {
        dataFor: '2023-05-03 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-05-04 14:13:28',
        vitalSignScore: 71.2,
      },
      {
        dataFor: '2023-05-05 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-05-06 14:13:28',
        vitalSignScore: 70.9,
      },
      {
        dataFor: '2023-05-07 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-05-08 14:13:28',
        vitalSignScore: 72.1,
      },
      {
        dataFor: '2023-05-09 14:13:28',
        vitalSignScore: 72,
      },
      {
        dataFor: '2023-05-10 14:13:28',
        vitalSignScore: 71.8,
      },
      {
        dataFor: '2023-05-11 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-05-12 14:13:28',
        vitalSignScore: 73.3,
      },
      {
        dataFor: '2023-05-13 14:13:28',
        vitalSignScore: 73.6,
      },
      {
        dataFor: '2023-05-14 14:13:28',
        vitalSignScore: 73,
      },
      {
        dataFor: '2023-05-15 14:13:28',
        vitalSignScore: 72.7,
      },
      {
        dataFor: '2023-05-16 14:13:28',
        vitalSignScore: 72.1,
      },
      {
        dataFor: '2023-05-17 14:13:28',
        vitalSignScore: 72.4,
      },
      {
        dataFor: '2023-05-18 14:13:28',
        vitalSignScore: 72.1,
      },
      {
        dataFor: '2023-05-19 14:13:28',
        vitalSignScore: 71.5,
      },
      {
        dataFor: '2023-05-20 14:13:28',
        vitalSignScore: 71.9,
      },
      {
        dataFor: '2023-05-21 14:13:28',
        vitalSignScore: 72.2,
      },
      {
        dataFor: '2023-05-22 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-05-23 14:13:28',
        vitalSignScore: 72.6,
      },
      {
        dataFor: '2023-05-24 14:13:28',
        vitalSignScore: 72.3,
      },
      {
        dataFor: '2023-05-25 14:13:28',
        vitalSignScore: 73.5,
      },
      {
        dataFor: '2023-05-26 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-05-27 14:13:28',
        vitalSignScore: 74.5,
      },
      {
        dataFor: '2023-05-28 14:13:28',
        vitalSignScore: 75.2,
      },
      {
        dataFor: '2023-05-29 14:13:28',
        vitalSignScore: 74,
      },
      {
        dataFor: '2023-05-30 14:13:28',
        vitalSignScore: 74.2,
      },
      {
        dataFor: '2023-05-31 14:13:28',
        vitalSignScore: 73.1,
      },
      {
        dataFor: '2023-06-01 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-06-02 14:13:28',
        vitalSignScore: 72.8,
      },
      {
        dataFor: '2023-06-03 14:13:28',
        vitalSignScore: 73.1,
      },
      {
        dataFor: '2023-06-04 14:13:28',
        vitalSignScore: 73.7,
      },
      {
        dataFor: '2023-06-05 14:13:28',
        vitalSignScore: 73.4,
      },
      {
        dataFor: '2023-06-06 14:13:28',
        vitalSignScore: 74.3,
      },
      {
        dataFor: '2023-06-07 14:13:28',
        vitalSignScore: 74.6,
      },
      {
        dataFor: '2023-06-08 14:13:28',
        vitalSignScore: 74.4,
      },
      {
        dataFor: '2023-06-09 14:13:28',
        vitalSignScore: 74.8,
      },
      {
        dataFor: '2023-06-10 14:13:28',
        vitalSignScore: 75.8,
      },
      {
        dataFor: '2023-06-11 14:13:28',
        vitalSignScore: 77.7,
      },
      {
        dataFor: '2023-06-12 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-06-13 14:13:28',
        vitalSignScore: 74.7,
      },
      {
        dataFor: '2023-06-14 14:13:28',
        vitalSignScore: 74.7,
      },
      {
        dataFor: '2023-06-15 14:13:28',
        vitalSignScore: 74.6,
      },
      {
        dataFor: '2023-06-16 14:13:28',
        vitalSignScore: 76.2,
      },
      {
        dataFor: '2023-06-17 14:13:28',
        vitalSignScore: 75.7,
      },
      {
        dataFor: '2023-06-18 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-06-19 14:13:28',
        vitalSignScore: 76.1,
      },
      {
        dataFor: '2023-06-20 14:13:28',
        vitalSignScore: 76.5,
      },
      {
        dataFor: '2023-06-21 14:13:28',
        vitalSignScore: 75.9,
      },
      {
        dataFor: '2023-06-22 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-06-23 14:13:28',
        vitalSignScore: 76.6,
      },
      {
        dataFor: '2023-06-24 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-06-25 14:13:28',
        vitalSignScore: 77,
      },
      {
        dataFor: '2023-06-26 14:13:28',
        vitalSignScore: 76.1,
      },
      {
        dataFor: '2023-06-27 14:13:28',
        vitalSignScore: 75.1,
      },
      {
        dataFor: '2023-06-28 14:13:28',
        vitalSignScore: 74.5,
      },
      {
        dataFor: '2023-06-29 14:13:28',
        vitalSignScore: 74.7,
      },
      {
        dataFor: '2023-06-30 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-07-01 14:13:28',
        vitalSignScore: 76,
      },
      {
        dataFor: '2023-07-02 14:13:28',
        vitalSignScore: 76.3,
      },
      {
        dataFor: '2023-07-03 14:13:28',
        vitalSignScore: 76.4,
      },
      {
        dataFor: '2023-07-04 14:13:28',
        vitalSignScore: 77.7,
      },
      {
        dataFor: '2023-07-05 14:13:28',
        vitalSignScore: 79.5,
      },
      {
        dataFor: '2023-07-06 14:13:28',
        vitalSignScore: 78.9,
      },
      {
        dataFor: '2023-07-07 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-08 14:13:28',
        vitalSignScore: 77.5,
      },
      {
        dataFor: '2023-07-09 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-07-10 14:13:28',
        vitalSignScore: 77.9,
      },
      {
        dataFor: '2023-07-11 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-07-12 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-13 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-14 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-15 14:13:28',
        vitalSignScore: 77.8,
      },
      {
        dataFor: '2023-07-16 14:13:28',
        vitalSignScore: 76.7,
      },
      {
        dataFor: '2023-07-17 14:13:28',
        vitalSignScore: 76.4,
      },
      {
        dataFor: '2023-07-18 14:13:28',
        vitalSignScore: 77.1,
      },
      {
        dataFor: '2023-07-19 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-20 14:13:28',
        vitalSignScore: 77.1,
      },
      {
        dataFor: '2023-07-21 14:13:28',
        vitalSignScore: 76,
      },
      {
        dataFor: '2023-07-22 14:13:28',
        vitalSignScore: 77.8,
      },
      {
        dataFor: '2023-07-23 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-07-24 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-07-25 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-07-26 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-07-27 14:13:28',
        vitalSignScore: 76.6,
      },
      {
        dataFor: '2023-07-28 14:13:28',
        vitalSignScore: 77.2,
      },
      {
        dataFor: '2023-07-29 14:13:28',
        vitalSignScore: 76.5,
      },
      {
        dataFor: '2023-07-30 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-07-31 14:13:28',
        vitalSignScore: 77.8,
      },
      {
        dataFor: '2023-08-01 14:13:28',
        vitalSignScore: 78.2,
      },
      {
        dataFor: '2023-08-02 14:13:28',
        vitalSignScore: 78,
      },
      {
        dataFor: '2023-08-03 14:13:28',
        vitalSignScore: 77,
      },
      {
        dataFor: '2023-08-04 14:13:28',
        vitalSignScore: 76.2,
      },
      {
        dataFor: '2023-08-05 14:13:28',
        vitalSignScore: 76.3,
      },
      {
        dataFor: '2023-08-06 14:13:28',
        vitalSignScore: 77.2,
      },
      {
        dataFor: '2023-08-07 14:13:28',
        vitalSignScore: 78.6,
      },
      {
        dataFor: '2023-08-08 14:13:28',
        vitalSignScore: 80,
      },
      {
        dataFor: '2023-08-09 14:13:28',
        vitalSignScore: 80.6,
      },
      {
        dataFor: '2023-08-10 14:13:28',
        vitalSignScore: 79.6,
      },
      {
        dataFor: '2023-08-11 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-12 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-08-13 14:13:28',
        vitalSignScore: 77.7,
      },
      {
        dataFor: '2023-08-14 14:13:28',
        vitalSignScore: 77,
      },
      {
        dataFor: '2023-08-15 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-08-16 14:13:28',
        vitalSignScore: 78.9,
      },
      {
        dataFor: '2023-08-17 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-08-18 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-08-19 14:13:28',
        vitalSignScore: 77.4,
      },
      {
        dataFor: '2023-08-20 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-21 14:13:28',
        vitalSignScore: 78.2,
      },
      {
        dataFor: '2023-08-22 14:13:28',
        vitalSignScore: 77.9,
      },
      {
        dataFor: '2023-08-23 14:13:28',
        vitalSignScore: 76.9,
      },
      {
        dataFor: '2023-08-24 14:13:28',
        vitalSignScore: 77.3,
      },
      {
        dataFor: '2023-08-25 14:13:28',
        vitalSignScore: 79.5,
      },
      {
        dataFor: '2023-08-26 14:13:28',
        vitalSignScore: 80.4,
      },
      {
        dataFor: '2023-08-27 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-08-28 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-29 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-08-30 14:13:28',
        vitalSignScore: 78.1,
      },
      {
        dataFor: '2023-08-31 14:13:28',
        vitalSignScore: 78,
      },
      {
        dataFor: '2023-09-01 14:13:28',
        vitalSignScore: 78.3,
      },
      {
        dataFor: '2023-09-02 14:13:28',
        vitalSignScore: 79.2,
      },
      {
        dataFor: '2023-09-03 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-09-04 14:13:28',
        vitalSignScore: 80.4,
      },
      {
        dataFor: '2023-09-05 14:13:28',
        vitalSignScore: 80.8,
      },
      {
        dataFor: '2023-09-06 14:13:28',
        vitalSignScore: 79.8,
      },
      {
        dataFor: '2023-09-07 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-09-08 14:13:28',
        vitalSignScore: 78.9,
      },
      {
        dataFor: '2023-09-09 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-09-10 14:13:28',
        vitalSignScore: 79.1,
      },
      {
        dataFor: '2023-09-11 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-09-12 14:13:28',
        vitalSignScore: 79,
      },
      {
        dataFor: '2023-09-13 14:13:28',
        vitalSignScore: 79.3,
      },
      {
        dataFor: '2023-09-14 14:13:28',
        vitalSignScore: 78.7,
      },
      {
        dataFor: '2023-09-15 14:13:28',
        vitalSignScore: 78.4,
      },
      {
        dataFor: '2023-09-16 14:13:28',
        vitalSignScore: 77.2,
      },
      {
        dataFor: '2023-09-17 14:13:28',
        vitalSignScore: 78.6,
      },
      {
        dataFor: '2023-09-18 14:13:28',
        vitalSignScore: 78,
      },
      {
        dataFor: '2023-09-19 14:13:28',
        vitalSignScore: 78.8,
      },
      {
        dataFor: '2023-09-20 14:13:28',
        vitalSignScore: 78.5,
      },
      {
        dataFor: '2023-09-21 14:13:28',
        vitalSignScore: 79.1,
      },
      {
        dataFor: '2023-09-22 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-09-23 14:13:28',
        vitalSignScore: 80.1,
      },
      {
        dataFor: '2023-09-24 14:13:28',
        vitalSignScore: 80.8,
      },
      {
        dataFor: '2023-09-25 14:13:28',
        vitalSignScore: 79.8,
      },
      {
        dataFor: '2023-09-26 14:13:28',
        vitalSignScore: 79.9,
      },
      {
        dataFor: '2023-09-27 14:13:28',
        vitalSignScore: 80.1,
      },
      {
        dataFor: '2023-09-28 14:13:28',
        vitalSignScore: 80.1,
      },
      {
        dataFor: '2023-09-29 14:13:28',
        vitalSignScore: 81.5,
      },
      {
        dataFor: '2023-09-30 14:13:28',
        vitalSignScore: 81.7,
      },
      {
        dataFor: '2023-10-01 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-10-02 14:13:28',
        vitalSignScore: 83.8,
      },
      {
        dataFor: '2023-10-03 14:13:28',
        vitalSignScore: 83,
      },
      {
        dataFor: '2023-10-04 14:13:28',
        vitalSignScore: 81.5,
      },
      {
        dataFor: '2023-10-05 14:13:28',
        vitalSignScore: 81.4,
      },
      {
        dataFor: '2023-10-06 14:13:28',
        vitalSignScore: 82.1,
      },
      {
        dataFor: '2023-10-07 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-10-08 14:13:28',
        vitalSignScore: 81,
      },
      {
        dataFor: '2023-10-09 14:13:28',
        vitalSignScore: 80.6,
      },
      {
        dataFor: '2023-10-10 14:13:28',
        vitalSignScore: 79.7,
      },
      {
        dataFor: '2023-10-11 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-10-12 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-10-13 14:13:28',
        vitalSignScore: 82.2,
      },
      {
        dataFor: '2023-10-14 14:13:28',
        vitalSignScore: 82.3,
      },
      {
        dataFor: '2023-10-15 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-10-16 14:13:28',
        vitalSignScore: 82.1,
      },
      {
        dataFor: '2023-10-17 14:13:28',
        vitalSignScore: 81.3,
      },
      {
        dataFor: '2023-10-18 14:13:28',
        vitalSignScore: 80.5,
      },
      {
        dataFor: '2023-10-19 14:13:28',
        vitalSignScore: 80.9,
      },
      {
        dataFor: '2023-10-20 14:13:28',
        vitalSignScore: 81.8,
      },
      {
        dataFor: '2023-10-21 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-10-22 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-10-23 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-10-24 14:13:28',
        vitalSignScore: 81.2,
      },
      {
        dataFor: '2023-10-25 14:13:28',
        vitalSignScore: 81,
      },
      {
        dataFor: '2023-10-26 14:13:28',
        vitalSignScore: 82,
      },
      {
        dataFor: '2023-10-27 14:13:28',
        vitalSignScore: 81.2,
      },
      {
        dataFor: '2023-10-28 14:13:28',
        vitalSignScore: 81.6,
      },
      {
        dataFor: '2023-10-29 14:13:28',
        vitalSignScore: 81.5,
      },
      {
        dataFor: '2023-10-30 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-10-31 14:13:28',
        vitalSignScore: 82.3,
      },
      {
        dataFor: '2023-11-01 14:13:28',
        vitalSignScore: 82.1,
      },
      {
        dataFor: '2023-11-02 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-11-03 14:13:28',
        vitalSignScore: 81.2,
      },
      {
        dataFor: '2023-11-04 14:13:28',
        vitalSignScore: 81.1,
      },
      {
        dataFor: '2023-11-05 14:13:28',
        vitalSignScore: 82.3,
      },
      {
        dataFor: '2023-11-06 14:13:28',
        vitalSignScore: 81.9,
      },
      {
        dataFor: '2023-11-07 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-08 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-11-09 14:13:28',
        vitalSignScore: 83.1,
      },
      {
        dataFor: '2023-11-10 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-11 14:13:28',
        vitalSignScore: 82.4,
      },
      {
        dataFor: '2023-11-12 14:13:28',
        vitalSignScore: 83.4,
      },
      {
        dataFor: '2023-11-13 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-14 14:13:28',
        vitalSignScore: 82.5,
      },
      {
        dataFor: '2023-11-15 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-11-16 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-17 14:13:28',
        vitalSignScore: 81.9,
      },
      {
        dataFor: '2023-11-18 14:13:28',
        vitalSignScore: 82.9,
      },
      {
        dataFor: '2023-11-19 14:13:28',
        vitalSignScore: 83.9,
      },
      {
        dataFor: '2023-11-20 14:13:28',
        vitalSignScore: 84.5,
      },
      {
        dataFor: '2023-11-21 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-11-22 14:13:28',
        vitalSignScore: 82.5,
      },
      {
        dataFor: '2023-11-23 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-11-24 14:13:28',
        vitalSignScore: 83.8,
      },
      {
        dataFor: '2023-11-25 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-11-26 14:13:28',
        vitalSignScore: 84.2,
      },
      {
        dataFor: '2023-11-27 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2023-11-28 14:13:28',
        vitalSignScore: 84.5,
      },
      {
        dataFor: '2023-11-29 14:13:28',
        vitalSignScore: 83.1,
      },
      {
        dataFor: '2023-11-30 14:13:28',
        vitalSignScore: 81.7,
      },
      {
        dataFor: '2023-12-01 14:13:28',
        vitalSignScore: 82.6,
      },
      {
        dataFor: '2023-12-02 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-12-03 14:13:28',
        vitalSignScore: 83.6,
      },
      {
        dataFor: '2023-12-04 14:13:28',
        vitalSignScore: 82.8,
      },
      {
        dataFor: '2023-12-05 14:13:28',
        vitalSignScore: 83.6,
      },
      {
        dataFor: '2023-12-06 14:13:28',
        vitalSignScore: 82.7,
      },
      {
        dataFor: '2023-12-07 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-12-08 14:13:28',
        vitalSignScore: 83.7,
      },
      {
        dataFor: '2023-12-09 14:13:28',
        vitalSignScore: 85.1,
      },
      {
        dataFor: '2023-12-10 14:13:28',
        vitalSignScore: 84.9,
      },
      {
        dataFor: '2023-12-11 14:13:28',
        vitalSignScore: 84.6,
      },
      {
        dataFor: '2023-12-12 14:13:28',
        vitalSignScore: 84.9,
      },
      {
        dataFor: '2023-12-13 14:13:28',
        vitalSignScore: 83.3,
      },
      {
        dataFor: '2023-12-14 14:13:28',
        vitalSignScore: 84.6,
      },
      {
        dataFor: '2023-12-15 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2023-12-16 14:13:28',
        vitalSignScore: 86.1,
      },
      {
        dataFor: '2023-12-17 14:13:28',
        vitalSignScore: 84.6,
      },
      {
        dataFor: '2023-12-18 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2023-12-19 14:13:28',
        vitalSignScore: 85.2,
      },
      {
        dataFor: '2023-12-20 14:13:28',
        vitalSignScore: 84.1,
      },
      {
        dataFor: '2023-12-21 14:13:28',
        vitalSignScore: 85.2,
      },
      {
        dataFor: '2023-12-22 14:13:28',
        vitalSignScore: 85.3,
      },
      {
        dataFor: '2023-12-23 14:13:28',
        vitalSignScore: 87.4,
      },
      {
        dataFor: '2023-12-24 14:13:28',
        vitalSignScore: 85.6,
      },
      {
        dataFor: '2023-12-25 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2023-12-26 14:13:28',
        vitalSignScore: 86.3,
      },
      {
        dataFor: '2023-12-27 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2023-12-28 14:13:28',
        vitalSignScore: 85.6,
      },
      {
        dataFor: '2023-12-29 14:13:28',
        vitalSignScore: 86.2,
      },
      {
        dataFor: '2023-12-30 14:13:28',
        vitalSignScore: 86.3,
      },
      {
        dataFor: '2023-12-31 14:13:28',
        vitalSignScore: 88,
      },
      {
        dataFor: '2024-01-01 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-01-02 14:13:28',
        vitalSignScore: 86.1,
      },
      {
        dataFor: '2024-01-03 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-01-04 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-05 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-01-06 14:13:28',
        vitalSignScore: 86.1,
      },
      {
        dataFor: '2024-01-07 14:13:28',
        vitalSignScore: 86.9,
      },
      {
        dataFor: '2024-01-08 14:13:28',
        vitalSignScore: 87.5,
      },
      {
        dataFor: '2024-01-09 14:13:28',
        vitalSignScore: 86.5,
      },
      {
        dataFor: '2024-01-10 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2024-01-11 14:13:28',
        vitalSignScore: 85.5,
      },
      {
        dataFor: '2024-01-12 14:13:28',
        vitalSignScore: 87,
      },
      {
        dataFor: '2024-01-13 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2024-01-14 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-15 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-16 14:13:28',
        vitalSignScore: 86.4,
      },
      {
        dataFor: '2024-01-17 14:13:28',
        vitalSignScore: 86,
      },
      {
        dataFor: '2024-01-18 14:13:28',
        vitalSignScore: 84.7,
      },
      {
        dataFor: '2024-01-19 14:13:28',
        vitalSignScore: 85.5,
      },
      {
        dataFor: '2024-01-20 14:13:28',
        vitalSignScore: 86.4,
      },
      {
        dataFor: '2024-01-21 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-22 14:13:28',
        vitalSignScore: 87.6,
      },
      {
        dataFor: '2024-01-23 14:13:28',
        vitalSignScore: 87,
      },
      {
        dataFor: '2024-01-24 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-25 14:13:28',
        vitalSignScore: 85.7,
      },
      {
        dataFor: '2024-01-26 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-01-27 14:13:28',
        vitalSignScore: 87.1,
      },
      {
        dataFor: '2024-01-28 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-29 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-30 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-01-31 14:13:28',
        vitalSignScore: 86.9,
      },
      {
        dataFor: '2024-02-01 14:13:28',
        vitalSignScore: 86.5,
      },
      {
        dataFor: '2024-02-02 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-02-03 14:13:28',
        vitalSignScore: 86.7,
      },
      {
        dataFor: '2024-02-04 14:13:28',
        vitalSignScore: 86.6,
      },
      {
        dataFor: '2024-02-05 14:13:28',
        vitalSignScore: 86.2,
      },
      {
        dataFor: '2024-02-06 14:13:28',
        vitalSignScore: 86.8,
      },
      {
        dataFor: '2024-02-07 14:13:28',
        vitalSignScore: 89.1,
      },
      {
        dataFor: '2024-02-08 14:13:28',
        vitalSignScore: 88.9,
      },
      {
        dataFor: '2024-02-09 14:13:28',
        vitalSignScore: 89.7,
      },
      {
        dataFor: '2024-02-10 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-02-11 14:13:28',
        vitalSignScore: 88.5,
      },
      {
        dataFor: '2024-02-12 14:13:28',
        vitalSignScore: 88.4,
      },
      {
        dataFor: '2024-02-13 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-02-14 14:13:28',
        vitalSignScore: 86.3,
      },
      {
        dataFor: '2024-02-15 14:13:28',
        vitalSignScore: 86.2,
      },
      {
        dataFor: '2024-02-16 14:13:28',
        vitalSignScore: 85.9,
      },
      {
        dataFor: '2024-02-17 14:13:28',
        vitalSignScore: 88,
      },
      {
        dataFor: '2024-02-18 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-02-19 14:13:28',
        vitalSignScore: 89.5,
      },
      {
        dataFor: '2024-02-20 14:13:28',
        vitalSignScore: 90,
      },
      {
        dataFor: '2024-02-21 14:13:28',
        vitalSignScore: 90.2,
      },
      {
        dataFor: '2024-02-22 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-02-23 14:13:28',
        vitalSignScore: 87.2,
      },
      {
        dataFor: '2024-02-24 14:13:28',
        vitalSignScore: 87.3,
      },
      {
        dataFor: '2024-02-25 14:13:28',
        vitalSignScore: 87.6,
      },
      {
        dataFor: '2024-02-26 14:13:28',
        vitalSignScore: 88.2,
      },
      {
        dataFor: '2024-02-27 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-02-28 14:13:28',
        vitalSignScore: 89.3,
      },
      {
        dataFor: '2024-02-29 14:13:28',
        vitalSignScore: 89.8,
      },
      {
        dataFor: '2024-03-01 14:13:28',
        vitalSignScore: 89.3,
      },
      {
        dataFor: '2024-03-02 14:13:28',
        vitalSignScore: 88,
      },
      {
        dataFor: '2024-03-03 14:13:28',
        vitalSignScore: 88.7,
      },
      {
        dataFor: '2024-03-04 14:13:28',
        vitalSignScore: 88.8,
      },
      {
        dataFor: '2024-03-05 14:13:28',
        vitalSignScore: 89.4,
      },
      {
        dataFor: '2024-03-06 14:13:28',
        vitalSignScore: 88.2,
      },
      {
        dataFor: '2024-03-07 14:13:28',
        vitalSignScore: 87.5,
      },
      {
        dataFor: '2024-03-08 14:13:28',
        vitalSignScore: 87.7,
      },
      {
        dataFor: '2024-03-09 14:13:28',
        vitalSignScore: 87.7,
      },
      {
        dataFor: '2024-03-10 14:13:28',
        vitalSignScore: 89.4,
      },
      {
        dataFor: '2024-03-11 14:13:28',
        vitalSignScore: 90.3,
      },
      {
        dataFor: '2024-03-12 14:13:28',
        vitalSignScore: 90.7,
      },
      {
        dataFor: '2024-03-13 14:13:28',
        vitalSignScore: 90.9,
      },
      {
        dataFor: '2024-03-14 14:13:28',
        vitalSignScore: 90.7,
      },
      {
        dataFor: '2024-03-15 14:13:28',
        vitalSignScore: 90.8,
      },
    ],
  },
];

export const dummyValuesGraph: ValuesGraphItem[] = [
  {
    valueUuid: '38ee8eb4-0b56-43e0-9284-c64d2bc3d99a',
    graphData: [
      {
        dataFor: '2023-03-17 14:13:28',
        cultureValueScore: 73.5,
      },
      {
        dataFor: '2023-03-18 14:13:28',
        cultureValueScore: 72.2,
      },
      {
        dataFor: '2023-03-19 14:13:28',
        cultureValueScore: 72.2,
      },
      {
        dataFor: '2023-03-20 14:13:28',
        cultureValueScore: 72.5,
      },
      {
        dataFor: '2023-03-21 14:13:28',
        cultureValueScore: 73.6,
      },
      {
        dataFor: '2023-03-22 14:13:28',
        cultureValueScore: 72.3,
      },
      {
        dataFor: '2023-03-23 14:13:28',
        cultureValueScore: 71.5,
      },
      {
        dataFor: '2023-03-24 14:13:28',
        cultureValueScore: 70.2,
      },
      {
        dataFor: '2023-03-25 14:13:28',
        cultureValueScore: 70.8,
      },
      {
        dataFor: '2023-03-26 14:13:28',
        cultureValueScore: 70.5,
      },
      {
        dataFor: '2023-03-27 14:13:28',
        cultureValueScore: 70.8,
      },
      {
        dataFor: '2023-03-28 14:13:28',
        cultureValueScore: 71.9,
      },
      {
        dataFor: '2023-03-29 14:13:28',
        cultureValueScore: 72.2,
      },
      {
        dataFor: '2023-03-30 14:13:28',
        cultureValueScore: 72.2,
      },
      {
        dataFor: '2023-03-31 14:13:28',
        cultureValueScore: 71.6,
      },
      {
        dataFor: '2023-04-01 14:13:28',
        cultureValueScore: 71.4,
      },
      {
        dataFor: '2023-04-02 14:13:28',
        cultureValueScore: 72.3,
      },
      {
        dataFor: '2023-04-03 14:13:28',
        cultureValueScore: 72,
      },
      {
        dataFor: '2023-04-04 14:13:28',
        cultureValueScore: 72,
      },
      {
        dataFor: '2023-04-05 14:13:28',
        cultureValueScore: 70.5,
      },
      {
        dataFor: '2023-04-06 14:13:28',
        cultureValueScore: 69,
      },
      {
        dataFor: '2023-04-07 14:13:28',
        cultureValueScore: 69.3,
      },
      {
        dataFor: '2023-04-08 14:13:28',
        cultureValueScore: 70.5,
      },
      {
        dataFor: '2023-04-09 14:13:28',
        cultureValueScore: 71.7,
      },
      {
        dataFor: '2023-04-10 14:13:28',
        cultureValueScore: 72.9,
      },
      {
        dataFor: '2023-04-11 14:13:28',
        cultureValueScore: 71.4,
      },
      {
        dataFor: '2023-04-12 14:13:28',
        cultureValueScore: 71.9,
      },
      {
        dataFor: '2023-04-13 14:13:28',
        cultureValueScore: 70.4,
      },
      {
        dataFor: '2023-04-14 14:13:28',
        cultureValueScore: 72.4,
      },
      {
        dataFor: '2023-04-15 14:13:28',
        cultureValueScore: 73.4,
      },
      {
        dataFor: '2023-04-16 14:13:28',
        cultureValueScore: 73.7,
      },
      {
        dataFor: '2023-04-17 14:13:28',
        cultureValueScore: 73,
      },
      {
        dataFor: '2023-04-18 14:13:28',
        cultureValueScore: 71.5,
      },
      {
        dataFor: '2023-04-19 14:13:28',
        cultureValueScore: 70.1,
      },
      {
        dataFor: '2023-04-20 14:13:28',
        cultureValueScore: 69.7,
      },
      {
        dataFor: '2023-04-21 14:13:28',
        cultureValueScore: 70.4,
      },
      {
        dataFor: '2023-04-22 14:13:28',
        cultureValueScore: 72.6,
      },
      {
        dataFor: '2023-04-23 14:13:28',
        cultureValueScore: 73.7,
      },
      {
        dataFor: '2023-04-24 14:13:28',
        cultureValueScore: 73.4,
      },
      {
        dataFor: '2023-04-25 14:13:28',
        cultureValueScore: 72.4,
      },
      {
        dataFor: '2023-04-26 14:13:28',
        cultureValueScore: 71,
      },
      {
        dataFor: '2023-04-27 14:13:28',
        cultureValueScore: 70.4,
      },
      {
        dataFor: '2023-04-28 14:13:28',
        cultureValueScore: 69.5,
      },
      {
        dataFor: '2023-04-29 14:13:28',
        cultureValueScore: 71.5,
      },
      {
        dataFor: '2023-04-30 14:13:28',
        cultureValueScore: 72.2,
      },
      {
        dataFor: '2023-05-01 14:13:28',
        cultureValueScore: 73.1,
      },
      {
        dataFor: '2023-05-02 14:13:28',
        cultureValueScore: 71,
      },
      {
        dataFor: '2023-05-03 14:13:28',
        cultureValueScore: 71.9,
      },
      {
        dataFor: '2023-05-04 14:13:28',
        cultureValueScore: 71.2,
      },
      {
        dataFor: '2023-05-05 14:13:28',
        cultureValueScore: 71.9,
      },
      {
        dataFor: '2023-05-06 14:13:28',
        cultureValueScore: 70.9,
      },
      {
        dataFor: '2023-05-07 14:13:28',
        cultureValueScore: 72.3,
      },
      {
        dataFor: '2023-05-08 14:13:28',
        cultureValueScore: 72.1,
      },
      {
        dataFor: '2023-05-09 14:13:28',
        cultureValueScore: 72,
      },
      {
        dataFor: '2023-05-10 14:13:28',
        cultureValueScore: 71.8,
      },
      {
        dataFor: '2023-05-11 14:13:28',
        cultureValueScore: 72.4,
      },
      {
        dataFor: '2023-05-12 14:13:28',
        cultureValueScore: 73.3,
      },
      {
        dataFor: '2023-05-13 14:13:28',
        cultureValueScore: 73.6,
      },
      {
        dataFor: '2023-05-14 14:13:28',
        cultureValueScore: 73,
      },
      {
        dataFor: '2023-05-15 14:13:28',
        cultureValueScore: 72.7,
      },
      {
        dataFor: '2023-05-16 14:13:28',
        cultureValueScore: 72.1,
      },
      {
        dataFor: '2023-05-17 14:13:28',
        cultureValueScore: 72.4,
      },
      {
        dataFor: '2023-05-18 14:13:28',
        cultureValueScore: 72.1,
      },
      {
        dataFor: '2023-05-19 14:13:28',
        cultureValueScore: 71.5,
      },
      {
        dataFor: '2023-05-20 14:13:28',
        cultureValueScore: 71.9,
      },
      {
        dataFor: '2023-05-21 14:13:28',
        cultureValueScore: 72.2,
      },
      {
        dataFor: '2023-05-22 14:13:28',
        cultureValueScore: 72.3,
      },
      {
        dataFor: '2023-05-23 14:13:28',
        cultureValueScore: 72.6,
      },
      {
        dataFor: '2023-05-24 14:13:28',
        cultureValueScore: 72.3,
      },
      {
        dataFor: '2023-05-25 14:13:28',
        cultureValueScore: 73.5,
      },
      {
        dataFor: '2023-05-26 14:13:28',
        cultureValueScore: 73.7,
      },
      {
        dataFor: '2023-05-27 14:13:28',
        cultureValueScore: 74.5,
      },
      {
        dataFor: '2023-05-28 14:13:28',
        cultureValueScore: 75.2,
      },
      {
        dataFor: '2023-05-29 14:13:28',
        cultureValueScore: 74,
      },
      {
        dataFor: '2023-05-30 14:13:28',
        cultureValueScore: 74.2,
      },
      {
        dataFor: '2023-05-31 14:13:28',
        cultureValueScore: 73.1,
      },
      {
        dataFor: '2023-06-01 14:13:28',
        cultureValueScore: 73.4,
      },
      {
        dataFor: '2023-06-02 14:13:28',
        cultureValueScore: 72.8,
      },
      {
        dataFor: '2023-06-03 14:13:28',
        cultureValueScore: 73.1,
      },
      {
        dataFor: '2023-06-04 14:13:28',
        cultureValueScore: 73.7,
      },
      {
        dataFor: '2023-06-05 14:13:28',
        cultureValueScore: 73.4,
      },
      {
        dataFor: '2023-06-06 14:13:28',
        cultureValueScore: 74.3,
      },
      {
        dataFor: '2023-06-07 14:13:28',
        cultureValueScore: 74.6,
      },
      {
        dataFor: '2023-06-08 14:13:28',
        cultureValueScore: 74.4,
      },
      {
        dataFor: '2023-06-09 14:13:28',
        cultureValueScore: 74.8,
      },
      {
        dataFor: '2023-06-10 14:13:28',
        cultureValueScore: 75.8,
      },
      {
        dataFor: '2023-06-11 14:13:28',
        cultureValueScore: 77.7,
      },
      {
        dataFor: '2023-06-12 14:13:28',
        cultureValueScore: 76.7,
      },
      {
        dataFor: '2023-06-13 14:13:28',
        cultureValueScore: 74.7,
      },
      {
        dataFor: '2023-06-14 14:13:28',
        cultureValueScore: 74.7,
      },
      {
        dataFor: '2023-06-15 14:13:28',
        cultureValueScore: 74.6,
      },
      {
        dataFor: '2023-06-16 14:13:28',
        cultureValueScore: 76.2,
      },
      {
        dataFor: '2023-06-17 14:13:28',
        cultureValueScore: 75.7,
      },
      {
        dataFor: '2023-06-18 14:13:28',
        cultureValueScore: 76.7,
      },
      {
        dataFor: '2023-06-19 14:13:28',
        cultureValueScore: 76.1,
      },
      {
        dataFor: '2023-06-20 14:13:28',
        cultureValueScore: 76.5,
      },
      {
        dataFor: '2023-06-21 14:13:28',
        cultureValueScore: 75.9,
      },
      {
        dataFor: '2023-06-22 14:13:28',
        cultureValueScore: 76.9,
      },
      {
        dataFor: '2023-06-23 14:13:28',
        cultureValueScore: 76.6,
      },
      {
        dataFor: '2023-06-24 14:13:28',
        cultureValueScore: 76.9,
      },
      {
        dataFor: '2023-06-25 14:13:28',
        cultureValueScore: 77,
      },
      {
        dataFor: '2023-06-26 14:13:28',
        cultureValueScore: 76.1,
      },
      {
        dataFor: '2023-06-27 14:13:28',
        cultureValueScore: 75.1,
      },
      {
        dataFor: '2023-06-28 14:13:28',
        cultureValueScore: 74.5,
      },
      {
        dataFor: '2023-06-29 14:13:28',
        cultureValueScore: 74.7,
      },
      {
        dataFor: '2023-06-30 14:13:28',
        cultureValueScore: 76.9,
      },
      {
        dataFor: '2023-07-01 14:13:28',
        cultureValueScore: 76,
      },
      {
        dataFor: '2023-07-02 14:13:28',
        cultureValueScore: 76.3,
      },
      {
        dataFor: '2023-07-03 14:13:28',
        cultureValueScore: 76.4,
      },
      {
        dataFor: '2023-07-04 14:13:28',
        cultureValueScore: 77.7,
      },
      {
        dataFor: '2023-07-05 14:13:28',
        cultureValueScore: 79.5,
      },
      {
        dataFor: '2023-07-06 14:13:28',
        cultureValueScore: 78.9,
      },
      {
        dataFor: '2023-07-07 14:13:28',
        cultureValueScore: 77.4,
      },
      {
        dataFor: '2023-07-08 14:13:28',
        cultureValueScore: 77.5,
      },
      {
        dataFor: '2023-07-09 14:13:28',
        cultureValueScore: 76.7,
      },
      {
        dataFor: '2023-07-10 14:13:28',
        cultureValueScore: 77.9,
      },
      {
        dataFor: '2023-07-11 14:13:28',
        cultureValueScore: 77.3,
      },
      {
        dataFor: '2023-07-12 14:13:28',
        cultureValueScore: 77.4,
      },
      {
        dataFor: '2023-07-13 14:13:28',
        cultureValueScore: 77.4,
      },
      {
        dataFor: '2023-07-14 14:13:28',
        cultureValueScore: 77.4,
      },
      {
        dataFor: '2023-07-15 14:13:28',
        cultureValueScore: 77.8,
      },
      {
        dataFor: '2023-07-16 14:13:28',
        cultureValueScore: 76.7,
      },
      {
        dataFor: '2023-07-17 14:13:28',
        cultureValueScore: 76.4,
      },
      {
        dataFor: '2023-07-18 14:13:28',
        cultureValueScore: 77.1,
      },
      {
        dataFor: '2023-07-19 14:13:28',
        cultureValueScore: 77.4,
      },
      {
        dataFor: '2023-07-20 14:13:28',
        cultureValueScore: 77.1,
      },
      {
        dataFor: '2023-07-21 14:13:28',
        cultureValueScore: 76,
      },
      {
        dataFor: '2023-07-22 14:13:28',
        cultureValueScore: 77.8,
      },
      {
        dataFor: '2023-07-23 14:13:28',
        cultureValueScore: 78.4,
      },
      {
        dataFor: '2023-07-24 14:13:28',
        cultureValueScore: 79,
      },
      {
        dataFor: '2023-07-25 14:13:28',
        cultureValueScore: 77.3,
      },
      {
        dataFor: '2023-07-26 14:13:28',
        cultureValueScore: 77.3,
      },
      {
        dataFor: '2023-07-27 14:13:28',
        cultureValueScore: 76.6,
      },
      {
        dataFor: '2023-07-28 14:13:28',
        cultureValueScore: 77.2,
      },
      {
        dataFor: '2023-07-29 14:13:28',
        cultureValueScore: 76.5,
      },
      {
        dataFor: '2023-07-30 14:13:28',
        cultureValueScore: 77.4,
      },
      {
        dataFor: '2023-07-31 14:13:28',
        cultureValueScore: 77.8,
      },
      {
        dataFor: '2023-08-01 14:13:28',
        cultureValueScore: 78.2,
      },
      {
        dataFor: '2023-08-02 14:13:28',
        cultureValueScore: 78,
      },
      {
        dataFor: '2023-08-03 14:13:28',
        cultureValueScore: 77,
      },
      {
        dataFor: '2023-08-04 14:13:28',
        cultureValueScore: 76.2,
      },
      {
        dataFor: '2023-08-05 14:13:28',
        cultureValueScore: 76.3,
      },
      {
        dataFor: '2023-08-06 14:13:28',
        cultureValueScore: 77.2,
      },
      {
        dataFor: '2023-08-07 14:13:28',
        cultureValueScore: 78.6,
      },
      {
        dataFor: '2023-08-08 14:13:28',
        cultureValueScore: 80,
      },
      {
        dataFor: '2023-08-09 14:13:28',
        cultureValueScore: 80.6,
      },
      {
        dataFor: '2023-08-10 14:13:28',
        cultureValueScore: 79.6,
      },
      {
        dataFor: '2023-08-11 14:13:28',
        cultureValueScore: 78.8,
      },
      {
        dataFor: '2023-08-12 14:13:28',
        cultureValueScore: 77.3,
      },
      {
        dataFor: '2023-08-13 14:13:28',
        cultureValueScore: 77.7,
      },
      {
        dataFor: '2023-08-14 14:13:28',
        cultureValueScore: 77,
      },
      {
        dataFor: '2023-08-15 14:13:28',
        cultureValueScore: 79,
      },
      {
        dataFor: '2023-08-16 14:13:28',
        cultureValueScore: 78.9,
      },
      {
        dataFor: '2023-08-17 14:13:28',
        cultureValueScore: 78.4,
      },
      {
        dataFor: '2023-08-18 14:13:28',
        cultureValueScore: 77.3,
      },
      {
        dataFor: '2023-08-19 14:13:28',
        cultureValueScore: 77.4,
      },
      {
        dataFor: '2023-08-20 14:13:28',
        cultureValueScore: 78.8,
      },
      {
        dataFor: '2023-08-21 14:13:28',
        cultureValueScore: 78.2,
      },
      {
        dataFor: '2023-08-22 14:13:28',
        cultureValueScore: 77.9,
      },
      {
        dataFor: '2023-08-23 14:13:28',
        cultureValueScore: 76.9,
      },
      {
        dataFor: '2023-08-24 14:13:28',
        cultureValueScore: 77.3,
      },
      {
        dataFor: '2023-08-25 14:13:28',
        cultureValueScore: 79.5,
      },
      {
        dataFor: '2023-08-26 14:13:28',
        cultureValueScore: 80.4,
      },
      {
        dataFor: '2023-08-27 14:13:28',
        cultureValueScore: 80.9,
      },
      {
        dataFor: '2023-08-28 14:13:28',
        cultureValueScore: 78.8,
      },
      {
        dataFor: '2023-08-29 14:13:28',
        cultureValueScore: 78.8,
      },
      {
        dataFor: '2023-08-30 14:13:28',
        cultureValueScore: 78.1,
      },
      {
        dataFor: '2023-08-31 14:13:28',
        cultureValueScore: 78,
      },
      {
        dataFor: '2023-09-01 14:13:28',
        cultureValueScore: 78.3,
      },
      {
        dataFor: '2023-09-02 14:13:28',
        cultureValueScore: 79.2,
      },
      {
        dataFor: '2023-09-03 14:13:28',
        cultureValueScore: 80.9,
      },
      {
        dataFor: '2023-09-04 14:13:28',
        cultureValueScore: 80.4,
      },
      {
        dataFor: '2023-09-05 14:13:28',
        cultureValueScore: 80.8,
      },
      {
        dataFor: '2023-09-06 14:13:28',
        cultureValueScore: 79.8,
      },
      {
        dataFor: '2023-09-07 14:13:28',
        cultureValueScore: 79,
      },
      {
        dataFor: '2023-09-08 14:13:28',
        cultureValueScore: 78.9,
      },
      {
        dataFor: '2023-09-09 14:13:28',
        cultureValueScore: 78.8,
      },
      {
        dataFor: '2023-09-10 14:13:28',
        cultureValueScore: 79.1,
      },
      {
        dataFor: '2023-09-11 14:13:28',
        cultureValueScore: 78.4,
      },
      {
        dataFor: '2023-09-12 14:13:28',
        cultureValueScore: 79,
      },
      {
        dataFor: '2023-09-13 14:13:28',
        cultureValueScore: 79.3,
      },
      {
        dataFor: '2023-09-14 14:13:28',
        cultureValueScore: 78.7,
      },
      {
        dataFor: '2023-09-15 14:13:28',
        cultureValueScore: 78.4,
      },
      {
        dataFor: '2023-09-16 14:13:28',
        cultureValueScore: 77.2,
      },
      {
        dataFor: '2023-09-17 14:13:28',
        cultureValueScore: 78.6,
      },
      {
        dataFor: '2023-09-18 14:13:28',
        cultureValueScore: 78,
      },
      {
        dataFor: '2023-09-19 14:13:28',
        cultureValueScore: 78.8,
      },
      {
        dataFor: '2023-09-20 14:13:28',
        cultureValueScore: 78.5,
      },
      {
        dataFor: '2023-09-21 14:13:28',
        cultureValueScore: 79.1,
      },
      {
        dataFor: '2023-09-22 14:13:28',
        cultureValueScore: 80.9,
      },
      {
        dataFor: '2023-09-23 14:13:28',
        cultureValueScore: 80.1,
      },
      {
        dataFor: '2023-09-24 14:13:28',
        cultureValueScore: 80.8,
      },
      {
        dataFor: '2023-09-25 14:13:28',
        cultureValueScore: 79.8,
      },
      {
        dataFor: '2023-09-26 14:13:28',
        cultureValueScore: 79.9,
      },
      {
        dataFor: '2023-09-27 14:13:28',
        cultureValueScore: 80.1,
      },
      {
        dataFor: '2023-09-28 14:13:28',
        cultureValueScore: 80.1,
      },
      {
        dataFor: '2023-09-29 14:13:28',
        cultureValueScore: 81.5,
      },
      {
        dataFor: '2023-09-30 14:13:28',
        cultureValueScore: 81.7,
      },
      {
        dataFor: '2023-10-01 14:13:28',
        cultureValueScore: 83.3,
      },
      {
        dataFor: '2023-10-02 14:13:28',
        cultureValueScore: 83.8,
      },
      {
        dataFor: '2023-10-03 14:13:28',
        cultureValueScore: 83,
      },
      {
        dataFor: '2023-10-04 14:13:28',
        cultureValueScore: 81.5,
      },
      {
        dataFor: '2023-10-05 14:13:28',
        cultureValueScore: 81.4,
      },
      {
        dataFor: '2023-10-06 14:13:28',
        cultureValueScore: 82.1,
      },
      {
        dataFor: '2023-10-07 14:13:28',
        cultureValueScore: 82.8,
      },
      {
        dataFor: '2023-10-08 14:13:28',
        cultureValueScore: 81,
      },
      {
        dataFor: '2023-10-09 14:13:28',
        cultureValueScore: 80.6,
      },
      {
        dataFor: '2023-10-10 14:13:28',
        cultureValueScore: 79.7,
      },
      {
        dataFor: '2023-10-11 14:13:28',
        cultureValueScore: 81.1,
      },
      {
        dataFor: '2023-10-12 14:13:28',
        cultureValueScore: 81.1,
      },
      {
        dataFor: '2023-10-13 14:13:28',
        cultureValueScore: 82.2,
      },
      {
        dataFor: '2023-10-14 14:13:28',
        cultureValueScore: 82.3,
      },
      {
        dataFor: '2023-10-15 14:13:28',
        cultureValueScore: 82.9,
      },
      {
        dataFor: '2023-10-16 14:13:28',
        cultureValueScore: 82.1,
      },
      {
        dataFor: '2023-10-17 14:13:28',
        cultureValueScore: 81.3,
      },
      {
        dataFor: '2023-10-18 14:13:28',
        cultureValueScore: 80.5,
      },
      {
        dataFor: '2023-10-19 14:13:28',
        cultureValueScore: 80.9,
      },
      {
        dataFor: '2023-10-20 14:13:28',
        cultureValueScore: 81.8,
      },
      {
        dataFor: '2023-10-21 14:13:28',
        cultureValueScore: 83.7,
      },
      {
        dataFor: '2023-10-22 14:13:28',
        cultureValueScore: 83.7,
      },
      {
        dataFor: '2023-10-23 14:13:28',
        cultureValueScore: 82.9,
      },
      {
        dataFor: '2023-10-24 14:13:28',
        cultureValueScore: 81.2,
      },
      {
        dataFor: '2023-10-25 14:13:28',
        cultureValueScore: 81,
      },
      {
        dataFor: '2023-10-26 14:13:28',
        cultureValueScore: 82,
      },
      {
        dataFor: '2023-10-27 14:13:28',
        cultureValueScore: 81.2,
      },
      {
        dataFor: '2023-10-28 14:13:28',
        cultureValueScore: 81.6,
      },
      {
        dataFor: '2023-10-29 14:13:28',
        cultureValueScore: 81.5,
      },
      {
        dataFor: '2023-10-30 14:13:28',
        cultureValueScore: 82.7,
      },
      {
        dataFor: '2023-10-31 14:13:28',
        cultureValueScore: 82.3,
      },
      {
        dataFor: '2023-11-01 14:13:28',
        cultureValueScore: 82.1,
      },
      {
        dataFor: '2023-11-02 14:13:28',
        cultureValueScore: 81.1,
      },
      {
        dataFor: '2023-11-03 14:13:28',
        cultureValueScore: 81.2,
      },
      {
        dataFor: '2023-11-04 14:13:28',
        cultureValueScore: 81.1,
      },
      {
        dataFor: '2023-11-05 14:13:28',
        cultureValueScore: 82.3,
      },
      {
        dataFor: '2023-11-06 14:13:28',
        cultureValueScore: 81.9,
      },
      {
        dataFor: '2023-11-07 14:13:28',
        cultureValueScore: 82.9,
      },
      {
        dataFor: '2023-11-08 14:13:28',
        cultureValueScore: 83.7,
      },
      {
        dataFor: '2023-11-09 14:13:28',
        cultureValueScore: 83.1,
      },
      {
        dataFor: '2023-11-10 14:13:28',
        cultureValueScore: 82.9,
      },
      {
        dataFor: '2023-11-11 14:13:28',
        cultureValueScore: 82.4,
      },
      {
        dataFor: '2023-11-12 14:13:28',
        cultureValueScore: 83.4,
      },
      {
        dataFor: '2023-11-13 14:13:28',
        cultureValueScore: 82.9,
      },
      {
        dataFor: '2023-11-14 14:13:28',
        cultureValueScore: 82.5,
      },
      {
        dataFor: '2023-11-15 14:13:28',
        cultureValueScore: 82.7,
      },
      {
        dataFor: '2023-11-16 14:13:28',
        cultureValueScore: 82.9,
      },
      {
        dataFor: '2023-11-17 14:13:28',
        cultureValueScore: 81.9,
      },
      {
        dataFor: '2023-11-18 14:13:28',
        cultureValueScore: 82.9,
      },
      {
        dataFor: '2023-11-19 14:13:28',
        cultureValueScore: 83.9,
      },
      {
        dataFor: '2023-11-20 14:13:28',
        cultureValueScore: 84.5,
      },
      {
        dataFor: '2023-11-21 14:13:28',
        cultureValueScore: 82.8,
      },
      {
        dataFor: '2023-11-22 14:13:28',
        cultureValueScore: 82.5,
      },
      {
        dataFor: '2023-11-23 14:13:28',
        cultureValueScore: 82.7,
      },
      {
        dataFor: '2023-11-24 14:13:28',
        cultureValueScore: 83.8,
      },
      {
        dataFor: '2023-11-25 14:13:28',
        cultureValueScore: 83.3,
      },
      {
        dataFor: '2023-11-26 14:13:28',
        cultureValueScore: 84.2,
      },
      {
        dataFor: '2023-11-27 14:13:28',
        cultureValueScore: 84.7,
      },
      {
        dataFor: '2023-11-28 14:13:28',
        cultureValueScore: 84.5,
      },
      {
        dataFor: '2023-11-29 14:13:28',
        cultureValueScore: 83.1,
      },
      {
        dataFor: '2023-11-30 14:13:28',
        cultureValueScore: 81.7,
      },
      {
        dataFor: '2023-12-01 14:13:28',
        cultureValueScore: 82.6,
      },
      {
        dataFor: '2023-12-02 14:13:28',
        cultureValueScore: 82.8,
      },
      {
        dataFor: '2023-12-03 14:13:28',
        cultureValueScore: 83.6,
      },
      {
        dataFor: '2023-12-04 14:13:28',
        cultureValueScore: 82.8,
      },
      {
        dataFor: '2023-12-05 14:13:28',
        cultureValueScore: 83.6,
      },
      {
        dataFor: '2023-12-06 14:13:28',
        cultureValueScore: 82.7,
      },
      {
        dataFor: '2023-12-07 14:13:28',
        cultureValueScore: 83.3,
      },
      {
        dataFor: '2023-12-08 14:13:28',
        cultureValueScore: 83.7,
      },
      {
        dataFor: '2023-12-09 14:13:28',
        cultureValueScore: 85.1,
      },
      {
        dataFor: '2023-12-10 14:13:28',
        cultureValueScore: 84.9,
      },
      {
        dataFor: '2023-12-11 14:13:28',
        cultureValueScore: 84.6,
      },
      {
        dataFor: '2023-12-12 14:13:28',
        cultureValueScore: 84.9,
      },
      {
        dataFor: '2023-12-13 14:13:28',
        cultureValueScore: 83.3,
      },
      {
        dataFor: '2023-12-14 14:13:28',
        cultureValueScore: 84.6,
      },
      {
        dataFor: '2023-12-15 14:13:28',
        cultureValueScore: 84.7,
      },
      {
        dataFor: '2023-12-16 14:13:28',
        cultureValueScore: 86.1,
      },
      {
        dataFor: '2023-12-17 14:13:28',
        cultureValueScore: 84.6,
      },
      {
        dataFor: '2023-12-18 14:13:28',
        cultureValueScore: 84.7,
      },
      {
        dataFor: '2023-12-19 14:13:28',
        cultureValueScore: 85.2,
      },
      {
        dataFor: '2023-12-20 14:13:28',
        cultureValueScore: 84.1,
      },
      {
        dataFor: '2023-12-21 14:13:28',
        cultureValueScore: 85.2,
      },
      {
        dataFor: '2023-12-22 14:13:28',
        cultureValueScore: 85.3,
      },
      {
        dataFor: '2023-12-23 14:13:28',
        cultureValueScore: 87.4,
      },
      {
        dataFor: '2023-12-24 14:13:28',
        cultureValueScore: 85.6,
      },
      {
        dataFor: '2023-12-25 14:13:28',
        cultureValueScore: 86.7,
      },
      {
        dataFor: '2023-12-26 14:13:28',
        cultureValueScore: 86.3,
      },
      {
        dataFor: '2023-12-27 14:13:28',
        cultureValueScore: 87.2,
      },
      {
        dataFor: '2023-12-28 14:13:28',
        cultureValueScore: 85.6,
      },
      {
        dataFor: '2023-12-29 14:13:28',
        cultureValueScore: 86.2,
      },
      {
        dataFor: '2023-12-30 14:13:28',
        cultureValueScore: 86.3,
      },
      {
        dataFor: '2023-12-31 14:13:28',
        cultureValueScore: 88,
      },
      {
        dataFor: '2024-01-01 14:13:28',
        cultureValueScore: 86.8,
      },
      {
        dataFor: '2024-01-02 14:13:28',
        cultureValueScore: 86.1,
      },
      {
        dataFor: '2024-01-03 14:13:28',
        cultureValueScore: 86.8,
      },
      {
        dataFor: '2024-01-04 14:13:28',
        cultureValueScore: 86.6,
      },
      {
        dataFor: '2024-01-05 14:13:28',
        cultureValueScore: 86.8,
      },
      {
        dataFor: '2024-01-06 14:13:28',
        cultureValueScore: 86.1,
      },
      {
        dataFor: '2024-01-07 14:13:28',
        cultureValueScore: 86.9,
      },
      {
        dataFor: '2024-01-08 14:13:28',
        cultureValueScore: 87.5,
      },
      {
        dataFor: '2024-01-09 14:13:28',
        cultureValueScore: 86.5,
      },
      {
        dataFor: '2024-01-10 14:13:28',
        cultureValueScore: 86.7,
      },
      {
        dataFor: '2024-01-11 14:13:28',
        cultureValueScore: 85.5,
      },
      {
        dataFor: '2024-01-12 14:13:28',
        cultureValueScore: 87,
      },
      {
        dataFor: '2024-01-13 14:13:28',
        cultureValueScore: 86.7,
      },
      {
        dataFor: '2024-01-14 14:13:28',
        cultureValueScore: 87.2,
      },
      {
        dataFor: '2024-01-15 14:13:28',
        cultureValueScore: 86.6,
      },
      {
        dataFor: '2024-01-16 14:13:28',
        cultureValueScore: 86.4,
      },
      {
        dataFor: '2024-01-17 14:13:28',
        cultureValueScore: 86,
      },
      {
        dataFor: '2024-01-18 14:13:28',
        cultureValueScore: 84.7,
      },
      {
        dataFor: '2024-01-19 14:13:28',
        cultureValueScore: 85.5,
      },
      {
        dataFor: '2024-01-20 14:13:28',
        cultureValueScore: 86.4,
      },
      {
        dataFor: '2024-01-21 14:13:28',
        cultureValueScore: 87.2,
      },
      {
        dataFor: '2024-01-22 14:13:28',
        cultureValueScore: 87.6,
      },
      {
        dataFor: '2024-01-23 14:13:28',
        cultureValueScore: 87,
      },
      {
        dataFor: '2024-01-24 14:13:28',
        cultureValueScore: 86.6,
      },
      {
        dataFor: '2024-01-25 14:13:28',
        cultureValueScore: 85.7,
      },
      {
        dataFor: '2024-01-26 14:13:28',
        cultureValueScore: 86.6,
      },
      {
        dataFor: '2024-01-27 14:13:28',
        cultureValueScore: 87.1,
      },
      {
        dataFor: '2024-01-28 14:13:28',
        cultureValueScore: 87.2,
      },
      {
        dataFor: '2024-01-29 14:13:28',
        cultureValueScore: 87.2,
      },
      {
        dataFor: '2024-01-30 14:13:28',
        cultureValueScore: 87.2,
      },
      {
        dataFor: '2024-01-31 14:13:28',
        cultureValueScore: 86.9,
      },
      {
        dataFor: '2024-02-01 14:13:28',
        cultureValueScore: 86.5,
      },
      {
        dataFor: '2024-02-02 14:13:28',
        cultureValueScore: 86.8,
      },
      {
        dataFor: '2024-02-03 14:13:28',
        cultureValueScore: 86.7,
      },
      {
        dataFor: '2024-02-04 14:13:28',
        cultureValueScore: 86.6,
      },
      {
        dataFor: '2024-02-05 14:13:28',
        cultureValueScore: 86.2,
      },
      {
        dataFor: '2024-02-06 14:13:28',
        cultureValueScore: 86.8,
      },
      {
        dataFor: '2024-02-07 14:13:28',
        cultureValueScore: 89.1,
      },
      {
        dataFor: '2024-02-08 14:13:28',
        cultureValueScore: 88.9,
      },
      {
        dataFor: '2024-02-09 14:13:28',
        cultureValueScore: 89.7,
      },
      {
        dataFor: '2024-02-10 14:13:28',
        cultureValueScore: 88.7,
      },
      {
        dataFor: '2024-02-11 14:13:28',
        cultureValueScore: 88.5,
      },
      {
        dataFor: '2024-02-12 14:13:28',
        cultureValueScore: 88.4,
      },
      {
        dataFor: '2024-02-13 14:13:28',
        cultureValueScore: 87.2,
      },
      {
        dataFor: '2024-02-14 14:13:28',
        cultureValueScore: 86.3,
      },
      {
        dataFor: '2024-02-15 14:13:28',
        cultureValueScore: 86.2,
      },
      {
        dataFor: '2024-02-16 14:13:28',
        cultureValueScore: 85.9,
      },
      {
        dataFor: '2024-02-17 14:13:28',
        cultureValueScore: 88,
      },
      {
        dataFor: '2024-02-18 14:13:28',
        cultureValueScore: 87.2,
      },
      {
        dataFor: '2024-02-19 14:13:28',
        cultureValueScore: 89.5,
      },
      {
        dataFor: '2024-02-20 14:13:28',
        cultureValueScore: 90,
      },
      {
        dataFor: '2024-02-21 14:13:28',
        cultureValueScore: 90.2,
      },
      {
        dataFor: '2024-02-22 14:13:28',
        cultureValueScore: 88.7,
      },
      {
        dataFor: '2024-02-23 14:13:28',
        cultureValueScore: 87.2,
      },
      {
        dataFor: '2024-02-24 14:13:28',
        cultureValueScore: 87.3,
      },
      {
        dataFor: '2024-02-25 14:13:28',
        cultureValueScore: 87.6,
      },
      {
        dataFor: '2024-02-26 14:13:28',
        cultureValueScore: 88.2,
      },
      {
        dataFor: '2024-02-27 14:13:28',
        cultureValueScore: 88.7,
      },
      {
        dataFor: '2024-02-28 14:13:28',
        cultureValueScore: 89.3,
      },
      {
        dataFor: '2024-02-29 14:13:28',
        cultureValueScore: 89.8,
      },
      {
        dataFor: '2024-03-01 14:13:28',
        cultureValueScore: 89.3,
      },
      {
        dataFor: '2024-03-02 14:13:28',
        cultureValueScore: 88,
      },
      {
        dataFor: '2024-03-03 14:13:28',
        cultureValueScore: 88.7,
      },
      {
        dataFor: '2024-03-04 14:13:28',
        cultureValueScore: 88.8,
      },
      {
        dataFor: '2024-03-05 14:13:28',
        cultureValueScore: 89.4,
      },
      {
        dataFor: '2024-03-06 14:13:28',
        cultureValueScore: 88.2,
      },
      {
        dataFor: '2024-03-07 14:13:28',
        cultureValueScore: 87.5,
      },
      {
        dataFor: '2024-03-08 14:13:28',
        cultureValueScore: 87.7,
      },
      {
        dataFor: '2024-03-09 14:13:28',
        cultureValueScore: 87.7,
      },
      {
        dataFor: '2024-03-10 14:13:28',
        cultureValueScore: 89.4,
      },
      {
        dataFor: '2024-03-11 14:13:28',
        cultureValueScore: 90.3,
      },
      {
        dataFor: '2024-03-12 14:13:28',
        cultureValueScore: 90.7,
      },
      {
        dataFor: '2024-03-13 14:13:28',
        cultureValueScore: 90.9,
      },
      {
        dataFor: '2024-03-14 14:13:28',
        cultureValueScore: 90.7,
      },
      {
        dataFor: '2024-03-15 14:13:28',
        cultureValueScore: 90.8,
      },
    ],
  },
];
