import {Media, MediaRaw} from '../../interfaces/company-core-value';

export const sanitizeMediaValueFromRaw = (data: MediaRaw[]): Media[] => {
  return data.map((item: MediaRaw) => {
    return sanitizeSingleMediaValueFromRaw(item);
  });
};

export const sanitizeSingleMediaValueFromRaw = (data: MediaRaw): Media => {
  return {
    type: data.type,
    url: data.signed_url ?? data.url,
  };
};
