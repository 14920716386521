import {getProgressBarColor} from '../utils';
import styles from './styles.module.scss';

type HorizontalBarSizes = 'small' | 'medium' | 'large' | 'x-large';

type HorizontalBarVariants = 'normal' | 'gradient' | 'gradient2';

interface ElementProps {
  percentage: number;
  size?: HorizontalBarSizes;
  wrapperBackgroundColor?: string;
  progressBackgroundColor?: string;
  containerClassName?: string;
  progressBarClassName?: string;
  variant?: HorizontalBarVariants;
}

const HorizontalProgressBar = ({
  percentage,
  size,
  wrapperBackgroundColor,
  progressBackgroundColor,
  containerClassName,
  progressBarClassName,
  variant = 'normal',
}: ElementProps) => {
  let containerClass = `${styles.container} ${styles[variant]} `;

  if (size === 'x-large') {
    containerClass += styles.xLarge;
  } else if (size === 'large') {
    containerClass += styles.large;
  } else if (size === 'medium') {
    containerClass += styles.medium;
  } else {
    containerClass += styles.small;
  }

  return (
    <div
      className={containerClassName || containerClass}
      style={{
        backgroundColor: wrapperBackgroundColor || '#E8EAF5',
      }}
    >
      <div
        className={progressBarClassName || styles.progress}
        style={{
          background:
            progressBackgroundColor ||
            variant === 'gradient' ||
            variant === 'gradient2'
              ? 'var(--theme-primary-gradient, linear-gradient(107deg, #3ad0f8 0.53%, #2961f6 95.01%))'
              : getProgressBarColor(percentage),
          width: `${percentage}%`,
        }}
      />
    </div>
  );
};

export default HorizontalProgressBar;
