import {createMutation} from 'react-query-kit';
import {Team} from '../../../interfaces/team';
import {updateTeam} from '../../../repositories/instill';
import {UpdateTeamPayload} from '../../../repositories/instill/mutations/update-team';
import {useQueryClient} from '@tanstack/react-query';
import {
  RQ_FULL_USER_PROFILE,
  RQ_INFINITE_ELIGIBLE_MANAGERS,
  RQ_TEAMS,
} from '../queries/query-keys';

export const useUpdateTeam = () => {
  const queryClient = useQueryClient();

  return createMutation<Team | undefined, UpdateTeamPayload>({
    mutationFn: async (variables) => {
      return await updateTeam(
        variables.payload,
        variables.teamUuid,
        variables.companyUuid
      );
    },
    onSuccess() {
      queryClient.invalidateQueries([RQ_TEAMS]);
      queryClient.invalidateQueries([RQ_FULL_USER_PROFILE]);
      queryClient.invalidateQueries([RQ_INFINITE_ELIGIBLE_MANAGERS]);
    },
  })();
};
