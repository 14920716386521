import {atom, useRecoilState, SetterOrUpdater} from 'recoil';

const uploadedFilesLocalUrlMapState = atom<Record<string, string>>({
  key: 'uploadedFilesLocalUrlMap',
  default: {},
});

export function useUploadedFileLocalUrlsMapState(): [
  Record<string, string>,
  SetterOrUpdater<Record<string, string>>
] {
  const state = useRecoilState(uploadedFilesLocalUrlMapState);
  const uploadedFileLocalUrlsMap = state[0];
  const setUploadedFileLocalUrlsMap = state[1];

  return [uploadedFileLocalUrlsMap, setUploadedFileLocalUrlsMap];
}
