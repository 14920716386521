import 'chart.js/auto';
import {ComponentProps, ComponentRef, FunctionComponent, useRef} from 'react';
import {Radar} from 'react-chartjs-2';
import {useTranslation} from 'react-i18next';
import {
  PersonalityProfile,
  PersonalityScore,
} from '../../../interfaces/personality-profile';
import styles from './styles.module.scss';

export const getPersonalityScoreFromProfile = (
  data: PersonalityProfile
): PersonalityScore => {
  return {
    agreeableness: data.agreeableness,
    conscientiousness: data.conscientiousness,
    emotionality: data.emotionality,
    extroversion: data.extroversion,
    honesty: data.honesty,
    openness: data.openness,
  };
};

interface ElementProps {
  personalityScore: PersonalityScore[];
  variant?: 'primary' | 'secondary';
}

const HexacoRadar: FunctionComponent<ElementProps> = ({
  personalityScore,
  variant = 'primary',
}) => {
  const {t} = useTranslation('components');

  const labelsMap =
    variant === 'primary'
      ? {
          honesty: t('radar-chart.honesty-label'),
          emotionality: t('radar-chart.emotionality-label'),
          extroversion: t('radar-chart.extroversion-label'),
          agreeableness: t('radar-chart.agreeableness-label'),
          conscientiousness: t('radar-chart.conscientiousness-label'),
          openness: t('radar-chart.openness-label'),
        }
      : {
          honesty: t('radar-chart.letters.honesty-label'),
          emotionality: t('radar-chart.letters.emotionality-label'),
          extroversion: t('radar-chart.letters.extroversion-label'),
          agreeableness: t('radar-chart.letters.agreeableness-label'),
          conscientiousness: t('radar-chart.letters.conscientiousness-label'),
          openness: t('radar-chart.letters.openness-label'),
        };

  const OPTIONS: ComponentProps<typeof Radar>['options'] = {
    responsive: true,
    scales: {
      r: {
        beginAtZero: true,
        angleLines: {
          color: '#DADEEB',
        },
        grid: {
          lineWidth: 24,
          color: ({chart: {ctx}}) => {
            const color = ctx.createLinearGradient(0, 100, 400, 0);
            color.addColorStop(0.75, 'rgba(58, 208, 248, 0.10)');
            color.addColorStop(0.053, 'rgba(41, 97, 246, 0.10)');
            return ['transparent', color] as any;
          },
        },
        ticks: {
          display: false,
        },
        pointLabels:
          variant === 'primary'
            ? {
                color: '#7A819F',
                padding: 12,
                font: {
                  size: 12,
                },
              }
            : {
                backdropColor: 'rgba(58, 208, 248, 0.1)',
                padding: 32,
                backdropPadding: 12,
                color: '#2961f6',
                borderRadius: 6,
                font: {
                  size: 14,
                  weight: 'normal',
                },
              },
      },
    },
    elements: {
      line: {
        borderWidth: 1,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  let datasets: ComponentProps<typeof Radar>['data']['datasets'] = [
    {
      data: Object.keys(labelsMap).map(
        (key) => personalityScore[0][key as keyof PersonalityScore]
      ),
      label: 'hexaco',
      borderColor: ({chart: {ctx}}) => {
        const color = ctx.createLinearGradient(0, 100, 400, 0);
        color.addColorStop(0.053, 'rgba(58, 208, 248, 1)');
        color.addColorStop(0.75, 'rgba(41, 97, 246, 1)');
        return color;
      },
      pointBackgroundColor: ({chart: {ctx}}) => {
        const color = ctx.createLinearGradient(0, 100, 400, 0);
        color.addColorStop(0.053, 'rgba(58, 208, 248, 1)');
        color.addColorStop(0.75, 'rgba(41, 97, 246, 1)');
        return color;
      },
      pointBorderColor: 'transparent',
      backgroundColor: ({chart: {ctx}}) => {
        const bg = ctx.createLinearGradient(0, 100, 400, 0);
        bg.addColorStop(0.053, 'rgba(58, 208, 248, 0.4)');
        bg.addColorStop(0.9, 'rgba(41, 97, 246, 0.5)');
        return bg;
      },
    },
  ];

  if (!!personalityScore[1]) {
    datasets.push({
      label: 'hexaco',
      data: Object.keys(labelsMap).map(
        (key) => personalityScore[1][key as keyof PersonalityScore]
      ),
      borderColor: ({chart: {ctx}}) => {
        const color = ctx.createLinearGradient(0, 0, 300, 50);
        color.addColorStop(0.015, 'rgba(88, 29, 255, 1)');
        color.addColorStop(0.7, 'rgba(0, 133, 255, 1)');
        return color;
      },
      pointBackgroundColor: ({chart: {ctx}}) => {
        const color = ctx.createLinearGradient(0, 0, 300, 50);
        color.addColorStop(0.015, 'rgba(88, 29, 255, 1)');
        color.addColorStop(0.7, 'rgba(0, 133, 255, 1)');
        return color;
      },
      pointBorderColor: 'transparent',
      backgroundColor: ({chart: {ctx}}) => {
        const color = ctx.createLinearGradient(0, 0, 300, 50);
        color.addColorStop(0.015, 'rgba(88, 29, 255, 0.016)');
        color.addColorStop(0.7, 'rgba(0, 133, 255, 0.3)');
        return color;
      },
    });
  }

  const data: ComponentProps<typeof Radar>['data'] = {
    labels: Object.values(labelsMap),
    datasets: datasets,
  };

  const chartRef = useRef<ComponentRef<typeof Radar>>(null);

  return (
    <div className={styles.hexacoRadarWrapper}>
      <Radar ref={chartRef} options={OPTIONS} data={data} />
    </div>
  );
};

export default HexacoRadar;
