import {CompanyAssessmentEvents} from '../../../interfaces/company-assessment-events';
import {createQuery} from 'react-query-kit';
import {fetchCompanyAssessmentEvents} from '../../../repositories/instill';
import {FetchCompanyAssessmentEventsPayload} from '../../../repositories/instill/queries/fetch-company-assessment-events';

/**
 * @deprecated
 */
export const useCompanyAssessmentEvents = createQuery<
  CompanyAssessmentEvents | undefined,
  FetchCompanyAssessmentEventsPayload
>({
  primaryKey: 'companyAssessmentEvents',
  queryFn: async ({queryKey: [_, variables]}) => {
    if (!variables.assessmentUuid) {
      return Promise.reject();
    }

    return await fetchCompanyAssessmentEvents(
      variables.companyUuid,
      variables.assessmentUuid
    );
  },
});
