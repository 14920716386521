import * as echarts from 'echarts';
import 'echarts-wordcloud';
import {MeetingTopics} from 'interfaces/meeting';
import {useCallback, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import TextCard from 'ui/@_components/card/text-card';
import styles from './styles.module.scss';

interface ElementProps {
  topics: MeetingTopics[];
}

const TopicCloud = ({topics}: ElementProps) => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.components.meeting-details.components.topic-cloud',
  });

  const chartRef = useRef<HTMLDivElement>(null);

  const getColorBasedOnRelevance = useCallback((relevance: number) => {
    let color = '#cd0f6a';

    if (relevance > 90 && relevance < 100) {
      color = '#2961f6';
    } else if (relevance > 80) {
      color = '#5889ff';
    } else if (relevance > 60) {
      color = '#978fed';
    } else if (relevance > 40) {
      return '#cd0f6a';
    }

    return {
      color,
    };
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current);
      const onChartResize = () => chart.resize();

      const option = {
        tooltip: {},
        series: [
          {
            type: 'wordCloud',
            gridSize: 5,
            sizeRange: [20, 50],
            rotationRange: [0, 0],
            shape: 'circle',
            width: '100%',
            height: '100%',
            shrinkToFit: true,
            emphasis: {
              textStyle: {
                shadowBlur: 10,
                shadowColor: '#333',
              },
            },
            data: topics.map((topic) => {
              return {
                name: topic.topic,
                value: topic.relevance,
                textStyle: getColorBasedOnRelevance(topic.relevance),
              };
            }),
          },
        ],
      };

      chart.setOption(option);

      window.addEventListener('resize', onChartResize);

      return () => {
        window.removeEventListener('resize', onChartResize);
      };
    }
  }, [topics, getColorBasedOnRelevance]);

  return (
    <TextCard>
      <TextCard.Header title={t('title')} />
      <div className={styles.container}>
        <div ref={chartRef} className={styles.chart} />
      </div>
    </TextCard>
  );
};

export default TopicCloud;
