import {createQuery} from 'react-query-kit';
import {JobPositionSummary} from '../../../interfaces/job-position';
import {RQ_JOB_POSITIONS_SUMMARIES_ALL} from './query-keys';
import {
  FetchJobPositionsSummariesAllPayload,
  fetchJobPositionsSummariesAll,
} from 'repositories/instill/queries/fetch-job-positions-summaries-all';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {dummyJobPositionSummariesAll} from 'ui/@demo-data/job-position';

export const useJobPositionsSummariesAll = createQuery<
  JobPositionSummary[] | undefined,
  FetchJobPositionsSummariesAllPayload
>({
  primaryKey: RQ_JOB_POSITIONS_SUMMARIES_ALL,
  queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
    return await fetchJobPositionsSummariesAll(variables);
  }, dummyJobPositionSummariesAll),
  enabled: (data, variables) => {
    return !!variables.companyUuid;
  },
});
