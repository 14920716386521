import {useQueryClient} from '@tanstack/react-query';
import {createQuery} from 'react-query-kit';
import {dummyUserProfile} from 'ui/@demo-data/user-profile';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {FullUserProfile} from '../../../interfaces/user-profile';
import {fetchCompanyUserProfile} from '../../../repositories/instill';
import {FetchCompanyUserProfilePayload} from '../../../repositories/instill/queries/fetch-company-user-profile';
import {RQ_COMPANY_USER_PROFILE} from './query-keys';
import {dummyCompanyEmployees} from 'ui/@demo-data/company-employee';

type Response = FullUserProfile | undefined;
type Payload = FetchCompanyUserProfilePayload;

export const useCompanyUserProfile = createQuery<Response, Payload>({
  primaryKey: RQ_COMPANY_USER_PROFILE,
  queryFn: queryDemoWrapper(
    async ({queryKey: [_, variables]}) => {
      if (!variables.userProfileUuid) {
        return Promise.reject();
      }

      return await fetchCompanyUserProfile({
        companyUuid: variables.companyUuid,
        userProfileUuid: variables.userProfileUuid,
      });
    },
    ({queryKey: [_, variables]}) => {
      const currentEmployee = dummyCompanyEmployees.find(
        (employee) => employee.userProfileUuid === variables.userProfileUuid
      );

      if (currentEmployee) {
        return {
          ...dummyUserProfile,
          name: currentEmployee.name,
          email: currentEmployee.workEmail,
          picture: currentEmployee.picture,
          jobTitle: currentEmployee.jobTitle,
        };
      }

      return dummyUserProfile;
    }
  ),
});

export const useSyncSetCompanyUserProfile = () => {
  const queryClient = useQueryClient();

  const syncSetCompanyUserProfile = (
    companyUserProfileVariables: Payload,
    newCompanyUserProfile: Response
  ) => {
    queryClient.setQueryData(
      [RQ_COMPANY_USER_PROFILE, companyUserProfileVariables],
      newCompanyUserProfile
    );
  };

  return syncSetCompanyUserProfile;
};
