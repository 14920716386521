import {MouseEvent, ReactNode, useRef, useState} from 'react';
import useOnClickOutside from 'ui/@hooks/use-on-click-outside';
import styles from './styles.module.scss';

type menuParams = {onCloseMenu: () => void};
type toggleParams = {showMenu: boolean};

interface ElementProps {
  toggler: ReactNode | ((toggleParams: toggleParams) => ReactNode);
  menu: (menuParams: menuParams) => ReactNode;
  variant?: 'bottomRight' | 'bottomLeft' | 'bottomCenter';
  disabled?: boolean;
}

const FloatingMenu = ({
  toggler,
  menu,
  variant = 'bottomLeft',
  disabled = false,
}: ElementProps) => {
  const [showMenu, setShowMenu] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref.current, () => {
    setShowMenu(false);
  });

  let className = `${styles.menuWrapper} `;

  if (variant) {
    className += styles[variant];
  }

  const onTogglerClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setShowMenu((prevState) => !prevState);
  };

  const onCloseMenu = () => {
    setShowMenu(false);
  };

  return (
    <div className={styles.wrapper} ref={ref}>
      <button disabled={disabled} onClick={onTogglerClick}>
        {typeof toggler === 'function' ? toggler({showMenu}) : toggler}
      </button>

      {showMenu && <div className={className}>{menu({onCloseMenu})}</div>}
    </div>
  );
};

export default FloatingMenu;
