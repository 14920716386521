import {
  AssessmentResultSummary,
  AssessmentResultSummaryRaw,
  UserAssessmentResult,
  UserAssessmentResultRaw,
} from '../../interfaces/user-assessment-result';
import {sanitizePersonalityProfileFromRaw} from './personality-profile';
import {
  DEFAULT,
  PERSONALITY,
  CULTURE_DESIGN,
} from '../../constants/module-algorithms';
import {PersonalityProfileRaw} from '../../interfaces/personality-profile';
import {
  GenericModuleResult,
  GenericModuleResultRaw,
  CultureDesignModuleResult,
  CultureDesignModuleResultRaw,
} from '../../interfaces/user-assessment-module-result';

export const sanitizeUserAssessmentResultFromRaw = (
  data: UserAssessmentResultRaw
): UserAssessmentResult => {
  let payload = {
    id: data.id,
    moduleName: data.module_name,
    moduleSlug: data.module_slug,
    moduleAlgorithm: data.module_algorithm,
    moduleResult: sanitizeModuleResultFromRaw(
      data.module_result,
      data.module_algorithm
    ),
  } as UserAssessmentResult;

  if (data.module_description) {
    payload['moduleDescription'] = data.module_description;
  }

  return payload;
};

export const sanitizeModuleResultFromRaw = (
  data:
    | PersonalityProfileRaw
    | GenericModuleResultRaw
    | CultureDesignModuleResultRaw,
  algorithm: string
) => {
  let result = data as unknown;

  if (algorithm === PERSONALITY) {
    return sanitizePersonalityProfileFromRaw(result as PersonalityProfileRaw);
  } else if (algorithm === DEFAULT) {
    return sanitizeGenericModuleResultFromRaw(result as GenericModuleResultRaw);
  } else if (algorithm === CULTURE_DESIGN) {
    return sanitizeCultureDesignModuleResultFromRaw(
      result as CultureDesignModuleResultRaw
    );
  }

  throw new Error('Invalid algorithm');
};

export const sanitizeGenericModuleResultFromRaw = (
  data: GenericModuleResultRaw
): GenericModuleResult => {
  return {
    userScore: data.user_score,
  };
};

export const sanitizeCultureDesignModuleResultFromRaw = (
  data: CultureDesignModuleResultRaw
): CultureDesignModuleResult => {
  return {
    coreValues: data.core_values,
  };
};

export const sanitizeAssessmentResultSummary = (
  data: AssessmentResultSummaryRaw
): AssessmentResultSummary => {
  if (data.module_algorithm === PERSONALITY) {
    return {
      totalInvitedUsers: data.total_invited_users,
      completedResponseCount: data.completed_response_count,
      moduleAlgorithm: PERSONALITY,
      moduleData: {personalityTypes: data.module_data.personality_types},
    };
  }

  return {
    moduleAlgorithm: data.module_algorithm,
    totalInvitedUsers: data.total_invited_users,
    completedResponseCount: data.completed_response_count,
    moduleData: {score: data.module_data.score},
  };
};
