import {
  sanitizeCompanyCoreValuesFromRaw,
  sanitizeCompanyCoreValuesToRaw,
} from '../../../ui/@sanitizers/company-core-values';
import {CoreValueForm} from '../../../interfaces/company-core-value';
import InstillAPI from '../axios-instance';

export interface CreateCompanyCoreValuesPayload {
  companyCoreValuePayload: CoreValueForm[];
  companyUuid: string;
}

export async function createCompanyCoreValues(
  companyCoreValuePayload: CoreValueForm[],
  companyUuid: string
) {
  const sanitizedPayload = sanitizeCompanyCoreValuesToRaw(
    companyCoreValuePayload
  );

  const response = await InstillAPI.post(
    `/companies/${companyUuid}/values`,
    sanitizedPayload
  );

  return sanitizeCompanyCoreValuesFromRaw(response.data);
}
