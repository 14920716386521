import {QueryFunction} from '@tanstack/react-query';
import {createInfiniteQuery, createQuery} from 'react-query-kit';
import {fetchTeams} from 'repositories/instill';
import {FetchTeamsPayload} from 'repositories/instill/queries/fetch-teams';
import {dummyTeams} from 'ui/@demo-data/team';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {Meta} from '../../../interfaces/meta';
import {Team} from '../../../interfaces/team';
import getOffsetValue from '../../../utils/get-offset-value';
import {RQ_INFINITE_TEAMS, RQ_TEAMS} from './query-keys';

interface FetchTeams {
  data: Team[];
  meta: Meta;
}

const queryFn: QueryFunction<
  FetchTeams | undefined,
  [string, FetchTeamsPayload]
> = queryDemoWrapper(
  async ({queryKey: [_, variables]}) => {
    let payload = {
      offset: getOffsetValue({
        offset: variables.offset,
        currentPage: variables.currentPage ?? 0,
        itemsPerPage: variables.itemsPerPage ?? 0,
      }),
      limit: variables.limit,
      companyUuid: variables.companyUuid,
    } as FetchTeamsPayload;

    if (variables.sortBy) {
      payload['sortBy'] = variables.sortBy;
    }

    if (variables.orderBy) {
      payload['orderBy'] = variables.orderBy;
    }

    if (variables.query) {
      payload['query'] = variables.query;
    }

    const response = await fetchTeams(payload);
    if (!response) return;

    return {
      data: response.data,
      meta: response.meta,
    };
  },
  {
    data: dummyTeams,
    meta: {
      currentPage: 1,
      totalEntries: 5,
      totalPages: 1,
    },
  }
);

export const useTeams = createQuery<FetchTeams | undefined, FetchTeamsPayload>({
  primaryKey: RQ_TEAMS,
  queryFn: queryFn,
  enabled: (data, variables) => {
    return !!variables.companyUuid;
  },
});

export const useInfiniteTeams = createInfiniteQuery<
  FetchTeams | undefined,
  Omit<FetchTeamsPayload, 'itemsPerPage' | 'offset' | 'currentPage'>
>({
  primaryKey: RQ_INFINITE_TEAMS,
  queryFn,
  enabled: (data, variables) => {
    return !!variables.companyUuid;
  },
  getNextPageParam: (lastPage, allPages) => {
    return lastPage?.meta.totalPages &&
      lastPage?.meta.totalPages > allPages.length
      ? allPages.length + 1
      : undefined;
  },
  getPreviousPageParam: (firstPage, allPages) => {
    return allPages.length ? allPages.length - 1 : undefined;
  },
});
