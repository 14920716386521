import Button from 'ui/@_components/button';
import {ReactComponent as Arrow3Icon} from 'ui/@_components/kit/icons/arrow3.svg';
import useDisclosure from 'ui/@hooks/use-disclosure';
import InviteUser from 'ui/@module-components/modals/invite-user';
import styles from './styles.module.scss';
import {useTranslation} from 'react-i18next';
import {useCompanyEmployees} from 'ui/@hooks/queries';
import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';
import useDemoAccountDetails from 'ui/@hooks/use-demo-account-details';

const HEADER_INVITE_USERS_LIMIT = 10;

const HeaderInviteUsers = () => {
  const {t} = useTranslation('components', {
    keyPrefix: 'header.components.header-invite-users',
  });

  const {isOpen, onClose, onOpen} = useDisclosure();

  const companyUuid = useSafeCurrentCompanyUuid();

  const {brandName} = useDemoAccountDetails();

  const companyEmployees = useCompanyEmployees({
    variables: {
      companyUuid,
      limit: 0,
      offset: 0,
      includeInvitedUsers: true,
    },
  });

  if (companyEmployees.isLoading) {
    return null;
  }

  if ((companyEmployees.data?.data.length || 0) > HEADER_INVITE_USERS_LIMIT) {
    return null;
  }

  return (
    <>
      <InviteUser isOpen={isOpen} onClose={onClose} />

      <div className={styles.container}>
        <p className={styles.title}>
          {t('title', {
            brandName,
          })}
        </p>

        <Button variant="secondary" onClick={onOpen}>
          {t('invite')}
          <Arrow3Icon className={styles.arrowIcon} />
        </Button>
      </div>
    </>
  );
};

export default HeaderInviteUsers;
