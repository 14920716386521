import {Plans} from 'constants/plans';
import {Subscription} from 'interfaces/subscription';

export const dummyCurrentSubscriptions: Subscription[] = [
  {
    isActive: true,
    assessmentCount: 100,
    companyId: 1,
    createdAt: '2021-04-09T16:43:12.000Z',
    deletedAt: null,
    externalSubscriptionId: '123456789',
    flowCreditCount: 100,
    id: 1,
    isDeleted: false,
    nextBillingDate: '2030-04-09T16:43:12.000Z',
    numSeatsCount: 12,
    product: {
      externalId: 'prod_123456789',
      id: 1,
      name: Plans.FREE_TRAIL_PLAN,
    },
    updatedAt: null,
    uuid: '123456789',
    nextBillingAmount: 100,
    maxAssessmentCount: 100,
    maxFlowCreditCount: 72000,
    interval: 'month',
  },
];
