import {createQuery} from 'react-query-kit';
import {UserAssessment} from '../../../interfaces/user-assessment';
import {
  FetchCandidateAssessmentPayload,
  fetchCandidateAssessment,
} from '../../../repositories/instill/queries/fetch-candidate-assessment';
import {RQ_CANDIDATE_ASSESSMENT} from './query-keys';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {dummyCandidateAssessment} from 'ui/@demo-data/user-assessment';

export const useCandidateAssessment = createQuery<
  UserAssessment | undefined,
  FetchCandidateAssessmentPayload
>({
  primaryKey: RQ_CANDIDATE_ASSESSMENT,
  queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
    if (!variables.candidateToken || !variables.candidateUuid) {
      return Promise.reject();
    }

    return await fetchCandidateAssessment({
      candidateToken: variables.candidateToken,
      candidateUuid: variables.candidateUuid,
    });
  }, dummyCandidateAssessment),
  enabled: (data, variables) => {
    return !!variables.candidateToken && !!variables.candidateUuid;
  },
});
