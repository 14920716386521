import {
  sanitizeCompanyCoreValueFromRaw,
  sanitizeCompanyCoreValueToRaw,
} from '../../../ui/@sanitizers/company-core-values';
import {CoreValueForm} from '../../../interfaces/company-core-value';
import InstillAPI from '../axios-instance';

export interface UpdateCompanyCoreValuePayload {
  payload: CoreValueForm;
  companyUuid: string;
  coreValueUuid: string;
}

export async function updateCompanyCoreValue(
  payload: CoreValueForm,
  companyUuid: string,
  coreValueUuid: string
) {
  const sanitizedPayload = sanitizeCompanyCoreValueToRaw(payload);

  const response = await InstillAPI.put(
    `/companies/${companyUuid}/values/${coreValueUuid}`,
    sanitizedPayload
  );

  return sanitizeCompanyCoreValueFromRaw(response.data);
}
