import {useAuth0} from '@auth0/auth0-react';
import {EventName} from 'constants/analytics/event-name';
import {EventType} from 'constants/analytics/event-type';
import {
  IS_SIGNIN_TRACKED_STORAGE_KEY,
  IS_SIGNUP_TRACKED_STORAGE_KEY,
} from 'constants/local-storage-keys';
import {useEffect} from 'react';
import {Outlet} from 'react-router';
import {useLocation} from 'react-router-dom';
import useAnalytics from 'ui/@hooks/use-analytics';
import {AuthUser} from '../../../../interfaces/auth-user';
import {StringParam, useQueryParam} from 'use-query-params';

interface RequireAuthProps {
  user: AuthUser | null;
}

function RequireAuth({user}: RequireAuthProps) {
  const {
    isLoading: auth0IsLoading,
    error: auth0Error,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  const [screenHint] = useQueryParam('screen_hint', StringParam);

  const location = useLocation();

  const {trackEvent} = useAnalytics();

  useEffect(() => {
    const isSignInTracked = localStorage.getItem(IS_SIGNIN_TRACKED_STORAGE_KEY);

    const isSignupTracked = localStorage.getItem(IS_SIGNUP_TRACKED_STORAGE_KEY);

    if (!isSignInTracked && user && !isSignupTracked) {
      // ? Tracking here because signin code is in Auth0 Dashboard
      trackEvent({
        eventName: EventName.ONBOARDING.SIGNIN,
        eventType: EventType.BUTTON_CLICKED,
        eventProperties: {
          email: user.email,
        },
      });

      localStorage.setItem(IS_SIGNIN_TRACKED_STORAGE_KEY, 'true');
    }
  }, [trackEvent, user]);

  useEffect(() => {
    if (!user) {
      loginWithRedirect({
        appState: {returnTo: location.pathname},
        screen_hint: screenHint || undefined,
      });
    }
  }, [
    isAuthenticated,
    loginWithRedirect,
    auth0Error,
    auth0IsLoading,
    user,
    location,
    screenHint,
  ]);

  if (!user) return <></>;

  return <Outlet />;
}

export default RequireAuth;
