import {FunctionComponent, LabelHTMLAttributes} from 'react';
import styles from './styles.module.scss';

interface ElementProps extends LabelHTMLAttributes<HTMLLabelElement> {
  isRequired?: boolean;
  withOptionalText?: boolean;
}

const Label: FunctionComponent<ElementProps> = ({
  children,
  isRequired = false,
  withOptionalText = false,
  ...props
}) => {
  let classes = styles.label;

  if (props.className) {
    classes = `${classes} ${props.className}`;
  }

  return (
    <label {...props} className={classes}>
      {children}

      {isRequired && <span className={styles.asterik}> *</span>}

      {withOptionalText && <p className={styles.optionalText}>(optional)</p>}
    </label>
  );
};

export default Label;
