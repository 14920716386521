import Spinner from 'ui/@_components/spinner';
import styles from './styles.module.scss';
import {useTranslation} from 'react-i18next';

const IsUploading = () => {
  const {t} = useTranslation('components', {
    keyPrefix: 'upload-area.components.is-uploading',
  });

  return (
    <div className={styles.uploading}>
      <Spinner />
      <p className={styles.uploadText}>{t('uploading')}</p>
    </div>
  );
};

export default IsUploading;
