import {sanitizeValuesFromCultureSpecialistFromRaw} from 'ui/@sanitizers/culture-design-specialist';
import isResponseSuccessful from '../../../utils/is-response-successful';
import InstillAPI from '../axios-instance';

export interface GenerateValuesFromCultureSpecialistPayload {
  companyUuid: string;
  sessionUuid: string;
}

export const generateValuesFromCultureSpecialist = async (
  parameters: GenerateValuesFromCultureSpecialistPayload
) => {
  const {companyUuid, sessionUuid} = parameters;

  const response = await InstillAPI.post(
    `/companies/${companyUuid}/values/generate/${sessionUuid}/`
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return sanitizeValuesFromCultureSpecialistFromRaw(response.data.values);
};
