import {createQuery} from 'react-query-kit';
import {dummyTranscript} from 'ui/@demo-data/transcript';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {Transcript} from 'interfaces/transcript';
import {
  FetchTranscriptPayload,
  fetchTranscript,
} from 'repositories/instill/queries/fetch-transcript';
import {RQ_TRANSCRIPT} from './query-keys';

export const useTranscript = createQuery<
  Transcript[] | undefined,
  FetchTranscriptPayload
>({
  primaryKey: RQ_TRANSCRIPT,
  queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
    if (!variables.meetingUuid) {
      return Promise.reject();
    }

    return fetchTranscript(variables);
  }, dummyTranscript),
  enabled(data, variables) {
    return !!variables.meetingUuid;
  },
});
