import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import BaseCard from 'ui/@_components/card/base-card';
import Dialog from 'ui/@_components/kit/dialog';
import {ReactComponent as ChevronDownIcon} from 'ui/@_components/kit/icons/chevron-down.svg';
import {ReactComponent as ChevronRight} from 'ui/@_components/kit/icons/chevron-right.svg';
import HorizontalProgressBar from 'ui/@_components/progress-bar/horizontal';
import useFlowData from '../../../../@hooks/use-flow-data';
import ValuesVitalSignHelper from '../values-vital-sign-helper';
import styles from './styles.module.scss';

const Values = () => {
  const {t} = useTranslation('sense', {
    keyPrefix: 'meetings.show-v2.components.meeting-details.components.values',
  });

  const [isValuesExpanded, setIsValuesExpanded] = useState(true);

  const [showValueAlignmentDialog, setShowValueAlignmentDialog] =
    useState(false);

  const [activeValueAlignment, setActiveValueAlignment] = useState(0);

  const {meeting, companyName} = useFlowData();

  if (!meeting || !meeting.valuesAlignment?.length) return <></>;

  const {valuesAlignment} = meeting;

  const onOpenValue = (index: number) => {
    setActiveValueAlignment(index);

    setShowValueAlignmentDialog(true);
  };

  const getFeedbackCount = (feedback: string) => {
    const feedbackList = feedback.split('. ');

    return feedbackList.length;
  };

  return (
    <>
      <BaseCard>
        <div className={styles.container}>
          <p className={styles.title}>
            {t('title', {
              companyName: companyName || '',
            })}

            <button
              className={
                isValuesExpanded
                  ? styles.expandedButton
                  : styles.unExpandedButton
              }
              onClick={() => {
                setIsValuesExpanded(!isValuesExpanded);
              }}
            >
              <ChevronDownIcon />
            </button>
          </p>

          {isValuesExpanded && (
            <ul className={styles.listContainer}>
              {valuesAlignment.map((valueAlignment, index) => (
                <li
                  className={styles.list}
                  key={index}
                  onClick={() => onOpenValue(index)}
                >
                  <div className={styles.nameContainer}>
                    {valueAlignment.name}

                    <p>
                      {`Feedback (${getFeedbackCount(
                        valueAlignment.feedback
                      )})`}
                      <ChevronRight className={styles.rightIcon} />
                    </p>
                  </div>

                  <div className={styles.progressBarContainer}>
                    <HorizontalProgressBar
                      percentage={valueAlignment.score || 0}
                    />
                    {valueAlignment.score}%
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </BaseCard>

      <Dialog
        variant="large"
        isOpen={showValueAlignmentDialog}
        onClose={() => {
          setShowValueAlignmentDialog(!showValueAlignmentDialog);
        }}
      >
        <Dialog.Header title={valuesAlignment[activeValueAlignment].name} />

        <Dialog.Content>
          <ValuesVitalSignHelper
            parameter={valuesAlignment[activeValueAlignment]}
          />
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default Values;
