import {routeNames} from 'constants/route-names';
import {useTranslation} from 'react-i18next';
import Button from 'ui/@_components/button';
import FloatingIconMenu from 'ui/@_components/floating-icon-menu';
import UserProfileAvatar from 'ui/@_components/user-profile-avatar';
import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';
import {useFullUserProfile} from 'ui/@hooks/queries';
import useDemoAccountDetails from 'ui/@hooks/use-demo-account-details';
import useInDesktop from 'ui/@hooks/use-in-desktop';
import useLogout from 'ui/@hooks/use-logout';
import styles from './styles.module.scss';

const HeaderProfile = () => {
  const {t} = useTranslation('components', {
    keyPrefix: 'header.components.header-profile',
  });

  const {applicationRoute, peopleTeamsRoute, peopleRoute, settingsRoute} =
    routeNames;

  const companyUuid = useSafeCurrentCompanyUuid();

  const userProfile = useFullUserProfile({
    variables: {
      companyUuid,
    },
  });

  const {showLogoutCta} = useDemoAccountDetails();

  const logout = useLogout();
  const isDesktop = useInDesktop();

  return (
    <FloatingIconMenu
      variant={isDesktop ? 'bottomCenter' : 'bottomLeft'}
      icon={
        <div className={styles.toggleContainer}>
          <UserProfileAvatar
            profilePic={userProfile.data?.picture || ''}
            userName={userProfile.data?.name || ''}
            size="small"
          />

          {isDesktop && <p className={styles.name}>{userProfile.data?.name}</p>}
        </div>
      }
    >
      {() => (
        <div className={styles.menuContainer}>
          <Button
            as="link"
            to={`/${applicationRoute}/${peopleTeamsRoute}/${peopleRoute}/${userProfile.data?.uuid}`}
            variant="tertiary"
          >
            {t('my-profile')}
          </Button>

          <Button
            as="link"
            to={`/${applicationRoute}/${settingsRoute}`}
            variant="tertiary"
          >
            {t('settings')}
          </Button>

          {showLogoutCta && (
            <>
              <FloatingIconMenu.Divider />

              <Button variant="tertiaryAlert" onClick={() => logout()}>
                {t('logout')}
              </Button>
            </>
          )}
        </div>
      )}
    </FloatingIconMenu>
  );
};

export default HeaderProfile;
