import styles from './styles.module.scss';

interface ElementProps {
  checked: boolean;
  onChange?: () => void;
}

const Radio = ({checked, onChange}: ElementProps) => {
  if (checked) {
    return (
      <div className={styles.radioCheckedOuter} onClick={() => onChange?.()}>
        <div className={styles.radioCheckedInner} />
      </div>
    );
  }

  return <div className={styles.unCheckedRadio} onClick={() => onChange?.()} />;
};

export default Radio;
