import InstillAPI from '../axios-instance';

export interface CheckInviteTokenResponse {
  companyLogoUrl: string | null;
  companyName: string;
  companyUuid: string;
  tokenRedeemed: boolean;
}

export interface CheckInviteTokenPayload {
  token: string;
}

export async function checkInviteToken({
  token,
}: CheckInviteTokenPayload): Promise<CheckInviteTokenResponse> {
  const result = await InstillAPI.get(`/user-invites/${token}/check`);

  return {
    companyLogoUrl: result.data.company_logo_url,
    companyName: result.data.company_name,
    companyUuid: result.data.company_uuid,
    tokenRedeemed: result.data.token_redeemed,
  };
}
