import {sanitizeValuesGetHelpFromRaw} from 'ui/@sanitizers/values-get-help';
import InstillAPI from '../axios-instance';

export interface ValueGetHelpPayload {
  helpField: 'underlying_traits' | 'behaviour_expectations';
  companyUuid: string;
  coreValue: string;
  valueDescription: string;
}

export async function valuesGetHelp({
  helpField,
  companyUuid,
  coreValue,
  valueDescription,
}: ValueGetHelpPayload) {
  const response = await InstillAPI.post(
    `/companies/${companyUuid}/values/get_help/value/${helpField}`,
    {
      core_value: coreValue,
      value_description: valueDescription,
    }
  );

  return sanitizeValuesGetHelpFromRaw(response.data);
}
