import {atom, useRecoilState, useRecoilValue} from 'recoil';

const accountCreationLoaderShowState = atom<boolean>({
  key: 'accountCreationLoaderShow',
  default: false,
});

export function useAccountCreationLoaderShowState() {
  return useRecoilState(accountCreationLoaderShowState);
}

export function useAccountCreationLoaderShow() {
  return useRecoilValue(accountCreationLoaderShowState);
}
