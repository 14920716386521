import {Transcript} from 'interfaces/transcript';
import {RefObject, useEffect} from 'react';

interface ITranscriptProps {
  transcript: Transcript[];
  playerRef: RefObject<HTMLAudioElement>;
  wordsRef: RefObject<HTMLSpanElement>;
  currentWordClassName: string;
}

const useTranscriptPlayer = (props: ITranscriptProps) => {
  const {transcript, wordsRef, playerRef, currentWordClassName} = props;

  useEffect(() => {
    const onTimeUpdate = () => {
      const activeWordIndex = transcript.findIndex((word) => {
        return word.start > playerRef.current!.currentTime;
      });

      let newIndex = 0;
      let iterator = 0;

      // remove the styling from other spans so that we get the effect of current word highlighted
      wordsRef.current?.childNodes.forEach((element, index) => {
        if (element instanceof Element) {
          if (element.nodeName === 'SPAN') {
            iterator = iterator + 1;
            if (iterator === activeWordIndex) {
              newIndex = index;
            }
          }

          element.classList.remove(currentWordClassName);
        }
      });

      const wordElement = wordsRef.current?.childNodes[newIndex];

      if (wordElement instanceof Element) {
        wordElement.classList.add(currentWordClassName);
      }
    };

    playerRef.current?.addEventListener('timeupdate', onTimeUpdate);

    return () =>
      // eslint-disable-next-line react-hooks/exhaustive-deps
      playerRef.current?.removeEventListener('timeupdate', onTimeUpdate);
  }, [transcript, playerRef, wordsRef, currentWordClassName]);
};

export default useTranscriptPlayer;
