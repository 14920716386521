import {createMutation} from 'react-query-kit';
import {
  FeatureRequestPayload,
  featureRequest,
} from 'repositories/instill/mutations/feature-request';

export const useFeatureRequest = () => {
  return createMutation<string | undefined, FeatureRequestPayload>({
    mutationFn: async (variables) => {
      return await featureRequest(variables);
    },
  })();
};
