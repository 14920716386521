import {CandidateForJobPosition} from 'interfaces/candidate';

export const candidateProfile = {
  uuid: '1a2b3c4d',
  name: 'John Doe',
  email: 'john.doe@example.com',
  createdAt: '2024-03-19T08:00:00Z',
  updatedAt: '2024-03-19T08:30:00Z',
};

export const candidatesForJob: CandidateForJobPosition[] = [
  {
    id: '51',
    createdAt: '2024-01-12T16:26:37.909383+00:00',
    updatedAt: '2024-03-18T05:00:00.106875+00:00',
    assessmentStatus: 'completed',
    candidateUuid: '1423a5fd-15fb-45ea-82e8-f7b974d48682',
    dueDate: '2024-01-17T18:30:00+00:00',
    email: 'djkasper001@gmail.com',
    isActive: false,
    jobPositionUuid: '77b7b92d-1dcf-41f2-abc9-69dbc90bfc49',
    jobPositionTitle: 'Graphics Designer',
    name: 'Jennifer Johnson',
    valuesCalibrationScore: 70,
  },
  {
    id: '14',
    createdAt: '2024-01-08T15:52:52.138925+00:00',
    updatedAt: '2024-03-18T05:00:00.106875+00:00',
    assessmentStatus: 'completed',
    candidateUuid: 'fed91a56-74d0-451c-bd0a-92ffbdf94632',
    dueDate: '2024-01-17T18:30:00+00:00',
    email: 'madhvendra+design@instill.ai',
    isActive: false,
    jobPositionUuid: '77b7b92d-1dcf-41f2-abc9-69dbc90bfc49',
    jobPositionTitle: 'Graphics Designer',
    name: 'briewill@gmail.com',
    valuesCalibrationScore: 85,
  },
  {
    id: '129',
    createdAt: '2024-01-18T18:44:14.888719+00:00',
    updatedAt: '2024-03-18T05:00:00.106875+00:00',
    assessmentStatus: 'completed',
    candidateUuid: '6a5d8531-08f8-41d7-88ec-ed84756d40a7',
    dueDate: '2024-01-29T18:30:00+00:00',
    email: 'madhvendra+newuser@instill.ai',
    isActive: false,
    jobPositionUuid: '77b7b92d-1dcf-41f2-abc9-69dbc90bfc49',
    jobPositionTitle: 'Graphics Designer',
    name: 'Dorothy Black',
    valuesCalibrationScore: 85,
  },
  {
    id: '52',
    createdAt: '2024-01-12T17:11:09.269718+00:00',
    updatedAt: '2024-03-18T05:00:00.106875+00:00',
    assessmentStatus: 'completed',
    candidateUuid: '6f8dafe5-c1e9-48bf-a72d-18929a80f221',
    dueDate: '2024-01-17T18:30:00+00:00',
    email: 'madhvendra+design1@instill.ai',
    isActive: false,
    jobPositionUuid: '77b7b92d-1dcf-41f2-abc9-69dbc90bfc49',
    jobPositionTitle: 'Graphics Designer',
    name: 'johnmchenry@gmail.com',
    valuesCalibrationScore: 30,
  },
  {
    id: '125',
    createdAt: '2024-01-18T18:35:32.936484+00:00',
    updatedAt: '2024-03-18T05:00:00.106875+00:00',
    assessmentStatus: 'completed',
    candidateUuid: 'cb44253c-92d8-4958-8cae-d590f0e23cce',
    dueDate: '2024-01-24T18:30:00+00:00',
    email: 'madhvendra+test345@instill.ai',
    isActive: false,
    jobPositionUuid: '77b7b92d-1dcf-41f2-abc9-69dbc90bfc49',
    jobPositionTitle: 'Graphics Designer',
    name: 'Carrie Bradshaw',
    valuesCalibrationScore: 60,
  },
];
