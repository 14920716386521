import {QueryHookOptionsInt, createQuery} from 'react-query-kit';
import {dummyMeetings} from 'ui/@demo-data/meeting';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {MeetingListItem} from '../../../interfaces/meeting';
import {Meta} from '../../../interfaces/meta';
import {
  FetchMeetingsPayload,
  fetchMeetings,
} from '../../../repositories/instill/queries/fetch-meetings';
import getOffsetValue from '../../../utils/get-offset-value/get-offset-value';
import {RQ_MEETINGS} from './query-keys';
import useHasPlanAccess from '../use-has-plan-access';
import {Features} from 'constants/plan-access/features';
import paramEnabled from 'utils/query-utils/param-enabled';

interface FetchMeetingsResponsePayload {
  data: MeetingListItem[];
  meta: Meta;
}

export const useMeetings = (
  params: QueryHookOptionsInt<
    FetchMeetingsResponsePayload | undefined,
    Error,
    FetchMeetingsResponsePayload | undefined,
    FetchMeetingsPayload
  >
) => {
  const {hasPlanAccess} = useHasPlanAccess();

  return createQuery<
    FetchMeetingsResponsePayload | undefined,
    FetchMeetingsPayload
  >({
    primaryKey: RQ_MEETINGS,
    queryFn: queryDemoWrapper(
      async ({queryKey: [_, variables]}) => {
        let payload = {
          offset: getOffsetValue({
            offset: variables.offset,
            currentPage: variables.currentPage ?? 0,
            itemsPerPage: variables.itemsPerPage ?? 0,
          }),
          limit: variables.limit,
          companyUuid: variables.companyUuid,
          includeMeetingsWithNoName: variables.includeMeetingsWithNoName,
        } as FetchMeetingsPayload;

        if (variables.sortBy) {
          payload['sortBy'] = variables.sortBy;
        }

        if (variables.orderBy) {
          payload['orderBy'] = variables.orderBy;
        }

        if (variables.query) {
          payload['query'] = variables.query;
        }

        return await fetchMeetings(payload);
      },
      {
        data: dummyMeetings(),
        meta: {
          currentPage: 1,
          totalEntries: 7,
          totalPages: 1,
        },
      }
    ),
  })({
    ...params,
    enabled(data, variables) {
      return (
        hasPlanAccess(Features.INSTILL_FLOW) &&
        paramEnabled(params.enabled, data, variables)
      );
    },
  });
};
