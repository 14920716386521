import {createQuery} from 'react-query-kit';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {RQ_CUSTOMER_PORTAL} from './query-keys';
import {
  FetchCustomerPortalPayload,
  fetchCustomerPortal,
} from 'repositories/instill/queries/fetch-customer-portal';
import {CustomerPortalResponse} from 'interfaces/customer-portal';
import {dummyCustomerPortal} from 'ui/@demo-data/customer-portal';
import {QueryHookOptionsInt} from 'react-query-kit';
import useHasPlanAccess from '../use-has-plan-access';
import {paidPlans} from 'constants/plans';

type Response = CustomerPortalResponse | undefined;
type Payload = FetchCustomerPortalPayload;

export const useCustomerPortal = <T = Response>(
  params: QueryHookOptionsInt<Response, Error, T, FetchCustomerPortalPayload>
) => {
  const {currentPlans} = useHasPlanAccess();

  return createQuery<Response, Payload>({
    primaryKey: RQ_CUSTOMER_PORTAL,
    queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
      return await fetchCustomerPortal({
        companyUuid: variables.companyUuid,
      });
    }, dummyCustomerPortal),
    enabled: () => {
      return currentPlans.some((plan) => paidPlans.includes(plan));
    },
  })(params);
};
