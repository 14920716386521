import styles from './styles.module.scss';

interface ElementProps {
  url: string;
  type: 'image' | 'video' | 'document';
  alt: string;
  height: string | number;
  width: string | number;
  className?: string;
}

const DynamicMedia = ({
  alt,
  height,
  type,
  url,
  width,
  className,
}: ElementProps) => {
  if (type === 'image') {
    return (
      <img
        src={url}
        alt={alt}
        height={height}
        width={width}
        className={className}
      />
    );
  }

  if (type === 'video') {
    return (
      <video
        src={url}
        height={height}
        width={width}
        controls
        muted
        className={`${styles.video} ${className}`}
      />
    );
  }

  return null;
};

export default DynamicMedia;
