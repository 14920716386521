import {createMutation} from 'react-query-kit';
import {CultureDesignAIResponse} from '../../../interfaces/culture-design-specialist';
import {generateCultureDesignResponse} from '../../../repositories/instill';
import {GenerateCultureDesignPayload} from '../../../repositories/instill/mutations/generate-culture-design-response';

export const useGenerateCultureDesignResponse = createMutation<
  CultureDesignAIResponse | undefined,
  GenerateCultureDesignPayload
>({
  mutationFn: async (variables) => {
    return await generateCultureDesignResponse({
      sessionUuid: variables.sessionUuid,
      companyUuid: variables.companyUuid,
      payload: variables.payload,
    });
  },
});
