import {createQuery} from 'react-query-kit';
import {fetchUserProfileForCompany} from '../../../repositories/instill';
import {FullUserProfile} from '../../../interfaces/user-profile';

export interface FetchUserProfileForCompanyPayload {
  companyUuid: string;
}

/**
 * @deprecated not being used currently in beta app
 */
export const useUserProfileForCompany = createQuery<
  FullUserProfile | undefined,
  FetchUserProfileForCompanyPayload
>({
  primaryKey: 'userProfileForCompany',
  queryFn: async ({queryKey: [_, variables]}) => {
    return await fetchUserProfileForCompany(variables.companyUuid);
  },
});
