import {Link} from 'react-router-dom';
import useInDesktop from 'ui/@hooks/use-in-desktop';
import styles from './styles.module.scss';

interface ElementProps {
  breadcrumbItem: string;
  links?: string[];
}

const Breadcrumb = ({breadcrumbItem, links = []}: ElementProps) => {
  const breadcrumbItems = breadcrumbItem.split(',');

  const inDesktop = useInDesktop();

  const activeItem = (index: number) => {
    return index === breadcrumbItems.length - 1;
  };

  const itemTextStyles = (index: number) =>
    activeItem(index) ? styles.activeText : styles.text;

  const trimBreadcrumbText = (text: string) => {
    if (inDesktop) return text;

    if (text.length < 10) return text;

    return `${text.slice(0, 10)}..`;
  };

  return (
    <ul className={styles.itemStyles}>
      {breadcrumbItems.map((item, index) => {
        return (
          <li key={index}>
            {links[index] ? (
              <Link to={links[index]} className={styles.link}>
                <span className={itemTextStyles(index)}>
                  {trimBreadcrumbText(item)}
                </span>
              </Link>
            ) : (
              <span className={itemTextStyles(index)}>
                {trimBreadcrumbText(item)}
              </span>
            )}

            {!activeItem(index) && (
              <div className={styles.separationLine}>|</div>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default Breadcrumb;
