import {createQuery} from 'react-query-kit';
import {fetchNotificationSettings} from '../../../repositories/instill';
import {NotificationSettings} from '../../../interfaces/notification-settings';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {dummyNotificationSettings} from 'ui/@demo-data/notification-settings';

export const useNotificationSettings = createQuery<
  NotificationSettings | undefined,
  {companyUuid: string}
>({
  primaryKey: 'notificationSettings',
  queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
    return fetchNotificationSettings(variables.companyUuid);
  }, dummyNotificationSettings),
});
