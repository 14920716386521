import {CandidateForJobPosition} from 'interfaces/candidate';
import {createQuery} from 'react-query-kit';
import {FetchCandidatseForJobPayload} from 'repositories/instill/queries/fetch-candidiates-for-job';
import {candidatesForJob} from 'ui/@demo-data/candidate';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {Meta} from '../../../interfaces/meta';
import {fetchCandidatesForJob} from '../../../repositories/instill/queries';
import {RQ_CANDIDATES_FOR_JOBS} from './query-keys';

export interface FetchCandidatesResponsePayload {
  data: CandidateForJobPosition[];
  meta: Meta;
}

export const useCandidatesForJob = createQuery<
  FetchCandidatesResponsePayload | undefined,
  FetchCandidatseForJobPayload
>({
  primaryKey: RQ_CANDIDATES_FOR_JOBS,
  queryFn: queryDemoWrapper(
    async ({queryKey: [_, variables]}) => {
      return await fetchCandidatesForJob(variables);
    },
    {
      data: candidatesForJob,
      meta: {
        currentPage: 1,
        totalEntries: 10,
        totalPages: 1,
      },
    }
  ),
});
