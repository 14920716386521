import isResponseSuccessful from 'utils/is-response-successful';
import InstillAPI from '../axios-instance';

export interface FeatureRequestPayload {
  companyUuid: string;
  featureName: string;
}

export const featureRequest = async ({
  companyUuid,
  featureName,
}: FeatureRequestPayload) => {
  const response = await InstillAPI.post(
    `/companies/${companyUuid}/feature-request`,
    {
      feature_request: featureName,
    }
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return response.data as string;
};
