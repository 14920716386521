import {
  CultureDesignAIResponse,
  CultureDesignAIResponseRaw,
  CultureDesignUserResponse,
  CultureDesignUserResponseRaw,
  ValuesFromSpecialist,
  ValuesFromSpecialistRaw,
} from 'interfaces/culture-design-specialist';

export const sanitizeCultureDesignUserResponseToRaw = (
  data: CultureDesignUserResponse
): CultureDesignUserResponseRaw => {
  return {
    user_response: data.userResponse,
  };
};

export const sanitizeCultureDesignAIResponseFromRaw = (
  data: CultureDesignAIResponseRaw
): CultureDesignAIResponse => {
  return {
    response: data.response,
    isEnded: data.is_ended,
  };
};

export const sanitizeValuesFromCultureSpecialistFromRaw = (
  data: ValuesFromSpecialistRaw[]
): ValuesFromSpecialist[] => {
  return data.map((value: ValuesFromSpecialistRaw) => {
    return {
      name: value.name,
      description: value.description,
      underlyingTraits: value.underlying_traits,
      exceedsExpectations: value.exceeds_expectations,
      meetsExpectations: value.meets_expectations,
      missesExpectations: value.misses_expectations,
    };
  });
};
