import {Roles} from 'constants/roles';
import {CompanyEmployee} from 'interfaces/company-employee';

export const dummyCompanyEmployees: CompanyEmployee[] = [
  {
    kudosGivenCount: 0,
    personalityTrait: 'The Enigma',
    jobTitle: 'CEO',
    manager: null,
    name: 'Jane Doe',
    picture: '/static/demo-account/profile.jpeg',
    team: {
      uuid: 'e049a182-37f6-4e6d-bf1b-216ee9ed3cb6',
      name: 'Product Team',
      colorHex: '#fefefe',
    },
    userProfileUuid: '05fdc4f7-7a9b-4579-849e-ee0621aabb84',
    workEmail: 'jane@instill.ai',
    kudosReceivedCount: 0,
    joinedDate: '2024-01-08T17:49:11.080910+00:00',
    isActive: true,
    role: Roles.ADMIN,
  },
  {
    personalityTrait: 'Strategist',
    jobTitle: 'Product Manager',
    manager: null,
    name: 'Dan Johnson',
    picture: '/static/demo-account/people/person4.png',
    team: {
      uuid: 'ec85e407-f557-4194-8e35-81ad531f2a27',
      name: 'Product Team',
      colorHex: '#fefefe',
    },
    userProfileUuid: '47b47d84-972f-4b38-820d-67ed914ad858',
    workEmail: 'dan@instill.ai',
    kudosReceivedCount: 0,
    kudosGivenCount: 0,
    joinedDate: '2024-01-08T21:02:19.623151+00:00',
    isActive: true,
    role: Roles.MANAGER,
  },
  {
    personalityTrait: 'Nurturer',
    jobTitle: 'Sr Backend Engineer',
    manager: null,
    name: 'Ken Northwood',
    picture: '/static/demo-account/people/person3.png',
    team: {
      uuid: 'e049a182-37f6-4e6d-bf1b-216ee9ed3cb6',
      name: 'Development Team',
      colorHex: '#fefefe',
    },
    userProfileUuid: '76026980-81d0-4180-a3fa-35a6215ce063',
    workEmail: 'ken@instill.ai',
    kudosReceivedCount: 0,
    kudosGivenCount: 0,
    joinedDate: '2024-01-10T09:49:14.916562+00:00',
    isActive: true,
    role: Roles.MANAGER,
  },
  {
    personalityTrait: 'Sage',
    jobTitle: 'Sr HR',
    manager: null,
    name: 'Walter White',
    picture: '/static/demo-account/people/person5.png',
    team: {
      uuid: 'e049a182-37f6-4e6d-bf1b-216ee9ed3cb6',
      name: 'HR Team',
      colorHex: '#fefefe',
    },
    userProfileUuid: '76026980-81d0-4180-a3fa-055f215ce063',
    workEmail: 'walter@instill.ai',
    kudosReceivedCount: 0,
    kudosGivenCount: 0,
    joinedDate: '2024-01-10T09:49:14.916562+00:00',
    isActive: true,
    role: Roles.ADMIN,
  },
  {
    personalityTrait: 'Nurturer',
    jobTitle: 'Sr Software Engineer',
    manager: null,
    name: 'Sam Smith',
    picture: '/static/demo-account/people/person2.jpeg',
    team: {
      uuid: 'e049a182-37f6-4e6d-bf1b-216ee9ed3cb6',
      name: 'Development Team',
      colorHex: '#fefefe',
    },
    userProfileUuid: '76026980-81d0-4180-a3fa-05a6215g5063',
    workEmail: 'sam@instill.ai',
    kudosReceivedCount: 0,
    kudosGivenCount: 0,
    joinedDate: '2024-01-10T09:49:14.916562+00:00',
    isActive: true,
    role: Roles.ADMIN,
  },
  {
    personalityTrait: 'Nurturer',
    jobTitle: 'Sr Designer',
    manager: null,
    name: 'Will Smith',
    picture: '/static/demo-account/people/person6.png',
    team: {
      uuid: 'e049a182-37f6-4e6d-bf1b-216ee9ed3cb6',
      name: 'Design Team',
      colorHex: '#fefefe',
    },
    userProfileUuid: '76026980-81d0-4180-a3fa-05a6215ce063',
    workEmail: 'will@instill.ai',
    kudosReceivedCount: 0,
    kudosGivenCount: 0,
    joinedDate: '2024-01-10T09:49:14.916562+00:00',
    isActive: true,
    role: Roles.ADMIN,
  },
  {
    personalityTrait: 'Sage',
    jobTitle: 'Sr HR',
    manager: null,
    name: 'Walter White',
    picture: '/static/demo-account/people/person5.png',
    team: {
      uuid: 'e049a182-37f6-4e6d-bf1b-216ee9ed3cb6',
      name: 'HR Team',
      colorHex: '#fefefe',
    },
    userProfileUuid: '76026980-81d0-4180-a3fa-055f215ce063',
    workEmail: 'walter@instill.ai',
    kudosReceivedCount: 0,
    kudosGivenCount: 0,
    joinedDate: '2024-01-10T09:49:14.916562+00:00',
    isActive: true,
    role: Roles.ADMIN,
  },
  {
    personalityTrait: 'Nurturer',
    jobTitle: 'Sr Software Engineer',
    manager: null,
    name: 'Sam Smith',
    picture: '/static/demo-account/people/person2.jpeg',
    team: {
      uuid: 'e049a182-37f6-4e6d-bf1b-216ee9ed3cb6',
      name: 'Development Team',
      colorHex: '#fefefe',
    },
    userProfileUuid: '76026980-81d0-4180-a3fa-05a6215g5063',
    workEmail: 'sam@instill.ai',
    kudosReceivedCount: 0,
    kudosGivenCount: 0,
    joinedDate: '2024-01-10T09:49:14.916562+00:00',
    isActive: true,
    role: Roles.ADMIN,
  },
  {
    personalityTrait: 'Nurturer',
    jobTitle: 'Sr Designer',
    manager: null,
    name: 'Will Smith',
    picture: '/static/demo-account/people/person6.png',
    team: {
      uuid: 'e049a182-37f6-4e6d-bf1b-216ee9ed3cb6',
      name: 'Design Team',
      colorHex: '#fefefe',
    },
    userProfileUuid: '76026980-81d0-4180-a3fa-05a6215ce063',
    workEmail: 'will@instill.ai',
    kudosReceivedCount: 0,
    kudosGivenCount: 0,
    joinedDate: '2024-01-10T09:49:14.916562+00:00',
    isActive: true,
    role: Roles.ADMIN,
  },
  {
    personalityTrait: 'Strategist',
    jobTitle: 'Product Manager',
    manager: null,
    name: 'Dan Johnson',
    picture: '/static/demo-account/people/person4.png',
    team: {
      uuid: 'ec85e407-f557-4194-8e35-81ad531f2a27',
      name: 'Product Team',
      colorHex: '#fefefe',
    },
    userProfileUuid: '47b47d84-972f-4b38-820d-67ed914ad858',
    workEmail: 'dan@instill.ai',
    kudosReceivedCount: 0,
    kudosGivenCount: 0,
    joinedDate: '2024-01-08T21:02:19.623151+00:00',
    isActive: true,
    role: Roles.MANAGER,
  },
  {
    personalityTrait: 'Nurturer',
    jobTitle: 'Sr Backend Engineer',
    manager: null,
    name: 'Ken Northwood',
    picture: '/static/demo-account/people/person3.png',
    team: {
      uuid: 'e049a182-37f6-4e6d-bf1b-216ee9ed3cb6',
      name: 'Development Team',
      colorHex: '#fefefe',
    },
    userProfileUuid: '76026980-81d0-4180-a3fa-35a6215ce063',
    workEmail: 'ken@instill.ai',
    kudosReceivedCount: 0,
    kudosGivenCount: 0,
    joinedDate: '2024-01-10T09:49:14.916562+00:00',
    isActive: true,
    role: Roles.MANAGER,
  },
];
