import {ComponentProps} from 'react';
import Input from '../input';
import Select from '../select';
import styles from './styles.module.scss';

interface ElementProps<T extends string> {
  options: {
    label: string;
    value: string;
  }[];
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  selectValue: T;
  onChangeSelect: (value: T) => void;
  inputProps?: ComponentProps<typeof Input>;
}

const InputWithSelect = <T extends string>({
  options,
  onChange,
  placeholder,
  value,
  onChangeSelect,
  selectValue,
  inputProps,
}: ElementProps<T>) => {
  return (
    <div className={styles.inputWithSelect}>
      <Input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        {...(inputProps ?? {})}
      />

      <div className={styles.selectContainer}>
        <Select
          variant="noOutline"
          value={selectValue}
          onChange={(e) => onChangeSelect(e.target.value as T)}
        >
          {options.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default InputWithSelect;
