import {createQuery} from 'react-query-kit';
import {CompanyAssessment} from '../../../interfaces/company-assessments';
import {fetchCompanyAssessments} from '../../../repositories/instill';
import {FetchCompanyAssessmentPayload} from '../../../repositories/instill/queries/fetch-company-assessments';
import {RQ_COMPANY_ASSESSMENTS} from './query-keys';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {dummyCompanyAssessments} from 'ui/@demo-data/company-assessment';

export const useCompanyAssessments = createQuery<
  CompanyAssessment[] | undefined,
  FetchCompanyAssessmentPayload
>({
  primaryKey: RQ_COMPANY_ASSESSMENTS,
  queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
    return await fetchCompanyAssessments(variables.companyUuid);
  }, dummyCompanyAssessments),
  enabled: (_, variables) => {
    return !!variables.companyUuid;
  },
});
