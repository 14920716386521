import {CSSProperties, FunctionComponent, PropsWithChildren} from 'react';
import styles from './styles.module.scss';

interface ElementProps {
  border?: boolean;
  shadow?: boolean;
  spacing?: 'small' | 'xSmall';
  expandFullHeight?: boolean;
  horizontalCenter?: boolean;
  verticalCenter?: boolean;
  minHeight?: boolean;
  style?: CSSProperties;
}

const BaseCard: FunctionComponent<PropsWithChildren<ElementProps>> = ({
  children,
  border = false,
  shadow = false,
  spacing,
  expandFullHeight = false,
  horizontalCenter = false,
  verticalCenter = false,
  minHeight = false,
  style,
}) => {
  let className = `${styles.cardContainer} `;

  if (border) {
    className += `${styles.border} `;
  }

  if (shadow) {
    className += `${styles.shadow} `;
  }

  if (expandFullHeight) {
    className += `${styles.expandFullHeight} `;
  }

  if (horizontalCenter) {
    className += `${styles.horizontalCenter} `;
  }

  if (verticalCenter) {
    className += `${styles.verticalCenter} `;
  }

  if (spacing) {
    className += styles[spacing];
  }

  if (minHeight) {
    className += styles.minHeight;
  }

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

export default BaseCard;
