import {
  Company,
  CompanyCreate,
  CompanyCreateRaw,
  CompanyProfileUpsert,
  CompanyProfileUpsertRaw,
  CompanyRaw,
} from '../../interfaces/company';
import {sanitizeCompanySettingsFromRaw} from './company-settings';

export const sanitizeCompanyFromRaw = (data: CompanyRaw): Company => {
  return {
    companyLogo: data.signed_company_logo_url,
    companySettings: sanitizeCompanySettingsFromRaw(data.company_settings),
    createdAt: data.created_at,
    creatorUuid: data.creator_uuid,
    emailDomain: data.email_domain,
    id: data.id,
    industry: data.industry,
    isActive: data.is_active ?? false,
    isAppInstalled: data.is_app_installed ?? false,
    isCultureDefined: data.is_defined_culture ?? false,
    isDashboardActive: data.is_dashboard_active ?? false,
    isOnboarded: data.is_onboarded,
    isSignatureRequiredOnValues: data.is_signature_required_on_values ?? true,
    leadershipManifesto: data.leadership_manifesto,
    mission: data.mission,
    name: data.name,
    size: data.size_range,
    updatedAt: data.updated_at ?? null,
    uuid: data.uuid,
    vision: data.vision,
    websiteUrl: data.website_url,
    slackTeamId: data.slack_team_id,
    hideSmallTeamScores: data.hide_small_team_scores,
  };
};

export const sanitizeCompaniesFromRaw = (data: CompanyRaw[]): Company[] => {
  return data.map(sanitizeCompanyFromRaw);
};

export const sanitizeCompanyProfileForRaw = (
  data: CompanyProfileUpsert
): CompanyProfileUpsertRaw => {
  return {
    name: data.name,
    industry: data.industry,
    size_range: data.size,
    website_url: data.websiteUrl,
    ...(data.companyLogo && {company_logo: data.companyLogo}),
  };
};

export const sanitizeCompanyCreateForRaw = (
  data: CompanyCreate
): CompanyCreateRaw => {
  return {
    name: data.name,
    size_range: data.sizeRange,
    ...(data.companyLogo && {company_logo: data.companyLogo}),
  };
};
