import isResponseSuccessful from 'utils/is-response-successful';
import InstillAPI from '../axios-instance';

export interface SendReminderToCandidatePayload {
  companyUuid: string;
  candidateUuid: string;
}

export const sendReminderToCandidate = async (
  parameters: SendReminderToCandidatePayload
) => {
  const {companyUuid, candidateUuid} = parameters;

  const response = await InstillAPI.post(
    `/companies/${companyUuid}/candidates/${candidateUuid}/remind`
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }
  return response;
};
