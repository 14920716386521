import {ReactNode} from 'react';
import {ReactComponent as InfoIcon} from 'ui/@_components/kit/icons/info.svg';
import Tooltip from '../tooltip';
import styles from './styles.module.scss';

interface ElementProps {
  children: ReactNode;
  content: string;
  jsxContent?: ReactNode;
}

const InfoTooltip = ({children, content, jsxContent}: ElementProps) => {
  return (
    <div className={styles.infoTooltip}>
      {children}
      <Tooltip content={jsxContent || content}>
        <InfoIcon className={styles.infoIcon} />
      </Tooltip>
    </div>
  );
};

export default InfoTooltip;
