export const EventName = {
  ACCOUNT_SET_UP: {
    CODIFY_YOUR_VALUES: {
      ADD_ANOTHER_VALUE:
        'Account Set Up – Codify Your Values – Add another value',
      EMBED_YOUTUBE:
        'Account Set Up – Codify Your Values – Embed Youtube Video',
      PREFIX: 'Account Set Up – Codify Your Values',
      SAVE_CONTINUE: 'Account Set Up – Codify Your Values – Save & Continue',
      SKIP_FOR_NOW: 'Account Set Up – Codify Your Values – Skip for now',
      UPLOAD_IMAGE_FROM_COMPUTER:
        'Account Set Up – Codify Your Values – Upload Image from computer',
      UPLOAD_MEDIA:
        'Account Set Up – Codify Your Values – Upload Media or a Training Module',
    },
  },
  ASSESSMENTS: {
    RETURN_TO_DASHBOARD: 'Assessments – Return to dashboard',
  },
  APPLY: {
    ACTION_STEP_STATUS: {
      CLEAR_SEARCH: 'Apply – Action Step Status – Clear search',
      SEARCH: 'Apply – Action Step Status – Search',
    },
  },
  CANDIDATES: {
    REGISTER: {
      INVALID_TOKEN: 'Candidates – Register – Invalid token',
    },
    ASSESSMENT: {
      QUESTION_ANSWERED: 'Candidates – Assessment – Question answered',
    },
  },
  COMPANY_REGISTRATION: {
    ENTER_YOUR_NAME: 'Company Registration – Enter your name',
    TELL_US_ABOUT_COMPANY:
      'Company Registration – Tell us a bit about your company',
    PERSONAL_INFORMATION: 'Company Registration – Personal Information',
  },
  COMPONENTS: {
    INTERACTIVE_VALUES: {
      CREATE_NEW_VALUE: 'Interactive Values – Create new value',
      EMBED_YOUTUBE_VIDEO:
        'Interactive Values – New Value dialog – Embed YouTube Video',
      SAVE_VALUE: 'Interactive Values – New Value dialog – Save value',
      UPLOAD_IMAGE_FROM_COMPUTER:
        'Interactive Values – New Value dialog – Upload Image from computer',
    },
    HIERARCHY_EDIT: {
      ADD_DIRECT_MANAGER: 'Hierarchy Edit – Add direct manager',
      ADD_DIRECT_REPORT: 'Hierarchy Edit – Add direct report',
      ADD_JOB_TITLE: 'Hierarchy Edit – Add job title',
    },
    NOTIFICATION_CENTER: {
      SHOW_NOFICIATIONS: 'Notification Center – Show notifications',
      LOAD_MORE: 'Notification Center – Load more',
    },
    KUDOS: {
      RECOGNIZE_COLLEAGUE: 'Kudos – Recognize a colleague',
      SEND_KUDO_TO_COLLEAGUE: 'Kudos – Send kudo to a colleague',
      CANCEL_KUDO: 'Kudos – Cancel kudo',
      HIDE_KUDO: 'Kudos – Hide kudo',
      UNHIDE_KUDO: 'Kudos – Unhide kudo',
    },
    UPLOAD_MEDIA: {
      EMBED_YOUTUBE_VIDEO: 'Upload Media Component – Embed Youtube Video',
      UPLOAD_IMAGE_FROM_COMPUTER:
        'Upload Media Component – Upload Image from computer',
    },
  },
  DESIGN: {
    LEADERSHIP_MANIFESTO: 'Design – Leadership Manifesto',
    MISSION: 'Design – Mission',
    VISION: 'Design – Vision',
  },
  HOME: {
    CULTURE_CHECKLIST: {
      LINK_CLICKED: 'Home – Culture Checklist – Link clicked',
    },
    MEETINGS_LIST: {
      LINK_CLICKED: 'Home – Meetings List – Link clicked',
    },
    PLAYBOOKS: {
      LEARN_MORE: 'Home – Playbooks Just For You – Learn more',
      LINK_CLICKED: 'Home – Playbooks Just For You – Link clicked',
    },
  },
  ACTION_STEPS: {
    PAGE_VIEW: 'Page View – Action Steps',
    ACTION_STEP_IMPROVE: 'ActionSteps_ImpScore_Clicked',
    ACTION_STEP_SET_REMINDER: 'ActionSteps_SetReminder_Clicked',
    ACTION_STEP_COMPLETE: 'ActionSteps_Complete_Clicked',
    DETAILS: 'Action Steps – Details',
    NAVIGATE_FROM_DASHBOARD: 'Dashboard – Action Steps',
    NAVIGATE_FROM_DASHBOARD_HIGHLIGHT: 'Dashboard – Action Steps – Details',
    ACTION_STEP_DETAIL_DIALOG: {
      CLOSE_DIALOG: 'Action Steps – Action Step Dialog – Close',
      OPEN_CONFIRM_DIALOG:
        'Action Steps – Action Step Dialog – Open Confirm Dialog',
      COMPLETE_DIALOG: {
        COMPLETE:
          'Action Steps – Action Step Dialog – Complete Dialog – Complete',
        CANCEL: 'Action Steps – Action Step Dialog – Complete Dialog – Cancel',
      },
    },
  },
  JOBS: {
    CANDIDATES: {
      ADD_CANDIDATE: 'Jobs – Candidates – Add candidate',
      EDIT_CANDIDATE: 'Jobs – Candidates – Edit Candidate',
      EDIT_CANDIDATE_DIALOG: {
        UPDATE_CANDIDATE: 'Jobs – Candidates – Edit Candidate – Update',
        CLOSE_DIALOG: 'Jobs – Candidates – Edit Candidate – Cancel',
      },
      CANDIDATE_RESULT_DIALOG: {
        OPEN_DIALOG: 'Jobs – Candidates – Candidate Result Dialog – Open',
      },
      CANDIDATE_DIALOG: {
        FORM: {
          CLOSE_DIALOG:
            'Jobs – Candidates – Initiate Candidate Assessment – Cancel',
          GO_TO_SUMMARY:
            'Jobs – Candidates – Initiate Candidate Assessment – Next',
        },
        SUMMARY: {
          INITIATE_ASSESSMENT:
            'Jobs – Candidates – Confirmation – Initiate Assessment',
          UPDATE_ASSESSMENT_DETAILS:
            'Jobs – Candidates – Confirmation – Update Assessment Details',
        },
      },
    },
  },
  PAGE_VIEWS: {
    APPLY: {
      ACTION_STEPS: 'Page View – Apply – Action Steps',
      ACTION_STEP_STATUS: 'Page View – Apply – Action Step Status',
    },
    DASHBOARD: 'Page View – Dashboard',
    CANDIDATES: {
      REGISTER: 'Page View – Candidates – Register',
      ASSESSMENT: 'Page View – Candidates – Assessment',
      FEEDBACK: 'Page View – Candidates – Feedback',
    },
    VALUE: {
      INDEX: 'Page View – Value',
    },
    DESIGN: 'Page View – Design',
    JOBS: {
      JOB_POSITIONS: 'Page View – Jobs – Job Positions',
      CANDIDATES: 'Page View – Jobs – Candidates',
    },
    KUDOS: 'Page View – Kudos',
    MY_PROFILE: 'Page View – My Profile',
    LINK_SLACK: 'Page View – Link Slack',
    MEASURE: {
      OVERALL_HEALTH: 'Page View – Measure – Overall Health',
      YOUR_ASSESSMENTS: 'Page View – Measure – Your Assessments',
      YOUR_REPORTS: 'Page View – Measure – Your Reports',
      MANAGE_ASSESSMENTS: {
        INDEX: 'Page View – Measure – Manage Assessments – List',
        SHOW: 'Page View – Measure – Manage Assessments – Events',
      },
    },
    ORGANIZATION_SETUP: 'Page View – Organization Setup',
    PEOPLE: {
      EMPLOYEES: {
        INDEX: 'Page View – People – Employees – List',
        SHOW: 'Page View – People – Employees – Show',
      },
      TEAMS: 'Page View – People – Teams',
      MANAGE_DIRECTORY: 'Page View – People – Manage Directory',
    },
    SETUP: 'Page View - Setup',
    FLOW: {
      MEETINGS: {
        INDEX: 'Page View - Flow - Meetings - List',
        SHOW: 'Page View - Flow - Meetings - Show',
      },
    },
    USER_SETUP: 'Page View - User Setup',
    SETTINGS: {
      CONFIGURATIONS: 'Page View – Settings – Configurations',
      MANAGE_COMPANY_PROFILE: 'Page View – Settings – Manage Company Profile',
    },
    ADMIN_PORTAL: 'Page View – Admin Portal',
    CULTURE: {
      INDEX: 'Page View - Values Dashboard',
      CREATE: {
        INDEX: 'Page View - CultureEdit',
      },
    },
    ASSESSMENTS_POLlS: {
      MANAGE: {
        INDEX: 'Page View - ManageAssessment',
      },
      MY_ASSESSMENTS: {
        INDEX: 'Page View - MyAssessment',
      },
      ASSESSMENTS: {
        INDEX: 'Page View - AssessmentPage',
        COMPLETED: {
          INDEX: 'Page View - Assessment Complete',
        },
      },
      ASSESSMENTS_SUMMARY: {
        INDEX: 'Page View - Report',
      },
    },
  },
  MEASURE: {
    MANAGE_ASSESSMENTS: {
      EVENTS: {
        VIEW_REPORT: 'Measure – Manage Assessments – Events – View Report',
        DOWNLOAD_REPORT:
          'Measure – Manage Assessments – Events – Download Report',
      },
      POPULATION_DIALOG: 'Measure – Manage Assessments – Population Dialog',
      SEND_ASSESSMENT: 'Measure – Manage Assessments – Send assessment',
      SEND_MESSAGE:
        'Measure – Manage Assessments – Send message dialog – Send message',
      TURN_OFF_ASSESSMENT: 'Measure – Manage Assessments – Turn off assessment',
      UPDATE_SETTINGS:
        'Measure – Manage Assessments – Update setting dialog – Update',
      VIEW_EVENTS: 'Measure – Manage Assessments – View Events',
    },
    YOUR_REPORTS: {
      VIEW_RESULT: 'Measure – Your Reports – View Results',
    },
    YOUR_ASSESSMENTS: {
      VIEW_RESULT: 'Measure – Your Assessments – View Results',
      START_ASSESSMENT: 'Measure – Your Assessments – Start assessment',
    },
  },
  PEOPLE: {
    PAGE_VIEW: 'Page View - People and Teams _People',
    INVITE_PEOPLE: 'InvitePeople_Clicked',
    PEOPLE_INVITED: 'People_Invited',
    INVITE_REMIND: 'Invite_Remind_Clicked',
    CHANGE_ROLE: 'Change_Role_Clicked',
    CHANGED_ROLE: 'Role_Changed',
    SHOW: {
      LANDED: 'PersonalProfile_Loaded',
    },
    EMPLOYEES: {
      CLEAR_SEARCH: 'People – Employees – Clear search',
      PROFILE_CLICK_MANAGER_COLUMN:
        'People – Employees – Manager column – Profile',
      PROFILE_CLICK_NAME_COLUMN: 'People – Employees – Name column – Profile',
      SEARCH: 'People – Employees – Search',
    },
    MANAGE_DIRECTORY: {
      CLEAR_SEARCH: 'People – Manage Directory – Clear search',
      INVITE_USERS: 'People – Manage Directory – Invite users',
      PERMISSIONS_CHANGES: 'People – Manage Directory – Permissions changes',
      PROFILE_CLICK_MANAGER_COLUMN:
        'People – Manage Directory – Manager column – Profile',
      PROFILE_CLICK_NAME_COLUMN:
        'People – Manage Directory – Name column – Profile',
      SEARCH: 'People – Manage Directory – Search',
      SEND_INVITES: 'People – Manage Directory – Invite users – Send invites',
      SEND_REMINDER: 'People – Manage Directory – Send reminder',
      STATUS_CHANGES: 'People – Manage Directory – Active/Inactive changes',
    },
    TEAMS: {
      CREATE_TEAM: 'Createteam_clicked',
      TEAM_CREATED: 'Team_Created',
      TEAM_DELETED: 'Team_Deleted',
      SHOW: {
        PAGE_VIEW: 'Page View - Team_page',
        EDIT_TEAM: 'EditTeam_Clicked',
        TEAM_EDITED: 'Team_Edited',
        REMOVE_USER: 'Team_Removeuser_Removed',
        MOVE_TO_ANOTHER_TEAM: 'Team_MoveUser_Clicked',
        MOVED_TO_ANOTHER_TEAM: 'Team_MoveUser_Moved',
        OFF_BOARD_USER: 'Team_User_offboard_Clicked',
        OFF_BOARDED_USER: 'Team_User_Offboarded',
      },
      PAGE_VIEW: 'Page View - People and Teams _Teams',
      CLEAR_SEARCH: 'People – Teams – Clear search',
      SEARCH: 'People – Teams – Search',
      DETAILS_DIALOG: {
        OPEN_DIALOG: 'People – Teams – Details Dialog – Open',
        CLOSE_DIALOG: 'People – Teams – Details Dialog – Cancel',
        UPDATE_TEAM: 'People – Teams – Details Dialog – Update',
        DELETE_TEAM: 'People – Teams – Details Dialog – Delete',
        PROFILE: 'People – Teams – Details Dialog – Profile',
      },
    },
  },
  FLOW: {
    PAGE_VIEW: 'Page View - Instill Sense',
    NEW_MEETING: 'Instill_Sense_new_meeting_clicked',
    NEW_MEETING_ADDED: 'Instill_Sense_new_meeting_added',
    MEETING_CLICK: 'Instill_Sense_meeting_clicked',
    MEETING_SEARCH: 'Instill_Sense_search',
    REPORT: {
      PAGE_VIEW: 'Page View - Instill Sense - Meeting Report',
      REPORT_SHARE: 'Instill_Sense_report_share',
      REPORT_FEEDBACK: 'Instill_Sense_report_feedback',
    },
    INDEX: {
      INVITE_FLOW: 'Flow – Meetings – Index – Invite Flow ',
    },
    SHOW: {
      MEETING_DETAILS: {
        COMPLETE_TRANSCRIPT:
          'Flow – Meetings – Show – Meeting Details – Complete Transcript',
      },
    },
  },
  ADMIN_PORTAL: {
    COMPANY_LIST: {
      CLEAR_SEARCH: 'Company List – Clear search',
      CREATE_NEW_COMPANY: 'Admin Portal – Company List – Create New Company',
      SAVE_COMPANY:
        'Admin Portal - Company List - New Company dialog – Save Company',
      SEARCH: 'Company List – Search',
    },
  },
  PREFIXES: {
    HOME: 'Home',
    VALUES_SHOW: 'Interactive Values – Details',
  },
  PROFILE: {
    ORGANIZATION_STRUCTURE: {
      ADD_DIRECT_MANAGER:
        'Profile Details – Organization Structure – Add direct manager',
      ADD_DIRECT_REPORT:
        'Profile Details – Organization Structure – Add direct report',
      ADD_JOB_TITLE: 'Profile Details – Organization Structure – Add job title',
    },
  },
  VALUES: {
    SHOW: {
      DELETE_VALUE: 'Interactive Values – Details – Delete value',
      EDIT_VALUE: 'Interactive Values – Details – Edit value',
    },
  },
  CULTURE: {
    INDEX: {
      EXAMPLE_CLICK: 'Values_Dashboard_example_clicked',
      CODIFY_CULTURE_CLICK: 'Values_Dashboard_CodifyCulture_Clicked',
      EXPLORE_CARD_EDIT_CLICK: 'Edit_values_CTA_clicked',
    },
    SHOW: {},
    CREATE: {
      INDEX: {
        VALUE_SUBMIT_CLICK: 'Values_Dashboard_CultureEdit_Value_Submit_clicked',
        VALUE_PUBLISH_CLICK: 'Values_Dashboard_CultureEdit_Value_Published',
        ADD_NEW_VALUE_CLICK: 'Values_Dashboard_CultureEdit_add_Value_clicked',
        GET_HELP_CLICK: 'Values_Dashboard_Manual_GetHelp_clicked',
        VISION_MISSION_SUBMIT_CLICK:
          'Values_Dashboard_Manual_MissionVission_Submit_clicked',
      },
      CHATBOT: {
        ADD_MANUALLY_CLICK: 'Values_Dashboard_CodifyCulture_add_manually',
        CHATBOT_CLICK: 'Values_Dashboard_CodifyCulture_Bot',
      },
    },
  },
  GETTING_STARTED: {
    PAGE_VIEW: 'Page View - Getting Started',
    SAMPLE_REPORT_CLICK: 'Getting_started_samplereport_clicked',
    STARTED_SUCCESS_AI: 'Getting_started_success_AI_addedd',
    CALENDAR_SYNCED: 'Calendar_Synced',
  },
  DASHBOARD: {
    INDEX: {
      STEPS: {
        DISMISS: 'Getting_Started_Dismissed',
        COMPANY_PROFILE: {
          CLICK: 'CompanyProfile_clicked',
          SELECT: 'CompanyProfile_Selected',
        },
        BUSINESS_OUTCOMES: {
          CLICK: 'Dashboard_BusinessOutcome_clicked',
          SELECT: 'Dashboard_BusinessOutcome_Selected',
        },
        COMPANY_VALUES: {
          CLICK: 'Dashboard_SetupCompanyValues_clicked',
        },
        INVITE_TEAM: {
          CLICK: 'Dashboard_TeamInvite_clicked',
          INVITE: 'Dashboard_TeamInvite_Invited',
        },
        FLOW: {
          CLICK: 'Dashboard_InstillFlow_clicked',
          INVITE: 'Dashboard_InstillFlow_Invited',
        },
        INTEGRATION: {
          CLICK: 'Integrations',
        },
      },
      CULTURE_VITAL_SIGN: {
        INTERVAL_CHANGE: 'Dashboard_CultureVitalSign_ChartIntervalChanged',
        CLICK: 'Dashboard_CultureVital_clicked',
      },
      BUSINESS_OUTCOMES: {
        CLICK: 'Dashboard_BusinessOutcome_Edit_clicked',
        EDIT: 'Dashboard_BusinessOutcome_Edited',
      },
      CANDIDATE_CALIBRATION: {
        CLICK: 'Dashboard_CandidateCalibration_Clicked',
      },
    },
    CULTURE_VITALS_SHOW: {
      PAGE_VIEW: 'Page View - CultureVitalSign',
      TAKE_ASSESSMENT_OR_SEE_REPORT: 'CultureVitalSign_Assessment_clicked',
    },
  },
  ASSESSMENTS_POLlS: {
    MANAGE_ASSESSMENTS: {
      TURN_ON: 'Assessment_TurnOn_clicked',
      TURN_ON_CONFIRM: 'Assessment_TurnedOn_clicked',
      REMIND: 'Assessment_Remind_Clicked',
      REMIND_CONFIRM: 'Assessment_Remind_Sent',
    },
    MY_ASSESSMENTS: {
      CONTINUE: 'MyAssessment_Continue',
      REPORT: 'MyAssessment_report',
    },
    ASSESSMENTS: {
      BACK: 'AssessmentPage_Back_clicked',
    },
    ASSESSMENTS_SUMMARY: {
      DOWNLOAD: 'AssessmentReport-Download',
    },
    ASSESSMENT_POPULATION: {
      PAGE_VIEW: 'Page View - Assessment_Details',
      TAKE_ASSESSMENT: 'Take_The_Assessment_Clicked',
      REMIND_ASSESSMENT: 'Assessment_Remind_clicked',
      REMIND_ALL: 'Assessment_Remind_all_clicked',
      REMINDER_SENT: 'Assessment_Reminder_Sent',
    },
  },
  ONBOARDING: {
    GO_TO_DASHBOARD: 'Verification_Page_GoToDashboard_Clicked',
    EMAIL_VERIFIED: 'Email_Verified',
    VERIFY_EMAIL: 'Verify_email',
    RESEND_VERIFY_EMAIL: 'Resend_Verify_email',
    COMPANY_REGISTERED: 'Company_bg_submitted',
    SIGNUP: 'Signup_Email',
    SIGNIN: 'Signin',
    SIGNUP_SSO: 'Signup_sso',
  },
  CANDIDATE_CALIBRATION: {
    COMMON: {
      SORT: 'Candidate_Calibration_sort',
      FILTER: 'Candidate_Calibration_filter',
      SEARCH: 'Candidate_Calibration_search',
    },
    JOB_POSITIONS_INDEX: {
      PAGE_VIEW: 'Page View - Candidate Calibration',
      CREATE_JOB_POSITION: 'Create_job_clicked',
      JOB_CREATED: 'Job_created',
      JOB_EDITED: 'Job_edited',
      JOB_ARCHIVED: 'Job_archived',
      JOB_REOPENED: 'Job_reopened',
      JOB_DELETED: 'Job_deleted',
    },
    JOB_POSITIONS_SHOW: {
      INDEX: {
        PAGE_VIEW: 'Page View - Candidate Calibration - Job Overview',
        SEND_ASSESSMENT_CLICK: 'Send_Assessment_clicked',
        ASSESSMENT_SENT: 'Assessment_sent',
        CANDIDATE_ASSESSMENT_ACTION: 'Candidate_assessment_action',
        CANDIDATE_DETAILS_EDITED: 'Candidate_details_edited',
        CANDIDATE_DELETED: 'Candidate_deleted',
      },
      REPORT: {
        PAGE_VIEW: 'Page View - Candidate Calibration - Report',
        REPORT_DELETED: 'Candidate_Calibration_Report_deleted',
        REPORT_SHARED: 'Candidate_Calibration_Report_share',
        REPORT_EXPORT: 'Candidate_Calibration_Report_exportPDF',
        REPORT_COMPARE: 'Candidate_Calibration_Report_compare_clicked',
        REPORT_COMPARE_SELECTED:
          'Candidate_Calibration_Report_compare_candidate selected',
      },
    },
  },
  PLANS: {
    INDEX: {
      PLAN_PAGE_SUBSCRIBE_CLICK: 'PlanPage_Subscribe_clicked',
      PAGE_VIEW_PLAN_PAGE: 'Page View - PlanPage',
    },
    CHECKOUT: {
      CHECKOUT_PAGE_BACK: 'CheckoutPage_Back_CTA_clicked',
      PAGE_VIEW_CHECKOUT: 'Page View - Checkout',
    },
  },
  SETTINGS: {
    SECURITY: {
      ANONYMITY_FILTER: 'Anonymity Filter _ update',
      PAGE_VIEW_SECURITY: 'Page View - Security',
    },
    MY_PROFILE: {
      PAGE_VIEW: 'Page View - Settings_MyProfile',
      EDIT: 'MyProfile_edit_clicked',
    },
    COMPANY_PROFILE: {
      PAGE_VIEW: 'Page View - Settings_CompanyProfile',
      EDIT: 'CompanyProfile_edit_clicked',
      UPDATED: 'CompanyProfile_updated',
    },
    FLOW: {
      PAGE_VIEW: 'Page View - Instill Flow',
    },
    BILLING: {
      BILLING_OVERVIEW_CLICK: 'Billing_BillingOverview_clicked',
      BILLING_GET_MORE_CREDITS: 'Billing_GetMoreCredits_clicked',
      BILLING_MANAGE_SUBSCRIPTION_CLICK: 'Billing_ManageSubscription_clicked',
      BILLING_BUY_NEW: 'Billing_BuyNew_clicked',
      PAGE_VIEW_BILLING: 'Page View - Billing',
    },
  },
  WELCOME_MODAL: {
    EXPLORE_CLICKED: 'Start_Exploring_clicked',
  },
  USER_SETUP: {
    SUBMIT: 'User_detail_submitted',
  },
  PAGE_PLAN_ACCESS_MODAL: {
    UPGRADE_PLAN_CLICK: 'UpgradeYourPlan_CTA_Clicked',
  },
} as const;
