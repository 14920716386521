import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import TextCard from 'ui/@_components/card/text-card';
import Dialog from 'ui/@_components/kit/dialog';
import ItemsList from './@components/items-list';

interface ElementProps {
  participants: string[];
}

const Participants = ({participants}: ElementProps) => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.components.meeting-details.components.participants',
  });

  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const onOpenDialog = () => {
    setIsDialogVisible(true);
  };

  const onCloseDialog = () => {
    setIsDialogVisible(false);
  };

  const trimmedParticipants = participants.slice(
    0,
    Math.min(3, participants.length)
  );

  return (
    <>
      <TextCard>
        <TextCard.Header
          title={t('title')}
          trailingCta={{
            title: t('see-all-button'),
            onClick: onOpenDialog,
          }}
        />

        <TextCard.Content>
          <ItemsList participants={trimmedParticipants} />
        </TextCard.Content>
      </TextCard>

      <Dialog isOpen={isDialogVisible} onClose={onCloseDialog}>
        <Dialog.Header title={t('title')} />

        <Dialog.Content>
          <ItemsList participants={participants} />
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default Participants;
