import {ComponentProps, ReactNode} from 'react';
import styles from './styles.module.scss';

interface ElementProps extends ComponentProps<'button'> {
  children: ReactNode;
}

const IconButton = ({children, ...props}: ElementProps) => {
  return (
    <button className={styles.iconButton} type="button" {...props}>
      {children}
    </button>
  );
};

export default IconButton;
