import AudioPlayer from 'ui/@_components/audio-player';
import Spinner from 'ui/@_components/spinner';
import useInDesktop from 'ui/@hooks/use-in-desktop';
import AddValue from 'ui/@module-components/page-banners/add-value';
import ConfigureAssessments from 'ui/@module-components/page-banners/configure-assessments';
import InviteTeamBanner from 'ui/@module-components/page-banners/invite-team-banner';
import Personality from 'ui/@module-components/page-banners/personality';
import useFlowData from '../../@hooks/use-flow-data';
import CultureVitals from './@components/culture-vitals';
import CustomCultureKpi from './@components/custom-culture-kpi';
import InfoSummary from './@components/info-summary';
import KeyTakeaways from './@components/key-takeaways';
import MeetingFeedback from './@components/meeting-feedback';
import Overview from './@components/overview';
import Transcript from './@components/transcript';
import Values from './@components/values';
import styles from './styles.module.scss';

const MeetingDetails = () => {
  const {meeting, isCompanySelected, transcriptIsLoading} = useFlowData();

  const isDesktop = useInDesktop();

  if (meeting?.summary.summary.length === 0) {
    return <Spinner />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.mainColumn}>
        {isCompanySelected && <InviteTeamBanner />}

        {isCompanySelected && <AddValue />}

        {isCompanySelected && <Personality />}

        {isCompanySelected && <ConfigureAssessments />}

        <InfoSummary />

        <Overview />

        <KeyTakeaways />

        {isCompanySelected && isDesktop && <MeetingFeedback />}

        {!transcriptIsLoading && isDesktop && <Transcript />}

        {!!meeting?.presignedAudioUrl && (
          <div className={styles.audioPlayer}>
            <AudioPlayer
              trackName={meeting.name}
              audioUrl={meeting.presignedAudioUrl}
            />
          </div>
        )}
      </div>

      <div className={styles.sideColumn}>
        <CultureVitals />

        <CustomCultureKpi />

        <Values />

        {!transcriptIsLoading && !isDesktop && <Transcript />}

        {isCompanySelected && !isDesktop && <MeetingFeedback />}
      </div>
    </div>
  );
};

export default MeetingDetails;
