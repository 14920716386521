import {DemoAccountType, demoAccountType} from 'constants/demo-account';
import {DEMO_ACCOUNT_TYPE} from 'constants/local-storage-keys';
import {useCallback} from 'react';
import {useCurrentAuthUserState} from 'ui/@atoms/current-auth-user';
import {dummyAuthUser} from 'ui/@demo-data/auth-user';

const useDemoAccount = () => {
  const [, setCurrentAuthUser] = useCurrentAuthUserState();

  const startDemoAccount = useCallback(
    (demoAccountType: DemoAccountType) => {
      setCurrentAuthUser(dummyAuthUser);

      sessionStorage.setItem(DEMO_ACCOUNT_TYPE, demoAccountType);
    },
    [setCurrentAuthUser]
  );

  const endDemoAccount = useCallback(() => {
    sessionStorage.removeItem(DEMO_ACCOUNT_TYPE);
  }, []);

  return {
    endDemoAccount,
    isDemoAccount: !!demoAccountType,
    startDemoAccount,
    demoAccountType,
  };
};

export default useDemoAccount;
