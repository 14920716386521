import {Field, FieldProps, Form, Formik} from 'formik';
import Dialog from 'ui/@_components/kit/dialog';
import UploadArea from 'ui/@_components/kit/form/upload-area/upload-area';
import styles from './styles.module.scss';
import {fetchPresignedUploadURLResourceType} from 'repositories/instill/queries/fetch-presigned-url-config';
import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';
import {useCompany} from 'ui/@hooks/queries';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import signedToUnsignedUrl from 'utils/url-utils/signed-to-unsigned-url';
import {useUpdateCompany} from 'ui/@hooks/mutations';
import {sanitizeCompanyProfileForRaw} from 'ui/@sanitizers/company';
import {toast} from 'react-toastify';
import Button from 'ui/@_components/button';
import apiErrorGuard from 'utils/error-utils/api-error-guard';

interface ElementProps {
  isOpen: boolean;
  onClose: () => void;
}

interface FormValues {
  companyLogo: string;
}

const CompanyLogoModal = ({isOpen, onClose}: ElementProps) => {
  const companyUuid = useSafeCurrentCompanyUuid();
  const {t} = useTranslation('moduleComponents', {
    keyPrefix: 'modals.company-logo-modal',
  });

  const currentCompany = useCompany({
    variables: {
      companyUuid,
    },
  });

  const initialValues: FormValues = {
    companyLogo: currentCompany.data?.companyLogo ?? '',
  };

  const updateCompany = useUpdateCompany();

  const validationSchema = Yup.object()
    .shape({
      companyLogo: Yup.string(),
    })
    .defined();

  const onFormSubmit = async (values: FormValues) => {
    const payload = sanitizeCompanyProfileForRaw({
      industry: currentCompany.data?.industry || '',
      companyLogo: signedToUnsignedUrl(values.companyLogo),
      name: currentCompany.data?.name || '',
      size: currentCompany.data?.size || '',
    });

    try {
      await updateCompany.mutateAsync({
        payload,
        companyUuid,
      });

      toast.success(t('success'));
    } catch (error) {
      apiErrorGuard(() => {
        toast.error(t('error'));
      })(error);
    }

    onClose();
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <Dialog.Header title={t('title')} isCloseButtonVisible={false} />

      <Dialog.Content>
        <div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount
            onSubmit={onFormSubmit}
          >
            {({values, dirty, isValid}) => (
              <Form>
                <Field name="companyLogo">
                  {({form}: FieldProps<string>) => (
                    <UploadArea
                      title={t('upload-title')}
                      acceptedFileTypes={['.jpg', '.jpeg', '.png']}
                      files={
                        values.companyLogo
                          ? [{url: values.companyLogo, type: 'image'}]
                          : []
                      }
                      setFiles={(companyLogo) => {
                        if (companyLogo[0]) {
                          form.setFieldValue('companyLogo', companyLogo[0].url);
                        } else {
                          form.setFieldValue('companyLogo', '');
                        }
                      }}
                      resource={
                        fetchPresignedUploadURLResourceType.COMPANY_LOGO
                      }
                      companyUuid={companyUuid}
                    />
                  )}
                </Field>

                <div className={styles.buttonsWrapper}>
                  <Button variant="secondary" fullWidth onClick={onClose}>
                    {t('cancel')}
                  </Button>

                  <Button type="submit" fullWidth disabled={!dirty || !isValid}>
                    {t('save')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Dialog.Content>
    </Dialog>
  );
};

export default CompanyLogoModal;
