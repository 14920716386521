import {atom, useRecoilState, useRecoilValue} from 'recoil';
import {AuthUser} from '../../interfaces/auth-user';

const currentAuthUserState = atom<AuthUser | null>({
  key: 'currentAuthUser',
  default: null,
});

/**
 * @deprecated Use useFullUserProfile or useCurrentAuthUser from 'hooks/queries' instead of this
 */
export function useCurrentAuthUserState() {
  return useRecoilState(currentAuthUserState);
}

/**
 * @deprecated Use useFullUserProfile or useCurrentAuthUser from 'hooks/queries' instead of this
 */
export function useCurrentAuthUser() {
  return useRecoilValue(currentAuthUserState);
}
