import {createMutation} from 'react-query-kit';
import {upsertAssessmentResponse} from '../../../repositories/instill';
import {UpsertAssessmentResponsePayload} from '../../../repositories/instill/mutations/upsert-assessment-response';
import {useQueryClient} from '@tanstack/react-query';
import {
  RQ_COMPANY_ASSESSMENTS,
  RQ_USER_ASSESSMENT,
  RQ_USER_ASSESSMENTS,
} from '../queries/query-keys';

export const useUpsertAssessmentResponse = () => {
  const queryClient = useQueryClient();

  return createMutation<
    {value: string} | undefined,
    UpsertAssessmentResponsePayload
  >({
    mutationFn: async (variables) => {
      return await upsertAssessmentResponse(
        {value: variables.value},
        variables.userAssessmentUuid,
        variables.moduleUuid,
        variables.questionUuid
      );
    },
    onSuccess() {
      queryClient.invalidateQueries([RQ_USER_ASSESSMENT]);
      queryClient.invalidateQueries([RQ_USER_ASSESSMENTS]);
      queryClient.invalidateQueries([RQ_COMPANY_ASSESSMENTS]);
    },
  })();
};
