export enum Actions {
  ADD_EMPLOYEE,
  ADD_TEAM,
  ADD_VALUE,
  DELETE_TEAM,
  DELETE_VALUE,
  EDIT_MISSION_VISION_MANIFESTO,
  EDIT_ORGANIZATION_STRUCTURE,
  EDIT_TEAM,
  EDIT_VALUE,
  EDIT_BUSINESS_OUTCOMES,
  INVITE_ADMIN,
  INVITE_OWNER,
  INVITE_MANAGER,
  UPDATE_KUDO_VISIBILITY,
  UPDATE_ROLE_TO_ADMIN,
  UPDATE_ROLE_TO_MANAGER,
  UPDATE_ROLE_TO_MEMBER,
  UPDATE_ROLE_TO_OWNER,
  VIEW_COMPANY_SETTINGS,
  VIEW_CANDIDATE_CALIBRATION,
  VIEW_MANAGE_ASSESSMENTS,
  VIEW_MANAGE_DIRECTORY,
  VIEW_ACTION_STEP_STATUS,
  VIEW_BUSINESS_OUTCOMES,
  VIEW_DASHBOARD_STEPS,
  EDIT_COMPANY,
  VIEW_INTEGRATIONS,
  UPDATE_PLAN,
  VIEW_BILLING,
  EDIT_INSTILL_FLOW_SETTINGS,
  VIEW_CULTURE_KPI,
  MODIFY_CULTURE_KPI,
}
