import {AddonRequests, Features} from 'constants/plan-access/features';
import {Field, FieldProps, Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import Button from 'ui/@_components/button';
import CardCurvedDesign from 'ui/@_components/card-curved-design';
import Dialog from 'ui/@_components/kit/dialog';
import FormControl from 'ui/@_components/kit/form/form-control';
import Input from 'ui/@_components/kit/form/input';
import Label from 'ui/@_components/kit/form/label';
import Textarea from 'ui/@_components/kit/form/textarea';
import ActionStepsPreviewUrl from 'ui/@_components/kit/images/preview/action-steps-preview.png';
import CandidateCalibrationReportPreviewUrl from 'ui/@_components/kit/images/preview/candidate-calibration-report-preview.png';
import CultureVitalsWidgetPreviewUrl from 'ui/@_components/kit/images/preview/culture-vitals-widget-preview.png';
import InstillCultureIndexWidgetPreviewUrl from 'ui/@_components/kit/images/preview/instill-culture-index-widget-preview.png';
import InstillFlowPreviewUrl from 'ui/@_components/kit/images/preview/instill-flow-preview.png';
import ValuesWidgetPreviewUrl from 'ui/@_components/kit/images/preview/values-widget-preview.png';
import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';
import {useFeatureRequest} from 'ui/@hooks/mutations/use-feature-request';
import {useSeatsRequest} from 'ui/@hooks/mutations/use-seats-request';
import apiErrorGuard from 'utils/error-utils/api-error-guard';
import * as Yup from 'yup';
import styles from './styles.module.scss';

interface RequestFormValues {
  message: string;
  seatsRequested: number;
}

const initialRequestValues: RequestFormValues = {
  message: '',
  seatsRequested: 1,
};

type RequestType =
  | Features.CANDIDATE_CALIBRATION
  | Features.INSTILL_CULTURE_INDEX
  | Features.CULTURE_VITALS
  | Features.CULTURE_KPI
  | Features.VALUES_ALIGNMENT_SCORE
  | Features.ACTION_STEPS
  | Features.INSTILL_FLOW
  | AddonRequests.INSTILL_FLOW_MINUTES
  | AddonRequests.CANDIDATE_CALIBRATION_LIMIT
  | AddonRequests.SEAT_REQUEST;

interface ElementProps {
  isOpen: boolean;
  onHide: () => void;
  requestType: RequestType;
}

const RequestModal = ({isOpen, onHide, requestType}: ElementProps) => {
  const {t} = useTranslation('moduleComponents', {
    keyPrefix: 'request-modal',
  });

  const companyUuid = useSafeCurrentCompanyUuid();

  const requestSeat = useSeatsRequest();
  const requestFeature = useFeatureRequest();

  const onSeatsRequest = (seatsRequested: number) => {
    requestSeat.mutate(
      {
        companyUuid,
        seatsRequested,
      },
      {
        onSuccess: () => {
          toast.success(t('seats-success'));
        },
        onError: apiErrorGuard(() => {
          toast.error(t('failure'));
        }),
      }
    );

    onHide();
  };

  const onFeatureRequest = () => {
    requestFeature.mutate(
      {
        companyUuid,
        featureName: requestType,
      },
      {
        onSuccess: () => {
          toast.success(t('feature-success'));
        },
        onError: apiErrorGuard(() => {
          toast.error(t('failure'));
        }),
      }
    );

    onHide();
  };

  const onSubmit = (values: RequestFormValues) => {
    switch (requestType) {
      case AddonRequests.SEAT_REQUEST: {
        onSeatsRequest(values.seatsRequested);

        break;
      }

      case AddonRequests.INSTILL_FLOW_MINUTES: {
        console.log('API to be integrated');

        break;
      }

      case AddonRequests.CANDIDATE_CALIBRATION_LIMIT: {
        console.log('API to be integrated');

        break;
      }

      default: {
        onFeatureRequest();
      }
    }
  };

  const requestFormValidationSchema = Yup.object().shape({
    message: Yup.string().required(t('message-form-error')),
    seatsRequested:
      requestType === AddonRequests.SEAT_REQUEST
        ? Yup.number().min(1).required(t('seats-request-form-error'))
        : Yup.number(),
  });

  const mapDataForRequestTypes: Record<
    RequestType,
    {imgUrl: string; title: string; infos: string[]}
  > = {
    [AddonRequests.SEAT_REQUEST]: {
      imgUrl: '',
      infos: [],
      title: '',
    },
    [Features.ACTION_STEPS]: {
      imgUrl: ActionStepsPreviewUrl,
      title: t('request-type-details.action-steps.title'),
      infos: [
        t('request-type-details.action-steps.info1'),
        t('request-type-details.action-steps.info2'),
        t('request-type-details.action-steps.info3'),
        t('request-type-details.action-steps.info4'),
      ],
    },
    [Features.CANDIDATE_CALIBRATION]: {
      imgUrl: CandidateCalibrationReportPreviewUrl,
      title: t('request-type-details.candidate-calibration.title'),
      infos: [
        t('request-type-details.candidate-calibration.info1'),
        t('request-type-details.candidate-calibration.info2'),
        t('request-type-details.candidate-calibration.info3'),
        t('request-type-details.candidate-calibration.info4'),
      ],
    },
    [AddonRequests.CANDIDATE_CALIBRATION_LIMIT]: {
      imgUrl: CandidateCalibrationReportPreviewUrl,
      title: t('request-type-details.candidate-calibration-limit.title'),
      infos: [t('request-type-details.candidate-calibration-limit.info1')],
    },
    [Features.CULTURE_VITALS]: {
      imgUrl: CultureVitalsWidgetPreviewUrl,
      title: t('request-type-details.culture-vitals.title'),
      infos: [
        t('request-type-details.culture-vitals.info1'),
        t('request-type-details.culture-vitals.info2'),
        t('request-type-details.culture-vitals.info3'),
        t('request-type-details.culture-vitals.info4'),
      ],
    },
    [Features.CULTURE_KPI]: {
      imgUrl: CultureVitalsWidgetPreviewUrl,
      title: t('request-type-details.culture-kpi.title'),
      infos: [
        t('request-type-details.culture-kpi.info1'),
        t('request-type-details.culture-kpi.info2'),
        t('request-type-details.culture-kpi.info3'),
        t('request-type-details.culture-kpi.info4'),
      ],
    },
    [Features.INSTILL_CULTURE_INDEX]: {
      imgUrl: InstillCultureIndexWidgetPreviewUrl,
      title: t('request-type-details.instill-culture-index.title'),
      infos: [
        t('request-type-details.instill-culture-index.info1'),
        t('request-type-details.instill-culture-index.info2'),
        t('request-type-details.instill-culture-index.info3'),
        t('request-type-details.instill-culture-index.info4'),
      ],
    },
    [Features.INSTILL_FLOW]: {
      imgUrl: InstillFlowPreviewUrl,
      title: t('request-type-details.instill-flow.title'),
      infos: [
        t('request-type-details.instill-flow.info1'),
        t('request-type-details.instill-flow.info2'),
        t('request-type-details.instill-flow.info3'),
        t('request-type-details.instill-flow.info4'),
      ],
    },
    [AddonRequests.INSTILL_FLOW_MINUTES]: {
      imgUrl: InstillFlowPreviewUrl,
      title: t('request-type-details.instill-flow-minutes.title'),
      infos: [t('request-type-details.instill-flow-minutes.info1')],
    },
    [Features.VALUES_ALIGNMENT_SCORE]: {
      imgUrl: ValuesWidgetPreviewUrl,
      title: t('request-type-details.values-alignment-score.title'),
      infos: [
        t('request-type-details.values-alignment-score.info1'),
        t('request-type-details.values-alignment-score.info2'),
        t('request-type-details.values-alignment-score.info3'),
        t('request-type-details.values-alignment-score.info4'),
      ],
    },
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onHide}
      innerContainerStyle={{
        maxWidth: '650px',
      }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          <div className={styles.leftContent}>
            <p className={styles.title}>{t('title')}</p>

            <p className={styles.subtitle}>{t('subtitle')}</p>
          </div>

          <CardCurvedDesign size="xLarge" />
        </div>

        <Formik
          initialValues={initialRequestValues}
          validationSchema={requestFormValidationSchema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {({errors, touched}) => (
            <Form>
              <div className={styles.bodyContainer}>
                {!!mapDataForRequestTypes[requestType]?.title && (
                  <div className={styles.requestInfoContainer}>
                    <img
                      className={styles.previewImage}
                      src={mapDataForRequestTypes[requestType].imgUrl}
                      alt={mapDataForRequestTypes[requestType].title}
                      width="286px"
                      height="150px"
                    />

                    <div className={styles.requestInfoContent}>
                      <p className={styles.requestInfoTitle}>
                        {mapDataForRequestTypes[requestType].title}
                      </p>

                      {mapDataForRequestTypes[requestType].infos.length > 1 ? (
                        <ul
                          className={`${styles.requestInfoPoints} ${styles.requestBullets}`}
                        >
                          {mapDataForRequestTypes[requestType].infos.map(
                            (info, index) => (
                              <li key={index}>{info}</li>
                            )
                          )}
                        </ul>
                      ) : (
                        <p className={styles.requestInfoPoints}>
                          {mapDataForRequestTypes[requestType].infos[0]}
                        </p>
                      )}
                    </div>
                  </div>
                )}

                {requestType === AddonRequests.SEAT_REQUEST && (
                  <Field name="seatsRequested">
                    {({field}: FieldProps<string>) => (
                      <FormControl
                        touched={!!touched.seatsRequested}
                        unknownTypeError={errors.seatsRequested}
                      >
                        <div className={styles.messageContent}>
                          <Label isRequired>{t('form-seats-label')}</Label>

                          <Input
                            {...field}
                            placeholder={t('seats-placeholder')}
                          />
                        </div>
                      </FormControl>
                    )}
                  </Field>
                )}

                <Field name="message">
                  {({field}: FieldProps<string>) => (
                    <FormControl
                      touched={!!touched.message}
                      unknownTypeError={errors.message}
                    >
                      <div className={styles.messageContent}>
                        <Label isRequired>{t('form-message-label')}</Label>

                        <Textarea
                          {...field}
                          rows={5}
                          placeholder={t('message-placeholder')}
                        />
                      </div>
                    </FormControl>
                  )}
                </Field>
              </div>

              <div className={styles.footerContainer}>
                <Button variant="secondary" onClick={onHide}>
                  {t('cancel')}
                </Button>

                <Button type="submit">{t('submit')}</Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

export default RequestModal;
