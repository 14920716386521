import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful/is-response-successful';

export interface UpdateFlowReportSharedPayload {
  companyUuid: string;
}

export async function updateFlowReportShared({
  companyUuid,
}: UpdateFlowReportSharedPayload) {
  const response = await InstillAPI.post(
    `/companies/${companyUuid}/user-profiles/me/meeting-report-shared`
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }
}
