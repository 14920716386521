import {QueryFunction} from '@tanstack/query-core';
import {CultureGraphItem} from 'interfaces/graph';
import {Meta} from 'interfaces/meta';
import {createInfiniteQuery} from 'react-query-kit';
import {
  CultureGraphPayload,
  fetchCultureGraph,
} from 'repositories/instill/queries/fetch-culture-graph';
import {dummyCultureGraph} from 'ui/@demo-data/graph';
import getOffsetValue from 'utils/get-offset-value';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {RQ_INFINITE_CULTURE_GRAPH} from './query-keys';

interface FetchCultureGraphResponsePayload {
  data: CultureGraphItem[];
  meta: Meta;
}

const queryFn: QueryFunction<
  FetchCultureGraphResponsePayload | undefined,
  [string, CultureGraphPayload]
> = queryDemoWrapper(
  async ({queryKey: [_, variables], pageParam = 0}) => {
    const response = await fetchCultureGraph({
      cadence: variables.cadence,
      companyUuid: variables.companyUuid,
      limit: variables.limit,
      teamUuid: variables.teamUuid,
      offset:
        variables.offset ??
        getOffsetValue({
          currentPage: pageParam,
          itemsPerPage: variables.limit ?? 0,
        }),
    });

    if (!response) {
      return;
    }

    return {
      data: response.data,
      // TODO: BE is not giving meta for this api. ask them to add it
      meta: {
        currentPage: 0,
        totalEntries: 0,
        totalPages: 0,
      },
    };
  },
  ({queryKey: [_, variables]}) => {
    const mapSkipPoints: Record<typeof variables.cadence, number> = {
      '1D': 1,
      '1W': 7,
      '2W': 14,
      '1M': 30,
      '3M': 60,
      '1Y': 365,
    };

    return {
      data: dummyCultureGraph
        .filter((_, idx) => idx % mapSkipPoints[variables.cadence] === 0)
        .slice(-variables.limit ?? 0),
      meta: {currentPage: 0, totalEntries: 0, totalPages: 0},
    };
  }
);

export const useInfiniteCultureGraph = createInfiniteQuery<
  FetchCultureGraphResponsePayload | undefined,
  Omit<CultureGraphPayload, 'offset'>
>({
  primaryKey: RQ_INFINITE_CULTURE_GRAPH,
  queryFn,
  enabled: (data, variables) => {
    return !!variables.companyUuid;
  },
  getNextPageParam: (lastPage, allPages) => {
    return lastPage?.data.at(0)?.combinedAverage === 0
      ? undefined
      : allPages.length + 1;
  },
  getPreviousPageParam: (firstPage, allPages) => {
    return allPages.length ? allPages.length - 1 : undefined;
  },
});
