import {useQueryClient} from '@tanstack/react-query';
import {createMutation} from 'react-query-kit';
import {Kudo} from '../../../interfaces/kudo';
import {createKudo} from '../../../repositories/instill';
import {CreateKudoPayload} from '../../../repositories/instill/mutations/create-kudo';
import {RQ_KUDOS, RQ_KUDOS_COUNT} from '../queries/query-keys';

export const useCreateKudo = () => {
  const queryClient = useQueryClient();

  return createMutation<Kudo | undefined, CreateKudoPayload>({
    mutationFn: async (variables) => {
      return await createKudo(variables.companyUuid, variables.payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([RQ_KUDOS_COUNT]);
      queryClient.invalidateQueries([RQ_KUDOS]);
    },
  })();
};
