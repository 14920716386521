import {ReactComponent as SearchIcon} from 'ui/@_components/kit/icons/search.svg';
import useSearchInput from '../../@hooks/use-search-input';
import styles from './styles.module.scss';

interface ElementProps {
  value: string;
  onChange: (value: string) => void;
  debouncedSearch?: boolean;
  debounceTimeout?: number;
  placeholder?: string;
  customContainerCss?: string;
  fullWidth?: boolean;
}

const Search = ({
  value,
  onChange,
  debouncedSearch = true,
  debounceTimeout = 800,
  placeholder = '',
  customContainerCss,
  fullWidth = false,
}: ElementProps) => {
  const {handleKeyDown, onInputChange, searchText} = useSearchInput({
    onChange,
    debouncedSearch,
    debounceTimeout,
    value,
  });

  let className = customContainerCss
    ? customContainerCss
    : styles.searchContainer;

  if (fullWidth) {
    className += ` ${styles.fullWidth}`;
  }

  return (
    <div className={className}>
      <SearchIcon className={styles.searchIcon} />

      <input
        className={styles.input}
        placeholder={placeholder}
        value={searchText}
        onChange={(event) => onInputChange(event.target.value)}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default Search;
