import {useQueryClient} from '@tanstack/react-query';
import {createMutation} from 'react-query-kit';
import {FullUserProfile} from '../../../interfaces/user-profile';
import {updateMeUserProfile} from '../../../repositories/instill';
import {UpdateMyUserProfilePayload} from '../../../repositories/instill/mutations/update-me-user-profile';
import {
  useSyncSetCompanyUserProfile,
  useSyncSetFullUserProfile,
} from '../queries';
import {
  RQ_COMPANY_EMPLOYEES,
  RQ_INFINITE_COMPANY_EMPLOYEES,
} from '../queries/query-keys';

export const useUpdateMyUserProfile = () => {
  const queryClient = useQueryClient();
  const syncSetFullUserProfile = useSyncSetFullUserProfile();
  const syncSetCompanyUserProfile = useSyncSetCompanyUserProfile();

  return createMutation<
    FullUserProfile | undefined,
    UpdateMyUserProfilePayload
  >({
    mutationFn: async (variables) => {
      return await updateMeUserProfile(variables);
    },
    onSuccess: (data, variables) => {
      syncSetFullUserProfile(
        {
          companyUuid: variables.companyUuid,
        },
        data
      );

      syncSetCompanyUserProfile(
        {
          userProfileUuid: data?.uuid,
          companyUuid: variables.companyUuid,
        },
        data
      );

      queryClient.invalidateQueries([RQ_COMPANY_EMPLOYEES]);
      queryClient.invalidateQueries([RQ_INFINITE_COMPANY_EMPLOYEES]);
    },
  })();
};
