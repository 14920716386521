import {useAuth0} from '@auth0/auth0-react';
import {
  COMPANY_ONBOARDING_COMPLETE_STORAGE_KEY,
  IS_SIGNIN_TRACKED_STORAGE_KEY,
  IS_SIGNUP_TRACKED_STORAGE_KEY,
  REDIRECT_URL_ON_LOGIN_STORAGE_KEY,
  SELECTED_COMPANY_UUID_KEY,
} from 'constants/local-storage-keys';
import {useCallback} from 'react';
import useDemoAccount from './use-demo-account';

const useLogout = () => {
  const {logout: auth0Logout} = useAuth0();
  const {endDemoAccount} = useDemoAccount();

  const onClearStorage = useCallback(() => {
    localStorage.removeItem(SELECTED_COMPANY_UUID_KEY);
    localStorage.removeItem(REDIRECT_URL_ON_LOGIN_STORAGE_KEY);
    localStorage.removeItem(COMPANY_ONBOARDING_COMPLETE_STORAGE_KEY);
    localStorage.removeItem(IS_SIGNUP_TRACKED_STORAGE_KEY);
    localStorage.removeItem(IS_SIGNIN_TRACKED_STORAGE_KEY);
  }, []);

  const logout = useCallback(() => {
    onClearStorage();

    endDemoAccount();

    auth0Logout({returnTo: window.location.origin});
  }, [auth0Logout, onClearStorage, endDemoAccount]);

  return logout;
};

export default useLogout;
