export const getFirstName = (fullName: string) => {
  const names = fullName.split(' ');

  return names[0];
};

export const getNameInitials = (name: string) => {
  if (!name) return '!';

  const splitName = name.split(' ');

  if (splitName.length > 1) {
    return `${splitName[0][0]}${splitName[splitName.length - 1][0]}`;
  }

  return `${name[0]}${name[1]}`;
};
