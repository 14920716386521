import {KudosCount} from 'interfaces/kudo';
import {createQuery} from 'react-query-kit';
import {
  FetchKudosCountPayload,
  fetchKudosCount,
} from 'repositories/instill/queries/fetch-kudos-count';
import {kudosCount} from 'ui/@demo-data/kudo';
import queryDemoWrapper from 'utils/query-utils';
import {RQ_KUDOS_COUNT} from './query-keys';

export const useKudosCount = createQuery<
  KudosCount | undefined,
  FetchKudosCountPayload
>({
  primaryKey: RQ_KUDOS_COUNT,
  queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
    return fetchKudosCount({
      companyUuid: variables.companyUuid,
      valueUuid: variables.valueUuid,
      teamUuid: variables.teamUuid,
    });
  }, kudosCount),
});
