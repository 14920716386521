import {Actions} from 'constants/permissions/actions';
import {Roles} from 'constants/roles';
import {useHasPermission} from 'ui/@_components/has-permission';

const useInviteRolesOptions = () => {
  const hasPermission = useHasPermission();

  const inviteRoleOptions = [
    {
      label: 'Member',
      value: Roles.MEMBER,
    },
  ];

  if (hasPermission(Actions.INVITE_MANAGER)) {
    inviteRoleOptions.unshift({
      label: 'Manager',
      value: Roles.MANAGER,
    });
  }

  if (hasPermission(Actions.INVITE_ADMIN)) {
    inviteRoleOptions.unshift({
      label: 'Admin',
      value: Roles.ADMIN,
    });
  }

  if (hasPermission(Actions.INVITE_OWNER)) {
    inviteRoleOptions.unshift({
      label: 'Owner',
      value: Roles.OWNER,
    });
  }

  return inviteRoleOptions;
};

export default useInviteRolesOptions;
