import {Roles} from 'constants/roles';
import {Form, Formik, FormikHelpers} from 'formik';
import {useTranslation} from 'react-i18next';
import Button from 'ui/@_components/button/button';
import {FormControlV2} from 'ui/@_components/kit/form/form-control';
import InputWithSelect from 'ui/@_components/kit/form/input-with-select';
import * as Yup from 'yup';
import useInviteRolesOptions from '../../@hooks/use-invite-roles-options';
import styles from './styles.module.scss';

export interface UserToInvite {
  role: Roles;
  email: string;
}

const initialValues: UserToInvite = {
  email: '',
  role: Roles.MEMBER,
};

interface ElementProps {
  onSubmit: (
    values: UserToInvite,
    formikHelpers: FormikHelpers<UserToInvite>
  ) => void;
  inviteUsers: UserToInvite[];
}

const AddInviteUser = ({onSubmit, inviteUsers}: ElementProps) => {
  const {t} = useTranslation('moduleComponents', {
    keyPrefix: 'modals.invite-user.components.add-invite-user',
  });

  const inviteRolesOptions = useInviteRolesOptions();

  const validationSchema = Yup.object().shape({
    role: Yup.string(),
    email: Yup.string()
      .email(t('incorrect-email'))
      .notOneOf(
        inviteUsers.map((user) => user.email),
        t('already-added')
      ),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({touched, errors, values, setValues, handleBlur}) => {
        return (
          <Form>
            <div className={styles.userRow}>
              <FormControlV2 touched={!!touched} error={errors}>
                <InputWithSelect
                  value={values.email}
                  onChange={(newEmail) => {
                    setValues((prev) => ({
                      ...prev,
                      email: newEmail,
                    }));
                  }}
                  options={inviteRolesOptions}
                  onChangeSelect={(newSelectedRole) => {
                    setValues((prev) => ({
                      ...prev,
                      role: newSelectedRole,
                    }));
                  }}
                  selectValue={values.role}
                  placeholder={t('email-placeholder')}
                  inputProps={{
                    onBlur: handleBlur,
                    name: 'user',
                  }}
                />
              </FormControlV2>

              <Button
                variant="secondary"
                disabled={!values.email}
                type="submit"
              >
                {t('submit')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddInviteUser;
