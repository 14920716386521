import {createCompany} from '../../../repositories/instill';
import {createMutation} from 'react-query-kit';
import {Company} from '../../../interfaces/company';
import {CreateCompanyPayload} from '../../../repositories/instill/mutations/create-company';
import {useSyncSetCurrentAuthUser} from '../queries';

export const useCreateCompany = () => {
  const syncSetCurrentAuthUser = useSyncSetCurrentAuthUser();

  return createMutation<Company | undefined, CreateCompanyPayload>({
    mutationFn: async (variables) => {
      return await createCompany(variables.companyPayload);
    },
    onSuccess: (data) => {
      if (data) {
        syncSetCurrentAuthUser((prev) => {
          return prev
            ? {
                ...prev,
                companies: [...prev.companies, data],
              }
            : prev;
        });
      }
    },
  })();
};
