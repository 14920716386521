import {createQuery} from 'react-query-kit';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {RQ_CURRENT_SUBSCRIPTIONS} from './query-keys';
import {
  CurrentSubscriptionsPayload,
  fetchCurrentSubscriptions,
} from 'repositories/instill/queries/fetch-current-subscriptions';
import {Subscription} from 'interfaces/subscription';
import {dummyCurrentSubscriptions} from 'ui/@demo-data/current-subscriptions';

export const useCurrentSubscriptions = createQuery<
  Subscription[] | undefined,
  CurrentSubscriptionsPayload
>({
  primaryKey: RQ_CURRENT_SUBSCRIPTIONS,
  queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
    return await fetchCurrentSubscriptions(variables);
  }, dummyCurrentSubscriptions),
  enabled: (data, variables) => {
    return !!variables?.companyUuid;
  },
});
