import {ORDER_BY_PARAMETERS} from '../../../constants/filtering/order-by';
import {sanitizeCompanyEmployeesFromRaw} from '../../../ui/@sanitizers/company-employees';
import {sanitizeMetaFromRaw} from '../../../ui/@sanitizers/meta';
import isResponseSuccessful from '../../../utils/is-response-successful';
import InstillAPI from '../axios-instance';

export interface FetchCompanyEmployeePayload {
  companyUuid: string;
  currentPage?: number;
  includeInvitedUsers?: boolean;
  includeInActiveUsers?: boolean;
  itemsPerPage?: number;
  limit?: number;
  offset?: number;
  orderBy?: ORDER_BY_PARAMETERS;
  query?: string;
  sortBy?: string;
}

export async function fetchCompanyEmployees(
  params: FetchCompanyEmployeePayload
) {
  let url = `/companies/${params.companyUuid}/employees`;
  let queryParams: string[] = [];

  if (params.offset) {
    queryParams.push(`skip=${params.offset}`);
  }

  if (params.limit) {
    queryParams.push(`limit=${params.limit}`);
  }

  if (params.orderBy) {
    queryParams.push(`order=${params.orderBy}`);
  }

  if (params.sortBy) {
    queryParams.push(`sort_by=${params.sortBy}`);
  }

  if (params.query) {
    queryParams.push(`q=${params.query}`);
  }

  if (params.includeInActiveUsers) {
    queryParams.push(`include_inactive_users=${params.includeInActiveUsers}`);
  }

  if (params.includeInvitedUsers) {
    queryParams.push(`include_invited_users=${params.includeInvitedUsers}`);
  }

  if (queryParams) {
    url = `${url}?${queryParams.join('&')}`;
  }

  try {
    const response = await InstillAPI.get(url);

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return {
      data: sanitizeCompanyEmployeesFromRaw(response.data.data),
      meta: sanitizeMetaFromRaw(response.data.meta),
    };
  } catch (error) {
    console.error(error);
  }
}
