import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import BaseCard from 'ui/@_components/card/base-card';
import HorizontalProgressBar from 'ui/@_components/progress-bar/horizontal/horizontal';
import Spinner from 'ui/@_components/spinner';
import {useCandidateAlignmentResult} from 'ui/@hooks/queries/use-candidate-alignment-result';
import styles from './styles.module.scss';

const progressWrapperBackgroundColor = '#fcfcfe';

const AlignmentScore = () => {
  const {t} = useTranslation('candidateCalibration', {
    keyPrefix:
      'job-positions.show.report.components.main-content.components.alignment-score',
  });

  const {candidateId} = useParams();

  const candidateAlignmentResult = useCandidateAlignmentResult({
    variables: {
      candidateUuid: candidateId,
    },
  });

  if (candidateAlignmentResult.isLoading) return <Spinner />;

  if (!candidateAlignmentResult.data) return <></>;

  const {alignmentProfile: data} =
    candidateAlignmentResult.data.candidateResult.moduleResult;

  return (
    <BaseCard>
      <div className={styles.wrapper}>
        <div>
          <div className={styles.titleContainer}>
            <div className={styles.title}>{t('title')}</div>

            <div className={styles.score}>{data.score} %</div>
          </div>

          <div className={styles.progressContainer}>
            <HorizontalProgressBar
              size="large"
              percentage={data.score}
              wrapperBackgroundColor="#F3F4F9"
            />
          </div>

          <ul className={styles.indicator}>
            <li>{t('very-low')}</li>

            <li>{t('low')}</li>

            <li>{t('medium')}</li>

            <li>{t('high')}</li>

            <li>{t('very-high')}</li>
          </ul>

          <div className={styles.multiProgressContainer}>
            <div className={styles.multiProgressSubWrapper}>
              <div className={styles.multiProgressLabel}>
                <div>{t('personality-alignment')}</div>

                <div>{data.personalityAlignment.score} %</div>
              </div>
              <HorizontalProgressBar
                size="x-large"
                percentage={data.personalityAlignment.score}
                progressBackgroundColor="rgba(255, 204, 0, 0.4)"
                wrapperBackgroundColor={progressWrapperBackgroundColor}
              />
            </div>

            <div className={styles.multiProgressSubWrapper}>
              <div className={styles.multiProgressLabel}>
                <div>{t('values-alignment')}</div>

                <div>{data.valuesAlignment.score} %</div>
              </div>

              <div className={styles.invertedProgressBar}>
                <HorizontalProgressBar
                  size="x-large"
                  percentage={data.valuesAlignment.score}
                  progressBackgroundColor="rgba(52, 199, 89, 0.4)"
                  wrapperBackgroundColor={progressWrapperBackgroundColor}
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className={styles.narrativeTitle}>{t('narrative')}</div>

          <div className={styles.narrativeContainer}>{data.description}</div>
        </div>
      </div>
    </BaseCard>
  );
};

export default AlignmentScore;
