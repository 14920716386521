import {ComponentProps, Suspense, lazy} from 'react';
import {ComponentType} from 'react-spring';
import Spinner from 'ui/@_components/spinner';

const lazyPage = <T extends ComponentType<any>>(
  lazyProp: Parameters<typeof lazy<T>>[0]
) => {
  const Component = lazy<T>(lazyProp);

  return (props: ComponentProps<typeof Component>) => {
    return (
      <Suspense fallback={<Spinner />}>
        <Component {...props} />
      </Suspense>
    );
  };
};

export default lazyPage;
