import {Roles} from '../roles';
import {Actions} from './actions';

export const Permissions: Record<Actions, Roles[]> = {
  [Actions.VIEW_CULTURE_KPI]: [
    Roles.OWNER,
    Roles.ADMIN,
    Roles.MANAGER,
    Roles.MEMBER,
  ],
  [Actions.MODIFY_CULTURE_KPI]: [Roles.OWNER, Roles.ADMIN],
  [Actions.EDIT_INSTILL_FLOW_SETTINGS]: [Roles.OWNER, Roles.ADMIN],
  [Actions.EDIT_COMPANY]: [Roles.OWNER, Roles.ADMIN],
  [Actions.VIEW_BUSINESS_OUTCOMES]: [Roles.OWNER, Roles.ADMIN],
  [Actions.EDIT_BUSINESS_OUTCOMES]: [Roles.OWNER, Roles.ADMIN],
  [Actions.VIEW_DASHBOARD_STEPS]: [Roles.OWNER, Roles.ADMIN],
  [Actions.ADD_VALUE]: [Roles.OWNER, Roles.ADMIN],
  [Actions.EDIT_VALUE]: [Roles.OWNER, Roles.ADMIN],
  [Actions.DELETE_VALUE]: [Roles.OWNER, Roles.ADMIN],
  [Actions.EDIT_MISSION_VISION_MANIFESTO]: [Roles.OWNER, Roles.ADMIN],
  [Actions.EDIT_ORGANIZATION_STRUCTURE]: [Roles.OWNER, Roles.ADMIN],
  [Actions.EDIT_TEAM]: [Roles.OWNER, Roles.ADMIN, Roles.MANAGER],
  [Actions.ADD_TEAM]: [Roles.OWNER, Roles.ADMIN, Roles.MANAGER],
  [Actions.DELETE_TEAM]: [Roles.OWNER, Roles.ADMIN, Roles.MANAGER],
  [Actions.ADD_EMPLOYEE]: [Roles.OWNER, Roles.ADMIN, Roles.MANAGER],
  [Actions.INVITE_MANAGER]: [Roles.OWNER, Roles.ADMIN, Roles.MANAGER],
  [Actions.INVITE_ADMIN]: [Roles.OWNER, Roles.ADMIN],
  [Actions.INVITE_OWNER]: [],
  [Actions.UPDATE_KUDO_VISIBILITY]: [Roles.OWNER, Roles.ADMIN],
  [Actions.UPDATE_ROLE_TO_ADMIN]: [Roles.OWNER, Roles.ADMIN],
  [Actions.UPDATE_ROLE_TO_MANAGER]: [Roles.OWNER, Roles.ADMIN, Roles.MANAGER],
  [Actions.UPDATE_ROLE_TO_OWNER]: [Roles.OWNER],
  [Actions.UPDATE_ROLE_TO_MEMBER]: [
    Roles.OWNER,
    Roles.ADMIN,
    Roles.MANAGER,
    Roles.MEMBER,
  ],
  [Actions.VIEW_COMPANY_SETTINGS]: [Roles.OWNER, Roles.ADMIN],
  [Actions.VIEW_CANDIDATE_CALIBRATION]: [
    Roles.OWNER,
    Roles.ADMIN,
    Roles.MANAGER,
  ],
  [Actions.VIEW_MANAGE_ASSESSMENTS]: [Roles.OWNER, Roles.ADMIN],
  [Actions.VIEW_MANAGE_DIRECTORY]: [Roles.OWNER, Roles.ADMIN],
  [Actions.VIEW_ACTION_STEP_STATUS]: [Roles.OWNER, Roles.ADMIN],
  [Actions.VIEW_INTEGRATIONS]: [Roles.OWNER, Roles.ADMIN],
  [Actions.UPDATE_PLAN]: [Roles.OWNER, Roles.ADMIN],
  [Actions.VIEW_BILLING]: [Roles.OWNER, Roles.ADMIN],
};
