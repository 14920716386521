import {createMutation} from 'react-query-kit';
import {
  SeatsRequestPayload,
  seatsRequest,
} from 'repositories/instill/mutations/seats-request';

export const useSeatsRequest = () => {
  return createMutation<string | undefined, SeatsRequestPayload>({
    mutationFn: async (variables) => {
      return await seatsRequest(variables);
    },
  })();
};
