import {
  Assessment,
  AssessmentRaw,
  AssessmentStatusByVitalSign,
  AssessmentUserProfileSummary,
  AssessmentUserProfileSummaryRaw,
} from '../../interfaces/assessment';
import {sanitizeCompanyEmployeeShortFromRaw} from './company-employees';
import {sanitizeModuleFromRaw} from './module';

export const sanitizeAssessmentsFromRaw = (
  data: AssessmentRaw[]
): Assessment[] => {
  return data.map((assessment) => sanitizeAssessmentFromRaw(assessment));
};

export const sanitizeAssessmentFromRaw = (data: AssessmentRaw): Assessment => {
  const {uuid, name, rank, slug, overview} = data;

  return {
    uuid,
    name,
    rank,
    slug,
    overview,
    modules: sanitizeModuleFromRaw(data.modules),
  };
};

export const sanitizeAssessmentUserProfilesSummaryFromRaw = (
  data: AssessmentUserProfileSummaryRaw[]
): AssessmentUserProfileSummary[] => {
  return data.map((summary) =>
    sanitizeAssessmentUserProfileSummaryFromRaw(summary)
  );
};

export const sanitizeAssessmentUserProfileSummaryFromRaw = (
  data: AssessmentUserProfileSummaryRaw
): AssessmentUserProfileSummary => {
  return {
    uuid: data.uuid,
    lastResponseAt: data.last_response_at,
    assessmentEventId: data.assessment_event_id,
    userProfileId: data.user_profile_id,
    status: data.status,
    userProfile: sanitizeCompanyEmployeeShortFromRaw(data.user_profile),
  };
};

export const sanitizeAssessmentStatusByVitalSignFromRaw = (
  data: AssessmentStatusByVitalSign
): AssessmentStatusByVitalSign => {
  return {
    status: data.status,
  };
};
