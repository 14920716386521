import {useTranslation} from 'react-i18next';
import TextCard from 'ui/@_components/card/text-card';
import styles from './styles.module.scss';

interface ElementProps {
  summary: string[];
}

const MeetingSummary = ({summary}: ElementProps) => {
  const {t} = useTranslation('sense', {
    keyPrefix: 'meetings.show.components.meeting-details.components.summary',
  });

  return (
    <TextCard>
      <TextCard.Header title={t('title')} />
      <TextCard.Content>
        <ul className={styles.list}>
          {summary.map((summaryItem, index) => (
            <li className={styles.listItem} key={index}>
              {summaryItem}
            </li>
          ))}
        </ul>
      </TextCard.Content>
    </TextCard>
  );
};

export default MeetingSummary;
