import {Plans} from 'constants/plans';
import {Features} from './features';

export const PlanAccess: Record<Features, Plans[]> = {
  [Features.INSTILL_FLOW]: [
    Plans.FREE_TRAIL_PLAN,
    Plans.ADVANCE_PLAN,
    Plans.EVOLUTION_PLAN,
    Plans.BUILD_ADVANCE_PLAN,
  ],
  [Features.INSTILL_CULTURE_INDEX]: [
    Plans.FREE_TRAIL_PLAN,
    Plans.ADVANCE_PLAN,
    Plans.MEASURE_PLAN,
    Plans.EVOLUTION_PLAN,
    Plans.BUILD_ADVANCE_PLAN,
    Plans.BUILD_MEASURE_PLAN,
  ],
  [Features.VALUES_ALIGNMENT_SCORE]: [
    Plans.FREE_TRAIL_PLAN,
    Plans.ADVANCE_PLAN,
    Plans.BUILD_ADVANCE_PLAN,
    Plans.EVOLUTION_PLAN,
  ],
  [Features.BUSINESS_OUTCOMES]: [
    Plans.FREE_TRAIL_PLAN,
    Plans.ADVANCE_PLAN,
    Plans.BUILD_PLAN,
    Plans.MEASURE_PLAN,
    Plans.EVOLUTION_PLAN,
    Plans.BUILD_ADVANCE_PLAN,
    Plans.BUILD_MEASURE_PLAN,
  ],
  [Features.ASSESSMENTS_POLLS]: [
    Plans.FREE_TRAIL_PLAN,
    Plans.MEASURE_PLAN,
    Plans.ADVANCE_PLAN,
    Plans.EVOLUTION_PLAN,
    Plans.BUILD_ADVANCE_PLAN,
    Plans.BUILD_MEASURE_PLAN,
  ],
  [Features.CULTURE_VITALS]: [
    Plans.FREE_TRAIL_PLAN,
    Plans.ADVANCE_PLAN,
    Plans.MEASURE_PLAN,
    Plans.EVOLUTION_PLAN,
    Plans.BUILD_ADVANCE_PLAN,
    Plans.BUILD_MEASURE_PLAN,
  ],
  [Features.CULTURE_KPI]: [
    Plans.FREE_TRAIL_PLAN,
    Plans.ADVANCE_PLAN,
    Plans.EVOLUTION_PLAN,
    Plans.BUILD_ADVANCE_PLAN,
  ],
  [Features.CANDIDATE_CALIBRATION]: [
    Plans.FREE_TRAIL_PLAN,
    Plans.BUILD_PLAN,
    Plans.EVOLUTION_PLAN,
    Plans.BUILD_ADVANCE_PLAN,
    Plans.BUILD_MEASURE_PLAN,
  ],
  [Features.ACTION_STEPS]: [
    Plans.FREE_TRAIL_PLAN,
    Plans.ADVANCE_PLAN,
    Plans.EVOLUTION_PLAN,
    Plans.BUILD_ADVANCE_PLAN,
  ],
  [Features.PEOPLE_AND_TEAMS]: [
    Plans.FREE_TRAIL_PLAN,
    Plans.BUILD_PLAN,
    Plans.EVOLUTION_PLAN,
    Plans.ADVANCE_PLAN,
    Plans.FREE_PLAN,
    Plans.MEASURE_PLAN,
    Plans.BUILD_ADVANCE_PLAN,
    Plans.BUILD_MEASURE_PLAN,
  ],
  [Features.VALUES_DASHBOARD]: [
    Plans.FREE_TRAIL_PLAN,
    Plans.BUILD_PLAN,
    Plans.EVOLUTION_PLAN,
    Plans.ADVANCE_PLAN,
    Plans.FREE_PLAN,
    Plans.MEASURE_PLAN,
    Plans.BUILD_ADVANCE_PLAN,
    Plans.BUILD_MEASURE_PLAN,
  ],
};
