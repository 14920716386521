import {DemoAccountType} from 'constants/demo-account';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';
import useDemoAccount from './use-demo-account';
import whiteInstillLogo from 'assets/images/logo-white.png';
import cultureForcePrimaryLogo from 'assets/images/white-labelling/culture-force-primary.png';

interface DemoAccountDetails {
  flowBranding: string;
  cultureIndexBranding: string;
  primaryLogo: string;
  poweredBy: {
    show: boolean;
    text: string;
  };
  brandName: string;
  showDemoBar: boolean;
  showLogoutCta: boolean;
  mutationToast: {
    show: boolean;
    text: string;
  };
}

const genericData = (
  t: TFunction<'hooks', 'demo-account-details'>
): DemoAccountDetails => {
  return {
    primaryLogo: whiteInstillLogo,
    flowBranding: t('generic.flow-branding'),
    cultureIndexBranding: t('generic.culture-index-branding'),
    poweredBy: {
      show: false,
      text: t('generic.powered-by-text'),
    },
    brandName: t('generic.brand-name'),
    showDemoBar: true,
    showLogoutCta: true,
    mutationToast: {
      show: false,
      text: '',
    },
  };
};

const cultureForceData = (
  t: TFunction<'hooks', 'demo-account-details'>
): DemoAccountDetails => {
  return {
    primaryLogo: cultureForcePrimaryLogo,
    flowBranding: t('culture-force.flow-branding'),
    cultureIndexBranding: t('culture-force.culture-index-branding'),
    poweredBy: {
      show: true,
      text: t('culture-force.powered-by-text'),
    },
    brandName: t('culture-force.brand-name'),
    showDemoBar: false,
    showLogoutCta: false,
    mutationToast: {
      show: true,
      text: t('culture-force.mutation-toast-text'),
    },
  };
};

const useDemoAccountDetails = (): DemoAccountDetails => {
  const {demoAccountType} = useDemoAccount();

  const {t} = useTranslation('hooks', {
    keyPrefix: 'demo-account-details',
  });

  const data = {
    [DemoAccountType.GENERIC]: genericData(t),
    [DemoAccountType.CULTURE_FORCE]: cultureForceData(t),
  };

  return data[demoAccountType || DemoAccountType.GENERIC];
};

export default useDemoAccountDetails;
