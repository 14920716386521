import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {useCurrentAuthUserState} from '../@atoms/current-auth-user';
import styles from './styles.module.scss';
import useAnalytics from 'ui/@hooks/use-analytics';
import {EventName} from 'constants/analytics/event-name';
import {EventType} from 'constants/analytics/event-type';
import {useAuth0} from '@auth0/auth0-react';
import TwoPanePage from 'ui/@_components/two-pane-page/two-pane-page';

const EMAIL_VERIIFIED_STORAGE_KEY = 'Instill-emailVerified';

const EmailVerified = () => {
  const {t} = useTranslation('emailVerified');
  const navigate = useNavigate();
  const {user} = useAuth0();
  const [, setCurrentAuthUser] = useCurrentAuthUserState();

  const {trackEvent} = useAnalytics();

  useEffect(() => {
    if (user?.email) {
      trackEvent({
        eventName: EventName.ONBOARDING.EMAIL_VERIFIED,
        eventType: EventType.PAGE_VIEW,
        eventProperties: {
          email: user.email,
        },
      });
    }
  }, [trackEvent, user?.email]);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = '/';
    }, 2000);

    // We clear the user in order to force re-fetching the user.
    localStorage.setItem(EMAIL_VERIIFIED_STORAGE_KEY, 'true');
    setCurrentAuthUser(null);

    return () => clearTimeout(timer);
  }, [navigate, setCurrentAuthUser, trackEvent, user?.email]);

  return (
    <TwoPanePage>
      <div className={styles.container}>
        <h1 className={styles.title}>{t('title')}</h1>

        <h2
          className={styles.subtitle}
          dangerouslySetInnerHTML={{
            __html: t('subtitle', {
              email: user?.email || '',
            }),
          }}
        />
      </div>
    </TwoPanePage>
  );
};

export default EmailVerified;
