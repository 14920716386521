import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import BaseCard from 'ui/@_components/card/base-card';
import Spinner from 'ui/@_components/spinner';
import {useCandidateAlignmentResult} from 'ui/@hooks/queries/use-candidate-alignment-result';
import ListItem from './@components/list-item';
import styles from './styles.module.scss';

const ImpactsChallenges = () => {
  const {t} = useTranslation('candidateCalibration', {
    keyPrefix:
      'job-positions.show.report.components.main-content.components.impacts-challenges',
  });

  const {candidateId} = useParams();

  const candidateAlignmentResult = useCandidateAlignmentResult({
    variables: {
      candidateUuid: candidateId,
    },
  });

  if (candidateAlignmentResult.isLoading) return <Spinner />;

  if (!candidateAlignmentResult.data) return <></>;

  const {positiveNotes, negativeNotes} =
    candidateAlignmentResult.data.candidateResult.moduleResult;

  return (
    <BaseCard>
      <div className={styles.wrapper}>
        <div className={styles.subWrapper}>
          <div className={styles.title}>{t('impacts')}</div>

          {positiveNotes.map((positiveNote, index) => (
            <ListItem key={index} icon={t('impact-icon')} text={positiveNote} />
          ))}
        </div>

        <div className={styles.subWrapper}>
          <div className={styles.title}>{t('challenges')}</div>

          {negativeNotes.map((negativeNote, index) => (
            <ListItem
              key={index}
              icon={t('challenge-icon')}
              text={negativeNote}
            />
          ))}
        </div>
      </div>
    </BaseCard>
  );
};

export default ImpactsChallenges;
