import {UserAssessment, UserAssessments} from 'interfaces/user-assessment';

export const dummyUserAssessments: UserAssessments = [
  {
    uuid: '2e03e9c4-cbae-4c11-8e43-0dc17b257854',
    assessment: {
      uuid: '890cb1d9-aa6a-492d-be3b-00787cee8b54',
      name: 'Trust',
      overview:
        'Trust is the fundamental cornerstone of relationships and organizational foundations, encapsulating an environment where individuals lean on each other with assurance. It manifests through resource sharing, fostering collective growth as individuals confidently exchange knowledge and expertise. Dependability forms a vital pillar, setting the standard for commitments to be honored and responsibilities fulfilled. Honesty and transparency are intrinsic, establishing a culture where open communication prevails and information is shared with unwavering integrity. Nurturing camaraderie cultivates a sense of community and mutual support within the trust framework. The commitment to constructive conflict resolution mechanisms further solidifies trust, demonstrating a dedication to resolving differences amicably and fortifying the foundation of relational and organizational trust.',
      rank: 4,
      slug: 'trust',
      supportedSchedules: ['MONTHLY', 'QUARTERLY'],
    },
    assessmentModules: [
      {
        uuid: '99b7fa1d-f904-4a07-9b66-dc91015f975d',
      },
    ],
    dueDate: '2024-04-13T14:40:35.245815+00:00',
    numofQuestions: 11,
    numofResponses: 1,
    assessmentStatus: 'started',
  },
  {
    uuid: '7683f271-e851-4bac-a642-f82a11d46ff8',
    assessment: {
      uuid: '2ee807e4-78b9-457a-a825-d24fb97f5d82',
      name: 'Innovation',
      slug: 'innovation',
      overview:
        'Innovation is the dynamic process characterized by curiosity, continual learning, and adaptability, where individuals collaboratively engage in a collective pursuit of creative solutions. It thrives on the synergy of diverse perspectives within a collaborative and teamwork-oriented environment. Innovation involves calculated risk-taking, acknowledging that groundbreaking advancements often emerge from ventures into unexplored territories. An inherent aspect of innovation is the intentional inclusion of diverse opinions, ensuring a multifaceted approach that enriches the creative process.',
      rank: 6,
      supportedSchedules: ['MONTHLY', 'QUARTERLY'],
    },
    assessmentModules: [
      {
        uuid: '77654b9f-31a5-4836-a9f9-f8d987b3762d',
      },
    ],
    dueDate: '2024-04-13T14:40:45.976497+00:00',
    numofQuestions: 10,
    numofResponses: 0,
    assessmentStatus: 'not_started',
  },
  {
    uuid: '90b500ef-5b0c-4bc7-b6fb-b317e7b0bf10',
    assessment: {
      uuid: '65f45dc6-a38d-4ec1-aa9e-0a83a1dfaf5e',
      name: 'Burnout',
      slug: 'burnout',
      overview:
        "Burnout is a multifaceted phenomenon characterized by a state of physical, emotional, and mental exhaustion, often stemming from prolonged exposure to stress and a demanding work environment. It reflects a depletion of emotional energy, impacting one's ability to cope effectively with professional and personal challenges. Flexibility, in terms of adapting to changing demands, is crucial in mitigating burnout. Self-care practices play a pivotal role, involving intentional efforts to nurture one's well-being and resilience. The presence of chronic stress, coupled with a diminishing sense of effectiveness in handling responsibilities, contributes to burnout. Additionally, cynicism may manifest as a defense mechanism, reflecting a detachment and negativity towards work-related aspects.",
      rank: 7,
      supportedSchedules: ['MONTHLY', 'QUARTERLY'],
    },
    assessmentModules: [
      {
        uuid: 'b3028dc9-181b-4def-b9ee-801ab459fb72',
      },
    ],
    dueDate: '2024-04-18T20:05:11.196296+00:00',
    numofQuestions: 6,
    numofResponses: 1,
    assessmentStatus: 'started',
  },
  {
    uuid: '4fb3e491-30c2-4433-8484-bb4de30eba24',
    assessment: {
      uuid: '639171a2-836b-4aa7-983a-ba7362a9cb7d',
      name: 'What’s your workstyle',
      slug: 'personality',
      overview: null,
      rank: 1,
      supportedSchedules: ['ONE_TIME'],
    },
    assessmentModules: [
      {
        uuid: '3572a9cb-9d19-4b3b-aec6-dc2b2f949848',
      },
    ],
    dueDate: '2024-03-30T17:55:15.155000+00:00',
    numofQuestions: 55,
    numofResponses: 0,
    assessmentStatus: 'not_started',
  },
  {
    uuid: '17670fc6-760f-49fd-8f02-796d63e05343',
    assessment: {
      uuid: 'adbd630d-d85e-4fbd-8371-81d7cb38c2e9',
      name: 'Inclusion',
      slug: 'inclusion',
      overview:
        'Inclusion is the foundational construct that creates a sense of belonging within a group or organization, where individuals experience being seen and heard, fostering an environment where authenticity is valued. It encompasses individual recognition, acknowledging and celebrating the unique contributions of each member. Respect is a central tenet, permeating the culture to ensure that every individual is treated with dignity and consideration.',
      rank: 2,
      supportedSchedules: ['MONTHLY', 'QUARTERLY'],
    },
    assessmentModules: [
      {
        uuid: '61d8810e-9814-4c10-ad4e-eedf5fd419c6',
      },
    ],
    dueDate: '2024-06-07T19:56:19.671971+00:00',
    numofQuestions: 16,
    numofResponses: 3,
    assessmentStatus: 'started',
  },
];

export const dummyUserAssessment: UserAssessment = {
  uuid: '2e03e9c4-cbae-4c11-8e43-0dc17b257854',
  assessmentEvent: {
    uuid: '472b7379-f9e5-4d4b-857c-1ecdc3932a38',
    assessment: {
      uuid: '890cb1d9-aa6a-492d-be3b-00787cee8b54',
      name: 'Trust',
      overview:
        'Trust is the fundamental cornerstone of relationships and organizational foundations, encapsulating an environment where individuals lean on each other with assurance. It manifests through resource sharing, fostering collective growth as individuals confidently exchange knowledge and expertise. Dependability forms a vital pillar, setting the standard for commitments to be honored and responsibilities fulfilled. Honesty and transparency are intrinsic, establishing a culture where open communication prevails and information is shared with unwavering integrity. Nurturing camaraderie cultivates a sense of community and mutual support within the trust framework. The commitment to constructive conflict resolution mechanisms further solidifies trust, demonstrating a dedication to resolving differences amicably and fortifying the foundation of relational and organizational trust.',
      rank: 4,
      modules: [
        {
          estimatedDuration: 120,
          uuid: '99b7fa1d-f904-4a07-9b66-dc91015f975d',
          name: 'Trust',
          title: 'Trust',
          description:
            'This asesses trust within a team setting gauging the importance of having support, the satisfaction of helping others, the belief in supporting and encouraging team performance, and the level of support provided to team members.',
          rank: 1,
          notes: ['confidentiality'],
          questions: [
            {
              uuid: 'd30a9cd4-8c35-43c7-9a88-b0050a22b954',
              title: 'I feel a sense of support from my team',
              rank: 1,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: '1efad361-011b-4ff2-b5c0-fc3a92086b50',
              title: 'My team helps others achieve their potential',
              rank: 2,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: '7f71a124-942b-4a2c-bf65-c56bf3499f15',
              title:
                'My team members encourage each other to be better performers.',
              rank: 3,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: 'e2e964ec-fe70-45ca-8b56-494a7ee2bce1',
              title:
                'I support my team members no matter how many mistakes they make.',
              rank: 4,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: '9cc1812d-eb2b-40c0-9f1e-b2744caf3f12',
              title: 'I trust my teammates to deliver on their commitments.',
              rank: 5,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: 'caad235d-1296-41a4-85bd-05abe1e58089',
              title:
                "My team members have each other's best interests at heart.",
              rank: 6,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: '4077029e-00ef-4d23-b048-f94cd0ee3bf3',
              title:
                'I can rely on my team members to be honest and transparent with me.',
              rank: 7,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: 'f88e437c-6aeb-499a-8b54-176c8509cf0a',
              title:
                'My team effectively communicates and collaborates to achieve shared goals.',
              rank: 8,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: '00dd83c0-6e43-43da-8fdc-47e48459e955',
              title:
                'I am comfortable with openly sharing my thoughts and ideas with my team members.',
              rank: 9,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: 'e3ef1945-6cb8-40ae-b525-7d2a7b3cec7a',
              title:
                'My team is supportive and respectful towards one another, even when there are disagreements.',
              rank: 10,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: '4f676d73-5a15-4811-af11-023f7105d61b',
              title:
                'I am likely to seek help or support from my team members when I need it.',
              rank: 11,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
          ],
        },
      ],
      slug: 'trust',
    },
    endDate: '2050-03-13T16:23:26.157195+00:00',
    isActive: true,
    startDate: '2022-03-13T16:23:26.157195+00:00',
  },
  userResponses: [
    {
      id: '7538',
      createdAt: '2024-03-13T16:23:26.157195+00:00',
      question_uuid: 'd30a9cd4-8c35-43c7-9a88-b0050a22b954',
      value: '6',
      updatedAt: '2024-03-13T16:23:26.157195+00:00',
    },
  ],
  completedModulesUuid: [],
  status: 'started',
};

export const dummyCandidateAssessment: UserAssessment = {
  uuid: '2e03e9c4-cbae-4c11-8e43-0dc17b257854',
  assessmentEvent: {
    uuid: '472b7379-f9e5-4d4b-857c-1ecdc3932a38',
    assessment: {
      uuid: '890cb1d9-aa6a-492d-be3b-00787cee8b54',
      name: 'Trust',
      overview:
        'Trust is the fundamental cornerstone of relationships and organizational foundations, encapsulating an environment where individuals lean on each other with assurance. It manifests through resource sharing, fostering collective growth as individuals confidently exchange knowledge and expertise. Dependability forms a vital pillar, setting the standard for commitments to be honored and responsibilities fulfilled. Honesty and transparency are intrinsic, establishing a culture where open communication prevails and information is shared with unwavering integrity. Nurturing camaraderie cultivates a sense of community and mutual support within the trust framework. The commitment to constructive conflict resolution mechanisms further solidifies trust, demonstrating a dedication to resolving differences amicably and fortifying the foundation of relational and organizational trust.',
      rank: 4,
      modules: [
        {
          estimatedDuration: 120,
          uuid: '99b7fa1d-f904-4a07-9b66-dc91015f975d',
          name: 'Trust',
          title: 'Trust',
          description:
            'This asesses trust within a team setting gauging the importance of having support, the satisfaction of helping others, the belief in supporting and encouraging team performance, and the level of support provided to team members.',
          rank: 1,
          notes: ['confidentiality'],
          questions: [
            {
              uuid: 'd30a9cd4-8c35-43c7-9a88-b0050a22b954',
              title: 'I feel a sense of support from my team',
              rank: 1,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: '1efad361-011b-4ff2-b5c0-fc3a92086b50',
              title: 'My team helps others achieve their potential',
              rank: 2,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: '7f71a124-942b-4a2c-bf65-c56bf3499f15',
              title:
                'My team members encourage each other to be better performers.',
              rank: 3,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: 'e2e964ec-fe70-45ca-8b56-494a7ee2bce1',
              title:
                'I support my team members no matter how many mistakes they make.',
              rank: 4,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: '9cc1812d-eb2b-40c0-9f1e-b2744caf3f12',
              title: 'I trust my teammates to deliver on their commitments.',
              rank: 5,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: 'caad235d-1296-41a4-85bd-05abe1e58089',
              title:
                "My team members have each other's best interests at heart.",
              rank: 6,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: '4077029e-00ef-4d23-b048-f94cd0ee3bf3',
              title:
                'I can rely on my team members to be honest and transparent with me.',
              rank: 7,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: 'f88e437c-6aeb-499a-8b54-176c8509cf0a',
              title:
                'My team effectively communicates and collaborates to achieve shared goals.',
              rank: 8,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: '00dd83c0-6e43-43da-8fdc-47e48459e955',
              title:
                'I am comfortable with openly sharing my thoughts and ideas with my team members.',
              rank: 9,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: 'e3ef1945-6cb8-40ae-b525-7d2a7b3cec7a',
              title:
                'My team is supportive and respectful towards one another, even when there are disagreements.',
              rank: 10,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
            {
              uuid: '4f676d73-5a15-4811-af11-023f7105d61b',
              title:
                'I am likely to seek help or support from my team members when I need it.',
              rank: 11,
              type: 'seven_point_likert_with_labels',
              options: {
                labels: [
                  'Strongly disagree',
                  'Moderately disagree',
                  'Slightly disagree',
                  'Neutral',
                  'Slightly agree',
                  'Moderately agree',
                  'Strongly agree',
                ],
              },
            },
          ],
        },
      ],
      slug: 'trust',
    },
    endDate: '2050-03-13T16:23:26.157195+00:00',
    isActive: true,
    startDate: '2022-03-13T16:23:26.157195+00:00',
  },
  userResponses: [
    {
      id: '7538',
      createdAt: '2024-03-13T16:23:26.157195+00:00',
      question_uuid: 'd30a9cd4-8c35-43c7-9a88-b0050a22b954',
      value: '6',
      updatedAt: '2024-03-13T16:23:26.157195+00:00',
    },
  ],
  completedModulesUuid: [],
  status: 'started',
};
