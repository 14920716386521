import {CandidateAlignmentResult} from 'interfaces/candidate-alignment-result';

export const dummyCandidateAlignmentResult: CandidateAlignmentResult = {
  candidateResult: {
    id: '108',
    createdAt: '2023-11-15T20:03:47.275979+00:00',
    updatedAt: null,
    moduleName: 'Candidate Alignment',
    moduleSlug: 'candidate_alignment',
    moduleAlgorithm: 'candidate_alignment',
    moduleDescription:
      "This assessment measures the degree to which a candidate's personality traits align with the company's values and culture.",
    moduleResult: {
      alignmentProfile: {
        header: 'Strong Alignment with Company',
        description:
          'This candidate strongly aligns with your core values, particularly "Shared Responsibility” and "Leadership for Tomorrow”.  They will be a strong addition to your culture and flourish in your unique environment.  However, their personality closely mirrors that of several existing team members, which could potentially limit innovation and growth.  Diverse perspectives often drive better innovation, particularity on marketing teams.',
        score: 87,
        valuesAlignment: {
          score: 98,
          description:
            "The candidate's personal values closely align with the company's core values, particularly in terms of equality, justice, and sustainable living.",
        },
        personalityAlignment: {
          score: 76,
          description:
            "The candidate's personality traits align well with the company's average HEXACO scores, indicating a good fit in terms of honesty, emotionality, extroversion, agreeableness, conscientiousness, and openness.",
        },
      },
      positiveNotes: [
        'Innovative Thinking: Their value for societal advancement through innovation would likely inject fresh perspectives and creative ideas into marketing strategies.',
        'Embrace of Technology: With a strong emphasis on integrating humanity with technology, they could contribute to leveraging emerging technologies effectively in marketing campaigns and initiatives.',
        'Collaborative Strength: Their commitment to fostering unity and resilience could enhance team cohesion and effectiveness, promoting a supportive and collaborative work environment.',
        'Continuous Growth Mindset: Their dedication to continuous growth and self-improvement aligns well with the dynamic nature of the marketing industry, driving personal and team development.',
        'Leadership Potential: Their value for visionary leadership indicates potential for assuming leadership roles within the team, inspiring others with their forward-thinking mindset and commitment to innovation.',
      ],
      negativeNotes: [
        "Homogeneity in Thinking: Given their alignment with existing team members' personalities, there may be a risk of reinforcing existing thought patterns and limiting diversity of perspectives, which could hinder innovation.",
        'Overreliance on Technology: While embracing technology is essential, an overemphasis on it may neglect other crucial aspects of marketing, such as human connection and emotional resonance in campaigns.',
        "Resistance to Change: If the candidate's vision of unity and resilience conflicts with existing team dynamics or processes, there could be resistance to change or difficulty integrating their values into the team culture",
        'Potential for Complacency: A strong emphasis on continuous growth may lead to dissatisfaction or disengagement if opportunities for learning and development within the team are perceived as limited or stagnant.',
      ],
      personalityProfile: {
        agreeableness: 63,
        conscientiousness: 60,
        emotionality: 66,
        extroversion: 73,
        honesty: 84,
        openness: 72,
        persona: 'Speaker',
        personaContent: {
          summary:
            'The Pioneer is an adventurous, innovative, and adaptable personality type, bringing enthusiasm and creativity to the workplace.',
          traits: ['Creativity', 'Empathy', 'Curiosity'],
          description: {
            header: 'Excelling in dynamic environments',
            content:
              "The Dreamer's mind weaves a tapestry of vivid imaginations and profound reflections, making them exceptional storytellers, artists, and thinkers. They possess a natural flair for aesthetic beauty and a deep understanding of human emotions, often drawing them to the arts, literature, and philosophy. Their thoughts transcend the mundane, as they seek the ethereal and profound in everyday life.",
          },
          deepDive: {
            header: 'Embracing change and driving innovation',
            content:
              "The Pioneer's natural inclination towards exploration and experimentation makes them highly adaptable to change. They are quick to embrace new ideas and technologies, often leading the way in driving innovation within their team or organization. Their ability to think outside the box and challenge the status quo can inspire others to push boundaries and explore new possibilities. The Pioneer thrives in environments that encourage creativity and provide opportunities for growth and exploration.",
          },
          whenInAWellFunctioningGroup:
            "In a well-functioning group, the Pioneer's adventurous nature and innovative mindset can greatly contribute to the team's success. They are often the ones to propose bold ideas and take calculated risks, pushing the group to explore new avenues and think outside the box. Their adaptability allows them to easily collaborate with others, adjusting their approach to fit the needs of the team. The Pioneer's enthusiasm and creativity can inspire and motivate their teammates, fostering a dynamic and productive work environment.",
          whenOutOfSync:
            "When out of sync with others, the Pioneer may sometimes struggle with following established procedures or conforming to traditional ways of doing things. Their constant need for novelty and change can sometimes lead to impatience or restlessness, which may create tension within the group. It is important for the Pioneer to recognize the value of stability and consistency, and to communicate their ideas and suggestions in a way that is receptive to others' perspectives. Finding a balance between their adventurous nature and the needs of the team is key to maintaining positive relationships and achieving collective goals.",
        },
        personalValues: {
          selfDirection: 71,
          stimulation: 62,
          hedonism: 62,
          achievement: 52,
          power: 67,
          security: 67,
          conformity: 67,
          tradition: 52,
          benevolence: 57,
          universalism: 57,
          userPersonalValues: [
            {
              title: 'Visionary',
              description:
                'They prioritize visionary leadership that energizes and propels the team forward, emphasizing the importance of innovation and diversity for societal advancement.',
            },
            {
              title: 'Unity',
              description:
                'Their ideal society seamlessly integrates humanity with technology, demonstrating a deep appreciation for and commitment to driving technological progress.',
            },
            {
              title: 'Integration',
              description:
                'They envision a community built on unity and resilience, showcasing their dedication to fostering collaborative strength and perseverance in the face of challenges.',
            },
            {
              title: 'Growth',
              description:
                'Their emphasis on continuous growth reflects a personal value of lifelong learning and self-improvement, aligning with the dynamic nature of the marketing industry.',
            },
          ],
        },
      },
    },
  },
  candidateProfile: {
    candidateUuid: '311be6d8-6750-4046-b58f-ce45c346c930',
    jobPositionUuid: 'c53dff0e-1d44-4ded-978d-7907eaddf5d7',
    jobPositionTitle: 'Personal Assistant',
    email: 'jenniferjohnson@instill.co',
    name: 'Jennifer Johnson',
  },
};
