import {createMutation} from 'react-query-kit';
import {JobPosition} from '../../../interfaces/job-position';
import {updateJobPosition} from '../../../repositories/instill';
import {UpdateJobPositionPayload} from '../../../repositories/instill/mutations/update-job-position';
import {useQueryClient} from '@tanstack/react-query';
import {RQ_JOB_POSITION, RQ_JOB_POSITIONS} from '../queries/query-keys';

export const useUpdateJobPosition = () => {
  const queryClient = useQueryClient();

  return createMutation<JobPosition | undefined, UpdateJobPositionPayload>({
    mutationFn: async (variables) => {
      return await updateJobPosition({...variables});
    },
    onSuccess: () => {
      queryClient.refetchQueries([RQ_JOB_POSITIONS]);
      queryClient.invalidateQueries([RQ_JOB_POSITION]);
    },
  })();
};
