export enum SCHEDULE {
  ONE_TIME = 'ONE_TIME',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
}

export const SCHEDULE_TRANSLATION_MAP = {
  [SCHEDULE.ONE_TIME]: 'One Time',
  [SCHEDULE.MONTHLY]: 'Monthly',
  [SCHEDULE.QUARTERLY]: 'Quarterly',
};
