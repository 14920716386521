import {DemoAccountMutationError} from 'constants/errors';

const apiErrorGuard = (cb: (err: string) => void) => (error: unknown) => {
  if (error instanceof DemoAccountMutationError) {
    return;
  }

  if (error instanceof Error) {
    cb(error.message);
    return;
  }

  if (typeof error === 'string') {
    cb(error);
    return;
  }

  if (
    typeof error === 'object' &&
    error &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    cb(error.message);
    return;
  }

  return;
};

export default apiErrorGuard;
