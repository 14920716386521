import {EventName} from 'constants/analytics/event-name';
import {EventType} from 'constants/analytics/event-type';
import {CONTACT_US_URL} from 'constants/contact-us';
import {routeNames} from 'constants/route-names';
import {format} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import {Meeting} from 'interfaces/meeting';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import ApplicationSubHeader from 'ui/@_components/application-sub-header';
import Button from 'ui/@_components/button';
import {ReactComponent as IconPeople} from 'ui/@_components/kit/icons/people.svg';
import {ReactComponent as IconShare} from 'ui/@_components/kit/icons/share.svg';
import {useCurrentAuthUser} from 'ui/@atoms/current-auth-user';
import useAnalytics from 'ui/@hooks/use-analytics';
import useShareUrl from 'ui/@hooks/use-share-url';
import styles from './styles.module.scss';
import {useCurrentCompanyUuid} from 'ui/@atoms/current-company';
import {useUpdateFlowReportShared} from 'ui/@hooks/mutations/use-update-flow-report-shared';

interface ElementProps {
  meeting: Meeting;
}

const SubHeader = (props: ElementProps) => {
  const {meeting} = props;
  const {trackEvent} = useAnalytics();

  const {t} = useTranslation('sense', {
    keyPrefix: 'meetings.show.components.sub-header',
  });

  const {unAuthorizedMeetingRoute} = routeNames;

  const companyUuid = useCurrentCompanyUuid();

  const updateFlowReportShared = useUpdateFlowReportShared();

  const currentUser = useCurrentAuthUser();
  const {onShare} = useShareUrl();

  const meetingStartDate = useMemo(() => {
    if (!meeting) return;

    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return {
      date: format(
        utcToZonedTime(meeting.startDate, browserTimezone),
        'MMM do, yyyy'
      ),
      time: format(
        utcToZonedTime(meeting.startDate, browserTimezone),
        'h:mm a'
      ),
    };
  }, [meeting]);

  const onShareClick = () => {
    trackEvent({
      eventName: EventName.FLOW.REPORT.REPORT_SHARE,
      eventType: EventType.BUTTON_CLICKED,
      eventProperties: {
        name: meeting.name,
      },
    });

    onShare(`${unAuthorizedMeetingRoute}/${meeting.uuid}`);

    if (!companyUuid) return;

    updateFlowReportShared.mutate({
      companyUuid,
    });
  };

  const onGetInstill = () => {
    window.open(CONTACT_US_URL, '_blank');
  };

  return (
    <div className={styles.wrapper}>
      <ApplicationSubHeader>
        <ApplicationSubHeader.Leading>
          <div className={styles.title}>{meeting.name}</div>

          <div className={styles.subText}>
            {meetingStartDate?.date}

            <div className={styles.divider} />

            {meetingStartDate?.time}

            <div className={styles.participantsContainer}>
              <IconPeople />

              {meeting.participantsNames.length}
            </div>
          </div>
        </ApplicationSubHeader.Leading>

        <ApplicationSubHeader.Trailing>
          <Button
            className={styles.iconContainer}
            onClick={currentUser ? onShareClick : onGetInstill}
          >
            {currentUser ? <IconShare /> : t('get-instill')}
          </Button>
        </ApplicationSubHeader.Trailing>
      </ApplicationSubHeader>
    </div>
  );
};

export default SubHeader;
