import {FunctionComponent} from 'react';
import {ReactComponent as IconCheck} from '../../../@components/kit/icons/check.svg';
import {useTranslation} from 'react-i18next';
import headingStyles from '../../../../styles/classes/heading.module.scss';
import styles from './styles.module.scss';

export enum USER_CHECKLIST_STEP_KEYS {
  CREATED_PROFILE = 'createdProfile',
  SIGNED_OFF_ON_VALUES = 'signedOffOnValues',
}

export enum ORGANIZATION_CHECKLIST_STEP_KEYS {
  CODIFIED_VALUE = 'codifiedValue',
  INTEGRATED_SLACK_APP = 'integratedSlackApp',
  INVITED_TEAM = 'invitedTeam',
  SIGNED_OFF_ON_VALUES = 'signedOffOnValues',
}

export interface ChecklistItem {
  isActive: boolean;
  isCompleted?: boolean;
  key: USER_CHECKLIST_STEP_KEYS | ORGANIZATION_CHECKLIST_STEP_KEYS;
  label: string;
}

interface Props {
  checklistData?: ChecklistItem[] | null;
}

const SetupChecklist: FunctionComponent<Props> = ({checklistData}) => {
  const {t} = useTranslation('application');

  return (
    <div>
      <h2 className={headingStyles.h3}>
        {t('components.main-content.setup-checklist.title')}
      </h2>

      <ol className={styles.list}>
        {checklistData?.map((item, index) => {
          let className = [styles.item];

          if (item.isCompleted) {
            className.push(styles.completed);
          } else if (item.isActive) {
            className.push(styles.active);
          }

          return (
            <li key={index} className={className.join(' ')}>
              <div className={styles.itemCheckbox}>
                <IconCheck className={styles.icon} />
              </div>

              <span className={styles.itemText}>{item.label}</span>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default SetupChecklist;
