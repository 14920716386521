import {createQuery} from 'react-query-kit';
import {Dimension} from '../../../interfaces/dimension';
import {fetchDimensions} from '../../../repositories/instill/queries';
import {FetchDimensionsPayload} from '../../../repositories/instill/queries/fetch-dimensions';

/**
 * @deprecated
 */
export const useDimensions = createQuery<
  Dimension[] | undefined,
  FetchDimensionsPayload
>({
  primaryKey: 'dimensions',
  queryFn: async ({queryKey: [_, variables]}) => {
    return await fetchDimensions(variables.companyUuid);
  },
});
