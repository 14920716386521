import {createMutation} from 'react-query-kit';
import {
  ValueGetHelpPayload,
  valuesGetHelp,
} from 'repositories/instill/mutations/values-get-help';
import {ValueGetHelp} from 'interfaces/values-get-help';

export const useValuesGetHelp = createMutation<
  ValueGetHelp | undefined,
  ValueGetHelpPayload
>({
  mutationFn: async (variables) => {
    return await valuesGetHelp(variables);
  },
});
