export enum Plans {
  // user starts with -> FREE_TRAIL_PLAN [until 30days] -> if buys -> some paid plan
  // if doesnt buy -> FREE_PLAN

  FREE_PLAN = 'Foundation',
  FREE_TRAIL_PLAN = 'Free Trial',
  MEASURE_PLAN = 'Measure',
  BUILD_PLAN = 'Build',
  ADVANCE_PLAN = 'Measure with Advance AI',
  EVOLUTION_PLAN = 'Evolution',
  BUILD_MEASURE_PLAN = 'Build & Measure',
  BUILD_ADVANCE_PLAN = 'Build & Measure with Advance AI',
}

export const paidPlans = [
  Plans.MEASURE_PLAN,
  Plans.BUILD_PLAN,
  Plans.ADVANCE_PLAN,
  Plans.EVOLUTION_PLAN,
  Plans.BUILD_MEASURE_PLAN,
  Plans.BUILD_ADVANCE_PLAN,
];

export const numberOfTrailDays = 30;
