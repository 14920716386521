import {getNameInitials} from 'utils/string-utils/name';
import styles from './styles.module.scss';

interface ElementProps {
  name: string;
  useRandomColors?: boolean;
  size?: 'xSmall' | 'small' | 'normal' | 'large' | 'xLarge' | 'xxLarge';
  variant?: 'blue';
}

const AvatarPlaceholder = ({
  name,
  variant,
  size = 'normal',
  useRandomColors,
}: ElementProps) => {
  const variantClass = () => {
    const classes = [];

    if (variant) classes.push(styles[variant]);
    if (size) classes.push(styles[size]);

    return classes.join(' ');
  };

  return (
    <div
      className={`${styles.avatar} ${variantClass()} ${
        useRandomColors ? styles.randomColors : ''
      }`}
      style={{
        backgroundColor: useRandomColors
          ? randomColorsList[
              Math.floor(Math.random() * randomColorsList.length)
            ]
          : '',
      }}
    >
      <div className={styles.initials}>{getNameInitials(name)}</div>
    </div>
  );
};

export default AvatarPlaceholder;

const randomColorsList = [
  '#FBE5FF',
  '#EFE3D9',
  '#DAEAF1',
  '#C5EDF6',
  '#DFE7FD',
  '#FFE6BF',
  '#FFD8BC',
  '#E0FBE2',
  '#E0F9FF',
  '#FFF1E6',
  '#FFE0DA',
  '#E8FAD0',
  '#E7F8FF',
  '#FAEDCD',
  '#F1E4F3',
];
