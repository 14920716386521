import {Fragment} from 'react';
import BaseCard from 'ui/@_components/card/base-card';
import {formatDate} from 'utils/date';
import useFlowData from '../../../../@hooks/use-flow-data';
import styles from './styles.module.scss';

const MEETING_TIMESTAMP_FORMAT = 'HH.mm aaa';

const Transcript = () => {
  const {transcript, meeting} = useFlowData();

  if (!transcript || !meeting) return null;

  const formatTimestamp = (
    meetingStartDate: string,
    offsetInSeconds: number
  ) => {
    const date = new Date(meetingStartDate);

    date.setTime(date.getTime() + offsetInSeconds * 1000);

    return formatDate(date.toString(), MEETING_TIMESTAMP_FORMAT);
  };

  const renderTranscriptContent = (index: number) => {
    if (transcript[index - 1]?.speaker !== transcript[index]?.speaker) {
      return (
        <div className={styles.speaker}>
          {transcript[index]?.speaker || 'Speaker'}
          <span className={styles.speakerTimestamp}>
            {formatTimestamp(meeting.startDate, transcript[index].start)}
          </span>
        </div>
      );
    }

    return null;
  };

  return (
    <BaseCard>
      <p className={styles.title}>Transcript</p>

      <div className={styles.transcriptContainer}>
        {transcript.map((word, index) => (
          <Fragment key={index}>
            {renderTranscriptContent(index)}

            <span className={styles.words}>{word.text}&nbsp;</span>
          </Fragment>
        ))}
      </div>
    </BaseCard>
  );
};

export default Transcript;
