import {buildPageTitle} from '../../services/build-page-title';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import GeneralFooter from 'ui/@_components/general-footer/general-footer';
import MainContent from './@components/main-content/main-content';
import styles from './styles.module.scss';

const PageNotFound = () => {
  const {t} = useTranslation('applicationPageTitle');

  return (
    <>
      <Helmet>
        <title>{buildPageTitle(t('page-title.page-not-found'))}</title>
      </Helmet>
      <div className={styles.pageNotFound}>
        <div className={styles.contentCenter}>
          <MainContent />
        </div>
        <GeneralFooter />
      </div>
    </>
  );
};

export default PageNotFound;
