import {ComponentProps, ReactNode} from 'react';
import BaseCard from '../card/base-card';
import FloatingMenu from '../floating-menu';
import styles from './styles.module.scss';

interface ElementProps {
  icon: ReactNode;
  children: ComponentProps<typeof FloatingMenu>['menu'];
  variant?: ComponentProps<typeof FloatingMenu>['variant'];
}

const FloatingIconMenu = ({icon, children, variant}: ElementProps) => {
  return (
    <FloatingMenu
      toggler={({showMenu}) => (
        <div
          className={`${styles.iconContainer} ${
            showMenu ? styles.openState : ''
          }`}
        >
          {icon}
        </div>
      )}
      menu={(menuParams) => (
        <BaseCard border spacing="xSmall" shadow>
          {children(menuParams)}
        </BaseCard>
      )}
      variant={variant}
    />
  );
};

FloatingIconMenu.Divider = () => {
  return <div className={styles.divider} />;
};

export default FloatingIconMenu;
