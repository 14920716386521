import {createMutation} from 'react-query-kit';
import {
  deleteJobPositionPayload,
  deleteJobPosition,
} from '../../../repositories/instill/mutations/delete-job-position';
import {JobPosition} from '../../../interfaces/job-position';
import {useQueryClient} from '@tanstack/react-query';

export const useDeleteJobPosition = () => {
  const queryClient = useQueryClient();

  return createMutation<JobPosition | undefined, deleteJobPositionPayload>({
    mutationFn: async (variables) => {
      return await deleteJobPosition({...variables});
    },
    onSuccess: () => {
      queryClient.refetchQueries(['jobPositions']);
    },
  })();
};
