import {SENTIMENTAL_ANALYSIS_COLORS} from 'constants/sentimental-analysis';
import {SentimentalAnalysis as SentimentalAnalysisInterface} from 'interfaces/meeting';
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import {useTranslation} from 'react-i18next';
import TextCard from 'ui/@_components/card/text-card';
import styles from './styles.module.scss';

const strokeWidth = 5;

interface ElementProps {
  analysis: SentimentalAnalysisInterface;
}
const ToneAnalysis = ({analysis}: ElementProps) => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.components.meeting-details.components.sentimental-analysis',
  });

  return (
    <TextCard>
      <TextCard.Header title={t('title')} />
      <TextCard.Content>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <CircularProgressbar
              value={analysis.positive}
              text={t('percentage.label', {
                percentage: analysis.positive,
              })}
              className={styles.progressbar}
              strokeWidth={strokeWidth}
              styles={buildStyles(SENTIMENTAL_ANALYSIS_COLORS.POSITVE)}
            />
            {t('percentage.positive.label')}
          </li>

          <li className={styles.listItem}>
            <CircularProgressbar
              value={analysis.neutral}
              text={t('percentage.label', {
                percentage: analysis.neutral,
              })}
              className={styles.progressbar}
              strokeWidth={strokeWidth}
              styles={buildStyles(SENTIMENTAL_ANALYSIS_COLORS.NEUTRAL)}
            />
            {t('percentage.neutral.label')}
          </li>

          <li className={styles.listItem}>
            <CircularProgressbar
              value={analysis.negative}
              text={t('percentage.label', {
                percentage: analysis.negative,
              })}
              className={styles.progressbar}
              strokeWidth={strokeWidth}
              styles={buildStyles(SENTIMENTAL_ANALYSIS_COLORS.NEGATIVE)}
            />
            {t('percentage.negative.label')}
          </li>
        </ul>
      </TextCard.Content>
    </TextCard>
  );
};

export default ToneAnalysis;
