import {createQuery} from 'react-query-kit';
import {dummyCompanyCoreValues} from 'ui/@demo-data/company-core-values';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {CompanyCoreValue} from '../../../interfaces/company-core-value';
import {fetchCompanyCoreValues} from '../../../repositories/instill';
import {CompanyCoreValuePayload} from '../../../repositories/instill/queries/fetch-company-core-value';
import {sortByNameAndDescription} from '../../../utils/sort-by/name-and-description';
import {RQ_COMPANY_CORE_VALUES} from './query-keys';

export const useCompanyCoreValues = createQuery<
  CompanyCoreValue[] | undefined,
  CompanyCoreValuePayload
>({
  primaryKey: RQ_COMPANY_CORE_VALUES,
  queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
    const data = await fetchCompanyCoreValues(variables.companyUuid);

    return data ? sortByNameAndDescription(data) : undefined;
  }, dummyCompanyCoreValues),
  enabled: (data, variables) => {
    return !!variables.companyUuid;
  },
});
