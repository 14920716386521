import {useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {fetchPresignedUploadURLResourceType} from 'repositories/instill/queries/fetch-presigned-url-config';
import Button from 'ui/@_components/button';
import {ReactComponent as CloseIcon} from 'ui/@_components/kit/icons/close.svg';
import {ReactComponent as EditIcon} from 'ui/@_components/kit/icons/edit.svg';
import {
  useFetchPresignedUploadUrl,
  useUploadToPresignedUrl,
} from 'ui/@hooks/mutations';
import {useFormControlContext} from '../form-control';
import IsUploading from './@components/is-uploading/is-uploading';
import UploadingInfo from './@components/uploading-info/uploading-info';
import styles from './styles.module.scss';
import {useUploadedFileLocalUrlsMapState} from './@atoms/uploaded-files-local-url-map';
import {toast} from 'react-toastify';
import DynamicMedia from 'ui/@_components/dynamic-media';

const SUPPORTED_IMAGE_FILES = ['.png', '.gif', '.jpeg', '.jpg'] as const;
const SUPPORTED_VIDEO_FILES = ['.mp4'] as const;
const SUPPORTED_DOCUMENT_FILES = ['.pdf'] as const;

type AcceptedFiles =
  | (typeof SUPPORTED_IMAGE_FILES)[number]
  | (typeof SUPPORTED_VIDEO_FILES)[number]
  | (typeof SUPPORTED_DOCUMENT_FILES)[number];

export interface FileInput {
  url?: string;
  type?: 'video' | 'image' | 'document';
}

type ElementProps = {
  title: string;
  acceptedFileTypes?: AcceptedFiles[];
  maxFileSizeInMb?: number;
  files: FileInput[];
  alt?: string;
  resource: fetchPresignedUploadURLResourceType;
  companyUuid?: string;
  setFiles: (newFiles: FileInput[]) => void;
} & (
  | {
      isMultiAllowed?: true;
    }
  | {
      isMultiAllowed?: false;
    }
);

type IsUploadingInfo = Record<
  number,
  {
    name: string;
    size: number;
  } | null
>;

const UploadArea = ({
  title,
  acceptedFileTypes = ['.jpg', '.jpeg', '.png'],
  maxFileSizeInMb = 3,
  isMultiAllowed = false,
  files,
  setFiles,
  resource,
  companyUuid,
  alt,
}: ElementProps) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'upload-area',
  });

  const {hasError} = useFormControlContext();

  const fetchPresignedUploadURL = useFetchPresignedUploadUrl();
  const uploadToPresignedURL = useUploadToPresignedUrl();
  const [isUploading, setIsUploading] = useState<IsUploadingInfo>({});

  const [uploadedFileLocalUrlsMap, setUploadedFileLocalUrlsMap] =
    useUploadedFileLocalUrlsMapState();

  const acceptedImageTypes = acceptedFileTypes.filter((file) =>
    SUPPORTED_IMAGE_FILES.some((imageFileType) => imageFileType === file)
  );

  const acceptedVideoTypes = acceptedFileTypes.filter((file) =>
    SUPPORTED_VIDEO_FILES.some((videoFileType) => videoFileType === file)
  );

  const acceptedApplicationTypes = acceptedFileTypes.filter((file) =>
    SUPPORTED_DOCUMENT_FILES.some(
      (documentFileType) => documentFileType === file
    )
  );

  let accept = {};

  if (acceptedImageTypes.length) {
    accept = {
      ...accept,
      'image/*': acceptedImageTypes,
    };
  }

  if (acceptedVideoTypes.length) {
    accept = {
      ...accept,
      'video/*': acceptedVideoTypes,
    };
  }

  if (acceptedApplicationTypes.length) {
    accept = {
      ...accept,
      'application/*': acceptedApplicationTypes,
    };
  }

  const uploadFile = async (file: File, fileIndex: number) => {
    if (isMultiAllowed) {
      setIsUploading((prev) => ({
        ...prev,
        [fileIndex]: {
          name: file.name,
          size: file.size,
        },
      }));
    } else {
      setIsUploading({
        0: {
          name: file.name,
          size: file.size,
        },
      });
    }

    const config = await fetchPresignedUploadURL.mutateAsync({
      ressource: resource,
      mime: file.type,
      extension: file.name.split('.').pop() || '',
      companyUuid: companyUuid || undefined,
    });

    if (!config) return;

    const bucketURL = await uploadToPresignedURL.mutateAsync({
      config,
      file,
    });

    if (isMultiAllowed) {
      setIsUploading((prev) => ({
        ...prev,
        [fileIndex]: null,
      }));
    } else {
      setIsUploading({
        0: null,
      });
    }

    const fileType = file.type.split('/')[0];
    let type: FileInput['type'] | null = null;

    if (fileType === 'application') {
      type = 'document';
    } else if (fileType === 'video' || fileType === 'image') {
      type = fileType;
    } else {
      throw new Error(t('incorrect-file'));
    }

    if (!bucketURL) {
      throw new Error(t('upload-error'));
    }

    setUploadedFileLocalUrlsMap((prev) => ({
      ...prev,
      [bucketURL]: URL.createObjectURL(file),
    }));

    return {url: bucketURL, type};
  };

  const {getRootProps, getInputProps, open} = useDropzone({
    accept,
    maxSize: maxFileSizeInMb * 1000 * 1000,
    multiple: isMultiAllowed,
    noClick: true,
    onDropRejected(fileRejections, event) {
      for (let i = 0; i < fileRejections.length; i++) {
        const file = fileRejections[i].file;
        if (file.size > maxFileSizeInMb * 1000 * 1000) {
          toast.error(t('file-too-big', {maxFileSizeInMb}));
        }
      }
    },
    onDrop: async (droppedFiles) => {
      const uploadPromises = droppedFiles.map((file, fileIndex) =>
        uploadFile(file, Object.keys(isUploading).length + fileIndex)
      );
      const uploadedFiles = await Promise.allSettled(uploadPromises);

      const filteredFiles =
        uploadedFiles
          ?.filter((uploadedFile) => uploadedFile.status === 'fulfilled')
          .map((file) => {
            if (file.status === 'fulfilled') {
              return file.value || {};
            }

            return {};
          }) || [];

      if (isMultiAllowed) {
        setFiles([...files, ...filteredFiles]);
      } else {
        setFiles([filteredFiles[0]]);
      }
    },
  });

  let className = styles.uploadArea;

  if (hasError) {
    className += ` ${styles.error}`;
  }

  if (!isMultiAllowed && files[0]?.url) {
    return (
      <div {...getRootProps()} className={className}>
        {isUploading[0] ? (
          <IsUploading />
        ) : (
          <>
            {!!files[0]?.type && (
              <DynamicMedia
                height="100px"
                width="200px"
                type={files[0].type}
                url={uploadedFileLocalUrlsMap[files[0].url] || files[0].url}
                className={styles.fullImage}
                alt={alt || ''}
              />
            )}

            <div className={styles.iconsWrapper}>
              <button
                type="button"
                className={styles.iconContainer}
                onClick={open}
              >
                <EditIcon className={styles.icon} />
              </button>
              <button
                type="button"
                className={styles.iconContainer}
                onClick={() => setFiles([])}
              >
                <CloseIcon className={styles.icon} />
              </button>
            </div>
            <input {...getInputProps()} />
          </>
        )}
      </div>
    );
  }

  return (
    <div className={styles.rootContainer}>
      <div {...getRootProps()} className={className}>
        {!isMultiAllowed && isUploading[0] ? (
          <IsUploading />
        ) : (
          <>
            <div className={styles.noFiles}>
              <div>
                <p className={styles.title}>{title}</p>
                <p className={styles.uploadDescription}>
                  {t('accepted-files', {
                    files: acceptedFileTypes.join(', '),
                  })}
                </p>
                <p className={styles.uploadDescription}>
                  {t('max-file-size', {
                    size: maxFileSizeInMb,
                  })}
                </p>
              </div>
              <Button variant="secondary" type="button" onClick={open}>
                {t('browse')}
              </Button>
            </div>
            <input {...getInputProps()} />
          </>
        )}
      </div>
      {isMultiAllowed && (
        <>
          <div className={styles.multiImgContainer}>
            {files.map((file, index) => (
              <div key={index} className={styles.imageContainer}>
                {!!file.type && (
                  <DynamicMedia
                    width="136px"
                    height="94px"
                    type={file.type}
                    url={
                      uploadedFileLocalUrlsMap[file.url || ''] || file.url || ''
                    }
                    className={styles.multiImg}
                    alt={alt || ''}
                  />
                )}

                <button
                  type="button"
                  className={`${styles.iconContainer} ${styles.multiImgClose}`}
                  onClick={() => {
                    setFiles(files.filter((_, idx) => idx !== index));
                  }}
                >
                  <CloseIcon className={styles.icon} />
                </button>
              </div>
            ))}
          </div>
          <div className={styles.uploadInfoWrapper}>
            {Object.entries(isUploading)
              .filter(([fileIdx, fileUploadingInfo]) => fileUploadingInfo)
              .map(([fileIdx, fileUploadingInfo], index) => (
                <UploadingInfo
                  fileName={fileUploadingInfo?.name || ''}
                  fileSize={fileUploadingInfo?.size || 0}
                  key={index}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default UploadArea;
