import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import BaseCard from 'ui/@_components/card/base-card';
import Spinner from 'ui/@_components/spinner';
import {useCandidateAlignmentResult} from 'ui/@hooks/queries/use-candidate-alignment-result';
import Hexaco from './@components/hexaco/hexaco';
import Item from './@components/item';
import styles from './styles.module.scss';

const PersonalityProfileInResult = () => {
  const {t} = useTranslation('candidateCalibration', {
    keyPrefix:
      'job-positions.show.report.components.main-content.components.personality-profile',
  });

  const {candidateId} = useParams();

  const candidateAlignmentResult = useCandidateAlignmentResult({
    variables: {
      candidateUuid: candidateId,
    },
  });

  if (candidateAlignmentResult.isLoading) return <Spinner />;

  if (!candidateAlignmentResult.data) return <></>;

  const {personalityProfile: data} =
    candidateAlignmentResult.data.candidateResult.moduleResult;

  return (
    <BaseCard>
      <div className={styles.wrapper}>
        <div className={styles.profileValueContainer}>
          <Item
            tags={data.personaContent.traits}
            title={t('profile')}
            subtitle={data.personaContent.description.content}
          />

          <Item
            tags={data.personalValues.userPersonalValues.map(
              (value) => value.title
            )}
            title={t('values')}
            subtitle={data.personalValues.userPersonalValues.map(
              (value) => value.description
            )}
          />
        </div>

        <div>
          <Hexaco
            hexacoScore={{
              agreeableness: data.agreeableness,
              conscientiousness: data.conscientiousness,
              emotionality: data.emotionality,
              extroversion: data.extroversion,
              honesty: data.honesty,
              openness: data.openness,
            }}
          />
        </div>
      </div>
    </BaseCard>
  );
};

export default PersonalityProfileInResult;
