export const SENTIMENTAL_ANALYSIS_COLORS = {
  POSITVE: {
    pathColor: '#2961f6',
    trailColor: '#EFF0F3',
  },
  NEUTRAL: {
    pathColor: '#978fed',
    trailColor: '#EFF0F3',
  },
  NEGATIVE: {
    pathColor: '#cd0f6a',
    trailColor: '#EFF0F3',
  },
};
