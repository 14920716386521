import {FunctionComponent, PropsWithChildren} from 'react';
import BaseCard from '../card/base-card';
import Leading from './@components/leading';
import Trailing from './@components/trailing';
import styles from './styles.module.scss';

interface SubHeaderComponent extends FunctionComponent<PropsWithChildren> {
  Leading: typeof Leading;
  Trailing: typeof Trailing;
}

const ApplicationSubHeader: SubHeaderComponent = ({children}) => {
  return (
    <div className={styles.wrapper}>
      <BaseCard spacing="small">
        <div className={styles.container}>{children}</div>
      </BaseCard>
    </div>
  );
};

ApplicationSubHeader.Leading = Leading;
ApplicationSubHeader.Trailing = Trailing;

export default ApplicationSubHeader;
