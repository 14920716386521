import {DemoAccountType, demoAccountType} from 'constants/demo-account';
import {Company} from 'interfaces/company';

export const dummyCompanyData: () => Company = () => {
  if (demoAccountType === DemoAccountType.CULTURE_FORCE) {
    return {
      companyLogo: '/static/demo-account/culture-force.png',
      companySettings: {
        isHRISProviderUKG: true,
      },
      createdAt: '2024-03-19T09:00:00Z',
      creatorUuid: '1a2b3c4d',
      emailDomain: 'example.com',
      id: 'abcd1234',
      industry: 'Technology',
      isActive: true,
      isAppInstalled: true,
      isCultureDefined: true,
      isDashboardActive: true,
      isOnboarded: true,
      isSignatureRequiredOnValues: false,
      leadershipManifesto:
        'At Culture Force, we believe leadership is about fostering a culture of continuous improvement, open communication, and collective accountability. It’s our commitment to lead with integrity, inspire innovation, and empower every team member to contribute to our shared vision. We strive to be a beacon of inclusivity and collaboration, setting the standard for excellence in our industry.',
      mission:
        "We're in the business of making work better. How? By elevating employee engagement, helping leadership understand the team's needs, aligning everyone's efforts, and, oh, by the way, it tends to boost the bottom line. When work clicks, everything just falls into place. Join us on a journey towards building empowered, self-led teams! We welcome you to complete our contact form for a complimentary consultation, unlocking the potential for positive change with Culture Force by your side.",
      name: 'Tech Innovations Inc.',
      registrationStatus: 1,
      size: 'Large',
      slackTeamId: 'xyz123',
      updatedAt: '2024-03-19T09:30:00Z',
      uuid: '5678efgh',
      vision:
        "Our Vision is to provide leaders with the tools to instigate positive cultural change across the globe. Why? Because a whopping 79% of folks are ditching their jobs thanks to subpar leadership. Culture Force firmly believes there's a better way. By cultivating an environment where self-led teams can thrive, we're all about letting people do what they love and excel at – no more being micromanaged by someone who doesn't see your true value. You don't need a corner office or a fancy title; all it takes is a willingness to lead.",
      websiteUrl: 'https://example.com',
      hideSmallTeamScores: false,
    };
  }

  return {
    companyLogo: '/static/demo-account/company-logo.png',
    companySettings: {
      isHRISProviderUKG: true,
    },
    createdAt: '2024-03-19T09:00:00Z',
    creatorUuid: '1a2b3c4d',
    emailDomain: 'example.com',
    id: 'abcd1234',
    industry: 'Technology',
    isActive: true,
    isAppInstalled: true,
    isCultureDefined: true,
    isDashboardActive: true,
    isOnboarded: true,
    isSignatureRequiredOnValues: false,
    leadershipManifesto:
      'At Instill, we believe leadership is about fostering a culture of continuous improvement, open communication, and collective accountability. It’s our commitment to lead with integrity, inspire innovation, and empower every team member to contribute to our shared vision. We strive to be a beacon of inclusivity and collaboration, setting the standard for excellence in our industry.',
    mission:
      'We empower organizations to cultivate vibrant, high-performing cultures by leveraging cutting-edge technology to augment the human experience at work.',
    name: 'Tech Innovations Inc.',
    registrationStatus: 1,
    size: 'Large',
    slackTeamId: 'xyz123',
    updatedAt: '2024-03-19T09:30:00Z',
    uuid: '5678efgh',
    vision:
      'To reimagine the standard for organizational culture globally, enhancing individual and team performance, well-being, and transformative impact.',
    websiteUrl: 'https://example.com',
    hideSmallTeamScores: false,
  };
};
