import {Actions} from 'constants/permissions/actions';
import {Features} from 'constants/plan-access/features';
import {useHasPermission} from 'ui/@_components/has-permission';
import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';
import useHasPlanAccess from 'ui/@hooks/use-has-plan-access';
import {useFullUserProfile} from './queries';

export enum GETTING_STARTED_STEP {
  SYNC_CALENDAR = 'sync-calendar',
  AI_ADDED = 'ai-added',
  SEE_FLOW_REPORT = 'see-flow-report',
  SHARE_FLOW_REPORT = 'share-flow-report',
  INVITE_USER = 'invite-user',
  ADD_COMPANY_VALUES = 'add-company-values',
  SIGN_OFF_COMPANY_VALUES = 'sign-off-company-values',
  PERSONALITY_TEST = 'personality-test',
  PROFILE_SETUP = 'profile-setup',
  PROFILE_SETUP_SUCCESS = 'profile-setup-success',
  SELECT_BUSINESS_OUTCOMES = 'select-business-outcomes',
  CREATE_CULTURE_KPI = 'create-culture-kpi',
  CONFIGURE_ASSESSMENT = 'configure-assessment',
  HIRING_ASSESSMENT = 'hiring-assessment',
  VIEW_BILLING = 'view-billing',
  COMPLETED = 'completed',
}

const useGettingStartedStep = () => {
  const companyUuid = useSafeCurrentCompanyUuid();

  const fullOnboardingChecklist = useFullUserProfile({
    variables: {
      companyUuid,
    },
    select(data) {
      return data?.fullOnboardingChecklist;
    },
  });

  const hasPermission = useHasPermission();

  const {hasPlanAccess} = useHasPlanAccess();

  const allSteps = [
    {
      stepId: GETTING_STARTED_STEP.SYNC_CALENDAR,
      stepCount: 1,
      hasPlanAccess: hasPlanAccess(Features.INSTILL_FLOW),
      hasPermission: true,
      stepDoneCondition:
        !!fullOnboardingChecklist.data?.hasGeneratedMeetingReport ||
        !!fullOnboardingChecklist.data?.hasSyncedCalendar,
      description:
        'required to be done if calendar is not synced or no meeting report is generated',
    },
    {
      stepId: GETTING_STARTED_STEP.AI_ADDED,
      stepCount: 0,
      hasPlanAccess: hasPlanAccess(Features.INSTILL_FLOW),
      hasPermission: true,
      stepDoneCondition:
        !!fullOnboardingChecklist.data?.hasGeneratedMeetingReport,
      description:
        'required to be done if calendar no meeting report is generated',
    },
    {
      stepId: GETTING_STARTED_STEP.SEE_FLOW_REPORT,
      stepCount: 1,
      hasPlanAccess: hasPlanAccess(Features.INSTILL_FLOW),
      hasPermission: true,
      stepDoneCondition:
        !!fullOnboardingChecklist.data?.hasSeenFirstMeetingReport,
      description: 'required to be done if user has not seen any flow report',
    },
    {
      stepId: GETTING_STARTED_STEP.SHARE_FLOW_REPORT,
      stepCount: 1,
      hasPlanAccess: hasPlanAccess(Features.INSTILL_FLOW),
      hasPermission: true,
      stepDoneCondition:
        !!fullOnboardingChecklist.data?.hasSharedFirstMeetingReport,
      description: 'required to be done if user has not shared any flow report',
    },
    {
      stepId: GETTING_STARTED_STEP.INVITE_USER,
      stepCount: 1,
      hasPlanAccess: hasPlanAccess(Features.PEOPLE_AND_TEAMS),
      hasPermission: hasPermission(Actions.ADD_EMPLOYEE),
      stepDoneCondition:
        !!fullOnboardingChecklist.data?.hasInvitedFirstUser ||
        !!fullOnboardingChecklist.data?.skippedUserInvite,
      description:
        'done if user has invited any other user or he has skipped user invite',
    },
    {
      stepId: GETTING_STARTED_STEP.ADD_COMPANY_VALUES,
      stepCount: 1,
      hasPlanAccess: hasPlanAccess(Features.VALUES_DASHBOARD),
      hasPermission: hasPermission(Actions.ADD_VALUE),
      stepDoneCondition: !!fullOnboardingChecklist.data?.hasAddedCompanyValues,
      description:
        'required to be done if company values have not been created',
    },
    {
      stepId: GETTING_STARTED_STEP.PERSONALITY_TEST,
      stepCount: 1,
      hasPlanAccess: hasPlanAccess(Features.ASSESSMENTS_POLLS),
      hasPermission: true,
      stepDoneCondition:
        !!fullOnboardingChecklist.data?.hasTakenPersonalityTest,
      description: 'required to be done if user has not taken personality test',
    },
    {
      stepId: GETTING_STARTED_STEP.PROFILE_SETUP,
      stepCount: 1,
      hasPlanAccess: hasPlanAccess(Features.PEOPLE_AND_TEAMS),
      hasPermission: true,
      stepDoneCondition: !!fullOnboardingChecklist.data?.hasCompletedProfile,
      description: 'required to be done if user profile is not completed',
    },
    {
      stepId: GETTING_STARTED_STEP.PROFILE_SETUP_SUCCESS,
      stepCount: 0,
      hasPlanAccess: hasPlanAccess(Features.PEOPLE_AND_TEAMS),
      hasPermission: true,
      stepDoneCondition:
        !!fullOnboardingChecklist.data?.hasCompletedProfile &&
        !!fullOnboardingChecklist.data?.hasAnyPersonalActionStep,
      description:
        'done if user profile is completed and personal action step is created',
    },
    {
      stepId: GETTING_STARTED_STEP.SELECT_BUSINESS_OUTCOMES,
      stepCount: 1,
      hasPlanAccess: hasPlanAccess(Features.BUSINESS_OUTCOMES),
      hasPermission: hasPermission(Actions.EDIT_BUSINESS_OUTCOMES),
      stepDoneCondition:
        !!fullOnboardingChecklist.data?.hasSelectedBusinessOutcomes,
      description:
        'required to be done if business outcomes have not been selected',
    },
    {
      stepId: GETTING_STARTED_STEP.CREATE_CULTURE_KPI,
      stepCount: 1,
      hasPlanAccess: hasPlanAccess(Features.CULTURE_KPI),
      hasPermission: hasPermission(Actions.MODIFY_CULTURE_KPI),
      stepDoneCondition: !!fullOnboardingChecklist.data?.hasCreatedCultureKpi,
      description: 'required to be done if culture kpi has not been created',
    },
    {
      stepId: GETTING_STARTED_STEP.CONFIGURE_ASSESSMENT,
      stepCount: 1,
      hasPlanAccess: hasPlanAccess(Features.ASSESSMENTS_POLLS),
      hasPermission: hasPermission(Actions.VIEW_MANAGE_ASSESSMENTS),
      stepDoneCondition:
        !!fullOnboardingChecklist.data?.hasConfiguredAssessment,
      description: 'required to be done if assessment is not configured',
    },
    {
      stepId: GETTING_STARTED_STEP.HIRING_ASSESSMENT,
      stepCount: 1,
      hasPlanAccess: hasPlanAccess(Features.CANDIDATE_CALIBRATION),
      hasPermission: hasPermission(Actions.VIEW_CANDIDATE_CALIBRATION),
      stepDoneCondition: !!fullOnboardingChecklist.data?.hasInvitedCandidates,
      description:
        'required to be done if no candidates have been invited for hiring assessment',
    },
    {
      stepId: GETTING_STARTED_STEP.SIGN_OFF_COMPANY_VALUES,
      stepCount: () => {
        // ? Sign off step is not there in case company values are not created
        if (!fullOnboardingChecklist.data?.hasAddedCompanyValues) {
          return 0;
        }

        // ? Sign off step is not there in case user has created value
        if (fullOnboardingChecklist.data?.hasCreatedValue) {
          return 0;
        }

        return 1;
      },
      hasPlanAccess: hasPlanAccess(Features.VALUES_DASHBOARD),
      hasPermission: true,
      stepDoneCondition: () => {
        if (!fullOnboardingChecklist.data?.hasAddedCompanyValues) {
          return true;
        }

        if (fullOnboardingChecklist.data?.hasSignedOff) {
          return true;
        }

        if (fullOnboardingChecklist.data?.hasCreatedValue) {
          return true;
        }

        return false;
      },
      description:
        'required to be done if company values are there but user has not signed of or not created values',
    },
    {
      stepId: GETTING_STARTED_STEP.VIEW_BILLING,
      stepCount: 0,
      hasPlanAccess: true,
      hasPermission: hasPermission(Actions.VIEW_BILLING),
      stepDoneCondition: false,
      description: 'for final UI in case of permission of billing',
    },
    {
      stepId: GETTING_STARTED_STEP.COMPLETED,
      stepCount: 0,
      hasPlanAccess: true,
      hasPermission: true,
      stepDoneCondition: false,
      description: 'for final UI in case not has permission of billing',
    },
  ];

  const getCurrentGettingStartedStep = () => {
    for (let i = 0; i < allSteps.length; i++) {
      const step = allSteps[i];

      if (step.hasPlanAccess && step.hasPermission) {
        const stepDoneCondition =
          typeof step.stepDoneCondition === 'function'
            ? step.stepDoneCondition()
            : step.stepDoneCondition;

        if (!stepDoneCondition) {
          return step.stepId;
        }
      }
    }

    return null;
  };

  const getCompletedStepsCount = () => {
    let completedStepsCount = 0;

    for (let i = 0; i < allSteps.length; i++) {
      const step = allSteps[i];

      if (step.hasPlanAccess && step.hasPermission) {
        const stepDoneCondition =
          typeof step.stepDoneCondition === 'function'
            ? step.stepDoneCondition()
            : step.stepDoneCondition;
        const stepCount =
          typeof step.stepCount === 'function'
            ? step.stepCount()
            : step.stepCount;

        if (stepDoneCondition) {
          completedStepsCount += stepCount;
        }
      }
    }

    return completedStepsCount;
  };

  const getTotalStepsCount = () => {
    let totalStepsCount = 0;

    for (let i = 0; i < allSteps.length; i++) {
      const step = allSteps[i];

      if (step.hasPlanAccess && step.hasPermission) {
        const stepCount =
          typeof step.stepCount === 'function'
            ? step.stepCount()
            : step.stepCount;

        totalStepsCount += stepCount;
      }
    }

    return totalStepsCount;
  };

  const currentGettingStartedStep = getCurrentGettingStartedStep();

  const getIsCurrentStepAfterGivenStep = (step: GETTING_STARTED_STEP) => {
    const currentStepIdx = allSteps.findIndex(
      (stepObj) => stepObj.stepId === currentGettingStartedStep
    );

    const givenStepIdx = allSteps.findIndex(
      (stepObj) => stepObj.stepId === step
    );

    return currentStepIdx > givenStepIdx;
  };

  return {
    currentGettingStartedStep,
    isGettingStartedCompleted:
      currentGettingStartedStep === GETTING_STARTED_STEP.COMPLETED ||
      currentGettingStartedStep === GETTING_STARTED_STEP.VIEW_BILLING,
    totalSteps: getTotalStepsCount(),
    completedStepsCount: getCompletedStepsCount(),
    getIsCurrentStepAfterGivenStep,
  };
};

export default useGettingStartedStep;
