import {ValuesFromSpecialist} from 'interfaces/culture-design-specialist';
import {createMutation} from 'react-query-kit';
import {GenerateValuesFromCultureSpecialistPayload} from 'repositories/instill/mutations/generate-values-from-culture-specialist';
import {generateValuesFromCultureSpecialist} from '../../../repositories/instill';

export const useGenerateValuesFromCultureSpecialist = createMutation<
  ValuesFromSpecialist[] | undefined,
  GenerateValuesFromCultureSpecialistPayload
>({
  mutationFn: async (variables) => {
    return await generateValuesFromCultureSpecialist({
      sessionUuid: variables.sessionUuid,
      companyUuid: variables.companyUuid,
    });
  },
  retry: 40,
  retryDelay: 5000,
});
