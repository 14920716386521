import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface BusinessOutcomesPayload {
  companyUuid: string;
}

export async function fetchBusinessOutcomes(companyUuid: string) {
  try {
    const response = await InstillAPI.get(
      `/companies/${companyUuid}/business_outcomes`
    );

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return response.data as string[];
  } catch (error) {
    console.error(error);
  }
}
