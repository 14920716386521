import {Actions} from 'constants/permissions/actions';
import {useTranslation} from 'react-i18next';
import Button from 'ui/@_components/button';
import CardCurvedDesign from 'ui/@_components/card-curved-design';
import {useHasPermission} from 'ui/@_components/has-permission';
import ValuesDashboardPreviewUrl from 'ui/@_components/kit/images/preview/values-dashboard-preview.png';
import styles from './styles.module.scss';
import {useCustomerPortal} from 'ui/@hooks/queries/use-customer-portal';
import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';

interface ElementProps {
  onRequestAccess: () => void;
}

const UserLimitCard = ({onRequestAccess}: ElementProps) => {
  const {t} = useTranslation('moduleComponents', {
    keyPrefix: 'modals.invite-user.components.user-limit-card',
  });

  const hasPermission = useHasPermission();

  const companyUuid = useSafeCurrentCompanyUuid();

  const customerPortal = useCustomerPortal({
    variables: {
      companyUuid,
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.rightContent}>
        <p className={styles.title}>{t('title')}</p>

        <p className={styles.subtitle}>{t('subtitle')}</p>

        {hasPermission(Actions.UPDATE_PLAN) ? (
          !!customerPortal.data?.url && (
            <Button as="link" to={customerPortal.data.url}>
              {t('buy-more-seats')}
            </Button>
          )
        ) : (
          <Button onClick={onRequestAccess}>{t('contact-admin')}</Button>
        )}
      </div>

      <CardCurvedDesign
        size="medium"
        containerStyles={{
          height: '120%',
        }}
      >
        <img
          className={styles.previewImage}
          src={ValuesDashboardPreviewUrl}
          alt={'Values Preview'}
          height="150px"
          width="210px"
          loading="lazy"
        />
      </CardCurvedDesign>
    </div>
  );
};

export default UserLimitCard;
