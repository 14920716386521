import {useEffect, useRef, useState} from 'react';

interface HookProps {
  interval?: number;
  isCompleted?: boolean;
}

const useDeterminateProgressBar = ({
  interval = 800,
  isCompleted = false,
}: HookProps = {}) => {
  const timer = useRef<NodeJS.Timer>();
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    timer.current = setInterval(() => {
      setPercentage((prevPercent) =>
        prevPercent <= 90 ? prevPercent + 5 : prevPercent
      );
    }, interval);
    return () => {
      clearInterval(timer.current);
    };
  }, [interval]);

  useEffect(() => {
    if (isCompleted) {
      clearInterval(timer.current);
      setPercentage(100);
    }
  }, [isCompleted]);

  return percentage;
};

export default useDeterminateProgressBar;
