import {useQueryClient} from '@tanstack/react-query';
import {createMutation} from 'react-query-kit';
import {updateUserSettings} from '../../../repositories/instill';
import {UpdateUserSettingsPayload} from '../../../repositories/instill/mutations/update-user-settings';
import {RQ_FULL_USER_PROFILE} from '../queries/query-keys';

export const useUpdateUserSettings = () => {
  const queryClient = useQueryClient();

  return createMutation<string | undefined, UpdateUserSettingsPayload>({
    mutationFn: async (variables) => {
      return updateUserSettings({
        companyUuid: variables.companyUuid,
        payload: variables.payload,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([RQ_FULL_USER_PROFILE]);
    },
  })();
};
