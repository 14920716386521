import {routeNames} from 'constants/route-names';
import {format} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import Button from 'ui/@_components/button';
import BaseCard from 'ui/@_components/card/base-card';
import FloatingIconMenu from 'ui/@_components/floating-icon-menu';
import {ReactComponent as IconMore} from 'ui/@_components/kit/icons/more.svg';
import Spinner from 'ui/@_components/spinner';
import {useCandidateAlignmentResult} from 'ui/@hooks/queries/use-candidate-alignment-result';
import useDisclosure from 'ui/@hooks/use-disclosure';
import useShareUrl from 'ui/@hooks/use-share-url';
import {printPage} from 'utils/print/print';
import CompareModal from './@components/compare-modal/compare-modal';
import ItemContainer from './@components/item-container';
import styles from './styles.module.scss';
import useAnalytics from 'ui/@hooks/use-analytics';
import {useJobPosition} from 'ui/@hooks/queries/use-job-position';
import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';
import {EventName} from 'constants/analytics/event-name';
import {EventType} from 'constants/analytics/event-type';

const ReportDetails = () => {
  const {trackEvent} = useAnalytics();
  const {candidateId} = useParams();

  const {jobPositionId: jobPositionUuid} = useParams();

  const {candidatesRoute, candidateResult} = routeNames;

  const {t} = useTranslation('candidateCalibration', {
    keyPrefix:
      'job-positions.show.report.components.main-content.components.report-details',
  });
  const {isOpen, onClose, onOpen} = useDisclosure();

  const {onShare} = useShareUrl();

  const companyUuid = useSafeCurrentCompanyUuid();

  const jobPosition = useJobPosition({
    variables: {
      companyUuid,
      jobPositionUuid: jobPositionUuid || '',
    },
  });

  const formatDate = () => {
    return format(new Date(createdAt), 'dd MMM yy');
  };

  const candidateAlignmentResult = useCandidateAlignmentResult({
    variables: {
      candidateUuid: candidateId,
    },
  });

  const onCompareReport = () => {
    trackEvent({
      eventName:
        EventName.CANDIDATE_CALIBRATION.JOB_POSITIONS_SHOW.REPORT
          .REPORT_COMPARE,
      eventType: EventType.BUTTON_CLICKED,
      eventProperties: {
        candidateName:
          candidateAlignmentResult.data?.candidateProfile.name || '',
        jobTitle: jobPosition.data?.data.title || '',
      },
    });

    onOpen();
  };

  const onPrintClick = (onCloseMenu: () => void) => {
    trackEvent({
      eventName:
        EventName.CANDIDATE_CALIBRATION.JOB_POSITIONS_SHOW.REPORT.REPORT_EXPORT,
      eventType: EventType.BUTTON_CLICKED,
      eventProperties: {
        candidateName:
          candidateAlignmentResult.data?.candidateProfile.name || '',
        jobTitle: jobPosition.data?.data.title || '',
      },
    });

    onCloseMenu();

    printPage('candidate-result');
  };

  const onShareClick = (onCloseMenu: () => void) => {
    trackEvent({
      eventName:
        EventName.CANDIDATE_CALIBRATION.JOB_POSITIONS_SHOW.REPORT.REPORT_SHARED,
      eventType: EventType.BUTTON_CLICKED,
      eventProperties: {
        candidateName:
          candidateAlignmentResult.data?.candidateProfile.name || '',
        jobTitle: jobPosition.data?.data.title || '',
      },
    });
    onCloseMenu();
    onShare(`${candidatesRoute}/${candidateResult}/${candidateId}`);
  };

  if (candidateAlignmentResult.isLoading) return <Spinner />;
  if (!candidateAlignmentResult.data) return <></>;

  const {
    candidateProfile: {name, email, jobPositionTitle},
    candidateResult: {
      createdAt,
      moduleResult: {
        personalityProfile: {persona},
      },
    },
  } = candidateAlignmentResult.data;

  return (
    <BaseCard>
      <CompareModal
        isOpen={isOpen}
        onClose={onClose}
        currentCandidateData={candidateAlignmentResult.data}
      />

      <div className={styles.titleContainer}>
        <div className={styles.title}>{name}</div>

        <div className={styles.buttonContainer}>
          <Button onClick={onCompareReport}>{t('compare-cta')}</Button>

          <FloatingIconMenu icon={<IconMore />}>
            {({onCloseMenu}) => (
              <div className={styles.menuContaier}>
                <div
                  className={styles.hide}
                  onClick={() => onShareClick(onCloseMenu)}
                >
                  {t('share-action')}
                </div>

                <div onClick={() => onPrintClick(onCloseMenu)}>
                  {t('export-action')}
                </div>
              </div>
            )}
          </FloatingIconMenu>
        </div>
      </div>

      <div className={styles.divider} />

      <div className={styles.itemsWrapper}>
        <ItemContainer title={t('work-email')} value={email} />

        <ItemContainer title={t('personality-type')} value={persona} />

        <ItemContainer title={t('job-title')} value={jobPositionTitle} />

        <ItemContainer
          title={t('report-completion-time')}
          value={formatDate()}
        />
      </div>
    </BaseCard>
  );
};

export default ReportDetails;
