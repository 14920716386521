import {Fragment, FunctionComponent, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import useTranscriptPlayer from 'ui/@hooks/use-transcript-player';
import {Meeting} from '../../../interfaces/meeting';
import {Transcript} from '../../../interfaces/transcript';
import {formatDate} from '../../../utils/date';
import TextCard from '../card/text-card/text-card';
import styles from './styles.module.scss';

interface ElementProps {
  transcript: Transcript[];
  meeting: Meeting;
}

const MEETING_TIMESTAMP_FORMAT = 'HH.mm aaa';

const AudioTranscriptPlayer: FunctionComponent<ElementProps> = ({
  transcript,
  meeting,
}) => {
  const {t} = useTranslation('sense', {
    keyPrefix: 'transcript-player',
  });

  const playerRef = useRef<HTMLAudioElement>(null);
  const wordsRef = useRef<HTMLSpanElement>(null);

  useTranscriptPlayer({
    transcript,
    wordsRef,
    playerRef,
    currentWordClassName: 'currentWord',
  });

  const formatTimestamp = (
    meetingStartDate: string,
    offsetInSeconds: number
  ) => {
    const date = new Date(meetingStartDate);

    date.setTime(date.getTime() + offsetInSeconds * 1000);

    return formatDate(date.toString(), MEETING_TIMESTAMP_FORMAT);
  };

  const renderTranscriptContent = (index: number) => {
    if (transcript[index - 1]?.speaker !== transcript[index]?.speaker) {
      return (
        <div className={styles.interventionSpeaker}>
          {transcript[index]?.speaker || t('speaker-text')}
          <span className={styles.interventionTimestamp}>
            {formatTimestamp(meeting.startDate, transcript[index].start)}
          </span>
        </div>
      );
    }

    return null;
  };

  return (
    <TextCard>
      <TextCard.Header>{t('title')}</TextCard.Header>

      <div className={styles.container}>
        <audio
          controls
          ref={playerRef}
          src={meeting.presignedAudioUrl!}
          className={styles.audioPlayer}
        />

        <div className={styles.mainContainer}>
          <div className={styles.transcriptionContainer}>
            <span ref={wordsRef} className={styles.textWrapper}>
              {transcript.map((word, index) => (
                <Fragment key={index}>
                  {renderTranscriptContent(index)}

                  <span className={styles.words}>{word.text}&nbsp;</span>
                </Fragment>
              ))}
            </span>
          </div>
        </div>
      </div>
    </TextCard>
  );
};

export default AudioTranscriptPlayer;
