import {FunctionComponent, PropsWithChildren} from 'react';
import Button from '../../../button/button';
import styles from './styles.module.scss';

interface ElementProps {
  title?: string;
  trailingCta?: {
    title: string;
    onClick: () => void;
  };
}

const Header: FunctionComponent<PropsWithChildren<ElementProps>> = ({
  children,
  title,
  trailingCta,
}) => {
  return (
    <div className={styles.headerStyles}>
      {title || children}

      {trailingCta && (
        <Button variant="tertiaryInversed" onClick={trailingCta.onClick}>
          {trailingCta.title}
        </Button>
      )}
    </div>
  );
};

export default Header;
