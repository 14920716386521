import {createQuery} from 'react-query-kit';
import {dummyMeeting} from 'ui/@demo-data/meeting';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {RQ_MEETING} from './query-keys';
import {Meeting} from 'interfaces/meeting';
import {
  FetchMeetingPayload,
  fetchMeeting,
} from 'repositories/instill/queries/fetch-meeting';

export const useMeeting = createQuery<Meeting | undefined, FetchMeetingPayload>(
  {
    primaryKey: RQ_MEETING,
    queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
      if (!variables.meetingUuid) {
        return Promise.reject();
      }

      return fetchMeeting(variables);
    }, dummyMeeting),
    enabled(data, variables) {
      return !!variables.meetingUuid;
    },
  }
);
