import {useTranslation} from 'react-i18next';
import Button from 'ui/@_components/button';
import styles from './styles.module.scss';

const INSTILL_REPRESENTATIVE_MEETINGS_URL =
  'https://meetings.hubspot.com/dan-kasper';

function InstillRegistrationRequired() {
  const {t} = useTranslation('authFeedback');

  const onButtonClick = () => {
    window.open(INSTILL_REPRESENTATIVE_MEETINGS_URL);
  };

  return (
    <>
      <h1 className={styles.title}>
        {t('components.instill-registration-required.title')}
      </h1>

      <p className={styles.text}>
        {t('components.instill-registration-required.text')}
      </p>

      <Button onClick={onButtonClick}>
        {t('components.instill-registration-required.button')}
      </Button>
    </>
  );
}

export default InstillRegistrationRequired;
