import {QueryFunction} from '@tanstack/query-core';
import {createInfiniteQuery, createQuery} from 'react-query-kit';
import {dummyCompanyEmployees} from 'ui/@demo-data/company-employee';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {CompanyEmployee} from '../../../interfaces/company-employee';
import {Meta} from '../../../interfaces/meta';
import {fetchCompanyEmployees} from '../../../repositories/instill';
import {FetchCompanyEmployeePayload} from '../../../repositories/instill/queries/fetch-company-employees';
import getOffsetValue from '../../../utils/get-offset-value';
import {
  RQ_COMPANY_EMPLOYEES,
  RQ_INFINITE_COMPANY_EMPLOYEES,
} from './query-keys';

interface FetchCompanyEmployeesResponsePayload {
  data: CompanyEmployee[];
  meta: Meta;
}

const queryFn: QueryFunction<
  FetchCompanyEmployeesResponsePayload | undefined,
  [string, FetchCompanyEmployeePayload]
> = queryDemoWrapper(
  async ({queryKey: [_, variables], pageParam = 0}) => {
    let payload = {
      offset:
        variables.offset ??
        getOffsetValue({
          currentPage: pageParam,
          itemsPerPage: variables.limit ?? 0,
        }),
      limit: variables.limit,
      companyUuid: variables.companyUuid,
    } as FetchCompanyEmployeePayload;

    if (variables.sortBy) {
      payload['sortBy'] = variables.sortBy;
    }

    if (variables.orderBy) {
      payload['orderBy'] = variables.orderBy;
    }

    if (variables.query) {
      payload['query'] = variables.query;
    }

    if (variables.includeInvitedUsers) {
      payload['includeInvitedUsers'] = variables.includeInvitedUsers;
    }

    if (variables.includeInActiveUsers) {
      payload['includeInActiveUsers'] = variables.includeInActiveUsers;
    }

    const response = await fetchCompanyEmployees(payload);

    if (!response) return;

    return {
      data: response.data,
      meta: response.meta,
    };
  },
  {
    data: dummyCompanyEmployees,
    meta: {
      currentPage: 1,
      totalEntries: 5,
      totalPages: 1,
    },
  }
);

export const useCompanyEmployees = createQuery<
  FetchCompanyEmployeesResponsePayload | undefined,
  FetchCompanyEmployeePayload
>({
  primaryKey: RQ_COMPANY_EMPLOYEES,
  queryFn,
  enabled: (data, variables) => {
    return !!variables.companyUuid;
  },
});

export const useInfiniteCompanyEmployees = createInfiniteQuery<
  FetchCompanyEmployeesResponsePayload | undefined,
  Omit<FetchCompanyEmployeePayload, 'itemsPerPage' | 'offset' | 'currentPage'>
>({
  primaryKey: RQ_INFINITE_COMPANY_EMPLOYEES,
  queryFn,
  enabled: (data, variables) => {
    return !!variables.companyUuid;
  },
  getNextPageParam: (lastPage, allPages) => {
    return lastPage?.meta.totalPages &&
      lastPage?.meta.totalPages > allPages.length
      ? allPages.length + 1
      : undefined;
  },
  getPreviousPageParam: (firstPage, allPages) => {
    return allPages.length ? allPages.length - 1 : undefined;
  },
});
