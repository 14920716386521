export const companyBusinessOutcomes = [
  'Safety and Wellness',
  'Financial Performance',
  'Brand Reputation',
];

export const dummyPreDefinedBusinessOutComes = [
  'Financial Performance',
  'Productivity and Performance',
  'Customer Satisfaction and Loyalty',
  'Retention',
  'Safety and Wellness',
  'Time to Market',
  'Efficiency and Cost Reduction',
  'Employee Recruitment and Talent Acquisition',
  'Brand Reputation',
];
