import {
  CompanyCoreValue,
  CompanyCoreValueRaw,
  CoreValueForm,
  CoreValueFormToRaw,
} from '../../interfaces/company-core-value';
import {
  sanitizeExpectationsFormToRaw,
  sanitizeExpectationsFromRaw,
} from './expectations';
import {
  sanitizeMediaValueFromRaw,
  sanitizeSingleMediaValueFromRaw,
} from './value-media';

export const sanitizeCompanyCoreValuesFromRaw = (
  data: CompanyCoreValueRaw[]
): CompanyCoreValue[] => {
  return data.map((coreValue) => sanitizeCompanyCoreValueFromRaw(coreValue));
};

export const sanitizeCompanyCoreValueFromRaw = (
  data: CompanyCoreValueRaw
): CompanyCoreValue => {
  return {
    name: data.name,
    description: data.description,
    uuid: data.uuid,
    expectations: sanitizeExpectationsFromRaw(data.expectations),
    media: sanitizeMediaValueFromRaw(data.media),
    underlyingTraits: data.underlying_traits,
    cover: data.cover_media_signed
      ? sanitizeSingleMediaValueFromRaw(data.cover_media_signed)
      : null,
  };
};

export const sanitizeCompanyCoreValuesToRaw = (
  data: CoreValueForm[]
): CoreValueFormToRaw[] => {
  return data.map((coreValue) => {
    return sanitizeCompanyCoreValueToRaw(coreValue);
  });
};

export const sanitizeCompanyCoreValueToRaw = (
  data: CoreValueForm
): CoreValueFormToRaw => {
  return {
    name: data.name,
    description: data.description,
    expectations: sanitizeExpectationsFormToRaw(data.expectations),
    media: data.media,
    underlying_traits: data.underlyingTraits,
    cover_media: data.cover,
  };
};
