import {CultureVitalSign} from 'interfaces/meeting';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

interface ElementProps {
  cultureVitalSign: CultureVitalSign;
}

const getActiveClass = (score: number) => {
  if (score < 50) {
    return styles.scoreLow;
  }
  return styles.scoreHigh;
};

const SelectedTabView: FunctionComponent<ElementProps> = ({
  cultureVitalSign,
}) => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.components.meeting-details.components.culture-vitals.components.selected-tab-view',
  });

  const feedbackArray = cultureVitalSign.feedback.split('. '); // this could be done at BE

  return (
    <div
      className={`${styles.container} ${getActiveClass(
        cultureVitalSign.score
      )}`}
    >
      <div className={styles.feedBackContainer}>
        <div className={styles.title}>{t('feedback-title')}</div>

        <ul className={styles.list}>
          {feedbackArray.map((sentence, index) => (
            <li key={index} className={styles.listItem}>
              {sentence}
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.exampleContainer}>
        <div className={styles.title}>{t('examples-title')}</div>

        <ul className={styles.list}>
          {cultureVitalSign.examples.map((example, index) => (
            <li key={index} className={styles.listItem}>
              <span>{`"${example.quote}" -`}</span> {example.explanation}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SelectedTabView;
