import {Transcript} from 'interfaces/transcript';

export const dummyTranscript: Transcript[] = [
  {
    text: "Hello everyone, thank you for joining today's strategy meeting. Let's start with a quick round of introductions.",
    start: 0,
    end: 6,
    speaker: 'Alice Johnson',
  },
  {
    text: "Glad to be here. I'm Bob, the marketing manager, looking forward to discussing our plans.",
    start: 7,
    end: 13,
    speaker: 'Bob Smith',
  },
  {
    text: 'Hi, Charlie here, the lead on product development. Excited to share updates from our team.',
    start: 14,
    end: 20,
    speaker: 'Charlie Davis',
  },
  {
    text: "This is Diane, the finance director. I'll be providing insights on our budget and financial planning.",
    start: 21,
    end: 27,
    speaker: 'Diane Evans',
  },
  {
    text: "Thank you all. Let's dive into our main agenda. Bob, can you kick things off with the marketing strategy for the upcoming quarter?",
    start: 28,
    end: 34,
    speaker: 'Alice Johnson',
  },
  {
    text: 'Certainly, Alice. Our goal is to increase our market share by targeting the millennial demographic with a new digital campaign...',
    start: 35,
    end: 95,
    speaker: 'Bob Smith',
  },
  {
    text: "On the product side, we're excited to announce that we're ahead of schedule on the development of our new app, which aligns perfectly with Bob's marketing strategy...",
    start: 96,
    end: 156,
    speaker: 'Charlie Davis',
  },
  {
    text: "From a financial standpoint, we've allocated a significant portion of our budget to support the marketing campaign and app development, ensuring we stay on target with our financial goals...",
    start: 157,
    end: 217,
    speaker: 'Diane Evans',
  },
  {
    text: "Great, it sounds like we have a solid plan moving forward. Now, let's discuss potential challenges and how we plan to address them...",
    start: 218,
    end: 278,
    speaker: 'Alice Johnson',
  },
  {
    text: "One concern is the potential pushback from traditional users not familiar with app-based services. We'll need to consider a phased approach...",
    start: 279,
    end: 339,
    speaker: 'Bob Smith',
  },
  {
    text: "Regarding the app, we're working closely with user experience designers to ensure it's intuitive for all age groups, which should help mitigate some of these concerns...",
    start: 340,
    end: 400,
    speaker: 'Charlie Davis',
  },
  {
    text: "Financially, we're prepared for initial fluctuations in revenue as we roll out the new app. Our projections account for this phase...",
    start: 401,
    end: 461,
    speaker: 'Diane Evans',
  },
  {
    text: "I appreciate everyone's input. It's clear we have a comprehensive strategy and are prepared for the challenges ahead. Let's move to assigning specific tasks...",
    start: 462,
    end: 522,
    speaker: 'Alice Johnson',
  },
  {
    text: 'Bob, continue refining the marketing plan. Charlie, keep the development on track and update us on any changes. Diane, please monitor the budget closely...',
    start: 523,
    end: 583,
    speaker: 'Alice Johnson',
  },
  {
    text: "Will do. I'll also prepare a detailed report on market trends and how our campaign can adapt to these changes...",
    start: 584,
    end: 644,
    speaker: 'Bob Smith',
  },
  {
    text: "Understood. I'll coordinate with the tech team to ensure our milestones are met without sacrificing quality...",
    start: 645,
    end: 705,
    speaker: 'Charlie Davis',
  },
  {
    text: "I'll make sure our financial strategies are flexible enough to support our goals and will report back on a weekly basis...",
    start: 706,
    end: 766,
    speaker: 'Diane Evans',
  },
  {
    text: "Excellent. As we wrap up, does anyone have any final thoughts or concerns they'd like to discuss in today's meeting?",
    start: 767,
    end: 827,
    speaker: 'Alice Johnson',
  },
  {
    text: "I think it's important we also consider potential partnerships that could amplify our campaign's reach. I'll start reaching out to potential partners.",
    start: 828,
    end: 888,
    speaker: 'Bob Smith',
  },
  {
    text: "Agreed, and from a development perspective, integrating feedback from early users will be crucial. I'll set up a beta testing group.",
    start: 889,
    end: 949,
    speaker: 'Charlie Davis',
  },
  {
    text: "Let's also keep an eye on the market for any sudden shifts that might affect our strategy. I'll ensure our budget remains adaptable.",
    start: 950,
    end: 1010,
    speaker: 'Diane Evans',
  },
  {
    text: "Thank you, team, for the productive discussion. We'll reconvene next week to follow up on these actions. Please prepare any updates or findings to share.",
    start: 1011,
    end: 1071,
    speaker: 'Alice Johnson',
  },
  {
    text: 'Looking forward to it. Have a great week, everyone.',
    start: 1072,
    end: 1122,
    speaker: 'Bob Smith',
  },
  {
    text: "Yes, see you all next week. Let's make this happen.",
    start: 1123,
    end: 1173,
    speaker: 'Charlie Davis',
  },
  {
    text: 'Thanks, everyone. Keep up the great work!',
    start: 1174,
    end: 1224,
    speaker: 'Diane Evans',
  },
  {
    text: 'Meeting adjourned. Thank you, everyone, for your contributions.',
    start: 1225,
    end: 1275,
    speaker: 'Alice Johnson',
  },
];
