import {QueryFunction} from '@tanstack/react-query';
import {Meta} from 'interfaces/meta';
import {UserProfile} from 'interfaces/user-profile';
import {createInfiniteQuery} from 'react-query-kit';
import {
  FetchEligibleManagersPayload,
  fetchEligibleManagers,
} from 'repositories/instill/queries/fetch-eligible-managers';
import {dummyManagers} from 'ui/@demo-data/user-profile';
import getOffsetValue from 'utils/get-offset-value';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {RQ_INFINITE_ELIGIBLE_MANAGERS} from './query-keys';

interface FetchEligibleManagersResponsePayload {
  data: UserProfile[];
  meta: Meta;
}

const queryFn: QueryFunction<
  FetchEligibleManagersResponsePayload | undefined,
  [string, FetchEligibleManagersPayload]
> = queryDemoWrapper(
  async ({queryKey: [_, variables], pageParam = 0}) => {
    let payload = {
      offset:
        variables.offset ??
        getOffsetValue({
          currentPage: pageParam,
          itemsPerPage: variables.limit ?? 0,
        }),
      limit: variables.limit,
      companyUuid: variables.companyUuid,
    } as FetchEligibleManagersPayload;

    if (variables.sortBy) {
      payload['sortBy'] = variables.sortBy;
    }

    if (variables.orderBy) {
      payload['orderBy'] = variables.orderBy;
    }

    if (variables.teamUuid) {
      payload['teamUuid'] = variables.teamUuid;
    }

    if (variables.query) {
      payload['query'] = variables.query;
    }

    if (variables.includeInvitedUsers) {
      payload['includeInvitedUsers'] = variables.includeInvitedUsers;
    }

    const response = await fetchEligibleManagers(payload);

    if (!response) return;

    return {
      data: response.data,
      meta: response.meta,
    };
  },
  {
    data: dummyManagers,
    meta: {
      currentPage: 1,
      totalEntries: 4,
      totalPages: 1,
    },
  }
);

export const useInfiniteEligibleManagers = createInfiniteQuery<
  FetchEligibleManagersResponsePayload | undefined,
  Omit<FetchEligibleManagersPayload, 'itemsPerPage' | 'offset' | 'currentPage'>
>({
  primaryKey: RQ_INFINITE_ELIGIBLE_MANAGERS,
  queryFn,
  enabled: (data, variables) => {
    return !!variables.companyUuid;
  },
  getNextPageParam: (lastPage, allPages) => {
    return lastPage?.meta.totalPages &&
      lastPage?.meta.totalPages > allPages.length
      ? allPages.length + 1
      : undefined;
  },
  getPreviousPageParam: (firstPage, allPages) => {
    return allPages.length ? allPages.length - 1 : undefined;
  },
});
