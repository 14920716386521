import {Meta, MetaRaw} from '../../interfaces/meta';
import {
  NotificationRaw,
  Notification,
  NOTIFICATION_EVENT,
  LiveNotification,
  LiveNotificationRaw,
  MarkNotificationsAsReadRawPayload,
} from '../../interfaces/notification';
import {sanitizeMetaFromRaw} from './meta';
import invitedToAssessmentSanitizer from './notification-payload/invited-to-assessment';
import jobPositionEndedSanitizer from './notification-payload/job-position-ended';
import kudoReceivedSanitizer from './notification-payload/kudo-received';
import actionStepSentSanitizer from './notification-payload/action-step-sent';

// We duplicate the `Meta` interface because we need a way to know if there are any
// unread notifications but we also need to keep everything in place for pagination.
// It is for the same reason we define a new `sanitizeMetaFromRaw` in this file.
export interface NotificationMetaRaw extends MetaRaw {
  extras: {
    has_unread_notifications: boolean;
  };
}

export interface NotificationMeta extends Meta {}

export const sanitizeNotificationMetaFromRaw = (
  data: NotificationMetaRaw
): NotificationMeta => {
  return {
    ...sanitizeMetaFromRaw(data),
  };
};

const payloadSanitizers = {
  [NOTIFICATION_EVENT.INVITED_TO_ASSESSMENT]: invitedToAssessmentSanitizer,
  [NOTIFICATION_EVENT.JOB_POSITION_ENDED]: jobPositionEndedSanitizer,
  [NOTIFICATION_EVENT.KUDO_RECEIVED]: kudoReceivedSanitizer,
  [NOTIFICATION_EVENT.ACTION_STEP_SENT]: actionStepSentSanitizer,
};

export const sanitizeNotificationsFromRaw = (
  data: NotificationRaw[]
): Notification[] => {
  return data.map((notification) => {
    return sanitizeNotificationFromRaw(notification);
  });
};

export const sanitizeNotificationFromRaw = (
  data: NotificationRaw
): Notification => {
  const eventName = data.event;

  return {
    createdAt: data.created_at,
    event: data.event,
    payload: payloadSanitizers[eventName](data.payload as any),
    readAt: data.read_at,
    uuid: data.uuid,
  };
};

export const sanitizeSocketNotificationFromRaw = (
  data: LiveNotificationRaw
): LiveNotification => {
  return {
    event: data.payload.event,
    payload: sanitizeNotificationFromRaw(data.payload),
  };
};

export const sanitizeMarkAsReadNotificationsForRaw = (
  notificationUuids: string[]
): MarkNotificationsAsReadRawPayload => {
  return {
    notification_uuids: notificationUuids,
  };
};
