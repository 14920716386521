import logoSource from 'assets/images/logo.png';
import {useTranslation} from 'react-i18next';
import {ReactComponent as TwoLineTuning} from 'ui/@_components/kit/icons/2-line-tuning.svg';
import dashboardViewImage from 'ui/@_components/kit/icons/dashboard-view.svg';
import {ReactComponent as LineGraphIcon} from 'ui/@_components/kit/icons/line-graph.svg';
import {ReactComponent as MagicWand} from 'ui/@_components/kit/icons/magic-wand.svg';
import {ReactComponent as OfficeBag} from 'ui/@_components/kit/icons/office-bag-gradient.svg';
import {ReactComponent as PanaromaIcon} from 'ui/@_components/kit/icons/panorama.svg';
import {ReactComponent as CircularProgressIcon} from 'ui/@_components/kit/icons/round-progressbar.svg';
import useLogout from 'ui/@hooks/use-logout';
import Button from '../button';
import Dialog from '../kit/dialog';
import styles from './styles.module.scss';
import {useAuth0} from '@auth0/auth0-react';

interface SignupPromptModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SignupPromptModal = ({isOpen, onClose}: SignupPromptModalProps) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'signup-prompt-modal',
  });

  const logout = useLogout();
  const {loginWithRedirect} = useAuth0();

  const onSignup = () => {
    logout();

    loginWithRedirect({
      screen_hint: 'signup',
      prompt: 'login',
    });
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <Dialog.Header>
        <div className={styles.logoContainer}>
          <img src={logoSource} alt="logo" height="60px" width="90px" />
        </div>
      </Dialog.Header>

      <Dialog.Content>
        <div className={styles.contentContainer}>
          <div className={styles.header}>
            <p className={styles.title}>{t('title')}</p>

            <p className={styles.subtitle}>{t('subtitle')}</p>
          </div>

          <div className={styles.body}>
            <p className={styles.description}>{t('description')}</p>

            <div className={styles.featureContainer}>
              <ul className={styles.featureList}>
                <li>
                  <CircularProgressIcon />
                  {t('features-list.feature-1')}
                </li>

                <li>
                  <MagicWand />

                  {t('features-list.feature-2')}
                </li>

                <li>
                  <LineGraphIcon />

                  {t('features-list.feature-3')}
                </li>

                <li>
                  <PanaromaIcon />

                  {t('features-list.feature-4')}
                </li>

                <li>
                  <TwoLineTuning />

                  {t('features-list.feature-5')}
                </li>

                <li>
                  <OfficeBag />

                  {t('features-list.feature-6')}
                </li>
              </ul>

              <img src={dashboardViewImage} alt="Dashboard icon" />
            </div>
          </div>

          <Button fullWidth onClick={onSignup}>
            {t('signup')}
          </Button>
        </div>
      </Dialog.Content>
    </Dialog>
  );
};

export default SignupPromptModal;
