import {EventName} from 'constants/analytics/event-name';
import {EventType} from 'constants/analytics/event-type';
import {routeNames} from 'constants/route-names';
import {useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router';
import {buildPageTitle} from 'services/build-page-title';
import BackButton from 'ui/@_components/back-button/back-button';
import Spinner from 'ui/@_components/spinner';
import {useCurrentAuthUser} from 'ui/@atoms/current-auth-user';
import {useCurrentCompanyUuid} from 'ui/@atoms/current-company';
import {useMeeting, useTranscript} from 'ui/@hooks/queries';
import useAnalytics from 'ui/@hooks/use-analytics';
import MeetingDetails from './@components/meeting-details/meeting-details';
import SubHeader from './@components/sub-header';
import styles from './styles.module.scss';
import useDemoAccountDetails from 'ui/@hooks/use-demo-account-details';

const MeetingsShow = () => {
  const {t} = useTranslation(['sense', 'applicationPageTitle']);
  const {trackEvent} = useAnalytics();

  const {applicationRoute, senseRoute, meetingsRoute} = routeNames;

  const {meetingId: meetingUuid} = useParams<string>();

  const companyUuid = useCurrentCompanyUuid();
  const currentUser = useCurrentAuthUser();

  const navigate = useNavigate();

  const {flowBranding} = useDemoAccountDetails();

  const meeting = useMeeting({
    enabled: Boolean(meetingUuid),
    variables: {
      companyUuid,
      meetingUuid,
    },
    onError() {
      navigate(`/${applicationRoute}/${senseRoute}/${meetingsRoute}/`);
    },
  });

  const transcript = useTranscript({
    variables: {
      companyUuid,
      meetingUuid,
    },
  });

  useEffect(() => {
    if (meeting.data?.name) {
      trackEvent({
        eventName: EventName.FLOW.REPORT.PAGE_VIEW,
        eventType: EventType.PAGE_VIEW,
        eventProperties: {
          name: meeting.data.name,
        },
      });
    }
  }, [meeting.data?.name, trackEvent]);

  if (meeting.isLoading || transcript.isLoading) return <Spinner />;
  if (!meeting.data || !transcript.data) return <></>;

  const publicContainerStyles = currentUser ? '' : styles.publicContainer;

  return (
    <div className={publicContainerStyles}>
      <Helmet>
        <title>
          {buildPageTitle([
            meeting.data.name,
            t('page-title.sense.meetings.index', {ns: 'applicationPageTitle'}),
            t(flowBranding, {ns: 'applicationPageTitle'}),
          ])}
        </title>
      </Helmet>

      {!!currentUser && <BackButton title={t('meetings.show.back-button')} />}

      <SubHeader meeting={meeting.data} />

      <MeetingDetails meeting={meeting.data} transcript={transcript.data} />
    </div>
  );
};

export default MeetingsShow;
