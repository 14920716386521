import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import BaseCard from 'ui/@_components/card/base-card';
import InfoTooltip from 'ui/@_components/info-tooltip';
import Dialog from 'ui/@_components/kit/dialog';
import {ReactComponent as ChevronDownIcon} from 'ui/@_components/kit/icons/chevron-down.svg';
import {ReactComponent as ChevronRight} from 'ui/@_components/kit/icons/chevron-right.svg';
import {ReactComponent as CultureSafety} from 'ui/@_components/kit/icons/culture-safety.svg';
import {ReactComponent as SemicircleGuage} from 'ui/@_components/kit/icons/semicircle-guage.svg';
import HorizontalProgressBar from 'ui/@_components/progress-bar/horizontal/horizontal';
import {handleBurnoutName} from 'utils/handle-renames/burnout';
import roundOff from 'utils/number/round-off';
import useFlowData from '../../../../@hooks/use-flow-data';
import ValuesVitalSignHelper from '../values-vital-sign-helper';
import styles from './styles.module.scss';

const CultureVitals = () => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show-v2.components.meeting-details.components.culture-vitals',
  });

  const [isCultureVitalsExpanded, setIsCultureVitalsExpanded] = useState(true);

  const [showVitalSign, setShowVitalSign] = useState(false);
  const [activeVitalSign, setActiveVitalSign] = useState(0);

  const {meeting, valuesAlignmentScore} = useFlowData();

  if (!meeting) return <></>;

  const {cultureVitalSigns} = meeting;

  const getAverage = () => {
    const total = cultureVitalSigns.reduce((acc, curr) => acc + curr.score, 0);

    const avg = (total + valuesAlignmentScore) / (cultureVitalSigns.length + 1);

    return roundOff(avg, 0);
  };

  const onOpenVitalSign = (index: number) => {
    setActiveVitalSign(index);

    setShowVitalSign(true);
  };

  const getFeedbackCount = (feedback: string) => {
    const feedbackList = feedback.split('. ');

    return feedbackList.length;
  };

  return (
    <>
      <div className={styles.container}>
        <BaseCard>
          <div className={styles.titleContainer}>
            <CultureSafety />

            <div className={styles.scoreTitle}>
              <InfoTooltip content={t('title-tooltip')}>
                {t('score-title')}
              </InfoTooltip>
            </div>
          </div>

          <div className={styles.guageContainer}>
            <p>{t('0')}</p>

            <p className={styles.half}>{t('50')}</p>

            <p className={styles.score}>{getAverage()}%</p>

            <div>
              <div className={styles.knob} />

              <SemicircleGuage />
            </div>

            <p>{t('100')}</p>
          </div>

          <div className={styles.divider} />
        </BaseCard>

        <BaseCard>
          <p className={styles.title}>
            {t('title')}

            <button
              className={
                isCultureVitalsExpanded
                  ? styles.expandedButton
                  : styles.unExpandedButton
              }
              onClick={() =>
                setIsCultureVitalsExpanded(!isCultureVitalsExpanded)
              }
            >
              <ChevronDownIcon />
            </button>
          </p>

          {isCultureVitalsExpanded && (
            <ul className={styles.listContainer}>
              {cultureVitalSigns.map((vitalSign, index) => (
                <li
                  className={styles.list}
                  key={index}
                  onClick={() => onOpenVitalSign(index)}
                >
                  <div className={styles.nameContainer}>
                    {handleBurnoutName(vitalSign.name)}

                    <p>
                      {`Feedback (${getFeedbackCount(vitalSign.feedback)})`}
                      <ChevronRight className={styles.rightIcon} />
                    </p>
                  </div>

                  <div className={styles.progressBarContainer}>
                    <HorizontalProgressBar percentage={vitalSign.score} />
                    {vitalSign.score}%
                  </div>
                </li>
              ))}
            </ul>
          )}
        </BaseCard>
      </div>

      <Dialog
        variant="large"
        isOpen={showVitalSign}
        onClose={() => {
          setShowVitalSign(!showVitalSign);
        }}
      >
        <Dialog.Header
          title={handleBurnoutName(cultureVitalSigns[activeVitalSign].name)}
        />

        <Dialog.Content>
          <ValuesVitalSignHelper
            parameter={cultureVitalSigns[activeVitalSign]}
          />
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default CultureVitals;
