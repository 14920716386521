import {createQuery} from 'react-query-kit';
import {UserAssessment} from '../../../interfaces/user-assessment';
import {
  FetchUserAssessmentPayload,
  fetchUserAssessment,
} from '../../../repositories/instill/queries/fetch-user-assessment';
import {RQ_USER_ASSESSMENT} from './query-keys';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {dummyUserAssessment} from 'ui/@demo-data/user-assessment';

export const useUserAssessment = createQuery<
  UserAssessment | undefined,
  FetchUserAssessmentPayload
>({
  primaryKey: RQ_USER_ASSESSMENT,
  queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
    return await fetchUserAssessment(variables.userAssessmentUuid);
  }, dummyUserAssessment),
  enabled: (data, variables) => {
    return !!variables.userAssessmentUuid;
  },
});
