import {FunctionComponent, SelectHTMLAttributes} from 'react';
import {ReactComponent as IconChevronDown} from '../../icons/chevron-left.svg';
import {useFormControlContext} from '../form-control';
import styles from './styles.module.scss';

interface ElementProps extends SelectHTMLAttributes<HTMLSelectElement> {
  variant?: 'borderless' | 'noOutline';
}

const Select: FunctionComponent<ElementProps> = ({variant, ...props}) => {
  let containerClasses = styles.container;
  const {hasError} = useFormControlContext();

  if (hasError) {
    containerClasses = `${containerClasses} ${styles.error}`;
  }

  if (variant) {
    containerClasses = `${containerClasses} ${styles[variant]}`;
  }

  return (
    <div className={containerClasses}>
      <select className={styles.select} {...props} />

      <div className={styles.arrowContainer}>
        <IconChevronDown className={styles.arrowIcon} />
      </div>
    </div>
  );
};

export default Select;
