import {createQuery} from 'react-query-kit';
import {FetchJobPositionsPayload} from 'repositories/instill/queries/fetch-job-positions';
import {dummyJobPositions} from 'ui/@demo-data/job-position';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {JobPosition} from '../../../interfaces/job-position';
import {Meta} from '../../../interfaces/meta';
import {fetchJobPositions} from '../../../repositories/instill/queries';
import {RQ_JOB_POSITIONS} from './query-keys';

interface FetchJobPositionsResponsePayload {
  data: JobPosition[];
  meta: Meta;
}

export const useJobPositions = createQuery<
  FetchJobPositionsResponsePayload | undefined,
  FetchJobPositionsPayload
>({
  primaryKey: RQ_JOB_POSITIONS,
  queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
    return await fetchJobPositions(variables);
  }, dummyJobPositions),
  cacheTime: 5 * 60 * 1000, // 5 mins
  staleTime: 5 * 60 * 1000, // 5 mins
});
