import {routeNames} from 'constants/route-names';
import {format} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import {useTranslation} from 'react-i18next';
import Button from 'ui/@_components/button';
import BaseCard from 'ui/@_components/card/base-card';
import {ReactComponent as CalendarIcon} from 'ui/@_components/kit/icons/calendar.svg';
import {ReactComponent as ClockIcon} from 'ui/@_components/kit/icons/clock-outlined.svg';
import {ReactComponent as PeopleIcon} from 'ui/@_components/kit/icons/people-2.svg';
import useInDesktop from 'ui/@hooks/use-in-desktop';
import useFlowData from '../../../../@hooks/use-flow-data';
import Participants from './@components/participants';
import TopTopics from './@components/top-topics';
import styles from './styles.module.scss';

const InfoSummary = () => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show-v2.components.meeting-details.components.info-summary',
  });

  const {meeting} = useFlowData();
  const inDesktop = useInDesktop();

  if (!meeting) return <></>;

  const {applicationRoute, peopleTeamsRoute, peopleRoute} = routeNames;

  const meetingDateTime = () => {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const date = format(
      utcToZonedTime(meeting.startDate, browserTimezone),
      'EEE, MMM d, yyyy'
    );
    const time = format(
      utcToZonedTime(meeting.startDate, browserTimezone),
      'h:mm a'
    );

    return `${date} @ ${time}`;
  };

  const durationFromSecToMin = () => Math.floor(meeting.duration / 60);

  return (
    <BaseCard>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.titleContainer}>
            <span>{inDesktop ? t('summary') : meeting.name}</span>
          </div>

          <div className={styles.timeOwnerContainer}>
            <div>
              <CalendarIcon /> {meetingDateTime()}
            </div>

            <div>
              <ClockIcon /> {`${durationFromSecToMin()}min`}
            </div>

            <div>
              <PeopleIcon />

              <Button
                variant="tertiary"
                as="link"
                to={`/${applicationRoute}/${peopleTeamsRoute}/${peopleRoute}/${meeting.userProfile.uuid}`}
              >
                {meeting.userProfile.name}
              </Button>
            </div>
          </div>

          <TopTopics />
        </div>

        <Participants />
      </div>
    </BaseCard>
  );
};

export default InfoSummary;
