import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';
import {sanitizeCultureGraphFromRaw} from 'ui/@sanitizers/graph';
import addUrlQueriesConditionally from 'utils/url-utils/add-url-queries-conditionally';

export interface CultureGraphPayload {
  companyUuid: string;
  limit: number;
  offset?: number;
  cadence: '1D' | '1W' | '2W' | '1M' | '3M' | '1Y';
  teamUuid?: string;
}

export async function fetchCultureGraph(payload: CultureGraphPayload) {
  const {companyUuid, limit, offset, cadence, teamUuid} = payload;

  const url = addUrlQueriesConditionally(
    `/companies/${companyUuid}/organization-health/culture-graph`,
    [
      {
        key: 'limit',
        value: limit,
        condition: limit !== undefined,
      },
      {
        key: 'skip',
        value: offset,
        condition: offset !== undefined,
      },
      {
        key: 'cadence',
        value: cadence,
        condition: !!cadence,
      },
      {
        key: 'team_uuid',
        value: teamUuid,
        condition: !!teamUuid,
      },
    ]
  );

  try {
    const response = await InstillAPI.get(url);

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return {data: sanitizeCultureGraphFromRaw(response.data), meta: {}};
  } catch (error) {
    console.error(error);
  }
}
