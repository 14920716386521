import {sanitizeTeamFromRaw} from '../../../ui/@sanitizers/teams';
import isResponseSuccessful from '../../../utils/is-response-successful';
import InstillAPI from '../axios-instance';

export interface FetchTeamPayload {
  companyUuid: string;
  teamUuid: string;
}

export async function fetchTeam(parameters: FetchTeamPayload) {
  const {companyUuid, teamUuid} = parameters;

  let url = `/companies/${companyUuid}/teams/single?team_uuid=${teamUuid}`;

  try {
    const response = await InstillAPI.get(url);

    if (response.data.length || !isResponseSuccessful(response)) {
      return;
    }

    return sanitizeTeamFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
