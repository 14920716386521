export const getProgressBarColor = (percentage: number) => {
  if (percentage <= 40) {
    return '#FF2D55';
  }
  if (percentage >= 65) {
    return '#34C759';
  }

  return '#FFCC00';
};
