import {CultureVitalSign} from 'interfaces/meeting';
import {FunctionComponent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import TextCard from 'ui/@_components/card/text-card';
import List from './@components/list';
import SelectedTabView from './@components/selected-tab-view/selected-tab-view';
import styles from './styles.module.scss';

interface ElementProps {
  cultureVitalSigns: CultureVitalSign[];
}

const CultureVitals: FunctionComponent<ElementProps> = ({
  cultureVitalSigns,
}) => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.components.meeting-details.components.culture-vitals',
  });

  const [activeVitalSignIndex, setActiveVitalSignIndex] = useState<number>(0);

  if (!cultureVitalSigns?.length) return <></>;

  const onTabClick = (tabIndex: number) => {
    setActiveVitalSignIndex(tabIndex);
  };

  return (
    <div className={styles.wrapper}>
      <TextCard>
        <TextCard.Header>{t('title')}</TextCard.Header>

        <TextCard.Content>
          <List
            onTabClick={onTabClick}
            cultureVitalSigns={cultureVitalSigns}
            activeVitalSignIndex={activeVitalSignIndex}
          />

          <SelectedTabView
            cultureVitalSign={cultureVitalSigns[activeVitalSignIndex]}
          />
        </TextCard.Content>
      </TextCard>
    </div>
  );
};

export default CultureVitals;
