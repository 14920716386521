import {ReactNode} from 'react';
import styles from './styles.module.scss';
import {ReactComponent as CloseIcon} from 'ui/@_components/kit/icons/close.svg';
import IconButton from '../icon-button';

interface ElementProps {
  children: ReactNode;
  onClose?: () => void;
}

const Chip = ({children, onClose}: ElementProps) => {
  return (
    <div className={styles.smallChip}>
      {children}

      {!!onClose && (
        <IconButton onClick={onClose}>
          <CloseIcon className={styles.closeIcon} />
        </IconButton>
      )}
    </div>
  );
};

export default Chip;
