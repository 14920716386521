import {sanitizeJobPositionFromRaw} from '../../../ui/@sanitizers/job-position';
import isResponseSuccessful from '../../../utils/is-response-successful';
import InstillAPI from '../axios-instance';

export interface FetchJobPositionPayload {
  companyUuid: string;
  jobPositionUuid: string;
}

export async function fetchJobPosition(parameters: FetchJobPositionPayload) {
  const {companyUuid, jobPositionUuid} = parameters;

  let url = `/companies/${companyUuid}/job-position/${jobPositionUuid}`;

  try {
    const response = await InstillAPI.get(url);

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return {
      data: sanitizeJobPositionFromRaw(response.data),
    };
  } catch (error) {
    console.error(error);
  }
}
