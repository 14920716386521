import {createQuery} from 'react-query-kit';
import {candidateProfile} from 'ui/@demo-data/candidate';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {CandidateProfile} from '../../../interfaces/candidate';
import {fetchCandidateProfile} from '../../../repositories/instill';
import {FetchCandidateProfilePayload} from '../../../repositories/instill/queries/fetch-candidate-profile';
import {RQ_CANDIDATE_PROFILE} from './query-keys';

export const useCandidateProfile = createQuery<
  CandidateProfile | undefined,
  FetchCandidateProfilePayload
>({
  primaryKey: RQ_CANDIDATE_PROFILE,
  queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
    return await fetchCandidateProfile(variables);
  }, candidateProfile),
  enabled: (data, variables) => {
    return !!variables.candidateToken;
  },
});
