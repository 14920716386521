import {Actions} from 'constants/permissions/actions';
import {ReactNode} from 'react';
import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';
import {useFullUserProfile} from 'ui/@hooks/queries';
import {useUserPermission} from 'ui/@hooks/use-user-permission';

interface HasPermissionProps {
  action: Actions;
  children: ReactNode;
}

export const useHasPermission = () => {
  const companyUuid = useSafeCurrentCompanyUuid();

  const userProfile = useFullUserProfile({
    variables: {
      companyUuid,
    },
  });

  const hasPermission = useUserPermission(userProfile.data?.role || '');

  return hasPermission;
};

const HasPermission = ({action, children}: HasPermissionProps) => {
  const hasPermission = useHasPermission();

  if (!hasPermission(action)) return <></>;

  return <>{children}</>;
};

interface HasAnyPermissionProps {
  actions: Actions[];
  children: ReactNode;
}

export const HasAnyPermission = ({
  actions,
  children,
}: HasAnyPermissionProps) => {
  const hasPermission = useHasPermission();

  if (actions.some((action) => hasPermission(action))) return <>{children}</>;

  return <></>;
};

interface HasAllPermissionProps {
  actions: Actions[];
  children: ReactNode;
}

export const HasAllPermission = ({
  actions,
  children,
}: HasAllPermissionProps) => {
  const hasPermission = useHasPermission();

  if (actions.every((action) => hasPermission(action))) return <>{children}</>;

  return <></>;
};

export default HasPermission;
