import {createMutation} from 'react-query-kit';
import {
  CreateJobPositionPayload,
  createJobPosition,
} from '../../../repositories/instill/mutations/create-job-position';
import {JobPosition} from '../../../interfaces/job-position';
import {useQueryClient} from '@tanstack/react-query';
import {
  RQ_JOB_POSITIONS,
  RQ_JOB_POSITIONS_SUMMARIES_ALL,
} from '../queries/query-keys';

export const useCreateJobPosition = () => {
  const queryClient = useQueryClient();

  return createMutation<JobPosition | undefined, CreateJobPositionPayload>({
    mutationFn: async (variables) => {
      return await createJobPosition({...variables});
    },
    onSuccess: () => {
      queryClient.invalidateQueries([RQ_JOB_POSITIONS]);
      queryClient.invalidateQueries([RQ_JOB_POSITIONS_SUMMARIES_ALL]);
    },
  })();
};
