import AlignmentScore from './@components/alignment-score';
import ImpactsChallenges from './@components/impacts-challenges';
import PersonalityProfile from './@components/personality-profile';
import ReportDetails from './@components/report-details';
import styles from './styles.module.scss';

const MainContent = () => {
  return (
    <div className={styles.wrapper} id="candidate-result">
      <ReportDetails />

      <AlignmentScore />

      <PersonalityProfile />

      <ImpactsChallenges />
    </div>
  );
};

export default MainContent;
