import {useQueryClient} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {createMutation} from 'react-query-kit';
import {
  InviteUsersPayload,
  userInvites,
} from '../../../repositories/instill/mutations/user-invites';
import {useSyncSetFullUserProfile} from '../queries';
import {
  RQ_COMPANY_EMPLOYEES,
  RQ_INFINITE_COMPANY_EMPLOYEES,
} from '../queries/query-keys';

export const useInviteUsers = () => {
  const queryClient = useQueryClient();
  const syncSetFullUserProfile = useSyncSetFullUserProfile();

  return createMutation<
    AxiosResponse<any, any> | undefined,
    InviteUsersPayload
  >({
    mutationFn: async (variables) => {
      return await userInvites({
        userInvites: variables.userInvites,
        companyUuid: variables.companyUuid,
      });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([RQ_COMPANY_EMPLOYEES]);
      queryClient.invalidateQueries([RQ_INFINITE_COMPANY_EMPLOYEES]);

      if (variables.userInvites.length > 0) {
        syncSetFullUserProfile(
          {
            companyUuid: variables.companyUuid,
          },
          (prev) =>
            prev
              ? {
                  ...(prev || {}),
                  fullOnboardingChecklist: {
                    ...prev.fullOnboardingChecklist,
                    hasInvitedFirstUser: true,
                  },
                }
              : prev
        );
      }
    },
  })();
};
