import {useAuth0} from '@auth0/auth0-react';
import {useTranslation} from 'react-i18next';
import Button from 'ui/@_components/button';
import styles from './styles.module.scss';

function CompanyEmailRequired() {
  const {logout} = useAuth0();
  const {t} = useTranslation('authFeedback');

  const onButtonClick = () => {
    logout({returnTo: window.location.origin});
  };

  return (
    <>
      <h1 className={styles.title}>
        {t('components.company-email-required.title')}
      </h1>

      <p className={styles.text}>
        {t('components.company-email-required.text')}
      </p>

      <Button className={styles.button} onClick={onButtonClick}>
        {t('components.company-email-required.button')}
      </Button>
    </>
  );
}

export default CompanyEmailRequired;
