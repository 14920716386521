import {CompanyChecklist} from '../../../interfaces/checklist';
import {createQuery} from 'react-query-kit';
import {FetchChecklistPayload} from '../../../repositories/instill/queries/fetch-company-checklist';
import {fetchCompanyChecklist} from '../../../repositories/instill';

/**
 * @deprecated
 */
export const useChecklistData = createQuery<
  CompanyChecklist | undefined,
  FetchChecklistPayload
>({
  primaryKey: 'checklistData',
  queryFn: async ({queryKey: [_, variables]}) => {
    return await fetchCompanyChecklist(variables.companyUuid);
  },
});
