import {AssessmentStatusByVitalSign} from 'interfaces/assessment';
import {createQuery} from 'react-query-kit';
import {
  FetchAssessmentStatusByVitalSignPayload,
  fetchAssessmentStatusByVitalSign,
} from 'repositories/instill/queries/fetch-assessment-status-by-vital-sign';
import {RQ_ASSESSMENT_STATUS_BY_VITAL_SIGN} from './query-keys';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {dummyAssessmentStatusByVitalSign} from 'ui/@demo-data/assessment';

export const useAssessmentStatusByVitalSign = createQuery<
  AssessmentStatusByVitalSign | undefined,
  FetchAssessmentStatusByVitalSignPayload
>({
  primaryKey: RQ_ASSESSMENT_STATUS_BY_VITAL_SIGN,
  queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
    return await fetchAssessmentStatusByVitalSign({
      companyUuid: variables.companyUuid,
      vitalSign: variables.vitalSign,
    });
  }, dummyAssessmentStatusByVitalSign),
  enabled(data, variables) {
    return !!variables.companyUuid;
  },
});
