import {sanitizeTranscriptFromRaw} from '../../../ui/@sanitizers/transcript';
import isResponseSuccessful from '../../../utils/is-response-successful';
import InstillAPI from '../axios-instance';

export interface FetchTranscriptPayload {
  companyUuid?: string;
  meetingUuid?: string;
}

export const fetchTranscript = async (parameters: FetchTranscriptPayload) => {
  const {companyUuid, meetingUuid} = parameters;

  const url = companyUuid
    ? `/companies/${companyUuid}/meetings/${meetingUuid}/transcript`
    : `/public/companies/meetings/${meetingUuid}/transcript`;

  try {
    const response = await InstillAPI.get(url);

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeTranscriptFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
};
