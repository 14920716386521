import {AuthUser} from 'interfaces/auth-user';

export const dummyAuthUser: AuthUser = {
  companies: [
    {
      companyLogo: '/static/demo-account/company-logo.png',
      companySettings: {
        isHRISProviderUKG: false,
      },
      createdAt: '2022-08-30T18:20:12.360749+00:00',
      creatorUuid: '6db65b0c-e3ba-4426-b6db-634119c60b2e',
      emailDomain: 'instill.ai',
      id: '19',
      industry: 'Engineering',
      isActive: false,
      isAppInstalled: false,
      isCultureDefined: true,
      isDashboardActive: true,
      isSignatureRequiredOnValues: true,
      leadershipManifesto:
        'We define ourselves through action. We inspire others around us to create lasting change themselves and find success no matter their challenge or enviorment. We choose to win through the unity of people, unimagined technology, and unresting resiliency. Our legacy will immortalize by galvanizing the actions of others and bonding humankind.',
      mission:
        'We equip organizations with a robust system of record for their core values, creating the groundwork for vibrant cultures and high-impact teams\n\nWe empower organizations to cultivate vibrant, high-performing cultures through an intelligent system of record for core values and an innovative culture operating system. \n\nWe empower organizations to cultivate vibrant, high-performing cultures through an intelligent Culture Operating System that leaverages cutting-edge technology to augment the human experience at work.  \n\nWe empower organizations to cultivate vibrant, high-performing cultures by leveraging  cutting-edge technology to augment the human experience at work.\n',
      name: 'Instill QA',
      size: '51-100',
      updatedAt: '2024-02-05T17:17:26.921184+00:00',
      uuid: 'ecaa4da6-2f58-4f14-8aa1-2c08ec3787c3',
      vision:
        'Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla Bla bla ',
      websiteUrl: 'http://www.instill.ai',
      slackTeamId: 'T05A75J10LE',
      isOnboarded: true,
      hideSmallTeamScores: false,
    },
  ],
  createdAt: '2023-11-10T17:39:21.177884+00:00',
  email: 'jane.demo@instill.ai',
  id: '290',
  isEmailVerified: true,
  userProfiles: [
    {
      connectedCalendars: [],
      companyUuid: 'ecaa4da6-2f58-4f14-8aa1-2c08ec3787c3',
      fullOnboardingChecklist: {
        hasCreatedValue: true,
        skippedUserInvite: false,
        hasAnyPersonalActionStep: true,
        hasCompletedProfile: true,
        hasAddedFirstMeeting: true,
        hasCreatedProfile: true,
        hasSignedOff: true,
        hasAddedCompanyValues: true,
        hasConfiguredAssessment: true,
        hasCreatedCultureKpi: true,
        hasGeneratedMeetingReport: true,
        hasInvitedCandidates: true,
        hasInvitedFirstUser: true,
        hasSeenFirstMeetingReport: true,
        hasSelectedBusinessOutcomes: true,
        hasSharedFirstMeetingReport: true,
        hasSyncedCalendar: true,
        hasTakenPersonalityTest: true,
      },
      isOnboarded: true,
      funFact: null,
      jobTitle: 'Senior Software Developer',
      kudosRemaining: 20,
      linkedinUrl: null,
      location: null,
      name: 'Hii Name',
      phoneNumber: '07352699286',
      picture:
        'https://instill-app-api-qa.s3.amazonaws.com/companies/ecaa4da6-2f58-4f14-8aa1-2c08ec3787c3/user-pictures/e3caba99-f8e2-49a8-817b-c68a9d85dd11.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAQRMCGQ5UABIBWVZL%2F20240315%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240315T102002Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEIP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIC3N8hG8EoL%2Bh9RHl1%2B7Iy9TXd2%2FbUupbr%2BQfIpUHJIWAiEA866EaNPrNqBRG%2BNfd4dLYcccUJcPsQhr85Wh528VvJoq9AMIi%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARACGgwwMzczMTcxNTA1NjgiDHJxzdD7DWZxcuh4aCrIAzvpRvIZoE%2FI%2FkTeyU09GDEYW8msXlr6DmxOWYMsYQumoxeljpYQXP451Bt9Ux%2BocejcIlbtKxuF2qQuSuCBiPvWZpkcF7iUG9Tf7VEtVmHqXJ3CWrrthbnJsqdGoWtCTyA2TGO1Yroz7ySLw%2Bk2OUqw6PeppWbxlXSWhRa7b7zYO25jQBEmWRLeBvhyxXwl1kqVEL09UzlmNZsoIOLauFJXRZKPgeY4Grz4ExAq7o09Y2HExbx%2FdfJz71CL3xzZs4KCOqRIipUZLqeP5Y747D33qFRPoSkRP3ta8QmUFgsHa5QioooZDOOKO39IilpayNQo4kK8ikWAvJcdqNZEv2cCiM31L30nDL3FUEQNx%2BaEsx1DeL1vW8ix8br%2FzpQYrMvGTF8cVgi1oby9Yd%2FPsLj8YaL%2BhNdwudixpmicww3aX0%2Fzrch1pugB5JLYGMyhRzB0QEnPleqk79OmpE%2BFa0OqPRgFgqzuakDcwikqEn84m0cDZ9CwZTY765UVnxwzwsCiRlFaQ8O9ohKw5KpS%2B54hp3oeG1kzacRufxjShoILExm18irszM14rcQ%2BJrfGN0%2BmkppZh%2FRgovlvuatQxvWDKAQJzARIfDC2wNCvBjqlAZK9ih27qkA9FMGyGzlDx7y9iGqVQ7zAkKbrKbDOqnCHCCas%2F%2Bh%2FDQacUEQlvOBOY8G%2B4tjO4MgW79W9HmmcIwokbJo1wh2Bi9U%2BEyhg%2FmOUY2TFLCrdwgXA7c1tIGLoZmdnQ%2B3pHVG3gt9TStwQbBxeC24joTSeEWw1KtO3dPubFqUNXMAiLtYlsHX%2Bxef7x9C4ioDvyTWIOxk8AkvL5DnE9G48%2Bg%3D%3D&X-Amz-Signature=21a4dc42f26a6e90cf96e9d1c82d9c83d864c6f1b0d15941fb341c5403cbd5df',
      preferredName: 'Hii Name',
      pronouns: 'he-him',
      role: 'owner',
      shortBio: null,
      status: 'joined',
      timeZone: 'Asia/Calcutta',
      uuid: '92cb1a2f-6bdd-476c-8288-9396bcfab135',
      workEmail: 'mathura@instill.ai',
      kudosGivenCount: 7,
      kudosReceivedCount: 8,
      percentageComplete: 100,
      personalEmail: null,
    },
  ],
  name: 'Jane Doe',
  picture:
    'https://lh3.googleusercontent.com/a/ACg8ocKkQF61fZTJ-bGZl_HGwuPbPOFZ8hNKjMcFnh6gcmtn=s96-c',
  updatedAt: null,
  uuid: '3ef8482a-f90e-4837-8b50-e586bc2000ca',
  isSuperAdmin: false,
};
