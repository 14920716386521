import {useQueryClient} from '@tanstack/react-query';
import {createMutation} from 'react-query-kit';
import {CompanyAssessment} from '../../../interfaces/company-assessments';
import {updateCompanyAssessmentSettings} from '../../../repositories/instill/mutations';
import {UpdateCompanyAssessmentSettingsPayload} from '../../../repositories/instill/mutations/update-company-assessment-settings';
import {
  RQ_COMPANY_ASSESSMENTS,
  RQ_USER_ASSESSMENTS,
} from '../queries/query-keys';

export const useUpdateCompanyAssessmentSettings = () => {
  const queryClient = useQueryClient();

  return createMutation<
    CompanyAssessment | undefined,
    UpdateCompanyAssessmentSettingsPayload
  >({
    mutationFn: async (variables) => {
      return await updateCompanyAssessmentSettings(
        variables.companyUuid,
        variables.assessmentUuid,
        variables.payload
      );
    },
    onSuccess() {
      queryClient.invalidateQueries([RQ_COMPANY_ASSESSMENTS]);
      queryClient.invalidateQueries([RQ_USER_ASSESSMENTS]);
    },
  })();
};
