import {CompanyUpdateRaw} from '../../../interfaces/company';
import {sanitizeCompanyFromRaw} from '../../../ui/@sanitizers/company';
import InstillAPI from '../axios-instance';

export interface UpdateCompanyPayload {
  companyUuid: string;
  payload: CompanyUpdateRaw;
}

export async function updateCompany(
  companyPayload: CompanyUpdateRaw,
  companyUuid: string
) {
  const response = await InstillAPI.put(
    `/companies/${companyUuid}`,
    companyPayload
  );

  return sanitizeCompanyFromRaw(response.data);
}
