import {useAuth0} from '@auth0/auth0-react';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {sendVerificationEmail} from 'repositories/instill';
import Button from 'ui/@_components/button';
import {ReactComponent as VerifyEmailIcon} from 'ui/@_components/kit/images/auth-feedback/verify-your-email.svg';
import styles from './styles.module.scss';
import useAnalytics from 'ui/@hooks/use-analytics';
import {EventName} from 'constants/analytics/event-name';
import {EventType} from 'constants/analytics/event-type';
import {IS_SIGNUP_TRACKED_STORAGE_KEY} from 'constants/local-storage-keys';
import TwoPanePage from 'ui/@_components/two-pane-page';
import useLogout from 'ui/@hooks/use-logout';
import useUserSignupProvider, {
  SIGNUP_PROVIDERS,
} from 'ui/@hooks/use-user-signup-provider';

interface VerifyYourEmailProps {
  email: string;
}

function VerifyYourEmail({email}: VerifyYourEmailProps) {
  const {t} = useTranslation('authFeedback', {
    keyPrefix: 'components.verify-your-account',
  });
  const {getIdTokenClaims, user} = useAuth0();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [retryTime, setRetryTime] = useState(0);

  const logout = useLogout();

  const {trackEvent} = useAnalytics();

  useEffect(() => {
    setRetryTime(retryCount * 15);
  }, [retryCount]);

  useEffect(() => {
    const timer = setInterval(() => {
      setRetryTime((prev) => (prev ? prev - 1 : 0));
    }, 1000);

    return () => clearTimeout(timer);
  }, [retryCount]);

  const provider = useUserSignupProvider();

  useEffect(() => {
    const isSignupTracked = localStorage.getItem(IS_SIGNUP_TRACKED_STORAGE_KEY);

    // ? Tracking here because signup code is in Auth0 Dashboard
    if (
      !isSignupTracked &&
      provider &&
      user?.email &&
      provider === SIGNUP_PROVIDERS.AUTH
    ) {
      trackEvent({
        eventName: EventName.ONBOARDING.SIGNUP,
        eventType: EventType.BUTTON_CLICKED,
        eventProperties: {
          email: user.email,
        },
      });

      localStorage.setItem(IS_SIGNUP_TRACKED_STORAGE_KEY, 'true');
    }
  }, [provider, trackEvent, user?.email]);

  useEffect(() => {
    trackEvent({
      eventName: EventName.ONBOARDING.VERIFY_EMAIL,
      eventType: EventType.PAGE_VIEW,
      eventProperties: {
        email: user?.email || '',
      },
    });
  }, [trackEvent, user?.email]);

  const onSendEmail = useCallback(async () => {
    const idToken = await getIdTokenClaims();

    if (!idToken) return;

    trackEvent({
      eventName: EventName.ONBOARDING.RESEND_VERIFY_EMAIL,
      eventType: EventType.BUTTON_CLICKED,
      eventProperties: {
        email: user?.email || '',
      },
    });

    try {
      // TODO : To make this api call using react query
      await sendVerificationEmail(idToken.__raw);
      setIsEmailSent(true);
      setRetryCount((prev) => prev + 1);
    } catch (error) {}
  }, [getIdTokenClaims, trackEvent, user?.email]);

  const renderContent = () => {
    if (isEmailSent) {
      return (
        <>
          <h1 className={styles.title}>{t('email-sent.title')}</h1>

          <p
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: t('text1', {
                email: email,
              }),
            }}
          />

          <p className={styles.text}>{t('text2')}</p>

          <div className={styles.icons}>
            <VerifyEmailIcon className={styles.icon} />
          </div>

          <Button fullWidth variant="secondary" onClick={logout}>
            {t('back')}
          </Button>

          <div className={styles.sendEmailContainer}>
            <p className={styles.getEmailText}>
              {retryTime
                ? t('retry', {
                    retryTime,
                  })
                : t("didn't-receive-email")}
            </p>

            <Button
              variant="tertiary"
              className={styles.sendEmailLink}
              onClick={onSendEmail}
              disabled={retryTime > 0}
            >
              {t('resend-email')}
            </Button>
          </div>
        </>
      );
    }

    return (
      <>
        <h1 className={styles.title}>{t('title')}</h1>

        <p
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: t('text1', {
              email: email,
            }),
          }}
        />

        <p className={styles.text}>{t('text2')}</p>

        <div className={styles.icons}>
          <VerifyEmailIcon className={styles.icon} />
        </div>

        <Button fullWidth variant="secondary" onClick={logout}>
          {t('back')}
        </Button>

        <div className={styles.sendEmailContainer}>
          <p className={styles.getEmailText}>{t('did-not-get-email')}</p>

          <Button
            variant="tertiary"
            className={styles.sendEmailLink}
            onClick={onSendEmail}
          >
            {t('resend-email')}
          </Button>
        </div>
      </>
    );
  };

  return (
    <TwoPanePage>
      <div>{renderContent()}</div>
    </TwoPanePage>
  );
}

export default VerifyYourEmail;
