import {createQuery} from 'react-query-kit';
import {fetchTeam} from 'repositories/instill';
import {FetchTeamPayload} from 'repositories/instill/queries/fetch-team';
import {dummyTeams} from 'ui/@demo-data/team';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {Team} from '../../../interfaces/team';
import {RQ_TEAMS} from './query-keys';

export const useTeam = createQuery<Team | undefined, FetchTeamPayload>({
  primaryKey: RQ_TEAMS,
  queryFn: queryDemoWrapper(
    async ({queryKey: [_, variables]}) => {
      return await fetchTeam(variables);
    },
    ({queryKey: [_, variables]}) => {
      return (
        dummyTeams.find((team) => team.uuid === variables.teamUuid) ||
        dummyTeams[0]
      );
    }
  ),
  enabled: (data, variables) => {
    return !!variables.companyUuid && !!variables.teamUuid;
  },
});
