import {FunctionComponent, PropsWithChildren, useState} from 'react';
import {animated, useTransition} from 'react-spring';
import Modal from '../../../@components/modal';
import Content from './content';
import DialogContext from './dialog-context';
import Header from './header';
import styles from './styles.module.scss';

type DialogVariants = 'small' | 'medium' | 'large' | 'xlarge';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  variant?: DialogVariants;
  innerContainerClassName?: string;
  containerClassName?: string;
  innerContainerStyle?: React.CSSProperties;
}

interface DialogComponent extends FunctionComponent<PropsWithChildren<Props>> {
  Header: typeof Header;
  Content: typeof Content;
}

const Dialog: DialogComponent = ({
  isOpen,
  variant,
  onClose,
  children,
  innerContainerClassName,
  containerClassName,
  innerContainerStyle,
}) => {
  const [container, setContainer] = useState<HTMLElement | null>(null);

  const transitions = useTransition(isOpen, {
    from: {
      transform: 'translateY(100%)',
      opacity: 0,
    },
    enter: {
      transform: 'translateY(0)',
      opacity: 1,
    },
    leave: {
      transform: 'translateY(100px)',
      opacity: 0,
    },
  });

  const animatedDivClassName = `${containerClassName || styles.container} ${
    variant ? styles[variant] : ''
  }`;

  return (
    <DialogContext.Provider value={{onClose}}>
      <Modal isOpen={isOpen} clickOutsideElement={container} onClose={onClose}>
        {transitions(
          (props, item) =>
            item && (
              <div className={styles.wrapper}>
                <animated.div className={animatedDivClassName} style={props}>
                  <div
                    className={innerContainerClassName || styles.innerContainer}
                    ref={setContainer}
                    style={innerContainerStyle}
                  >
                    {children}
                  </div>
                </animated.div>
              </div>
            )
        )}
      </Modal>
    </DialogContext.Provider>
  );
};

Dialog.Header = Header;
Dialog.Content = Content;

export default Dialog;
