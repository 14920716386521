import {
  VisionMissionGetHelp,
  VisionMissionGetHelpRaw,
} from 'interfaces/vision-mission-get-help';

export const sanitizeVisionMissionGetHelpFromRaw = (
  data: VisionMissionGetHelpRaw
): VisionMissionGetHelp => ({
  companyVision: data.company_vision,
  companyMission: data.company_mission,
  companyLeadershipManifesto: data.company_leadership_manifesto,
});
