import {AxiosResponse} from 'axios';
import {createMutation} from 'react-query-kit';
import {sendReminderToCandidate} from 'repositories/instill';
import {SendReminderToCandidatePayload} from 'repositories/instill/mutations/send-reminder-to-candidate';

export const useSendReminderToCandidate = createMutation<
  AxiosResponse<any, any> | undefined,
  SendReminderToCandidatePayload
>({
  mutationFn: async (variables) => {
    return await sendReminderToCandidate({
      companyUuid: variables.companyUuid,
      candidateUuid: variables.candidateUuid,
    });
  },
});
