import {ValueGetHelp, ValueGetHelpRaw} from 'interfaces/values-get-help';

export const sanitizeValuesGetHelpFromRaw = (
  data: ValueGetHelpRaw
): ValueGetHelp => ({
  underlyingTraits: data.underlying_traits,
  exceedsExpectations: data.exceeds_expectations,
  meetsExpectations: data.meets_expectations,
  missesExpectations: data.misses_expectations,
});
