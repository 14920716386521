import {ReactNode} from 'react';
import Branding from './@components/branding';
import styles from './styles.module.scss';

type TwoPanePageProps = {
  children: ReactNode;
};

const TwoPanePage = ({children}: TwoPanePageProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.secondaryPane}>
        <Branding />
      </div>

      <div className={styles.mainPane}>{children}</div>
    </div>
  );
};

export default TwoPanePage;
