import {SCHEDULE} from 'constants/schedule';
import {CompanyAssessment} from 'interfaces/company-assessments';

export const dummyCompanyAssessments: CompanyAssessment[] = [
  {
    assessment: {
      id: '9',
      createdAt: '2023-03-28T20:43:34.830827+00:00',
      updatedAt: '2024-02-28T16:37:28.494096+00:00',
      uuid: '890cb1d9-aa6a-492d-be3b-00787cee8b54',
      name: 'Trust',
      overview:
        'Trust is the fundamental cornerstone of relationships and organizational foundations, encapsulating an environment where individuals lean on each other with assurance. It manifests through resource sharing, fostering collective growth as individuals confidently exchange knowledge and expertise. Dependability forms a vital pillar, setting the standard for commitments to be honored and responsibilities fulfilled. Honesty and transparency are intrinsic, establishing a culture where open communication prevails and information is shared with unwavering integrity. Nurturing camaraderie cultivates a sense of community and mutual support within the trust framework. The commitment to constructive conflict resolution mechanisms further solidifies trust, demonstrating a dedication to resolving differences amicably and fortifying the foundation of relational and organizational trust.',
      rank: 4,
      supportedSchedules: ['MONTHLY', 'QUARTERLY'],
    },
    completionData: null,
    dateInitiated: null,
    startDate: null,
    dueDate: null,
    isActive: false,
    schedule: null,
    population: null,
  },
  {
    assessment: {
      id: '10',
      createdAt: '2023-03-28T20:43:35.379370+00:00',
      updatedAt: '2024-02-28T16:37:34.656264+00:00',
      uuid: 'eda4f4b8-b05c-4c49-980c-c40a4949a5fc',
      name: 'Psychological Safety',
      overview:
        'Psychological safety is the cornerstone of an environment where individuals feel secure expressing their true selves and ideas. It encourages vulnerability, open communication, and actively seeks constructive feedback for personal and collective development. Embracing failures as learning opportunities, welcoming dissenting opinions, and fostering interpersonal risk-taking contribute to a culture of innovation. Additionally, recognizing and valuing individuals for their unique contributions creates an inclusive space that supports diverse identities and backgrounds.',
      rank: 5,
      supportedSchedules: ['MONTHLY', 'QUARTERLY'],
    },
    completionData: null,
    dateInitiated: null,
    startDate: null,
    dueDate: null,
    isActive: false,
    schedule: null,
    population: null,
  },
  {
    assessment: {
      id: '11',
      createdAt: '2023-03-28T20:43:35.809454+00:00',
      updatedAt: '2024-02-28T16:37:39.159993+00:00',
      uuid: '2ee807e4-78b9-457a-a825-d24fb97f5d82',
      name: 'Innovation',
      overview:
        'Innovation is the dynamic process characterized by curiosity, continual learning, and adaptability, where individuals collaboratively engage in a collective pursuit of creative solutions. It thrives on the synergy of diverse perspectives within a collaborative and teamwork-oriented environment. Innovation involves calculated risk-taking, acknowledging that groundbreaking advancements often emerge from ventures into unexplored territories. An inherent aspect of innovation is the intentional inclusion of diverse opinions, ensuring a multifaceted approach that enriches the creative process.',
      rank: 6,
      supportedSchedules: ['MONTHLY', 'QUARTERLY'],
    },
    completionData: null,
    dateInitiated: null,
    startDate: null,
    dueDate: null,
    isActive: false,
    schedule: null,
    population: null,
  },
  {
    assessment: {
      id: '12',
      createdAt: '2023-03-28T20:43:36.283700+00:00',
      updatedAt: '2024-02-28T16:37:44.501220+00:00',
      uuid: '65f45dc6-a38d-4ec1-aa9e-0a83a1dfaf5e',
      name: 'Burnout',
      overview:
        "Burnout is a multifaceted phenomenon characterized by a state of physical, emotional, and mental exhaustion, often stemming from prolonged exposure to stress and a demanding work environment. It reflects a depletion of emotional energy, impacting one's ability to cope effectively with professional and personal challenges. Flexibility, in terms of adapting to changing demands, is crucial in mitigating burnout. Self-care practices play a pivotal role, involving intentional efforts to nurture one's well-being and resilience. The presence of chronic stress, coupled with a diminishing sense of effectiveness in handling responsibilities, contributes to burnout. Additionally, cynicism may manifest as a defense mechanism, reflecting a detachment and negativity towards work-related aspects.",
      rank: 7,
      supportedSchedules: ['MONTHLY', 'QUARTERLY'],
    },
    completionData: null,
    dateInitiated: null,
    startDate: null,
    dueDate: null,
    isActive: false,
    schedule: null,
    population: null,
  },
  {
    assessment: {
      id: '6',
      createdAt: '2022-09-21T22:02:52.546162+00:00',
      updatedAt: '2024-02-28T16:36:50.266958+00:00',
      uuid: '639171a2-836b-4aa7-983a-ba7362a9cb7d',
      name: 'What’s your workstyle',
      overview: null,
      rank: 1,
      supportedSchedules: ['ONE_TIME'],
    },
    completionData: {
      completedCount: 1,
      totalCount: 1,
    },
    dateInitiated: '2024-02-26T17:15:05.862313+00:00',
    startDate: '2024-02-26T17:15:05.870799+00:00',
    dueDate: '2124-02-26T17:15:05.870799+00:00',
    isActive: true,
    schedule: SCHEDULE.ONE_TIME,
    population: {
      isCompanyWide: true,
      userProfileUuids: [],
      teamUuids: [],
    },
  },
  {
    assessment: {
      id: '7',
      createdAt: '2023-02-15T18:46:15.668890+00:00',
      updatedAt: '2024-02-28T16:37:16.063686+00:00',
      uuid: 'adbd630d-d85e-4fbd-8371-81d7cb38c2e9',
      name: 'Inclusion',
      overview:
        'Inclusion is the foundational construct that creates a sense of belonging within a group or organization, where individuals experience being seen and heard, fostering an environment where authenticity is valued. It encompasses individual recognition, acknowledging and celebrating the unique contributions of each member. Respect is a central tenet, permeating the culture to ensure that every individual is treated with dignity and consideration.',
      rank: 2,
      supportedSchedules: ['MONTHLY', 'QUARTERLY'],
    },
    completionData: null,
    dateInitiated: null,
    startDate: null,
    dueDate: null,
    isActive: false,
    schedule: null,
    population: null,
  },
];
