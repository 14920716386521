import PersonalityImage from 'assets/images/personality.png';
import {routeNames} from 'constants/route-names';
import {useTranslation} from 'react-i18next';
import useGettingStartedStep, {
  GETTING_STARTED_STEP,
} from 'ui/@hooks/use-getting-started-step';
import Button from 'ui/@_components/button';
import styles from './styles.module.scss';

const Personality = () => {
  const {t} = useTranslation('gettingStarted', {
    keyPrefix: 'components.personality-banner',
  });

  const {applicationRoute, assessmentPollRoute, myAssessmentsRoute} =
    routeNames;

  const {currentGettingStartedStep} = useGettingStartedStep();

  if (currentGettingStartedStep !== GETTING_STARTED_STEP.PERSONALITY_TEST) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftContent}>
        <p className={styles.title}>{t('title')}</p>

        <div className={styles.buttonsContainer}>
          <Button
            as="link"
            to={`/${applicationRoute}/${assessmentPollRoute}/${myAssessmentsRoute}`}
          >
            {t('start')}
          </Button>
        </div>
      </div>

      <div className={styles.bigCircle} />

      <div className={styles.smallCircle} />

      <img
        src={PersonalityImage}
        className={styles.image}
        alt={t('img-alt')}
        width="260px"
      />
    </div>
  );
};

export default Personality;
