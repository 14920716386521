import * as Slider from '@radix-ui/react-slider';
import {useEffect, useRef, useState} from 'react';
import {ReactComponent as FastForwardIcon} from 'ui/@_components/kit/icons/fast-forawrd.svg';
import {ReactComponent as MessageIcon} from 'ui/@_components/kit/icons/message.svg';
import {ReactComponent as RewindIcon} from 'ui/@_components/kit/icons/rewind.svg';
import styles from './styles.module.scss';

interface ElementProps {
  audioUrl: string;
  trackName: string;
  onClickTranscript?: () => void;
}

const SECS_TO_SKIP = 10;
const SPEED_STEP = 0.5;
const DEFAULT_SPEED = 1;

const AudioPlayer = ({
  audioUrl,
  trackName,
  onClickTranscript,
}: ElementProps) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(DEFAULT_SPEED);
  const [progress, setProgress] = useState(0);

  const playPause = () => {
    if (!audioRef.current) {
      setIsPlaying(false);
      return;
    }

    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current
        .play()
        .catch(() => {})
        .finally(() => {
          setIsPlaying(true);
        });
    }
  };

  const skip = (time: 'back' | 'fwd') => {
    const audio = audioRef.current;
    if (!audio) return;

    if (time === 'back') {
      audio.currentTime = audio.currentTime - SECS_TO_SKIP;
    } else if (time === 'fwd') {
      audio.currentTime = audio.currentTime + SECS_TO_SKIP;
    }
  };

  const changeSpeed = () => {
    const audio = audioRef.current;
    if (!audio) return;

    if (speed >= 2) {
      setSpeed(SPEED_STEP);
    } else setSpeed(speed + SPEED_STEP);
  };

  const onTimeUpdate = () => {
    const audio = audioRef.current;
    if (!audio) return;

    setProgress((audio.currentTime / audio.duration) * 100);
  };

  const onSeekBarDrag = (values: number[]) => {
    const audio = audioRef.current;
    if (!audio) return;

    setProgress(values[0]);

    if (!audio.duration) {
      return;
    }

    audio.currentTime = (values[0] / 100) * audio.duration;
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    audio.playbackRate = speed;
  }, [speed]);

  return (
    <div className={styles.container}>
      <p className={styles.trackNameStyles}>{trackName}</p>

      <div className={styles.controls}>
        <div className={styles.mainControls}>
          <audio ref={audioRef} src={audioUrl} onTimeUpdate={onTimeUpdate} />

          <button className={styles.rewindForward} onClick={() => skip('back')}>
            <p>{SECS_TO_SKIP}</p>

            <RewindIcon />
          </button>

          <button className={styles.playPause} onClick={playPause}>
            {isPlaying ? (
              <div className={styles.pause}>
                <div className={styles.pauseLine} />
                <div className={styles.pauseLine} />
              </div>
            ) : (
              <div className={styles.play} />
            )}
          </button>

          <button className={styles.rewindForward} onClick={() => skip('fwd')}>
            <p>{SECS_TO_SKIP}</p>

            <FastForwardIcon />
          </button>
        </div>

        <div className={styles.inputContainer}>
          <Slider.Root
            className={styles.slider}
            max={100}
            value={[progress]}
            onValueChange={onSeekBarDrag}
          >
            <Slider.Track className={styles.sliderTrack}>
              <Slider.Range className={styles.sliderRange} />
            </Slider.Track>
            <Slider.Thumb className={styles.sliderThumb} />
          </Slider.Root>
        </div>
      </div>

      <div className={styles.speedTranscript}>
        <button onClick={changeSpeed} className={styles.speed}>
          {speed}x
        </button>

        {!!onClickTranscript && (
          <button
            className={styles.transcriptButton}
            onClick={onClickTranscript}
          >
            <MessageIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default AudioPlayer;
