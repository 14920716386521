import {ComponentProps, ReactNode, useId} from 'react';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import styles from './styles.module.scss';

interface ElementProps
  extends Omit<ComponentProps<typeof ReactTooltip>, 'content'> {
  children: ReactNode;
  content: string | ReactNode;
}

const Tooltip = ({children, content, ...props}: ElementProps) => {
  const id = useId();

  if (typeof content === 'string') {
    return (
      <span data-tooltip-id={id} data-tooltip-content={content}>
        {children}

        <ReactTooltip
          className={styles.tooltip}
          classNameArrow={styles.arrowStyle}
          id={id}
          clickable
          opacity={1}
          {...props}
        />
      </span>
    );
  }

  return (
    <span data-tooltip-id={id}>
      {children}

      <ReactTooltip
        className={styles.tooltip}
        id={id}
        clickable
        render={() => content}
        opacity={1}
        {...props}
      />
    </span>
  );
};

export default Tooltip;
