export enum Features {
  INSTILL_FLOW = 'instill_flow',
  ASSESSMENTS_POLLS = 'assessment_and_polls',
  CANDIDATE_CALIBRATION = 'candidate_calibration',
  ACTION_STEPS = 'action_steps',
  PEOPLE_AND_TEAMS = 'people_and_teams',
  VALUES_DASHBOARD = 'values_dashboard',
  CULTURE_VITALS = 'culture_vitals',
  INSTILL_CULTURE_INDEX = 'instill_culture_index',
  BUSINESS_OUTCOMES = 'business_outcomes',
  VALUES_ALIGNMENT_SCORE = 'values_alignment',
  CULTURE_KPI = 'culture_kpi',
}

export enum AddonRequests {
  INSTILL_FLOW_MINUTES = 'instill_flow_minutes',
  CANDIDATE_CALIBRATION_LIMIT = 'candidate_calibration_limit',
  SEAT_REQUEST = 'seats_request',
}
