import {createMutation} from 'react-query-kit';
import {
  FullUserProfile,
  UserProfileUpdate,
} from '../../../interfaces/user-profile';
import {updateMeUserProfile} from '../../../repositories/instill';
import {updateWorkStylePreferences} from '../../../repositories/instill/mutations';
import {
  WorkStylePreferences,
  WorkStylePreferencesRaw,
} from '../../../interfaces/work-style-preferences';

interface UpdateUserProfileAndWorkStylePreferencesPayload {
  companyUuid: string;
  userProfile: UserProfileUpdate;
  workStylePreferences: WorkStylePreferencesRaw;
}

export const useUpdateUserProfileAndWorkStylePreferences = createMutation<
  [FullUserProfile, WorkStylePreferences] | undefined,
  UpdateUserProfileAndWorkStylePreferencesPayload
>({
  mutationFn: async (variables) => {
    return await Promise.all([
      updateMeUserProfile({
        companyUuid: variables.companyUuid,
        payload: variables.userProfile,
      }),
      updateWorkStylePreferences(
        variables.workStylePreferences,
        variables.companyUuid
      ),
    ]);
  },
});
