import {
  Product,
  ProductRaw,
  Subscription,
  SubscriptionRaw,
} from 'interfaces/subscription';

export const sanitizeSubscriptionsFromRaw = (
  data: SubscriptionRaw[]
): Subscription[] => {
  return data.map((subscription) => sanitizeSubscriptionFromRaw(subscription));
};

export const sanitizeSubscriptionFromRaw = (
  data: SubscriptionRaw
): Subscription => {
  return {
    assessmentCount: data.assessment_count,
    companyId: data.company_id,
    createdAt: data.created_at,
    deletedAt: data.deleted_at,
    externalSubscriptionId: data.external_subscription_id,
    flowCreditCount: data.flow_credit_count,
    id: data.id,
    isActive: data.is_active,
    isDeleted: data.is_deleted,
    nextBillingDate: data.next_billing_date,
    numSeatsCount: data.num_seats_count,
    product: sanitizeProductFromRaw(data.product),
    updatedAt: data.updated_at,
    uuid: data.uuid,
    nextBillingAmount: data.next_billing_amount,
    maxAssessmentCount: data.max_assessment_count,
    maxFlowCreditCount: data.max_flow_credit_count,
    interval: data.interval,
  };
};

export const sanitizeProductFromRaw = (data: ProductRaw): Product => {
  return {
    externalId: data.external_id,
    id: data.id,
    name: data.name,
  };
};
