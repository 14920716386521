import {useQueryClient} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {createMutation} from 'react-query-kit';
import {deleteValue} from '../../../repositories/instill';
import {DeleteValuePayload} from '../../../repositories/instill/mutations/delete-value';

export const useDeleteCompanyCoreValue = () => {
  const queryClient = useQueryClient();

  return createMutation<
    AxiosResponse<any, any> | undefined,
    DeleteValuePayload
  >({
    mutationFn: async (variables) => {
      return await deleteValue({
        companyUuid: variables.companyUuid,
        valueUuid: variables.valueUuid,
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries(['companyCoreValues']);
    },
  })();
};
