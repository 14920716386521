import {Features} from 'constants/plan-access/features';
import {PlanAccess} from 'constants/plan-access/plan-access';
import {Plans} from 'constants/plans';
import {useSafeCurrentCompanyUuid} from 'ui/@atoms/current-company';
import {useCurrentSubscriptions} from './queries/use-current-subscriptions';

const useHasPlanAccess = () => {
  const companyUuid = useSafeCurrentCompanyUuid();

  const currentSubscriptions = useCurrentSubscriptions({
    variables: {
      companyUuid,
    },
    select(data) {
      return data?.map((subscription) => {
        return subscription.product.name;
      });
    },
  });

  const currentSubscriptionInterval = useCurrentSubscriptions({
    variables: {
      companyUuid,
    },
    select(data) {
      return data?.[0]?.interval || null;
    },
  });

  const currentPlans = currentSubscriptions.data?.length
    ? currentSubscriptions.data
    : [Plans.FREE_PLAN];

  const hasPlanAccess = (feature: Features) => {
    const plansThatAllowFeature = PlanAccess[feature];

    return plansThatAllowFeature.some((plan) => currentPlans.includes(plan));
  };

  const hasAnyPlanAccess = (features: Features[]) => {
    return features.some((feature) => hasPlanAccess(feature));
  };

  const hasAllPlanAccess = (features: Features[]) => {
    return features.every((feature) => hasPlanAccess(feature));
  };

  return {
    hasPlanAccess,
    hasAnyPlanAccess,
    hasAllPlanAccess,
    currentPlans,
    currentSubscriptionInterval: currentSubscriptionInterval.data || null,
  };
};

export default useHasPlanAccess;
