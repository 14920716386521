import {
  AssessmentResultSummary,
  UserAssessmentResult,
} from 'interfaces/user-assessment-result';

export const dummyAssessmentResultSummary: AssessmentResultSummary = {
  moduleAlgorithm: 'personality',
  totalInvitedUsers: 1,
  completedResponseCount: 1,
  moduleData: {
    personalityTypes: ['The Pathfinder'],
  },
};

export const dummyAssessmentResult: UserAssessmentResult = {
  id: '619',
  moduleName: 'What’s your workstyle',
  moduleSlug: 'personality',
  moduleAlgorithm: 'personality',
  moduleDescription:
    'This assessment leverages the PERSONALITY personality model to categorize your workstyle. The PERSONALITY model is the only universally accepted measure of the dimensions that define personality and it’s been the academic standard in psychology for decades.',
  moduleResult: {
    agreeableness: 86,
    conscientiousness: 69,
    emotionality: 87,
    extroversion: 75,
    honesty: 64,
    openness: 87,
    persona: 'The Pathfinder',
    personaContent: {
      name: 'The Pathfinder',
      description: {
        header: 'Excelling in problem-solving situations',
        content:
          'The Pathfinder possesses a unique blend of analytical thinking, creativity, and cooperative nature. They excel in problem-solving situations, using their analytical skills to break down complex issues and find innovative solutions. Their creative mindset allows them to think outside the box, bringing fresh perspectives and ideas to the table. Additionally, their cooperative nature enables them to effectively collaborate with others and build strong relationships within a team.',
      },
      deepDive: {
        headline: 'The Analytical Thinker',
        content:
          'The Pathfinder is a highly analytical thinker, capable of dissecting complex problems and analyzing them from multiple angles. They have a natural curiosity and a thirst for knowledge, constantly seeking to understand the underlying principles and mechanisms behind different phenomena. This analytical mindset enables them to identify patterns, make connections, and arrive at well-informed decisions. Their attention to detail and logical reasoning make them valuable assets in any workplace.',
      },
      outOfSync: {
        content:
          'When out of sync with others, the Pathfinder may become overly focused on their analytical thinking, potentially overlooking the emotional aspects of a situation. They may struggle with adapting to sudden changes or unexpected challenges, as their preference for structure and planning can hinder their flexibility. It is important for the Pathfinder to actively seek input from others, recognize the value of emotional intelligence, and be open to alternative approaches. By embracing collaboration and considering different perspectives, they can overcome these challenges and enhance their effectiveness in group settings.',
      },
      summary:
        'The Pathfinder is a balanced and adaptable personality type, known for their analytical thinking, creativity, and cooperative nature.',
      traits: ['Analytical', 'Creative', 'Cooperative'],

      wellFunctioningGroup: {
        content:
          "The Pathfinder thrives in a well-functioning group, contributing their analytical thinking, creativity, and cooperative nature to the team's success. They actively listen to others' ideas, value diverse perspectives, and are open to collaboration. In group discussions, they offer thoughtful insights and suggestions, helping the team to navigate challenges and make informed decisions. Their ability to think critically and propose innovative solutions makes them a valuable asset in problem-solving situations.",
      },
    },
    personalValues: {
      selfDirection: 57,
      stimulation: 57,
      hedonism: 67,
      achievement: 29,
      power: 57,
      security: 57,
      conformity: 67,
      tradition: 57,
      benevolence: 57,
      universalism: 57,
      userPersonalValues: [
        {
          title: 'Empathetic Engagement',
          description:
            "For me, the ability to connect with others on an emotional level is paramount. It's a reflection of my own deep-seated emotionality, and I value the richness that understanding and sharing feelings bring to relationships. It's like weaving a tapestry of human experiences where each thread is a shared moment or a comforting presence in times of need.",
        },
        {
          title: 'Harmony in Diversity',
          description:
            "I envision a society where the mosaic of different perspectives and backgrounds creates a harmonious whole. This aligns with my appreciation for openness and agreeableness, as I believe that embracing our differences can lead to a more vibrant and resilient community. It's about celebrating each unique note while creating a symphony of collective progress.",
        },
        {
          title: 'Joyful Discovery',
          description:
            "The pursuit of happiness through exploration and novelty is something that resonates deeply with me. With my high scores in extroversion and openness, I'm drawn to the invigorating sensation of encountering new ideas, places, and activities. It's like each day is a new chapter in an adventure book, filled with potential for joy and enlightenment.",
        },
        {
          title: 'Authentic Integrity',
          description:
            "Honesty is not just about truthfulness for me; it's about living with authenticity and integrity. It's the cornerstone of how I build trust and maintain meaningful connections with others. I strive to be true to my words and actions, creating a ripple effect of reliability and respect in my wake.",
        },
        {
          title: 'Conscious Collaboration',
          description:
            'The spirit of teamwork and the drive to achieve common goals is something I hold dear. My scores suggest a propensity for cooperative effort and a conscientious approach to responsibilities. I see each collaborative endeavor as a puzzle where every piece is essential, and when fitted together, reveals a masterpiece of collective achievement.',
        },
        {
          title: 'Sustainable Contentment',
          description:
            "I cherish the balance between pleasure and responsibility, which mirrors my hedonistic yet conscientious nature. I believe in finding happiness in the sustainable and ethical pleasures of life, ensuring that my actions today contribute to a better tomorrow for myself and for the world around me. It's about savoring the moment while planting seeds for the future.",
        },
      ],
    },
  },
};
