import {createMutation} from 'react-query-kit';
import {AxiosResponse} from 'axios';
import {deleteTeam} from '../../../repositories/instill';
import {DeleteTeamPayload} from '../../../repositories/instill/mutations/delete-team';
import {useQueryClient} from '@tanstack/react-query';
import {
  RQ_FULL_USER_PROFILE,
  RQ_INFINITE_ELIGIBLE_MANAGERS,
  RQ_TEAMS,
} from '../queries/query-keys';

export const useDeleteTeam = () => {
  const queryClient = useQueryClient();

  return createMutation<AxiosResponse<any, any> | undefined, DeleteTeamPayload>(
    {
      mutationFn: async (variables) => {
        return await deleteTeam({
          companyUuid: variables.companyUuid,
          teamUuid: variables.teamUuid,
        });
      },
      onSuccess() {
        queryClient.invalidateQueries([RQ_TEAMS]);
        queryClient.invalidateQueries([RQ_FULL_USER_PROFILE]);
        queryClient.invalidateQueries([RQ_INFINITE_ELIGIBLE_MANAGERS]);
      },
    }
  )();
};
