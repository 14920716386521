import React, {
  FunctionComponent,
  createContext,
  useContext,
  PropsWithChildren,
} from 'react';
import FormError from '../form-error';
import styles from './styles.module.scss';

interface Props {
  error?: string | null;
  required?: boolean;
  unknownTypeError?: unknown;
  variant?: 'floating-error' | 'columnCenter';
  touched?: boolean;
}

interface Context {
  error?: string | null;
  hasError: boolean;
  required: boolean;
}

const FormControlContext = createContext<Context>({
  required: false,
  hasError: false,
});

export const useFormControlContext = () => useContext(FormControlContext);

const getFinalError = (
  error?: string | null,
  unknownTypeError?: unknown
): string | null => {
  if (!error && !unknownTypeError) {
    return null;
  }

  if (error) {
    return error;
  }

  if (!unknownTypeError) {
    return null;
  }

  if (typeof unknownTypeError === 'string') {
    return unknownTypeError;
  }

  if (typeof unknownTypeError === 'number') {
    return unknownTypeError.toString();
  }

  if (typeof unknownTypeError === 'object') {
    if (Array.isArray(unknownTypeError)) {
      return getFinalError(error, unknownTypeError[0]);
    }

    return getFinalError(error, Object.values(unknownTypeError)?.[0]);
  }

  return JSON.stringify(unknownTypeError);
};

/**
 * @deprecated use FormControlV2
 */
const FormControl: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  error,
  unknownTypeError,
  required = false,
  touched = true,
  variant,
}) => {
  const finalError = getFinalError(error, unknownTypeError);
  const hasError = !!finalError && touched;

  const variantClass = variant ? styles[variant] : '';
  const containerClass = `${styles.container} ${variantClass}`;

  return (
    <FormControlContext.Provider
      value={{error: finalError, hasError, required}}
    >
      <div className={containerClass}>
        {children}

        {hasError && (
          <div className={styles.error}>
            <FormError>{finalError}</FormError>
          </div>
        )}
      </div>
    </FormControlContext.Provider>
  );
};

interface ElementProps {
  error?: unknown;
  required?: boolean;
  variant?: 'floating-error' | 'columnCenter';
  touched: boolean;
}

export const FormControlV2 = ({
  children,
  error,
  required = false,
  touched = false,
  variant,
}: PropsWithChildren<ElementProps>) => {
  const finalError = getFinalError(null, error);
  const hasError = !!finalError && touched;

  const variantClass = variant ? styles[variant] : '';
  const containerClass = `${styles.container} ${variantClass}`;

  return (
    <FormControlContext.Provider
      value={{error: finalError, hasError, required}}
    >
      <div className={containerClass}>
        {children}

        {hasError && (
          <div className={styles.error}>
            <FormError>{finalError}</FormError>
          </div>
        )}
      </div>
    </FormControlContext.Provider>
  );
};

export default FormControl;
