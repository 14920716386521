type Query = {
  key: string;
  condition: boolean;
  value: string | boolean | number | null | undefined;
};

const addUrlQueriesConditionally = (url: string, queries: Query[]) => {
  let queryParams: string[] = [];

  for (let i = 0; i < queries.length; i++) {
    if (queries[i].condition) {
      queryParams.push(
        `${queries[i].key}=${encodeURIComponent(queries[i].value ?? '')}`
      );
    }
  }

  if (queryParams.length === 0) {
    return url;
  }

  return `${url}?${queryParams.join('&')}`;
};

export default addUrlQueriesConditionally;
