import {useQueryClient} from '@tanstack/react-query';
import {createMutation} from 'react-query-kit';
import {UserProfile} from '../../../interfaces/user-profile';
import {updateUserProfileByAdmin} from '../../../repositories/instill/mutations';
import {UpddateUserProfileByAdminPayload} from '../../../repositories/instill/mutations/update-user-profile-by-admin';
import {RQ_COMPANY_EMPLOYEES} from '../queries/query-keys';

export const useUpdateUserProfileByAdmin = () => {
  const queryClient = useQueryClient();

  return createMutation<
    UserProfile | undefined,
    UpddateUserProfileByAdminPayload
  >({
    mutationFn: async (variables) => {
      return await updateUserProfileByAdmin(
        variables.companyEmployeePayload,
        variables.companyUuid,
        variables.userProfileUuid
      );
    },
    onSuccess() {
      queryClient.invalidateQueries([RQ_COMPANY_EMPLOYEES]);
    },
  })();
};
