import {createMutation} from 'react-query-kit';
import {
  updateFlowReportShared,
  UpdateFlowReportSharedPayload,
} from 'repositories/instill/mutations/update-flow-report-shared';
import {useSyncSetFullUserProfile} from '../queries';

export const useUpdateFlowReportShared = () => {
  const syncSetFullUserProfile = useSyncSetFullUserProfile();

  return createMutation<void, UpdateFlowReportSharedPayload>({
    mutationFn: async (variables) => {
      return await updateFlowReportShared({
        companyUuid: variables.companyUuid,
      });
    },
    onSuccess: (_, variables) => {
      syncSetFullUserProfile(
        {
          companyUuid: variables.companyUuid,
        },
        (prev) =>
          prev
            ? {
                ...(prev || {}),
                fullOnboardingChecklist: {
                  ...prev.fullOnboardingChecklist,
                  hasSharedFirstMeetingReport: true,
                },
              }
            : prev
      );
    },
  })();
};
