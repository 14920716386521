import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Dialog from 'ui/@_components/kit/dialog';
import useFlowData from 'ui/app/sense/meetings/show-v2/@hooks/use-flow-data';
import styles from './styles.module.scss';

const MAX_TOPICS_TO_SHOW = 5;

const TopTopics = () => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show-v2.components.meeting-details.components.info-summary.components.top-topics',
  });

  const [showTopicsDialog, setShowTopicsDialog] = useState(false);

  const {meeting} = useFlowData();

  if (!meeting) return <></>;

  const {
    summary: {topics},
  } = meeting;

  const showMoreTopics = topics.length > MAX_TOPICS_TO_SHOW;

  return (
    <>
      <div className={styles.container}>
        <p>{t('title')}</p>

        <ul className={styles.chipContainer}>
          {topics.slice(0, MAX_TOPICS_TO_SHOW).map((topic, index) => (
            <li key={index} className={styles.chip}>
              {topic.topic}
            </li>
          ))}

          {showMoreTopics && (
            <li
              className={styles.showMoreCta}
              onClick={() => {
                setShowTopicsDialog(!showTopicsDialog);
              }}
            >
              +{topics.length - MAX_TOPICS_TO_SHOW}
            </li>
          )}
        </ul>
      </div>

      <Dialog
        isOpen={showTopicsDialog}
        onClose={() => setShowTopicsDialog(false)}
      >
        <Dialog.Header title={t('dialog-title')} />

        <Dialog.Content>
          <ul className={styles.chipDialogContainer}>
            {topics.map((topic, index) => (
              <li key={index} className={styles.chip}>
                {topic.topic}
              </li>
            ))}
          </ul>
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default TopTopics;
