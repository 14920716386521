import {ReminderEmailAssessmentPayload} from 'interfaces/company-assessments';
import {sanitizeCompanyAssessmentMessagePayloadToRaw} from '../../../ui/@sanitizers/company-assessments';
import isResponseSuccessful from '../../../utils/is-response-successful';
import InstillAPI from '../axios-instance';

export interface SendReminderEmailForAssessmentPayload {
  companyUuid: string;
  assessmentUuid: string;
  payload: ReminderEmailAssessmentPayload;
}

export async function sendReminderEmailForAssessment(
  companyUuid: string,
  assessmentUuid: string,
  payload: ReminderEmailAssessmentPayload
) {
  const sanitizedPayload =
    sanitizeCompanyAssessmentMessagePayloadToRaw(payload);

  const response = await InstillAPI.post(
    `/companies/${companyUuid}/assessments/${assessmentUuid}/send-message`,
    sanitizedPayload
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return response;
}
