import {
  AlignmentProfile,
  AlignmentProfileRaw,
  CandidateAlignmentResult,
  CandidateAlignmentResultRaw,
  PersonalityProfile,
  PersonalityProfileRaw,
} from '../../interfaces/candidate-alignment-result';

export const sanitizeCandidateAlignmentResultFromRaw = (
  data: CandidateAlignmentResultRaw
): CandidateAlignmentResult => {
  return {
    candidateResult: {
      id: data.candidate_result.id,
      createdAt: data.candidate_result.created_at,
      updatedAt: data.candidate_result.updated_at,
      moduleName: data.candidate_result.module_name,
      moduleSlug: data.candidate_result.module_slug,
      moduleAlgorithm: data.candidate_result.module_algorithm,
      moduleDescription: data.candidate_result.module_description,
      moduleResult: {
        alignmentProfile: sanitizeAlignmentProfileFromRaw(
          data.candidate_result.module_result.alignment_profile
        ),
        positiveNotes: data.candidate_result.module_result.positive_notes.map(
          (note) => note
        ),
        negativeNotes: data.candidate_result.module_result.negative_notes.map(
          (note) => note
        ),
        personalityProfile: sanitizePersonaityProfileFromRaw(
          data.candidate_result.module_result.personality_profile
        ),
      },
    },
    candidateProfile: {
      candidateUuid: data.candidate_profile.candidate_uuid,
      jobPositionUuid: data.candidate_profile.job_position_uuid,
      jobPositionTitle: data.candidate_profile.job_position_title,
      email: data.candidate_profile.email,
      name: data.candidate_profile.name,
    },
  };
};

const sanitizeAlignmentProfileFromRaw = (
  data: AlignmentProfileRaw
): AlignmentProfile => {
  return {
    header: data.header,
    description: data.description,
    score: data.score,
    valuesAlignment: {
      description: data.values_alignment.description,
      score: data.values_alignment.score,
    },
    personalityAlignment: {
      description: data.personality_alignment.description,
      score: data.personality_alignment.score,
    },
  };
};

const sanitizePersonaityProfileFromRaw = (
  data: PersonalityProfileRaw
): PersonalityProfile => {
  return {
    agreeableness: data.agreeableness,
    conscientiousness: data.conscientiousness,
    emotionality: data.emotionality,
    extroversion: data.extroversion,
    honesty: data.honesty,
    openness: data.openness,
    persona: data.persona,
    personaContent: {
      summary: data.persona_content.summary,
      traits: data.persona_content.traits.map((trait) => trait),
      description: {
        header: data.persona_content.description.header,
        content: data.persona_content.description.content,
      },
      deepDive: {
        header: data.persona_content.description.header,
        content: data.persona_content.description.content,
      },
      whenInAWellFunctioningGroup:
        data.persona_content.when_in_a_well_functioning_group,
      whenOutOfSync: data.persona_content.when_out_of_sync,
    },
    personalValues: {
      selfDirection: data.personal_values.self_direction,
      stimulation: data.personal_values.stimulation,
      hedonism: data.personal_values.hedonism,
      achievement: data.personal_values.achievement,
      power: data.personal_values.power,
      security: data.personal_values.security,
      conformity: data.personal_values.conformity,
      tradition: data.personal_values.tradition,
      benevolence: data.personal_values.benevolence,
      universalism: data.personal_values.universalism,
      userPersonalValues: data.personal_values.user_personal_values.map(
        (personalValue) => personalValue
      ),
    },
  };
};
