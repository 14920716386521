import {createMutation} from 'react-query-kit';
import {
  VisionMissionGetHelpPayload,
  visionMissionGetHelp,
} from 'repositories/instill/mutations/vision-mission-get-help';
import {VisionMissionGetHelp} from 'interfaces/vision-mission-get-help';

export const useVisionMissionGetHelp = createMutation<
  VisionMissionGetHelp | undefined,
  VisionMissionGetHelpPayload
>({
  mutationFn: async (variables) => {
    return await visionMissionGetHelp(variables);
  },
});
