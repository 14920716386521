import TwoPanePage from '../../../@_components/two-pane-page';
import AlreadyLinked from './@components/already-linked';
import CompanyEmailRequired from './@components/company-email-required';
import GenericError from './@components/generic-error';
import InstillRegistrationRequired from './@components/instill-registration-required';
import styles from './styles.module.scss';

interface AuthFeedbackProps {
  errorPayload?:
    | {
        status: 'email_not_verified';
        masked_email: string;
      }
    | {
        status: 'already_linked';
      }
    | {
        status: 'auth0_error';
      }
    | {
        status: 'company_email_required';
      }
    | {
        status: 'instill_registration_required';
      };
}

function renderFeedbackComponent({errorPayload}: AuthFeedbackProps) {
  let component;

  switch (errorPayload?.status) {
    case 'already_linked':
      component = <AlreadyLinked />;
      break;
    case 'auth0_error':
      component = <GenericError />;
      break;
    case 'company_email_required':
      component = <CompanyEmailRequired />;
      break;
    case 'instill_registration_required':
      component = <InstillRegistrationRequired />;
      break;
    default:
      component = <GenericError />;
      break;
  }

  return <div className={styles.feedback}>{component}</div>;
}

function AuthFeedback(props: AuthFeedbackProps) {
  return <TwoPanePage>{renderFeedbackComponent(props)}</TwoPanePage>;
}

export default AuthFeedback;
