import {useQueryClient} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {createMutation} from 'react-query-kit';
import {completeActionStep} from '../../../repositories/instill';
import {CompleteActionStepPayload} from '../../../repositories/instill/mutations/complete-action-step';
import {RQ_USER_ACTION_STEPS} from '../queries/query-keys';

export const useCompleteActionStep = () => {
  const queryClient = useQueryClient();

  return createMutation<
    AxiosResponse<any, any> | undefined,
    CompleteActionStepPayload
  >({
    mutationFn: async (variables) => {
      return await completeActionStep({
        companyUuid: variables.companyUuid,
        actionStepUuid: variables.actionStepUuid,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([RQ_USER_ACTION_STEPS]);
    },
  })();
};
