import {DemoAccountType, demoAccountType} from 'constants/demo-account';
import {
  Meeting,
  MeetingListItem,
  MeetingsAnalysis,
  MeetingsListByVitalSign,
} from 'interfaces/meeting';
import {UserProfile} from 'interfaces/user-profile';

export const dummyMeetings: () => MeetingListItem[] = () => {
  if (demoAccountType === DemoAccountType.CULTURE_FORCE) {
    return [
      {
        duration: 902,
        cultureVitalSignsProcessed: true,
        valuesAlignmentProcessed: true,
        name: 'Culture Force Monthly Strategy & Roadmap',
        participantsNames: [
          'Abbas Mohammed',
          'Dan Kasper',
          'Paras Singhal',
          'Suraj Maharana',
          'Philip Philip',
          'Madhvendra Tiwari',
          'Mathura Das',
          'Bhavesh Navandar',
          'Mitch',
        ],
        startDate: '2024-03-20T17:45:27.711901+00:00',
        uuid: '5a5a3b2a-ad5a-41e2-98f1-c28d94d23c4a',
      },
      {
        duration: 852,
        cultureVitalSignsProcessed: true,
        valuesAlignmentProcessed: true,
        name: 'Culture Force - Daily',
        participantsNames: [
          'Bhavesh Navandar',
          'Mathura Das',
          'Suraj Maharana',
          'Paras Singhal',
          'Madhvendra Tiwari',
          'Philip Philip',
          'Dan Kasper',
        ],
        startDate: '2024-03-19T17:46:37.055280+00:00',
        uuid: '85bcc1db-64e8-4afd-805b-b9e495f20a40',
      },
      {
        duration: 1678,
        name: 'Ashish + Dan - Design Sync',
        cultureVitalSignsProcessed: true,
        valuesAlignmentProcessed: true,
        participantsNames: [
          'Dan Kasper',
          'Fireflies.ai Notetaker Hamet',
          'Abbas Mohammed',
          'Paras Singhal',
          'Suraj M',
          'Madhvendra Tiwari',
          'Bhavesh Navandar',
          'Mitchel Egia',
          'Philip',
          'mathuradas',
        ],
        startDate: '2024-03-18T17:18:01.220720+00:00',
        uuid: 'a91d8b97-22bb-4fc7-8833-40865d332f72',
      },
      {
        duration: 1836,
        cultureVitalSignsProcessed: true,
        valuesAlignmentProcessed: true,
        name: 'Board Meeting - Q1 2024',
        participantsNames: ['Dan Kasper', 'Madhvendra Tiwari'],
        startDate: '2024-03-18T15:22:35.776853+00:00',
        uuid: 'f31d3a08-395f-4ea3-a56e-fb49a71edb0f',
      },
      {
        duration: 965,
        cultureVitalSignsProcessed: true,
        valuesAlignmentProcessed: true,
        name: 'Share Ventures - Investor Meeting',
        participantsNames: ['ayushi ruhela', 'Madhvendra Tiwari'],
        startDate: '2024-03-14T15:01:42.143338+00:00',
        uuid: 'bbf5d30b-5bfe-44ae-ae1e-e6ac61262676',
      },
      {
        duration: 2997,
        cultureVitalSignsProcessed: true,
        valuesAlignmentProcessed: true,
        name: 'Culture Force design meeting',
        participantsNames: [
          'Charlie Davis',
          'Bob Smith',
          'Alice Johnson',
          'Diane Evans',
        ],
        startDate: '2024-03-14T14:02:07.768068+00:00',
        uuid: '61f5ece4-afb5-4eb6-a6f8-c3cd627e89ee',
      },
      {
        duration: 4889,
        cultureVitalSignsProcessed: true,
        valuesAlignmentProcessed: true,
        name: 'Madhvendra + Dan 1:1',
        participantsNames: [
          'Ashish Tripathi',
          'Philip',
          'Hamet Watt',
          'Fireflies.ai Notetaker Hamet',
          'Dan Kasper',
          "Mitch's OtterPilot",
          'Madhvendra Tiwari',
          'Mitchel Egia',
          'Bhavesh Navandar',
          'Abbas Mohammed',
          'mathuradas',
          'Abbas Mohammed',
          'Bhavesh Navandar',
          'Madhvendra Tiwari',
        ],
        startDate: '2024-03-13T17:01:03.551398+00:00',
        uuid: '55808885-1a1f-4441-9412-3fba4bb5ec7e',
      },
    ];
  }

  return [
    {
      duration: 902,
      cultureVitalSignsProcessed: true,
      valuesAlignmentProcessed: true,
      name: 'Instill Monthly Strategy & Roadmap',
      participantsNames: [
        'Abbas Mohammed',
        'Dan Kasper',
        'Paras Singhal',
        'Suraj Maharana',
        'Philip Philip',
        'Madhvendra Tiwari',
        'Mathura Das',
        'Bhavesh Navandar',
        'Mitch',
      ],
      startDate: '2024-03-20T17:45:27.711901+00:00',
      uuid: '5a5a3b2a-ad5a-41e2-98f1-c28d94d23c4a',
    },
    {
      duration: 852,
      cultureVitalSignsProcessed: true,
      valuesAlignmentProcessed: true,
      name: 'Instill - Daily',
      participantsNames: [
        'Bhavesh Navandar',
        'Mathura Das',
        'Suraj Maharana',
        'Paras Singhal',
        'Madhvendra Tiwari',
        'Philip Philip',
        'Dan Kasper',
      ],
      startDate: '2024-03-19T17:46:37.055280+00:00',
      uuid: '85bcc1db-64e8-4afd-805b-b9e495f20a40',
    },
    {
      duration: 1678,
      name: 'Ashish + Dan - Design Sync',
      cultureVitalSignsProcessed: true,
      valuesAlignmentProcessed: true,
      participantsNames: [
        'Dan Kasper',
        'Fireflies.ai Notetaker Hamet',
        'Abbas Mohammed',
        'Paras Singhal',
        'Suraj M',
        'Madhvendra Tiwari',
        'Bhavesh Navandar',
        'Mitchel Egia',
        'Philip',
        'mathuradas',
      ],
      startDate: '2024-03-18T17:18:01.220720+00:00',
      uuid: 'a91d8b97-22bb-4fc7-8833-40865d332f72',
    },
    {
      duration: 1836,
      cultureVitalSignsProcessed: true,
      valuesAlignmentProcessed: true,
      name: 'Board Meeting - Q1 2024',
      participantsNames: ['Dan Kasper', 'Madhvendra Tiwari'],
      startDate: '2024-03-18T15:22:35.776853+00:00',
      uuid: 'f31d3a08-395f-4ea3-a56e-fb49a71edb0f',
    },
    {
      duration: 965,
      cultureVitalSignsProcessed: true,
      valuesAlignmentProcessed: true,
      name: 'Share Ventures - Investor Meeting',
      participantsNames: ['ayushi ruhela', 'Madhvendra Tiwari'],
      startDate: '2024-03-14T15:01:42.143338+00:00',
      uuid: 'bbf5d30b-5bfe-44ae-ae1e-e6ac61262676',
    },
    {
      duration: 2997,
      cultureVitalSignsProcessed: true,
      valuesAlignmentProcessed: true,
      name: 'Instill design meeting',
      participantsNames: [
        'Charlie Davis',
        'Bob Smith',
        'Alice Johnson',
        'Diane Evans',
      ],
      startDate: '2024-03-14T14:02:07.768068+00:00',
      uuid: '61f5ece4-afb5-4eb6-a6f8-c3cd627e89ee',
    },
    {
      duration: 4889,
      cultureVitalSignsProcessed: true,
      valuesAlignmentProcessed: true,
      name: 'Madhvendra + Dan 1:1',
      participantsNames: [
        'Ashish Tripathi',
        'Philip',
        'Hamet Watt',
        'Fireflies.ai Notetaker Hamet',
        'Dan Kasper',
        "Mitch's OtterPilot",
        'Madhvendra Tiwari',
        'Mitchel Egia',
        'Bhavesh Navandar',
        'Abbas Mohammed',
        'mathuradas',
        'Abbas Mohammed',
        'Bhavesh Navandar',
        'Madhvendra Tiwari',
      ],
      startDate: '2024-03-13T17:01:03.551398+00:00',
      uuid: '55808885-1a1f-4441-9412-3fba4bb5ec7e',
    },
  ];
};

export const dummyMeeting: Meeting = {
  customCultureKpi: null,
  cultureVitalSigns: [
    {
      name: 'Innovation',
      slug: 'innovation',
      feedback:
        'The team demonstrates a robust commitment to innovation, shown by their approach to digital marketing, app development, and readiness to address market challenges. Their discussions highlight adaptability, forward-thinking, and an open attitude towards new ideas.',
      examples: [
        {
          quote:
            "I'm curious to explore alternative approaches to our current strategy; what are your thoughts?",
          explanation:
            "Bob's statement cultivates a culture of innovation by encouraging exploration and open-mindedness, fostering creativity and novel ideas.",
        },
        {
          quote:
            "Curiosity is a distraction; let's focus on executing the plan.",
          explanation:
            "Charlie's update stifles innovation by dismissing curiosity, hindering the team's ability to explore new possibilities and approaches.",
        },
        {
          quote:
            'Let’s revisit and reassess our team priorities at the end of the week.',
          explanation:
            "The team's consideration of user feedback fosters innovation by creating an environment that embraces change, allowing the team to adapt and respond creatively to new challenges.",
        },

        {
          quote:
            'I saw that you’re working on that new project, let me know how I can help.',
          explanation:
            "Alice's statement cultivates an innovative environment by fostering effective collaboration and teamwork, leveraging diverse strengths and perspectives.",
        },
      ],
      score: 90,
    },
    {
      name: 'Trust',
      slug: 'trust',
      feedback:
        'The conversation illustrates a high level of trust among team members, characterized by the delegation of responsibilities based on expertise and the open sharing of strategic plans and concerns.',
      examples: [
        {
          quote:
            "I have insights into this market that I looked at last year; I'll share my findings with the team to enhance our strategy",
          explanation:
            ': Bob statement Fosters a collaborative and supportive environment, enhancing trust and team effectiveness.',
        },
        {
          quote:
            "Let's pool our resources and expertise to see what is the best way to solve the problem...",
          explanation:
            "Alice's trust in Charlie to manage the development process.",
        },
        {
          quote:
            "I don't see the need to share my research; it's unrelated to the immediate task.",
          explanation:
            'Charlie undermines collaboration, limiting the flow of information and hindering overall team performance.',
        },
        {
          quote:
            'I’m happy to pick up your portion of the project while you’re out of office.',
          explanation:
            'Bob enhances trust by fostering a positive and supportive team culture, leading to improved collaboration and overall team satisfaction.',
        },
      ],
      score: 88,
    },
    {
      name: 'Psychological Safety',
      slug: 'psychological_safety',
      feedback:
        'The meeting environment promotes psychological safety, with members feeling secure to share updates, raise concerns, and offer feedback without fear of negative repercussions.',
      examples: [
        {
          quote:
            'My apologies, I made a mistake on that report I sent out last night but will send an updated version today.',
          explanation:
            "Alice's statement Promotes a culture of empathy and support, strengthening team bonds and psychological safety by encouraging open expression of vulnerabilities.",
        },
        {
          quote: 'Your new approach didn’t work and it set us back three days.',
          explanation:
            'Bob Inhibits authentic communication and connection, eroding psychological safety by discouraging team members from sharing their challenges and vulnerabilities.',
        },
        {
          quote:
            'Great job on that presentation! Your thorough research and clear communication made a significant impact.',
          explanation:
            "Diane's statement nurtures a culture of continuous improvement and support, enhancing psychological safety and individual development.",
        },
        {
          quote:
            'Truth be told, I made a similar mistake on my project recently.',
          explanation:
            "Charlie's statement cultivates an environment where failures are viewed as learning opportunities, promoting resilience and innovation.",
        },
      ],
      score: 92,
    },
    {
      name: 'Inclusion',
      slug: 'inclusion',
      feedback:
        "The inclusive nature of the meeting is highlighted by the active participation and acknowledgment of each team member's role and input, ensuring diverse perspectives are represented and valued.",
      examples: [
        {
          quote:
            'Your presence here isn’t just welcomed, it’s critical to the success of our team.',
          explanation:
            "Bob's statement cultivates a sense of unity and shared identity, fostering collaboration and creativity within an inclusive team.",
        },
        {
          quote:
            'I saw that you volunteered to take on additional responsibilities for this project. The team sees this and appreciates you.',
          explanation:
            "Charlie's commitment to including user feedback fosters an inclusive culture where every team member feels valued and respected, contributing to a diverse and innovative team dynamic.",
        },
        {
          quote:
            'If you want to fit in here, you should tone down your personality a bit.',
          explanation:
            "Diane's statement undermines authenticity by prioritizing a professional facade over genuine expression, potentially stifling creativity and hindering team bonding.",
        },
        {
          quote:
            'I want to give a shout-out to George and Alice, for their exceptional work on the last project.',
          explanation:
            "Bob's comment enhances team morale and engagement by recognizing and valuing individual contributions, fostering an inclusive and appreciative team culture.",
        },
      ],
      score: 87,
    },
    {
      name: 'Burnout',
      slug: 'burnout',
      feedback:
        'There were no direct indications of burnout in the discussion. The structured approach to task management and the distribution of responsibilities suggest efforts to prevent workload imbalances, which may mitigate burnout risks.',
      examples: [
        {
          quote:
            'I understand life happens, let’s push our meeting to next week.',
          explanation:
            "Alice's comment reduces burnout by promoting a flexible work environment that accommodates individual needs, enhancing overall well-being and work-life balance.",
        },
        {
          quote:
            'Feel free to go to the gym over lunch, I know how much you value your workouts.',
          explanation:
            "Diane's readiness mitigates burnout by prioritizing self-care practices and promoting a culture of well-being within the team, contributing to overall mental and physical health.",
        },
        {
          quote:
            'We still need you at the office if you’re sick, just wear a mask.',
          explanation:
            "Alice's comment increases burnout by neglecting the importance of self-care and well-being, potentially leading to heightened stress and decreased team resilience.",
        },
        {
          quote: 'I get a lot of energy from working on this project.',
          explanation:
            "Bob's comment mitigates burnout by addressing and managing emotional energy within the team, fostering emotional well-being and preventing emotional exhaustion.",
        },
      ],
      score: 95,
    },
  ],
  sentimentalAnalysis: {
    positive: 51,
    neutral: 42,
    negative: 9,
  },
  duration: 2997,
  name: 'Strategic Alignment and Implementation',
  presignedAudioUrl:
    'https://instill-app-api-beta.s3.amazonaws.com/companies/03b3998e-e55c-4544-88e6-015f18af850b/meetings-recordings/61f5ece4-afb5-4eb6-a6f8-c3cd627e89ee.wav?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAQRMCGQ5UIB24J6Q6%2F20240320%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240320T180612Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEAIaCXVzLWVhc3QtMSJGMEQCIHbhBwTbeOeGBKPXfRdPOp7521rJDnEwZuBNKsoDNEVvAiB7ZVJCp5EdLbcqgx66r3CHXwuxpucdcK8Z909Wdu60BSrvAwgaEAIaDDAzNzMxNzE1MDU2OCIMInSuNkKADWwSx0BmKswD7o6jtX0CeP%2FSyC8MKUQ7p47mW67zeNzN005hhkI2slA4jRrenIiFvuMLMeut4SKeZnMnXkp%2FwRXv70JzVkvbqPPMIDk11JmtrlvJ1bm4KPF6kupEuPg%2F1RgRqIaejWU%2F45cts7txAcLAMfcbYsE1HeEUvPC1%2Bh5Kgk8iJvH0ZdjlQP1ERlhKTxQ1kENP9So%2Bjh%2FEylnE0axWxbTxa6A%2FshRFD4yqkvC0fO3UTR04iFvF14Q4gFcmzLfl9CB9nplpW01guwqWbHY79FEmnIvzcfQDjnd%2Bm57CT5OPf61ZLVL%2BfCLK1DoAdXJiZHMZPdvQtSBy4SNL2i0jv5PPUxS8ooGJ%2BnN2TbxKdYY83wSg%2BDwE0qdd4QtULnCV48sFO6qTF05%2FKnlaQ1xCDx93CltLZHypwq927ihc75Sesjvd9%2FrpOSO0gab57Uc5NO3cFKDkIrq%2Fh5B3tkQosyZDm8WolrdatUX%2BjmPyuHK5VhS3WTJ091V2EQKCdZzMjmgzImgsoMgX%2BenD7n%2Bj9RmXaO9xjfnXA7cuaWEhZ68R%2FC3WT75uV36eO3Nqko7GRmPmrNSlXNnmgLzz%2Fc%2BmfRwi4NAnjCiwV%2BObHmBsr1MaszD2ruyvBjqmAZoXYvFUX5iq5XjZ3rAOx8HM8goag8vNE9KRWbDFfM%2B0KvyKTOGgACQxK%2FUFaxT4mKTHOD%2Fs3KfyY45D0Kxr8jrXYo9mJxFt1ZeTMcU1MmvD2C8vWpopTVyh4VmKncQL3fFkylmWlTxmObFN0pEfHatBGD9iMFWAYDv5vH%2B1HmcRWhr30bwNwOb3vovY1dxRf4gFTplm9SUv3hjaB%2BswCJhe2x9u8L4%3D&X-Amz-Signature=aefcf074a27850066374a1ea84bfed24297c74ca2033e48bfa1a214fceb38159',
  participantsNames: [
    'Charlie Davis',
    'Bob Smith',
    'Alice Johnson',
    'Diane Evans',
  ],
  calendarEventParticipants: [
    {
      email: 'charliedavis@gmail.com',
      associatedUser: {
        name: 'Bob Smith',
        picture: '/static/demo-account/people/person3.png',
        uuid: '91f5ece4-afb5-4eb6-a6f8-c3cd627e89ee',
      },
    },
    {
      email: 'charliedavis@gmail.com',
      associatedUser: {
        name: 'Alice Johnson',
        picture: '/static/demo-account/people/person4.png',
        uuid: '91f5ece4-afb5-4eb6-a6f8-c3cd627e89ee',
      },
    },
    {
      email: 'charliedavis@gmail.com',
      associatedUser: {
        name: 'Diane Evans',
        picture: '/static/demo-account/people/person5.png',
        uuid: '91f5ece4-afb5-4eb6-a6f8-c3cd627e89ee',
      },
    },
    {
      email: 'charliedavis@gmail.com',
      associatedUser: {
        name: 'Charlie Davis',
        picture: '/static/demo-account/people/person6.png',
        uuid: '91f5ece4-afb5-4eb6-a6f8-c3cd627e89ee',
      },
    },
  ],
  startDate: '2024-03-14T14:02:07.768068+00:00',
  uuid: '61f5ece4-afb5-4eb6-a6f8-c3cd627e89ee',
  feedback: {
    isPositive: true,
  },
  userProfile: {
    companyUuid: 'ecaa4da6-2f58-4f14-8aa1-2c08ec3787c3',
    fullOnboardingChecklist: {
      hasCompletedProfile: true,
      hasSignedOff: true,
      hasAddedCompanyValues: true,
      hasConfiguredAssessment: true,
      hasCreatedCultureKpi: true,
      hasGeneratedMeetingReport: true,
      hasInvitedCandidates: true,
      hasInvitedFirstUser: true,
      hasSeenFirstMeetingReport: true,
      hasSelectedBusinessOutcomes: true,
      hasSharedFirstMeetingReport: true,
      hasSyncedCalendar: true,
      hasTakenPersonalityTest: true,
    },
    isOnboarded: true,
    funFact: 'eadverhbderfb',
    jobTitle: 'PM',
    kudosRemaining: 20,
    linkedinUrl: null,
    location: 'Delhi, India',
    name: 'Madhvendra Tiwari - PM',
    phoneNumber: '+917073175130',
    picture:
      'https://instill-app-api-qa.s3.amazonaws.com/companies/ecaa4da6-2f58-4f14-8aa1-2c08ec3787c3/user-pictures/959fcee3-930a-4b41-a372-7dd165caeb80.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAQRMCGQ5UK4YRIICO%2F20240418%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240418T065730Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCICsuAcpdmYsw3XHjLlnl92ApVeijOf%2BGVUU4Iyrr87QkAiA8xvUOkv3qD6%2B1c3zhQqkY77VneNJ9RVEZ1uDQrDTfvir0Awjo%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAIaDDAzNzMxNzE1MDU2OCIMHrfMr0anehZTso4MKsgDUQfIlo6gSX%2BrJ6T%2FkZjg3U2ajsbpiZlKf0UlElmOs6qG0WrrL6rLcRruFt4cB4AlclpO9hPYMVA5gMBmUdPjPHKeAfWLPC4Nq9gpA0Y2urHV7QsntRL31XaK%2FAxlvbbIuQwslvYX03QTbMoJ3f6vQxM8xitkn4yti6AleYc2hPEZgOAhgiBLllC4eIrCj8ZV2sEPyBIDEd%2BB8L2NyBXuCumV1pL3t5PnjLr9PVdkBO7WBRxxrIs%2BhKEr3R2QYprq2LIjzhwxripGTnhI5f%2FZTKz0FljuBip6okvPuZad4Uwu%2BGRSWzRg6Lc9JW1xYqeBbJSR3ugC3ITPygTVfrXnkTadyFJ9qho%2FZAq387Sk1q47173IguqNUGZTM8CMYn%2FTwWnoTmGpIYVCOgdOgV9yRu0o9TEtuWeJ7eALt7X9wmP2LrirxvwQCBAwmIGz4%2BkJaSz8pQBrA%2BIMaBgufjRmHghLI3xIKkz9WEe09ilhKtblw6mP3GbdUAZhez%2BHWyN9qMtxbJMQA7l4HhoyoSyB1qj%2BPm%2BsmS%2FHIjaa0WOsWlS2mw4T3nGFN2oc67AhhyV9zAnk4B7KnzwytQTETt50Hi1i0BmNGi66MOb7grEGOqYBXXyvLJBSD%2F7%2Fwq9Myj54ytsyqjtoYL25UEUnO79ot8cFw8ELp9KLmP6Mu2629n2ZJdlFLux%2FzsXnuColrXgzykqgqyhOsTtp3ELEX%2Fs6xa7Nk1xpvpcaTNbDK%2BzQ4haMvOV8%2BMC78ch3aXR%2FYDkUaY3eU2GYydfcUf%2FIyyotK%2F1MsRlzklzlQ7ZT01iTW%2BVc99OQhLqrjYCjcBkIOtTEWDZWzK9tfw%3D%3D&X-Amz-Signature=f082670edc4db7dd4c75513d01084c2355bd82c7beb16cd22c27e80752f7cfb0',
    preferredName: null,
    pronouns: 'he-him',
    role: 'admin',
    shortBio: 'Hello I am Suraj and I am GenZ',
    status: 'joined',
    timeZone: 'Etc/GMT+12',
    uuid: 'efe79ca9-24cc-4747-8039-9247ea28e65f',
    workEmail: 'madhvendra+1@instill.ai',
  } as UserProfile,
  valuesAlignment: [
    {
      name: 'Shared Responsibility',
      slug: 'shared_responsibility',
      feedback:
        "The team showcased a strong sense of shared responsibility, actively engaging in dialogue that demonstrates a collective commitment to the project's success.",
      examples: [
        {
          quote:
            'Certainly, Alice. Our goal is to increase our market share...',
          explanation:
            'Bob Smith demonstrates shared responsibility by outlining the marketing goals.',
        },
        {
          quote:
            "On the product side, we're excited to announce that we're ahead of schedule...",
          explanation:
            'Charlie Davis shares progress, showing a shared commitment to the timeline.',
        },
        {
          quote:
            "From a financial standpoint, we've allocated a significant portion of our budget...",
          explanation:
            'Diane Evans indicates financial planning aligns with shared project goals.',
        },
      ],
      score: 80,
    },
    {
      name: 'Leverage Evidence',
      slug: 'leverage_evidence',
      feedback:
        'The meeting implied decision-making based on strategies and updates, though explicit mentions of data or evidence use were limited.',
      examples: [
        {
          quote:
            "We're prepared for initial fluctuations in revenue as we roll out the new app...",
          explanation:
            'Diane Evans shows financial strategies are based on projections, hinting at evidence use.',
        },
      ],
      score: 55,
    },
    {
      name: 'Teamwork for Transformation',
      slug: 'teamwork_for_transformation',
      feedback:
        'The collaborative spirit of the meeting underscores a team united in transforming their strategic direction through integrated efforts across departments.',
      examples: [
        {
          quote: 'Our goal is to increase our market share...',
          explanation:
            'Bob’s marketing strategy discussion with the team illustrates teamwork aimed at transformation.',
        },
        {
          quote: 'integrating feedback from early users will be crucial...',
          explanation:
            "Charlie's initiative for user feedback integration shows teamwork towards product excellence.",
        },
      ],
      score: 78,
    },
    {
      name: 'Nimble by Design',
      slug: 'nimble_by_design',
      feedback:
        "The team's discussions around adjusting strategies and proactive planning indicate a nimble and adaptable approach to challenges.",
      examples: [
        {
          quote:
            'One concern is the potential pushback from traditional users...',
          explanation:
            "Bob Smith's concern highlights an adaptive strategy for user acceptance.",
        },
        {
          quote:
            "Let's also keep an eye on the market for any sudden shifts...",
          explanation:
            'Diane Evans emphasizes adaptability in financial planning to market changes.',
        },
      ],
      score: 72,
    },
    {
      name: 'Integrity in Action',
      slug: 'integrity_in_action',
      feedback:
        'The professional and constructive communication among team members suggests underlying integrity, though explicit instances related to ethical action or decision-making were not detailed.',
      examples: [
        {
          quote: 'Looking forward to it. Have a great week, everyone.',
          explanation:
            "Bob Smith's courteous closing remarks reflect respect and integrity within the team.",
        },
      ],
      score: 62,
    },
    {
      name: 'Leadership for Tomorrow',
      slug: 'leadership_for_tomorrow',
      feedback:
        "The leaders displayed forward-thinking approaches, considering both immediate and long-term strategies for the company's growth and adaptation.",
      examples: [
        {
          quote: "I'll also prepare a detailed report on market trends...",
          explanation:
            "Bob Smith's initiative to analyze market trends shows a proactive leadership style.",
        },
        {
          quote: "I'll set up a beta testing group.",
          explanation:
            "Charlie Davis' commitment to incorporating user feedback demonstrates leadership focused on continuous improvement.",
        },
      ],
      score: 77,
    },
    {
      name: 'Innovation at the Heart',
      slug: 'innovation_at_the_heart',
      feedback:
        'The meeting contained elements of innovation, particularly in discussions around product development and market strategy, reflecting a core interest in innovative approaches.',
      examples: [
        {
          quote: "we're ahead of schedule on the development of our new app...",
          explanation:
            'Charlie Davis discusses progress with the app, highlighting innovation in product development.',
        },
        {
          quote:
            "I think it's important we also consider potential partnerships...",
          explanation:
            "Bob Smith's suggestion for partnerships suggests looking for innovative ways to enhance campaign reach",
        },
      ],
      score: 77,
    },
  ],
  summary: {
    summary: [
      'Development of a demo account strategy with emphasis on user restrictions and strategic placement of sign-up CTAs.',
      'Discussions about UX strategies led to proposals for innovative design components focused on enhancing the product.',
      'Consideration of component reusability as a design philosophy to streamline the development experience.',
      'Plans outlined for the front end team to proceed with dashboard design and demonstration account unfoldment.',
      'Debate around updating user management flows and invitation prompts for platform testing and usage.',
      'Options explored for both aggressive and subtle approaches to user prompts for subscribing at the end of a trial period.',
      'Adaptability and immediate response to team feedback seen as the primary decision-making method.',
    ],
    topics: [
      {
        topic: 'marketing strategy',
        relevance: 62.7,
      },
      {
        topic: 'product development',
        relevance: 41.35,
      },
      {
        topic: 'financial planning',
        relevance: 35.35,
      },
      {
        topic: 'app development',
        relevance: 33.35,
      },
      {
        topic: 'user experience',
        relevance: 30.35,
      },
      {
        topic: 'market trends',
        relevance: 28.35,
      },
      {
        topic: 'partnerships',
        relevance: 25.35,
      },
    ],
    participantTakeaways: [
      {
        participant: 'Alice Johnson',
        takeaways: [
          'Acknowledged the solid marketing, product, and financial plans for the upcoming quarter.',
          'Emphasized the importance of addressing potential challenges with a strategic approach.',
          'Assigned tasks to ensure the project stays on track and progress is regularly monitored.',
        ],
      },
      {
        participant: 'Bob Smith',
        takeaways: [
          'Presented the marketing strategy focused on targeting the millennial demographic.',
          'Highlighted the need for a phased approach to address potential pushback from traditional users.',
          'Committed to refining the marketing plan and exploring potential partnerships.',
        ],
      },
      {
        participant: 'Charlie Davis',
        takeaways: [
          'Reported that the app development is ahead of schedule, aligning with the marketing strategy.',
          'Stressed the importance of user experience to ensure the app is intuitive for all age groups.',
          'Will establish a beta testing group to gather feedback from early users.',
        ],
      },
      {
        participant: 'Diane Evans',
        takeaways: [
          'Confirmed that the budget supports both the marketing campaign and app development.',
          'Acknowledged the potential for initial revenue fluctuations during the app rollout.',
          'Will closely monitor the budget and provide flexible financial strategies to adapt to market changes.',
        ],
      },
    ],
  },
};

export const dummyMeetingsListByVitalSign: MeetingsListByVitalSign = {
  lastFourMeetings: [],
  meetingsAddedByUser: [],
};

export const dummyMeetingsAnalysis: MeetingsAnalysis = {
  pastDay: {
    count: 76,
    avgAnalysis: {
      positive: 80,
      negative: 15,
      neutral: 5,
    },
  },
  pastWeek: {
    count: 100,
    avgAnalysis: {
      positive: 80,
      negative: 10,
      neutral: 10,
    },
  },
  pastMonth: {
    count: 200,
    avgAnalysis: {
      positive: 70,
      negative: 20,
      neutral: 10,
    },
  },
};
