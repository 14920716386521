import {MeetingsListByVitalSign} from 'interfaces/meeting';
import {createQuery} from 'react-query-kit';
import {
  FetchMeetingsListByVitalSignsPayload,
  fetchMeetingsListByVitalSign,
} from 'repositories/instill/queries/fetch-meetings-list-by-vital-signs';
import {RQ_MEETINGS_LIST_BY_VITAL_SIGN} from './query-keys';
import queryDemoWrapper from 'utils/query-utils/query-demo-wraper';
import {dummyMeetingsListByVitalSign} from 'ui/@demo-data/meeting';

export const useMeetingsListByVitalSign = createQuery<
  MeetingsListByVitalSign | undefined,
  FetchMeetingsListByVitalSignsPayload
>({
  primaryKey: RQ_MEETINGS_LIST_BY_VITAL_SIGN,
  queryFn: queryDemoWrapper(async ({queryKey: [_, variables]}) => {
    return await fetchMeetingsListByVitalSign({
      companyUuid: variables.companyUuid,
      vitalSign: variables.vitalSign,
      teamUuid: variables.teamUuid,
    });
  }, dummyMeetingsListByVitalSign),
  enabled(data, variables) {
    return !!variables.companyUuid;
  },
});
