import {CandidateProfile} from '../../../interfaces/candidate';
import {createMutation} from 'react-query-kit';
import {updateCandidateProfile} from '../../../repositories/instill';
import {UpdateCandidateProfilePayload} from '../../../repositories/instill/mutations/update-candidate-profile';
import {useQueryClient} from '@tanstack/react-query';
import {RQ_CANDIDATE_PROFILE} from '../queries/query-keys';

export const useUpdateCandidateProfile = () => {
  const queryClient = useQueryClient();

  return createMutation<
    CandidateProfile | undefined,
    UpdateCandidateProfilePayload
  >({
    mutationFn: async (variables) => {
      return await updateCandidateProfile({
        candidateToken: variables.candidateToken,
        name: variables.name,
      });
    },
    onSuccess: (data) => {
      queryClient.refetchQueries([RQ_CANDIDATE_PROFILE]);
    },
  })();
};
