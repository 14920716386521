import actionSteps from './action-steps.json';
import adminPortal from './admin-portal.json';
import applicationPageTitle from './application-page-title.json';
import application from './application.json';
import apply from './apply.json';
import assessmentSummary from './assessment-summary.json';
import assessmentsPolls from './assessments-polls.json';
import assessments from './assessments.json';
import authFeedback from './auth-feedback.json';
import candidateCalibration from './candidate-calibration.json';
import candidates from './candidates.json';
import common from './common.json';
import components from './components.json';
import culture from './culture.json';
import dashboard from './dashboard.json';
import design from './design.json';
import emailVerified from './email-verified.json';
import hooks from './hooks.json';
import instillCoach from './instill-coach.json';
import invite from './invite.json';
import jobs from './jobs.json';
import kudos from './kudos.json';
import linkSlack from './link-slack.json';
import measure from './measure.json';
import moduleComponents from './module-components.json';
import moduleResultFeedback from './module-result-feedback.json';
import myCompanyProfile from './my-company-profile.json';
import myProfile from './my-profile.json';
import oauthCallback from './oauth-callback.json';
import organizationSetup from './organization-setup.json';
import pageNotFound from './page-not-found.json';
import peopleTeams from './people-teams.json';
import people from './people.json';
import plans from './plans.json';
import registerCompany from './register-company.json';
import selectCompany from './select-company.json';
import sense from './sense.json';
import settings from './settings.json';
import signup from './signup.json';
import userSetup from './user-setup.json';
import values from './values.json';
import gettingStarted from './getting-started.json';

const translations = {
  plans,
  registerCompany,
  assessmentsPolls,
  actionSteps,
  adminPortal,
  application,
  applicationPageTitle,
  gettingStarted,
  apply,
  assessments,
  assessmentSummary,
  authFeedback,
  candidates,
  common,
  components,
  dashboard,
  design,
  invite,
  jobs,
  kudos,
  linkSlack,
  measure,
  moduleComponents,
  moduleResultFeedback,
  myCompanyProfile,
  myProfile,
  organizationSetup,
  pageNotFound,
  people,
  selectCompany,
  instillCoach,
  sense,
  settings,
  signup,
  userSetup,
  values,
  culture,
  candidateCalibration,
  peopleTeams,
  emailVerified,
  oauthCallback,
  hooks,
} as const;

export default translations;
