import {
  CalendarEventParticipants,
  CalendarEventParticipantsRaw,
  CultureVitalSign,
  CultureVitalSignRaw,
  InviteSense,
  InviteSenseRaw,
  Meeting,
  MeetingFeedback,
  MeetingFeedbackRaw,
  MeetingListItem,
  MeetingListItemRaw,
  MeetingRaw,
  MeetingSummary,
  MeetingSummaryRaw,
  MeetingTopics,
  MeetingTopicsRaw,
  MeetingsAnalysis,
  MeetingsAnalysisDetail,
  MeetingsAnalysisDetailRaw,
  MeetingsAnalysisRaw,
  MeetingsListByVitalSign,
  MeetingsListByVitalSignRaw,
  SentimentalAnalysis,
  SentimentalAnalysisRaw,
  ValueAlignment,
  ValueAlignmentRaw,
} from '../../interfaces/meeting';
import {sanitizeUserProfileFromRaw} from './user-profile';

export const sanitizeMeetingsFromRaw = (
  data: MeetingListItemRaw[]
): MeetingListItem[] => {
  return data.map((meeting) => sanitizeMeetingListItemFromRaw(meeting));
};

export const sanitizeMeetingListItemFromRaw = (
  data: MeetingListItemRaw
): MeetingListItem => {
  return {
    duration: data.duration,
    name: data.name,
    participantsNames: data.participants_names,
    startDate: data.start_date,
    uuid: data.uuid,
    valuesAlignmentProcessed: data.values_alignment_processed,
    cultureVitalSignsProcessed: data.culture_vital_signs_processed,
  };
};

export const sanitizeMeetingFromRaw = (data: MeetingRaw): Meeting => {
  return {
    cultureVitalSigns: data.culture_vital_signs.map((cultureVitalSign) =>
      sanitizeCultureVitalSignsFromRaw(cultureVitalSign)
    ),
    customCultureKpi: !!data.custom_culture_kpi?.length
      ? data.custom_culture_kpi.map(
          (customCultureKpi) =>
            sanitizeCultureVitalSignsFromRaw(customCultureKpi) || null
        )
      : null,
    sentimentalAnalysis: sanitizeSentimentalAnalysisFromRaw(
      data.sentimental_analysis
    ),
    duration: data.duration,
    name: data.name,
    presignedAudioUrl: data.presigned_audio_url,
    participantsNames: data.participants_names,
    startDate: data.start_date,
    uuid: data.uuid,
    feedback: data.feedback
      ? sanitizeMeetingFeedbackFromRaw(data.feedback)
      : null,
    valuesAlignment: data.values_alignment.map((value) =>
      sanitizeValuesAlignmentFromRaw(value)
    ),
    userProfile: sanitizeUserProfileFromRaw(data.user_profile),
    summary: sanitizeMeetingSummaryFromRaw(data.summary),
    calendarEventParticipants: sanitizeCalendarEventParticipantsFromRaw(
      data.calendar_event_participants
    ),
  };
};

export const sanitizeCultureVitalSignsFromRaw = (
  data: CultureVitalSignRaw
): CultureVitalSign => {
  return {
    examples: data.examples,
    feedback: data.feedback,
    name: data.name,
    score: data.score,
    slug: data.slug,
  };
};

export const sanitizeMeetingSummaryFromRaw = (
  data: MeetingSummaryRaw
): MeetingSummary => {
  return {
    summary: data.summary,
    topics: data.topics.map((topic) => sanitizeTopicsFromRaw(topic)),
    participantTakeaways:
      data.participant_takeaways?.map((participant) => {
        return {
          participant: participant.participant,
          takeaways: participant.takeaways,
        };
      }) || null,
  };
};

export const sanitizeSentimentalAnalysisFromRaw = (
  data: SentimentalAnalysisRaw
): SentimentalAnalysis => {
  return {
    positive: data?.positive,
    neutral: data?.neutral,
    negative: data?.negative,
  };
};

export const sanitizeTopicsFromRaw = (
  data: MeetingTopicsRaw
): MeetingTopics => {
  return {
    topic: data.topic,
    relevance: data.relevance,
  };
};

export const sanitizeValuesAlignmentFromRaw = (
  data: ValueAlignmentRaw
): ValueAlignment => {
  return {
    examples: data.examples,
    feedback: data.feedback,
    name: data.name,
    score: data.score,
    slug: data.slug,
  };
};

export const sanitizeInviteSenseForRaw = (
  data: InviteSense
): InviteSenseRaw => {
  return {
    meeting_url: data.meetingUrl,
  };
};

export const sanitizeInviteSenseFromRaw = (
  data: InviteSenseRaw
): InviteSense => {
  return {
    meetingUrl: data.meeting_url,
  };
};

export const sanitizeMeetingFeedbackFromRaw = (
  data: MeetingFeedbackRaw
): MeetingFeedback => {
  return {
    isPositive: data.is_positive,
  };
};

export const sanitizeMeetingFeedbackForRaw = (
  data: MeetingFeedback
): MeetingFeedbackRaw => {
  return {
    is_positive: data.isPositive,
  };
};

export const sanitizeMeetingsAnalysisFromRaw = (
  data: MeetingsAnalysisRaw
): MeetingsAnalysis => ({
  pastDay: sanitizeMeetingsAnalysisDetail(data.past_day),
  pastMonth: sanitizeMeetingsAnalysisDetail(data.past_month),
  pastWeek: sanitizeMeetingsAnalysisDetail(data.past_week),
});

export const sanitizeMeetingsAnalysisDetail = (
  data: MeetingsAnalysisDetailRaw
): MeetingsAnalysisDetail => ({
  avgAnalysis: {
    negative: data.avg_analysis.negative,
    neutral: data.avg_analysis.neutral,
    positive: data.avg_analysis.positive,
  },
  count: data.count,
});

export const sanitizeMeetingsListByVitalSignFromRaw = (
  data: MeetingsListByVitalSignRaw
): MeetingsListByVitalSign => {
  return {
    lastFourMeetings: data.last_four_meetings.map((meeting) => {
      return {
        id: meeting.id,
        createdAt: meeting.created_at,
        updatedAt: meeting.updated_at,
        uuid: meeting.uuid,
        duration: meeting.duration,
        startDate: meeting.start_date,
        participantsCount: meeting.participants_count,
        platform: meeting.platform,
        name: meeting.name,
        participantsNames: meeting.participants_names,
      };
    }),
    meetingsAddedByUser: data.meetings_added_by_user,
  };
};

const sanitizeCalendarEventParticipantsFromRaw = (
  data: CalendarEventParticipantsRaw[]
): CalendarEventParticipants[] => {
  return data?.map((participant) => {
    return {
      email: participant.email,
      associatedUser: participant.associated_user
        ? {
            name: participant.associated_user.name,
            picture: participant.associated_user.picture,
            uuid: participant.associated_user.uuid,
          }
        : null,
    };
  });
};
