import {Route, Routes} from 'react-router';
import GoogleCalendarCallback from './google-calendar';
import MicrosoftOutlookCallback from './microsoft-outlook';
import {FunctionComponent} from 'react';
import {routeNames} from 'constants/route-names';

const OAuthCallback: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path={routeNames.googleCalendarCallbackRoute}
        element={<GoogleCalendarCallback />}
      />
      <Route
        path={routeNames.microsoftOutlookCallbackRoute}
        element={<MicrosoftOutlookCallback />}
      />
    </Routes>
  );
};

export default OAuthCallback;
