import {QueryFunction} from '@tanstack/react-query';
import {DEMO_ACCOUNT_TYPE} from 'constants/local-storage-keys';

const queryDemoWrapper = <T extends Object | undefined, U>(
  queryFn: QueryFunction<T, [string, U]>,
  demoDataFn: T | QueryFunction<T, [string, U]>
) => {
  if (sessionStorage.getItem(DEMO_ACCOUNT_TYPE)) {
    if (typeof demoDataFn === 'function') {
      return demoDataFn;
    }

    return () => demoDataFn;
  }

  return queryFn;
};

export default queryDemoWrapper;
