import {
  ValuesGraphData,
  ValuesGraphDataRaw,
  ValuesGraphItem,
  ValuesGraphItemRaw,
  CultureGraphItem,
  CultureGraphItemRaw,
  VitalSignsItem,
  VitalSignsItemRaw,
  VitalSignsGraphDataRaw,
  VitalSignsGraphData,
} from 'interfaces/graph';

export const sanitizeCultureGraphFromRaw = (
  data: CultureGraphItemRaw[]
): CultureGraphItem[] => {
  return data.map((cultureGraphItem) =>
    sanitizeCultureGraphItemFromRaw(cultureGraphItem)
  );
};

export const sanitizeCultureGraphItemFromRaw = (
  data: CultureGraphItemRaw
): CultureGraphItem => {
  return {
    dataFor: data.data_for,
    combinedAverage: data.combined_average,
    customCultureKpiAverage: data.custom_culture_kpi_avg,
    valuesAverage: data.culture_value_avg,
    vitalSignAverage: data.vital_sign_avg,
  };
};

export const sanitizeValuesGraphFromRaw = (
  data: ValuesGraphItemRaw[]
): ValuesGraphItem[] => {
  return data.map((valuesGraphItem) =>
    sanitizeValuesGraphItemFromRaw(valuesGraphItem)
  );
};

export const sanitizeValuesGraphItemFromRaw = (
  data: ValuesGraphItemRaw
): ValuesGraphItem => {
  return {
    valueUuid: data.value_uuid,
    graphData: data.graph_data.map((graphDataItem) =>
      sanitizeValuesGraphDataItemFromRaw(graphDataItem)
    ),
  };
};

export const sanitizeValuesGraphDataItemFromRaw = (
  data: ValuesGraphDataRaw
): ValuesGraphData => {
  return {
    dataFor: data.data_for,
    cultureValueScore: data.culture_value_score,
  };
};

export const sanitizeVitalSignsGraphFromRaw = (
  data: VitalSignsItemRaw[]
): VitalSignsItem[] => {
  return data.map((vitalSignsItem) =>
    sanitizeVitalSignsGraphItemFromRaw(vitalSignsItem)
  );
};

export const sanitizeVitalSignsGraphItemFromRaw = (
  data: VitalSignsItemRaw
): VitalSignsItem => {
  return {
    slug: data.slug,
    graphData: data.graph_data.map((graphDataItem) =>
      sanitizeVitalSignsGraphDataItemFromRaw(graphDataItem)
    ),
  };
};

export const sanitizeVitalSignsGraphDataItemFromRaw = (
  data: VitalSignsGraphDataRaw
): VitalSignsGraphData => ({
  vitalSignScore: data.vital_sign_score,
  dataFor: data.data_for,
});
