import Button from 'ui/@_components/button';
import styles from './styles.module.scss';
import InstillLogo from 'assets/images/logo.png';
import useInDesktop from 'ui/@hooks/use-in-desktop';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

const GeneralFooter = () => {
  const {t} = useTranslation('application', {
    keyPrefix: 'components.general-footer',
  });

  const inDesktop = useInDesktop();

  const renderLogo = () => {
    return (
      <Link
        to="https://instill.ai/"
        target="_blank"
        className={styles.logoContainer}
      >
        <img src={InstillLogo} className={styles.icon} alt="logo" />
      </Link>
    );
  };

  const renderButtons = () => {
    return (
      <div className={styles.leftButtons}>
        <Button
          variant="tertiaryInversed"
          as="link"
          to="https://instill.ai/features"
          target="_blank"
        >
          {t('what-is-instill')}
        </Button>

        <Button
          variant="tertiaryInversed"
          as="link"
          to="https://instill.ai/privacy-policy"
          target="_blank"
        >
          {t('privacy-policy')}
        </Button>

        <Button
          variant="tertiaryInversed"
          as="link"
          to="https://instill.ai/terms-of-service"
          target="_blank"
        >
          {t('terms')}
        </Button>
      </div>
    );
  };

  const renderPoweredBy = () => {
    return (
      <p className={styles.poweredBy}>
        {t('powered', {
          year: new Date().getFullYear(),
        })}
      </p>
    );
  };

  if (inDesktop) {
    return (
      <div className={styles.assessmentsFooter}>
        <div className={styles.footerLeft}>
          {renderLogo()}
          {renderButtons()}
        </div>

        {renderPoweredBy()}
      </div>
    );
  }

  return (
    <div className={`${styles.footer} ${styles.assessmentsFooter}`}>
      {renderButtons()}

      <div className={styles.footerBottom}>
        {renderLogo()}
        {renderPoweredBy()}
      </div>
    </div>
  );
};

export default GeneralFooter;
